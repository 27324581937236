import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	styled,
	Box,
} from '@mui/material';
import { ComponentProps, ReactNode } from 'react';

export type PrizeTableProps = {
	prizes: PrizeTablePrize[];
	chunkyBorder?: boolean;
	footer?: ReactNode;
};

export type PrizeTablePrize = {
	id: string;
	points: number;
	name: string;
};

export function PrizeTable({ prizes, chunkyBorder, footer }: PrizeTableProps) {
	return (
		<Wrapper chunkyBorder={chunkyBorder}>
			<Container chunkyBorder={chunkyBorder}>
				<Table>
					<colgroup>
						<col style={{ width: '25%' }} />
						<col style={{ width: '75%' }} />
					</colgroup>
					<TableHead>
						<TableRow>
							<PointCell>Points</PointCell>
							<TableCell>Prizes</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{prizes.map(({ id, points, name }) => (
							<TableRow key={id}>
								<PointCell>{points}</PointCell>
								<TableCell>{name}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Container>
			{footer}
		</Wrapper>
	);
}

const Wrapper = styled(Box, {
	shouldForwardProp: propName => propName !== 'chunkyBorder',
})<ComponentProps<typeof Box> & { chunkyBorder?: boolean }>(
	({ theme, chunkyBorder }) => ({
		borderRadius: 20,
		overflow: 'hidden',
		borderStyle: 'solid',
		borderWidth: chunkyBorder ? 10 : 5,
		borderColor: theme.palette.grey[200],
		backgroundColor: chunkyBorder
			? theme.palette.grey[200]
			: theme.palette.common.white,
	})
);

const Container = styled(TableContainer, {
	shouldForwardProp: propName => propName !== 'chunkyBorder',
})<ComponentProps<typeof TableContainer> & { chunkyBorder?: boolean }>(
	({ theme, chunkyBorder }) => ({
		paddingTop: 12,
		paddingBottom: chunkyBorder ? 21 : 11,
		paddingLeft: chunkyBorder ? 10 : 13,
		paddingRight: chunkyBorder ? 8 : 15,
		backgroundColor: theme.palette.common.white,
	})
);

const PointCell = styled(TableCell)({
	textAlign: 'center',
});
