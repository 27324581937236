import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { Prizes } from '../../../../api/Prizes';
import { PrizeEditorView, PrizeEditorViewProps } from './PrizeEditorView';

export type CreatePrizeEditorProps = {
	prizesClient?: Prizes;
	onCloseWindowClick: () => void;
	onPrizeCreated?: () => void;
	onPrizeCreationError?: (e: AxiosError) => void;
};

export function CreatePrizeEditor({
	prizesClient = new Prizes(),
	onPrizeCreated,
	onPrizeCreationError,
	onCloseWindowClick,
}: CreatePrizeEditorProps) {
	const [saving, setSaving] = useState(false);
	const handleSubmit: NonNullable<PrizeEditorViewProps['onSubmit']> =
		useCallback(values => {
			setSaving(true);
			prizesClient
				.createPrize(values)
				.then(() => {
					if (onPrizeCreated) onPrizeCreated();
				})
				.catch(e => {
					if (onPrizeCreationError) onPrizeCreationError(e);
				})
				.finally(() => setSaving(false));
		}, []);

	return (
		<PrizeEditorView
			saving={saving}
			processing={saving}
			onSubmit={handleSubmit}
			onCloseWindowClick={onCloseWindowClick}
			editing
		/>
	);
}
