/* eslint-disable react/no-array-index-key */
import { Grid, Box, Button, Typography, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useCallback, useMemo, useState } from 'react';
import {
	Dialog,
	DialogProps,
	DialogCloseReason,
} from '../../../../components/Dialog';
import { PrizeTable } from '../../../../components/PrizeTable';
import { usePrizes } from '../../../../hooks/usePrizes';
import { PrizesProvider } from '../../../../context/Prizes';
import { useRedemptionRequestsController } from '../../RedemptionRequestsController';
import { PrizeRedemptionConfirmation } from '../../../Dashboard/components/PrizeRedemptionConfirmation';
import { Can } from '../../../../components/Can';
import { Action, ActionSubject } from '../../../../utils/enums/abilities';
import { DateTimeFormatter } from '../../../../utils/DateTimeFormatter';
import { usePrizeRedemptionDetails } from '../../../../hooks/usePrizeRedemptionDetails';
import { RedemptionRequest } from '../../../../types/RedemptionRequest';
import { pluralOrSingularPoints } from '../../../../utils/helpers/pluralOrSingularPoints';

// eslint-disable-next-line no-shadow
enum DialogViews {
	approval = 'approval',
	confirmation = 'confirmation',
}

const DEFAULT_VIEW = DialogViews.approval;

type RequestDialogProps = Omit<DialogProps, 'onClose'> & {
	onClose: (reason: DialogCloseReason) => void;
	prizeRedemptionId?: string;
};

export function RequestDialog({
	onClose,
	prizeRedemptionId,
	...props
}: RequestDialogProps) {
	const { refetch } = useRedemptionRequestsController();
	const [redemptionUpdated, setRedemptionUpdated] = useState<boolean>(false);
	const handleClose = useCallback(() => {
		setRedemptionUpdated(false);
		if (onClose) onClose(DialogCloseReason.CloseButton);
	}, [onClose]);

	const view = useMemo(() => {
		if (redemptionUpdated) return DialogViews.confirmation;
		return DEFAULT_VIEW;
	}, [redemptionUpdated]);

	const handleRedemptionStatusUpdate = useCallback(() => {
		setRedemptionUpdated(true);
		refetch();
	}, []);

	return (
		<Dialog title='Badge Points Redemption' {...props} onClose={handleClose}>
			<PrizesProvider>
				{view === DialogViews.approval ? (
					<RequestDialogView
						prizeRedemptionId={prizeRedemptionId}
						handleRedemptionStatusUpdate={handleRedemptionStatusUpdate}
						onClose={handleClose}
					/>
				) : (
					<PrizeRedemptionConfirmation onContinue={handleClose} />
				)}
			</PrizesProvider>
		</Dialog>
	);
}

type RequestDialogViewProps = {
	onClose: () => void;
	prizeRedemptionId?: string;
	handleRedemptionStatusUpdate: () => void;
};

export function RequestDialogView({
	onClose,
	prizeRedemptionId,
	handleRedemptionStatusUpdate,
}: RequestDialogViewProps) {
	const { prizes, loading: fetchingPrizes } = usePrizes();

	const usePrizeRedemptionDetailsConfig = useMemo(
		() => ({
			onRedemptionStatusUpdate: handleRedemptionStatusUpdate,
		}),
		[handleRedemptionStatusUpdate]
	);
	const { redemption, updatingStatus, approveRedemptionRequest } =
		usePrizeRedemptionDetails(prizeRedemptionId, usePrizeRedemptionDetailsConfig);

	const prizeEntries = useMemo(
		() => (redemption ? prizeEntriesForDisplay(redemption.prizes) : []),
		[redemption]
	);

	return (
		<Box>
			<Grid spacing={8} container>
				<Grid item>
					{!fetchingPrizes && <PrizeTable prizes={prizes} chunkyBorder />}
				</Grid>
				<Grid sx={{ minWidth: '400px' }} item>
					<Typography variant='body2' gutterBottom>
						<>
							<b>DATE:</b> {DateTimeFormatter.format(redemption?.dateSent)}
						</>
					</Typography>
					<Typography variant='body2' gutterBottom>
						<>
							<b>EMPLOYEE:</b> {redemption?.from}
						</>
					</Typography>
					<Typography
						pt={5}
						pb={1}
						variant='body2'
						textTransform='uppercase'
						gutterBottom
					>
						<b>Request:</b>
					</Typography>
					{Boolean(redemption?.charity) && (
						<CharityFlag>Donate value to charity</CharityFlag>
					)}
					{prizeEntries.map((prize, i) => (
						<Prize key={i} variant='body2'>
							<>
								<b style={{ textTransform: 'capitalize' }}>
									{prize.points} {pluralOrSingularPoints(prize.points)} -
								</b>{' '}
								{prize.name}
							</>
						</Prize>
					))}
				</Grid>
			</Grid>

			<Grid pt={5} justifyContent='center' spacing={2} container>
				<Grid item>
					<Button variant='contained' color='secondary' onClick={onClose}>
						Close Window
					</Button>
				</Grid>
				{redemption?.confirmed === false && (
					<Can do={Action.Approve} on={ActionSubject.PrizeRedemption}>
						<Grid item>
							<LoadingButton
								variant='contained'
								loading={updatingStatus}
								onClick={approveRedemptionRequest}
							>
								Approve Redemption
							</LoadingButton>
						</Grid>
					</Can>
				)}
			</Grid>
		</Box>
	);
}

function prizeEntriesForDisplay(redemptionPrizes: RedemptionRequest['prizes']) {
	const result: RedemptionRequest['prizes'] = [];

	redemptionPrizes.forEach(prize => {
		for (let i = 0; i < prize.quantity; i += 1) {
			result.push(prize);
		}
	});

	return result;
}

const CharityFlag = styled(Typography)(({ theme }) => ({
	width: '100%',
	padding: '4px 8px',
	marginBottom: '20px',
	color: '#FF0000',
	textTransform: 'uppercase',
	backgroundColor: '#F26A2533',
	fontWeight: theme.typography.fontWeightBold,
}));

const Prize = styled(Typography)({
	backgroundColor: '#F8F8F8',
	width: '100%',
	padding: '8px 12px',
	marginBottom: '2px',
});
