import { useMemo } from 'react';
import { Colors } from '../../api/Colors';
import { useApiCall } from '../useApiCall';

export type UseOwnUserBadgesConfig = {
	colorsClient?: Colors;
	onRejection?: (reason: any) => void;
};

const DEFAULT_CONFIG = {};

export function useColors(config: UseOwnUserBadgesConfig = DEFAULT_CONFIG) {
	const apiClient = useMemo(() => config.colorsClient ?? new Colors(), []);
	const useApiCallConfig = useMemo(
		() => ({
			onRejection: config.onRejection,
		}),
		[config]
	);
	const params: [] = useMemo(() => [], []);
	const { data: colors = [], loading } = useApiCall(
		apiClient.getAllColors,
		params,
		useApiCallConfig
	);

	return {
		colors,
		loading,
	};
}
