import {
	Button,
	FormControlLabel,
	MenuItem,
	TextField,
	TextFieldProps,
	Typography,
	styled,
} from '@mui/material';
import { Formik, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import {
	ChangeEvent,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
} from 'react';
import { Prize } from '../../../../types/Prize';
import { singularOrPlural } from '../../../../utils/singularOrPlural';
import { validationSchema } from './schema';
import {
	INITIAL_VALUES,
	NO_PRIZE_SELECTED_MESSAGE,
	DEFAULT_SELECTED_PRIZE,
} from './PrizeRedemptionForm.config';
import { FormValues } from './PrizeRedemptionForm.type';
import { OverridableComponentProps } from '../../../../utils/types';
import { Checkbox } from '../../../../components/Checkbox';

export type PrizeRedemptionFormProps = FormProps & {
	onSubmit: (payload: onSubmitPayload) => void;
};

type onSubmitPayload = {
	selectedPrizeIds: string[];
	donateToCharity: boolean;
};

const FormContext = createContext<FormProps | undefined>(undefined);

function useFormContext() {
	const ctx = useContext(FormContext);
	if (!ctx)
		throw new Error('useFormContext() needs to be called within a FormContext');
	return ctx;
}

export function PrizeRedemptionForm({
	prizes,
	onSubmit,
}: PrizeRedemptionFormProps) {
	const value: FormProps = useMemo(
		() => ({
			prizes,
		}),
		[prizes]
	);

	const handleSubmit = useCallback(
		(values: FormValues) => {
			onSubmit({
				selectedPrizeIds: validPrizeIds(values.prizes),
				donateToCharity: values.donateToCharity,
			});
		},
		[onSubmit]
	);

	return (
		<FormContext.Provider value={value}>
			<Formik<FormValues>
				initialValues={INITIAL_VALUES}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				component={Form}
			/>
		</FormContext.Provider>
	);
}

type FormProps = {
	prizes: Prize[];
};

function Form({
	values,
	isValid,
	isSubmitting,
	isValidating,
	getFieldMeta,
	setFieldValue,
	getFieldProps,
	handleSubmit,
}: FormikProps<FormValues>) {
	const { prizes } = useFormContext();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (!isValid && !isValidating && isSubmitting) {
			enqueueSnackbar(NO_PRIZE_SELECTED_MESSAGE);
		}
	}, [isSubmitting, isValid, isValidating]);

	const canDonate = useMemo(() => {
		const selectedIds = validPrizeIds(values.prizes);

		if (!selectedIds.length) return false;

		return prizes
			.filter(prize => selectedIds.includes(prize.id))
			.every(({ donable }) => Boolean(donable));
	}, [values.prizes]);

	const prizeFieldHandler = useCallback(
		(field: string) => (e: ChangeEvent<HTMLInputElement>) => {
			const prizeId = e.target.value;
			const prize = prizes.find(({ id }) => prizeId === id);

			setFieldValue(field, prizeId);
			if (!prize?.donable) setFieldValue('donateToCharity', false);
		},
		[prizes]
	);

	const getFieldFeedbackProps = useCallback(
		(field: string) => {
			const { error, touched } = getFieldMeta(field);
			const hasError = touched && Boolean(error);

			return {
				error: hasError,
				helperText: hasError ? error : undefined,
			};
		},
		[getFieldMeta]
	);

	return (
		<Wrapper onSubmit={handleSubmit}>
			<CallToAction component='span'>Select up to 2</CallToAction>
			<PrizeDropdown
				SelectProps={{
					inputProps: {
						'aria-label': 'Prize 1',
					},
				}}
				{...getFieldProps('prizes[0]')}
				onChange={prizeFieldHandler('prizes[0]')}
				{...getFieldFeedbackProps('prizes')}
				prizes={prizes}
				select
			/>
			<PrizeDropdown
				SelectProps={{
					inputProps: {
						'aria-label': 'Prize 2',
					},
				}}
				{...getFieldProps('prizes[1]')}
				onChange={prizeFieldHandler('prizes[1]')}
				{...getFieldFeedbackProps('prizes')}
				prizes={prizes}
				select
			/>
			<DonateToCharityControl
				label="Donate value to Company's Charity"
				control={<Checkbox {...getFieldProps('donateToCharity')} />}
				disabled={!canDonate}
			/>
			<SubmitButton type='submit' variant='contained'>
				Submit Request To Redeem
			</SubmitButton>
		</Wrapper>
	);
}

const Wrapper = styled('form')({
	display: 'flex',
	flexDirection: 'column',
});

const CallToAction = styled(Typography)<
	OverridableComponentProps<typeof Typography>
>(({ theme }) => ({
	marginBottom: 8,
	textTransform: 'uppercase',
	lineHeight: 1.22,
	fontSize: theme.typography.pxToRem(12),
}));

const DonateToCharityControl = styled(FormControlLabel)({
	marginTop: 3,
	marginBottom: 20,
});

const SubmitButton = styled(Button)({
	marginTop: 12,
	maxWidth: 244,
	marginLeft: 'auto',
	marginRight: 'auto',
});

type PrizeDropdownProps = TextFieldProps & {
	prizes: Prize[];
};

const pointStrings = {
	singular: 'Point',
	plural: 'Points',
};

function PrizeDropdown({ prizes, ...props }: PrizeDropdownProps) {
	return (
		<TextField {...props} select>
			<MenuItem value={DEFAULT_SELECTED_PRIZE.id}>
				{DEFAULT_SELECTED_PRIZE.name}
			</MenuItem>
			{prizes.map(({ id, name, points }) => (
				<MenuItem key={id} value={id}>
					{points} {singularOrPlural(points, pointStrings)} - {name}
				</MenuItem>
			))}
		</TextField>
	);
}

function validPrizeIds(ids: string[]) {
	return ids.filter(id => id !== DEFAULT_SELECTED_PRIZE.id);
}
