import { Action, ActionSubject } from '../utils/enums/abilities';

export const AdminRoutePermissions = {
	BadgeAwardApproval: [
		{
			do: [Action.Update, Action.Read],
			on: ActionSubject.BadgeAward,
		},
	],
	PrizeRedemptionManagement: [
		{
			do: [Action.Read, Action.Approve, Action.Update],
			on: ActionSubject.PrizeRedemption,
		},
	],
	BadgeAwardHistory: [
		{
			do: Action.Read,
			on: ActionSubject.BadgeAward,
		},
	],
	BadgeManagement: [
		{
			do: [Action.Update, Action.Create, Action.Delete],
			on: ActionSubject.Badge,
		},
	],
	PrizeManagement: [
		{
			do: [Action.Update, Action.Create, Action.Delete],
			on: [ActionSubject.Prize, ActionSubject.RecipientRelationship],
		},
	],
	UserManagement: [
		{
			do: [Action.Create, Action.Update],
			on: ActionSubject.User,
		},
	],
	ManagerReport: [
		{
			do: [Action.Read, Action.Report],
			on: ActionSubject.BadgeAward,
		},
	],
	Settings: [
		{
			do: [Action.Read, Action.Update],
			on: ActionSubject.Settings,
		},
	],
};
