import { ComponentProps } from 'react';
import { Box, styled } from '@mui/material';
import { useThemeContext } from '../../context/Theme';


export type AppLogoProps = ComponentProps<typeof Box>;

export function AppLogo(props: AppLogoProps) {
	const { settings } = useThemeContext();
	return (
		<Box
			{...props}
			display='flex'
			flexDirection='row'
			justifyContent='center'
			alignItems='center'
			flexWrap='nowrap'
		>
			<Logo src={settings?.logo} alt='Application logo' />
			<ProductName src='/img/brag_badges.png' alt='Product name' />
		</Box>
	);
}
const Logo = styled('img')(({ theme }) => ({
	width: '100px',
	height: 'auto',
	marginRight: '20px',
	marginLeft: '20px',
	[theme.breakpoints.down('sm')]: {
		marginRight: 0,
		marginLeft: 0,
		width: '30%',
	},
}));
const ProductName = styled('img')(({ theme }) => ({
	width: '160px',
	height: 'auto',
	margin: '10px',
	[theme.breakpoints.down('sm')]: {
		width: '60%',
	},
}));
