import { useContext } from 'react';
import { RelationshipManagerContext } from '../../context/RelationshipManager';

export function useRelationshipManager() {
	const state = useContext(RelationshipManagerContext);

	if (!state) {
		throw new Error(
			'useRelationshipManager() cannot be used outside a RelationshipManagerProvider context'
		);
	}

	return state;
}
