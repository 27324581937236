import { Typography, styled } from '@mui/material';
import { ContainedPageTemplate } from '../../templates/ContainedPage';

export type PageNotFoundProps = {
	message?: string;
};

export function PageNotFound({
	message = '404 Page not found',
}: PageNotFoundProps) {
	return (
		<ContainedPageTemplate>
			<ErrorMessage variant='h1'>{message}</ErrorMessage>
		</ContainedPageTemplate>
	);
}

const ErrorMessage = styled(Typography)(({ theme }) => ({
	width: '100%',
	padding: '50px 0',
	textAlign: 'center',
	color: theme.palette.error.main,
}));
