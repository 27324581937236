export function readFileContents(file: Blob | File): Promise<string> {
	if (!isValidFile(file)) throw new Error('readFileContents() expects a valid file or blob');
	
	const reader = new FileReader();

	return new Promise((resolve, reject) => {

		
		function onLoad() {
			resolve(reader.result as string);
			cleanUp();
		}
		function onError(event: ProgressEvent) {
			reject(event);
			cleanUp();
		}

		function cleanUp () {
			reader.removeEventListener('load', onLoad);
			reader.removeEventListener('error', onError);
		}

		reader.addEventListener('load', onLoad, false);
		reader.addEventListener('error', onError, false);


		reader.readAsText(file);
	});
}

function isValidFile(file: any) {
	return file && (file instanceof File || file instanceof Blob); 
}