import {
	Autocomplete,
	AutocompleteProps,
	TextField,
	TextFieldProps,
	debounce,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { InteractionSettings } from '../../../../../config/interactionSettings';
import { useUsersContext } from '../../../../../context/Users';
import { Recipient } from '../../../../../types/Recipient';

const FAILED_RECIPIENT_FETCH_MESSAGE =
	'We could not get recipient data, please try again in a few minutes!';

export type UserNameSearchProps = Omit<
	AutocompleteProps<Recipient, false, false, false>,
	'onInputChange' | 'renderInput' | 'options'
>;

function getRecipientOptionLabel(option: Recipient) {
	return option.name;
}

function isOptionEqualToValue(option: Recipient, value: Recipient) {
	return option.id === value.id;
}

export function UserNameSearch(props: UserNameSearchProps) {
	const { enqueueSnackbar } = useSnackbar();
	const { searchByName } = useUsersContext();
	const [fetchingUsers, setFetchingUsers] = useState(false);
	const [availableUsers, setUsers] = useState<Recipient[]>([]);

	const findRecipientsByName = useCallback((partialName: string) => {
		setFetchingUsers(true);

		searchByName(partialName)
			.then(({ data }) => setUsers(data))
			.catch(() => {
				enqueueSnackbar(FAILED_RECIPIENT_FETCH_MESSAGE, {
					variant: 'error',
				});
			})
			.finally(() => {
				setFetchingUsers(false);
			});
	}, []);

	const handleNameChange = useCallback(
		debounce(
			(_: unknown, value: string) => findRecipientsByName(value),
			InteractionSettings.searchDebounceTime
		),
		[]
	);

	const renderRecipientNameInput = useCallback(
		({ InputProps, ...props }: TextFieldProps) => (
			<TextField
				{...props}
				InputProps={{ ...InputProps, endAdornment: null }}
				fullWidth
			/>
		),
		[]
	);

	return (
		<Autocomplete
			options={availableUsers}
			loading={fetchingUsers}
			onInputChange={handleNameChange}
			getOptionLabel={getRecipientOptionLabel}
			renderInput={renderRecipientNameInput}
			isOptionEqualToValue={isOptionEqualToValue}
			{...props}
		/>
	);
}
