import { useContext } from 'react';
import { AdminLinksContext } from '../../context/AdminLinks';

export function useAdminLinks() {
	const result = useContext(AdminLinksContext);

	if (!result) throw new OutsideAdminLinksContextError();

	return result;
}

export class OutsideAdminLinksContextError extends Error {
	constructor(
		message = 'useAdminLinks() cannot be used outside a AdminLinksContext'
	) {
		super(message);
	}
}
