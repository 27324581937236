import { useCallback, useMemo } from 'react';
import {
	Checkbox,
	FormControlLabel,
	Grid,
	styled,
	TextField,
	Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, FormikProps } from 'formik';
import { LoginRequest } from '../../../../types/LoginRequest';
import { validationSchema } from './schema';

type FirstAccessFormProps = {
	useremail: string;
	handleSubmit: (params: LoginRequest) => void;
	isSubmitting: boolean;
};

export function FirstAccessForm({
	useremail,
	handleSubmit,
	isSubmitting,
}: FirstAccessFormProps) {
	const initialValues = useMemo(
		() => ({
			email: useremail,
			password: '',
			rememberMe: false,
		}),
		[]
	);

	return (
		<Grid container direction='column' spacing={3}>
			<Grid item>
				<Typography variant='h1' color='primary'>
					Welcome!
				</Typography>
			</Grid>
			<Grid item>
				<Formik<LoginRequest>
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
					render={props => <Form {...props} isSubmitting={isSubmitting} />}
				/>
			</Grid>
		</Grid>
	);
}

function Form({
	handleSubmit,
	getFieldMeta,
	getFieldProps,
	isSubmitting,
}: FormikProps<LoginRequest>) {
	const getFieldFeedbackProps = useCallback(
		(field: string) => {
			const { error, touched } = getFieldMeta(field);
			const isError = touched && Boolean(error);

			return {
				error: isError,
				helperText: isError ? error : undefined,
			};
		},
		[getFieldMeta]
	);

	return (
		<form onSubmit={handleSubmit}>
			<Grid container direction='column' spacing={1}>
				<Grid item>
					<TextField
						type='text'
						disabled
						label='Username'
						placeholder='Enter your username or email'
						variant='standard'
						fullWidth
						{...getFieldProps('email')}
						{...getFieldFeedbackProps('email')}
					/>
				</Grid>
				<Grid item>
					<TextField
						type='password'
						label='Set Your New Password'
						placeholder='Enter your new password'
						variant='standard'
						fullWidth
						{...getFieldProps('password')}
						{...getFieldFeedbackProps('password')}
					/>
				</Grid>
				<Grid item>
					<FormControlLabel
						control={<Checkbox {...getFieldProps('rememberMe')} />}
						label='Remember me'
					/>
				</Grid>
				<ButtonGrid item>
					<LoadingButton
						loading={isSubmitting}
						type='submit'
						sx={{ maxWidth: 167 }}
						fullWidth
						variant='contained'
					>
						Register
					</LoadingButton>
				</ButtonGrid>
			</Grid>
		</form>
	);
}

const ButtonGrid = styled(Grid)(({ theme }) => ({
	paddingTop: '50px !important',
	[theme.breakpoints.down('md')]: {
		textAlign: 'center',
	},
}));
