import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { useOwnUserTally } from '../../hooks/useOwnUserTally';
import { usePrizes } from '../../hooks/usePrizes';
import { Prize } from '../../types/Prize';
import { UserTally } from '../../types/UserTally';

export type DashboardControllerProps = {
	children: ReactNode;
};

type DashboardObj = {
	tally: UserTally;
	fetchingTally: boolean;
	prizes: Prize[];
	fetchingPrizes: boolean;
	currentAwardId: string | undefined;
	setCurrentAwardId: (id: string | undefined) => void;
};

export const DashboardContext = createContext<DashboardObj | undefined>(
	undefined
);

export function useDashboardController(): DashboardObj {
	const result = useContext(DashboardContext);
	if (!result) {
		throw new Error(
			'useDashboardController() needs to be wrapped in a DashboardController'
		);
	}

	return result;
}

export function DashboardController({ children }: DashboardControllerProps) {
	const { tally, loading: fetchingTally } = useOwnUserTally();
	const { prizes, loading: fetchingPrizes } = usePrizes();
	const [currentAwardId, setCurrentAwardId] = useState<string>();

	const data: DashboardObj = useMemo(
		() => ({
			tally,
			fetchingTally,
			prizes,
			fetchingPrizes,
			currentAwardId,
			setCurrentAwardId,
		}),
		[tally, fetchingTally, currentAwardId, prizes, fetchingPrizes]
	);
	return (
		<DashboardContext.Provider value={data}>{children}</DashboardContext.Provider>
	);
}
