/* eslint-disable no-empty-function, no-useless-constructor */
import { AxiosInstance } from 'axios';
import { privateClient, publicClient } from './clients';

type factoryConfig = {
	publicClient?: AxiosInstance;
	privateClient?: AxiosInstance;
};

export function apiClientFactory<C extends ApiClient>(
	// eslint-disable-next-line no-shadow
	ClientClass: new (config: ApiClientConfig) => C,
	config?: factoryConfig
) {
	const clientConfig: ApiClientConfig = {
		httpClient: config?.publicClient,
		privateHttpClient: config?.privateClient,
	};
	return new ClientClass(clientConfig);
}

export type ApiClientConfig = {
	httpClient?: AxiosInstance;
	privateHttpClient?: AxiosInstance;
};
export abstract class ApiClient {
	protected httpClient: AxiosInstance = publicClient;

	protected protectedHttpClient: AxiosInstance = privateClient;

	constructor(config?: ApiClientConfig) {
		if (config?.httpClient) this.httpClient = config.httpClient;
		if (config?.privateHttpClient)
			this.protectedHttpClient = config.privateHttpClient;
	}
}
