import { useContext } from 'react';
import { PrizesContext } from '../../context/Prizes';

export function usePrizes() {
	const result = useContext(PrizesContext);

	if (!result) throw new OutsidePrizesContextError();

	return result;
}

export class OutsidePrizesContextError extends Error {
	constructor(
		message = 'usePrizesContext() cannot be used outside a PrizesContext'
	) {
		super(message);
	}
}
