import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
	Box,
	CircularProgress,
	FormControlLabel,
	Grid,
	TextField,
} from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { AdminPage } from '../../templates/AdminPage';
import { Settings } from '../../types/Settings';
import { settingsValidationSchema } from './schemas/settingsValidation.schema';
import { Fieldset } from '../../components/Fieldset';
import { FieldLabel } from '../../components/FieldLabel';
import { Checkbox } from '../../components/Checkbox';
import { Roles } from '../../utils/enums/Roles';
import { RoleGuard } from '../../components/RoleGuard/RoleGuard';

type SettingsViewProps = {
	loading: boolean;
	settings?: Settings;
	updatingSettings?: boolean;
	onSubmit?: (values: FormValues) => void;
	handleSetRedemptionNotification: () => void;
	receiveRedemptionNotification: boolean;
};

export type FormValues = Omit<Settings, 'logo'> & {
	logo?: File;
};

export function SettingsView({
	loading,
	settings,
	onSubmit,
	updatingSettings,
	handleSetRedemptionNotification,
	receiveRedemptionNotification,
}: SettingsViewProps) {
	const initialValues = useMemo(
		() => ({
			companyName: settings?.companyName ?? '',
			logo: new File([], ''),
			primaryColor: settings?.primaryColor ?? '',
			secondaryColor: settings?.secondaryColor ?? '',
		}),
		[settings]
	);

	const onSubmitForm = useCallback((values: FormValues) => {
		if (onSubmit) onSubmit(values);
	}, []);

	if (loading) return <Loading />;

	const renderUserSettings = () => (
		<RoleGuard roles={[Roles.Manager, Roles.Authorizer]}>
			<Grid xs={12} md={12} item display='flex'>
				<FormControlLabel
					label='Receive an email notification when a new redemption has been requested'
					control={
						<Checkbox
							checked={receiveRedemptionNotification}
							onChange={handleSetRedemptionNotification}
							inputProps={{ 'aria-label': 'controlled' }}
						/>
					}
				/>
			</Grid>
		</RoleGuard>
	);

	return (
		<AdminPage title='Manage your settings below.'>
			<RoleGuard roles={[Roles.SysAdmin]}>
				<Formik<FormValues>
					initialValues={initialValues}
					validationSchema={settingsValidationSchema}
					onSubmit={onSubmitForm}
				>
					{({
						getFieldProps,
						getFieldMeta,
						handleSubmit,
						setFieldValue,
						setFieldTouched,
					}) => {
						const getFieldFeedbackProps = (field: string) => {
							const { error, touched } = getFieldMeta(field);
							const hasError = touched && Boolean(error);

							return {
								error: hasError,
								helperText: hasError ? error : undefined,
							};
						};

						return (
							<Grid
								component='form'
								onSubmit={handleSubmit}
								container
								justifyContent='center'
								spacing={3}
								sx={theme => ({
									width: '100%',
									[theme.breakpoints.up('md')]: {
										width: '75%',
										paddingTop: '50px',
										paddingLeft: '50px',
									},
								})}
							>
								<Grid xs={12} md={6} item>
									<Fieldset>
										<FieldLabel id='settings-editor-name-input'>Company Name</FieldLabel>
										<TextField
											InputProps={{
												inputProps: {
													'aria-labelledby': 'settings-editor-name-input',
												},
											}}
											type='text'
											fullWidth
											{...getFieldProps('companyName')}
											{...getFieldFeedbackProps('companyName')}
										/>
									</Fieldset>
								</Grid>
								<Grid xs={12} md={6} item>
									<Fieldset>
										<FieldLabel id='settings-editor-logo-input'>logo</FieldLabel>
										<TextField
											InputProps={{
												inputProps: {
													'aria-labelledby': 'settings-editor-logo-input',
												},
											}}
											type='file'
											fullWidth
											{...getFieldFeedbackProps('logo')}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												setFieldTouched('logo', true);
												setFieldValue('logo', e.target.files?.[0]);
											}}
										/>
									</Fieldset>
								</Grid>
								<Grid xs={12} md={6} item>
									<Fieldset>
										<FieldLabel id='settings-editor-primary-color-input'>
											Primary Color
										</FieldLabel>
										<MuiColorInput
											fullWidth
											format='hex8'
											InputProps={{
												inputProps: {
													'aria-labelledby': 'settings-editor-primary-color-input',
												},
											}}
											{...getFieldProps('primaryColor')}
											{...getFieldFeedbackProps('primaryColor')}
											onChange={color => setFieldValue('primaryColor', color)}
										/>
									</Fieldset>
								</Grid>
								<Grid xs={12} md={6} item>
									<Fieldset>
										<FieldLabel id='settings-editor-secondary-color-input'>
											Secondary Color
										</FieldLabel>
										<MuiColorInput
											fullWidth
											format='hex8'
											InputProps={{
												inputProps: {
													'aria-labelledby': 'settings-editor-secondary-color-input',
												},
											}}
											{...getFieldProps('secondaryColor')}
											{...getFieldFeedbackProps('secondaryColor')}
											onChange={color => setFieldValue('secondaryColor', color)}
										/>
									</Fieldset>
								</Grid>
								{renderUserSettings()}
								<Grid xs={12} item>
									<LoadingButton
										color='primary'
										variant='contained'
										type='submit'
										loading={updatingSettings}
										sx={theme => ({
											width: '100%',
											[theme.breakpoints.up('md')]: {
												width: '160px',
											},
										})}
									>
										Save
									</LoadingButton>
								</Grid>
							</Grid>
						);
					}}
				</Formik>
			</RoleGuard>
			{renderUserSettings()}
		</AdminPage>
	);
}

function Loading() {
	return (
		<Box
			sx={{
				display: 'flex',
				height: '50vh',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<CircularProgress />
		</Box>
	);
}
