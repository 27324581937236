import { useCallback } from 'react';
import { useBrandSlug } from '../../context/BrandSlug';

export function useInternalLink() {
	const { brandSlug } = useBrandSlug();

	const internalLink = useCallback(
		(path: string) => `/${brandSlug}/${path}`,
		[brandSlug]
	);

	return internalLink;
}
