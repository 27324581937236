import { SvgIcon, SvgIconProps } from '@mui/material';

export function CheckboxIcon(props?: SvgIconProps) {
	return (
		<SvgIcon
			width='20'
			height='19'
			viewBox='0 0 20 19'
			fill='none'
			stroke='black'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<rect x='0.5' y='0.5' width='18.8309' height='17.5215' rx='1.5' />
		</SvgIcon>
	);
}
