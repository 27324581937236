import { RedemptionRequestsController } from './RedemptionRequestsController';
import { RedemptionRequestsView } from './RedemptionRequestsView';

export function RedemptionRequests() {
	return (
		<RedemptionRequestsController>
			<RedemptionRequestsView />
		</RedemptionRequestsController>
	);
}
