import { Box, Grid, styled, Typography } from '@mui/material';
import howTitle from '../../../../assets/img/howitwork-title.svg';
import howStep1 from '../../../../assets/img/howitwork-step1.svg';
import howStep2 from '../../../../assets/img/howitwork-step2.svg';
import howStep3 from '../../../../assets/img/howitwork-step3.svg';
import howBg from '../../../../assets/img/howitwork-bg.jpg';
import greatJob from '../../../../assets/img/great-job.svg';

export function HowItWorksSection() {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				width: '100%',
				textAlign: 'center',
				color: 'white',
			}}
		>
			<Box
				sx={{
					width: '100%',
					textAlign: 'center',
					padding: '28px 0 28px 0',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<HowTitle src={howTitle} />
			</Box>

			<Grid
				container
				sx={{
					padding: '0px 50px 0px 50px',
					textAlign: 'center',
				}}
			>
				<Grid xs={12} sm={12} md={4} item sx={{ margin: '50px 0' }}>
					<HowSteps src={howStep1} />
					<Typography variant='h3'>Get recognized</Typography>
					<Typography variant='subtitle1'>by your Team</Typography>
				</Grid>
				<Grid xs={12} sm={6} md={4} item sx={{ margin: '50px 0' }}>
					<HowSteps src={howStep2} />
					<Typography variant='h3'>Accumulate</Typography>
					<Typography variant='subtitle1'>Badge Points</Typography>
				</Grid>
				<Grid xs={12} sm={6} md={4} item sx={{ margin: '50px 0' }}>
					<HowSteps src={howStep3} />
					<Typography variant='h3'>Redeem</Typography>
					<Typography variant='subtitle1'>Award Points</Typography>
				</Grid>
			</Grid>

			<Box
				sx={{
					position: 'relative',
					width: '100%',
				}}
			>
				<Box>
					<HowBanner src={howBg} alt='' />
				</Box>
				<Box
					sx={theme => ({
						backgroundColor: '#2B2B2BCC',
						heigh: '138px',
						width: '100%',
						padding: '39px 0 39px 0',
						top: '30%',
						position: 'absolute',
						[theme.breakpoints.down('md')]: {
							position: 'unset',
							backgroundColor: 'unset',
							padding: '24px 0 24px 0',
						},
					})}
				>
					<RecognitionText>
						We recognize and appreciate the contributions you make to your team!
					</RecognitionText>
				</Box>

				<GreatJob src={greatJob} />
			</Box>
		</Box>
	);
}

const HowSteps = styled('img')({
	width: 'auto',
	height: '141px',
});
const HowTitle = styled('img')({
	width: 'auto',
	height: '32px',
});
const HowBanner = styled('img')({
	width: '100%',
	height: 'auto',
	display: 'block',
});
const GreatJob = styled('img')(({ theme }) => ({
	width: 'auto',
	height: '255px',
	position: 'absolute',
	zIndex: 99,
	bottom: 0,
	right: 0,
	[theme.breakpoints.down('md')]: {
		position: 'unset',
		width: '100%',
	},
}));

const RecognitionText = styled(Typography)(({ theme }) => ({
	width: '65%',
	paddingLeft: '20%',
	lineHeight: 1.25,
	fontSize: '1.375em',
	fontWeight: 700,
	textAlign: 'center',
	[theme.breakpoints.down('md')]: {
		paddingLeft: 'unset',
		maxWidth: '20ch',
		margin: '0 auto',
	},
}));
