import { ReactNode } from 'react';
import { useAuth } from '../../context/Auth';

export type RoleGuardProps = {
	children: ReactNode;
	roles: string[];
};

export function RoleGuard({ children, roles }: RoleGuardProps) {
	const { user } = useAuth();

	if (!user?.role.some(role => roles.includes(role))) {
		return null;
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
}
