/* eslint-disable prettier/prettier */
import { Grid, styled } from '@mui/material';
import { AppLogo } from '../../../../components/AppLogo';
import { ActionsPanel } from '../ActionsPanel';

export function DashboardHeader() {
	return (
		<DashWrapper>
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				sx={{ height: '100%' }}
			>
				<Grid xs={5} sm={5} md={4} item>
					<AppLogo
						sx={theme => ({
							paddingLeft: '20px',
							[theme.breakpoints.down('sm')]: {
								flexDirection: 'column',
							},
						})}
					/>
				</Grid>
				<Container xs={5} sm={5} md={8} item>
					<ActionsPanel />
				</Container>
			</Grid>
		</DashWrapper>
	);
}

const DashWrapper = styled('header')({
	height: 109,
	paddingTop: 10,
	paddingBottom: 10,
	background:
		'linear-gradient(290deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(50,50,50,1) 50%, rgba(50,50,50,1) 100%)',
});

const Container = styled(Grid)(({ theme }) => ({
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-around',
	[theme.breakpoints.up('sm')]: {
		flexDirection: 'row',
	},
}));
