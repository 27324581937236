import {
	Box,
	Button,
	Grid,
	IconButton,
	IconButtonProps,
	Skeleton,
	Switch,
	TextField,
	Typography,
	styled,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { FieldInputProps, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, {
	ChangeEvent,
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import * as yup from 'yup';
import { RemoveIcon } from '../../../../assets/Icons/Remove';
import { Dialog, DialogProps } from '../../../../components/Dialog';
import { FieldLabel } from '../../../../components/FieldLabel';
import { Fieldset } from '../../../../components/Fieldset';
import { SplashScreen } from '../../../../components/SplashScreen';
import { useUsersContext } from '../../../../context/Users';
import { Recipient } from '../../../../types/Recipient';
import { parseCsv } from '../../../../utils/helpers/parseCSV';
import { readFileContents } from '../../../../utils/helpers/readFileContents';
import { withoutInnerItems } from '../../../../utils/helpers/withoutInnerItems';
import { UserNameSearch } from './components/UserNameSearch';

export const POINT_AWARD_SUCCESS_MESSAGE = 'Points successfully awarded!';
export const POINT_AWARD_INPUT_ERROR_MESSAGE =
	'One or more users in the list are not in the system. Please check the list and try again!';
export const UNKNOWN_REQUEST_ERROR =
	'Something went wrong, please try again in a few minutes!';
export const FORBIDDEN_ERROR_MESSAGE =
	"You don't have the right permissions or have tried to award points to yourself";

export const validationSchema = yup.object({
	pairs: yup
		.array()
		.of(
			yup.object().shape({
				name: yup.string().optional(),
				email: yup
					.string()
					.email('Invalid email format')
					.required("You need to enter a user's email address"),
				reason: yup
					.string()
					.min(0)
					.max(150, 'Reason can not exceed 150 characters')
					.required('You need to enter a reason'),
				points: yup.number().min(1, 'Cannot be lower than 1').required(),
				id: yup.string().optional(),
			})
		)
		.min(1)
		.required(),
});

const TITLE_PROPS = {
	paddingBottom: '8px !important',
};

const REMOVE_BUTTON_STYLE = {
	marginTop: 20,
};

export type ManualPointAwardDialogProps = Omit<DialogProps, 'children'> & {
	onPointsSent?: () => void;
};

export function ManualPointAwardDialog({
	onPointsSent,
	...props
}: ManualPointAwardDialogProps) {
	const filePickerRef = useRef<HTMLInputElement>(null);
	const { enqueueSnackbar } = useSnackbar();
	const { awardPointsToUsers } = useUsersContext();
	const [splashScreenVisible, setSplashScreenVisible] = useState(false);
	const [pairsFromCSV, setPairsFromCSV] = useState<Array<UserPointEntry> | null>(
		null
	);
	const [formikKey, setFormikKey] = useState(0);

	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

	const ITEM_SIZE = useMemo(() => (isDesktop ? 100 : 304), [isDesktop]);

	const initialValues = useMemo(
		() => ({
			pairs: pairsFromCSV || [new UserPointEntry('', 0, '')],
		}),
		[pairsFromCSV]
	);

	const handleFileInputChange = useCallback(
		async (event: ChangeEvent<HTMLInputElement>) => {
			if (!event.target.files) return;

			const file = event.target.files.item(0);
			if (!file) return;

			setSplashScreenVisible(true);
			let fileContents: string;
			try {
				fileContents = await readFileContents(file);
			} catch (err) {
				console.error('Could not read CSV file', err);
				return;
			} finally {
				setSplashScreenVisible(false);
			}

			const csvContents = parseCsv(fileContents) as Array<{
				email: string;
				points: string;
				reason: string;
			}>;
			const entries = csvContents
				.filter(entry => entry.email && entry.points)
				.map(
					entry =>
						new UserPointEntry(
							entry.email,
							Number.parseInt(entry.points, 10),
							entry.reason ?? ''
						)
				);

			setPairsFromCSV(entries);
			setFormikKey(key => key + 1);
		},
		[]
	);

	const handleFormReset = useCallback(() => {
		setPairsFromCSV(null);
		if (filePickerRef.current) filePickerRef.current.value = ''; // resets the file input
	}, []);

	const openFilePicker = useCallback(() => {
		if (!filePickerRef.current) return;
		filePickerRef.current.click(); // triggers the file pick dialog
	}, []);

	const savePoints = useCallback(
		({ pairs }: FromValues) => {
			console.log('Sending points to users', pairs);
			awardPointsToUsers(
				pairs.map(({ email, reason, points }) => ({ email, reason, points }))
			)
				.then(() => {
					if (onPointsSent) onPointsSent();
					console.log('Points sent to users');
					enqueueSnackbar(POINT_AWARD_SUCCESS_MESSAGE, { variant: 'success' });
				})
				.catch(err => {
					console.error('Could not send points to users', err);

					switch (err.response.status) {
						case 400:
							enqueueSnackbar(POINT_AWARD_INPUT_ERROR_MESSAGE, { variant: 'warning' });
							break;
						case 403:
							enqueueSnackbar(FORBIDDEN_ERROR_MESSAGE, { variant: 'error' });
							break;
						default:
							enqueueSnackbar(UNKNOWN_REQUEST_ERROR, { variant: 'error' });
					}
				});
		},
		[onPointsSent]
	);

	return (
		<Dialog title='Manual Point Award' TitleProps={TITLE_PROPS} {...props}>
			<Formik
				key={formikKey}
				isInitialValid={false}
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={savePoints}
				onReset={handleFormReset}
				enableReinitialize
			>
				{({
					values,
					isValid,
					handleSubmit,
					resetForm,
					setFieldValue,
					getFieldMeta,
					getFieldProps,
				}) => {
					const addNewUser = () => {
						setFieldValue(
							'pairs',
							([] as UserPointEntry[]).concat(
								values.pairs,
								new UserPointEntry('', 0, '')
							)
						);
					};

					const userRemover: UserRemover = (userIndex: number) => () => {
						setFieldValue('pairs', withoutInnerItems(values.pairs, userIndex, 1));
					};

					const getFieldFeedbackProps: GetFieldFeedbackProps = (field: string) => {
						const { error, touched } = getFieldMeta(field);
						const hasError = touched && Boolean(error);
						return {
							error: hasError,
							helperText: hasError ? error : undefined,
						};
					};

					const listData = {
						entries: values.pairs,
						functions: {
							userRemover,
							setFieldValue,
							getFieldProps,
							getFieldFeedbackProps,
						},
					};

					const resetButtonVisible = values !== initialValues || pairsFromCSV;
					return (
						<form onSubmit={handleSubmit}>
							<InstructionSection>
								<Typography>
									You may also send the points by uploading a CSV file{' '}
									<FileUploadButton variant='text' onClick={openFilePicker}>
										here
									</FileUploadButton>
									. An example file can be found here:{' '}
									<FileExampleDownloadLink
										// @ts-ignore
										component='a'
										href='/manual-point-award-example.csv'
										download
									>
										Example.csv
									</FileExampleDownloadLink>
								</Typography>
								<Typography fontStyle='italic'>
									*The values must be comma-separated, and the file should contain 500
									entries or less
								</Typography>
							</InstructionSection>
							<ScrollRegion sx={{ height: ITEM_SIZE * values.pairs.length }}>
								<AutoSizer>
									{({ height, width }: { height: number; width: number }) => (
										<List
											className='List'
											height={height}
											itemSize={ITEM_SIZE}
											itemCount={values.pairs.length}
											itemData={listData}
											width={width}
										>
											{Row}
										</List>
									)}
								</AutoSizer>
							</ScrollRegion>
							<Actions display='flex' justifyContent='space-between'>
								<Box>
									<Button
										variant='contained'
										color='secondary'
										aria-label='Add New User to List'
										onClick={addNewUser}
									>
										Add User
									</Button>
									{resetButtonVisible && (
										<Button
											style={{
												marginLeft: 16,
											}}
											variant='contained'
											color='secondary'
											aria-label='reset form'
											onClick={() => resetForm()}
										>
											Reset
										</Button>
									)}
								</Box>
								<Button
									type='submit'
									variant='contained'
									color='primary'
									disabled={!isValid}
								>
									Submit
								</Button>
							</Actions>
						</form>
					);
				}}
			</Formik>
			<input
				type='file'
				data-testid='csv-input'
				style={{ display: 'none', visibility: 'hidden' }}
				ref={filePickerRef}
				onChange={handleFileInputChange}
			/>
			{splashScreenVisible && (
				<SplashScreen backgroundColor='rgba(0, 0, 0, .5)' textColor='white' />
			)}
		</Dialog>
	);
}

type RowProps = {
	index: number;
	style: React.CSSProperties | undefined;
	isScrolling?: boolean;
	data: {
		entries: UserPointEntry[];
		functions: {
			getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>;
			getFieldFeedbackProps: GetFieldFeedbackProps;
			userRemover: UserRemover;
			setFieldValue: (field: string, value: any) => void;
		};
	};
};

function Row({ index, data, isScrolling, style }: RowProps) {
	const [showName, setShowName] = useState(false);
	const [user, setUser] = useState<Recipient | null>(null);
	const { getFieldProps, getFieldFeedbackProps, userRemover, setFieldValue } =
		data.functions;

	const toggleShowName = useCallback(
		(_: unknown, checked: boolean) => setShowName(checked),
		[]
	);

	const inputId = useCallback(
		(field: string) => `ManualPointAwardDialog_${field}_input-${index}`,
		[index]
	);

	const getPairFieldName = useCallback(
		(field: string) => `pairs[${index}].${field}`,
		[index]
	);

	const onFetchedUserChange = useCallback(
		(_: unknown, user: Recipient | null) => {
			if (user) setFieldValue(getPairFieldName('email'), user.email);
			setUser(user);
		},
		[]
	);

	return (
		<Grid spacing={2} style={style} container>
			<Grid xs={12} md={1} item>
				<NameSwitch
					aria-label='Search user by name'
					title='Search user by name'
					data-testId='name-switch'
					onChange={toggleShowName}
				/>
			</Grid>
			<Grid xs={12} md={3} item>
				{!isScrolling ? (
					<Fieldset>
						<FieldLabel htmlFor={inputId(showName ? 'name' : 'email')}>
							{showName ? 'Name' : 'Email'}
						</FieldLabel>
						{showName ? (
							<UserNameSearch
								id={inputId('name')}
								value={user}
								onChange={onFetchedUserChange}
							/>
						) : (
							<TextField
								id={inputId('email')}
								placeholder='Email'
								type='text'
								{...getFieldProps(getPairFieldName('email'))}
								{...getFieldFeedbackProps(getPairFieldName('email'))}
								fullWidth
							/>
						)}
					</Fieldset>
				) : (
					<Skeleton
						variant='rectangular'
						width='100%'
						height={36}
						style={{ marginTop: 20 }}
					/>
				)}
			</Grid>
			<Grid xs={12} md={5} item>
				{!isScrolling ? (
					<Fieldset>
						<FieldLabel htmlFor={inputId('reason')}>Reason</FieldLabel>
						<TextField
							id={inputId('reason')}
							type='text'
							{...getFieldProps(getPairFieldName('reason'))}
							{...getFieldFeedbackProps(getPairFieldName('reason'))}
							fullWidth
						/>
					</Fieldset>
				) : (
					<Skeleton
						variant='rectangular'
						width='100%'
						height={36}
						style={{ marginTop: 20 }}
					/>
				)}
			</Grid>
			<Grid xs={12} md={2} item>
				{!isScrolling ? (
					<Fieldset>
						<FieldLabel htmlFor={inputId('points')}>Points</FieldLabel>
						<TextField
							id={inputId('points')}
							placeholder='0'
							type='number'
							inputProps={{
								min: 0,
							}}
							{...getFieldProps(getPairFieldName('points'))}
							{...getFieldFeedbackProps(getPairFieldName('points'))}
							fullWidth
						/>
					</Fieldset>
				) : (
					<Skeleton
						variant='rectangular'
						width='100%'
						height={36}
						style={{ marginTop: 20 }}
					/>
				)}
			</Grid>
			<Grid xs={12} md={1} item>
				{Boolean(index) &&
					(!isScrolling ? (
						<>
							<RemoveRowButton
								aria-describedby={`ManualPointAwardDialog_entry_${index}_delete_button_description`}
								title='Remove'
								onClick={userRemover(index)}
							/>
							<span
								style={{ display: 'none' }}
								id={`ManualPointAwardDialog_entry_${index}_delete_button_description`}
							>
								Removes user number {index + 1}
							</span>
						</>
					) : (
						<Skeleton
							variant='rectangular'
							width={100}
							height={36}
							style={{ marginTop: 20 }}
						/>
					))}
			</Grid>
		</Grid>
	);
}

function RemoveRowButton(props: Omit<IconButtonProps, 'children'>) {
	const theme = useTheme();

	return (
		<IconButton style={REMOVE_BUTTON_STYLE} {...props}>
			<RemoveIcon htmlColor={theme.palette.primary.main} />
		</IconButton>
	);
}

const NameSwitch = styled(Switch)({
	marginTop: 22,
});

const InstructionSection = styled(Box)({
	paddingBottom: 24,
});

const Actions = styled(Box)({
	paddingTop: 24,
});

const FileUploadButton = styled(Button)(({ theme }) => ({
	padding: 0,
	minWidth: 0,
	textDecoration: 'underline',
	color: theme.palette.primary.main,
}));

const FileExampleDownloadLink = styled(Typography)(({ theme }) => ({
	color: theme.palette.primary.main,
}));

const ScrollRegion = styled(Box)(({ theme }) => ({
	overflowY: 'auto',
	overflowX: 'hidden',
	flex: 1,
	maxHeight: '48vh',
	[theme.breakpoints.up('sm')]: {
		maxHeight: '72vh',
	},
	[theme.breakpoints.up('md')]: {
		maxHeight: '64vh',
	},
}));

class UserPointEntry {
	id: string;

	email: string;

	points: number;

	reason: string;

	name: string = '';

	constructor(email: string, points: number, reason: string) {
		this.id = Date.now().toString(); // Creates a "unique" id for use in the key property of the entries
		this.email = email;
		this.points = points;
		this.reason = reason;
	}
}

type FromValues = {
	pairs: UserPointEntry[];
};

type GetFieldFeedbackProps = (field: string) => {
	error: boolean;
	helperText: string | undefined;
};

type UserRemover = (userIndex: number) => () => void;
