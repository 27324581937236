import { SvgIcon, SvgIconProps } from '@mui/material';

export function PencilIcon(props?: SvgIconProps) {
	return (
		<SvgIcon
			width='11'
			height='11'
			viewBox='0 0 11 11'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='M9.81066 3.72192L7.25267 1.19041L8.0953 0.346575C8.32602 0.115525 8.60951 0 8.94576 0C9.28161 0 9.56489 0.115525 9.79561 0.346575L10.6382 1.19041C10.869 1.42146 10.9893 1.70033 10.9994 2.02701C11.0094 2.3533 10.8991 2.63196 10.6683 2.86301L9.81066 3.72192ZM8.93793 4.61096L2.55799 11H0V8.43836L6.37994 2.04932L8.93793 4.61096Z' />
		</SvgIcon>
	);
}
