import { Button, Skeleton, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { AvatarIcon } from '../../../../assets/Icons/Avatar';
import { useAuth } from '../../../../context/Auth';
import { useBrandSlug } from '../../../../context/BrandSlug';

export function UserHomeButton() {
	const { user } = useAuth();
	const { brandSlug } = useBrandSlug();

	return (
		<Button
			variant='text'
			to={`/${brandSlug}/dashboard`}
			startIcon={<AvatarIcon />}
			component={Link}
		>
			{user ? (
				<Name>{getFirstNames(user.name)}</Name>
			) : (
				<Skeleton variant='rectangular' animation='wave' width={120} height='1em' />
			)}
		</Button>
	);
}

function getFirstNames(name: string | null | undefined, maxNames = 2) {
	if (!(name && name.length)) return '';
	return name.split(' ').slice(0, maxNames).join(' ');
}

const Name = styled('span')({
	maxWidth: '24ch',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});
