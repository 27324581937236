import { AxiosResponseTransformer } from 'axios';
import { QueryParams } from '../../hooks/usePagination/usePagination';
import {
	CreateUserConfig,
	SingleUser,
	Transaction,
	UpdateUserConfig,
	User,
	UserSortableFields,
} from '../../types/User';
import { UserTally } from '../../types/UserTally';
import { extendAxiosTransformer } from '../../utils/extendAxiosTransformer.helper';
import { ApiClient } from '../ApiClient';
import {
	CreateUserDto,
	UserDto,
	UpdateUserDto,
	UserTallyDto,
	ChangePassConfig,
	UserPointRewardDto,
	TransactionDto,
} from './Users.type';
import { ResultList } from '../../types/ResultList';
import { Roles } from '../../utils/enums/Roles';

export class Users extends ApiClient {
	private baseEndpoint = '/users';

	getUserById = (id: string) =>
		this.protectedHttpClient.get<SingleUser>(`${this.baseEndpoint}/user/${id}`, {
			transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
				this.protectedHttpClient.defaults.transformResponse,
				[Users.userFromDto]
			),
		});

	deleteUser = (id: string): Promise<any> =>
		this.protectedHttpClient.delete(`${this.baseEndpoint}/${id}`);

	getAllUsers = (queryParams?: QueryParams<UserSortableFields>) =>
		this.protectedHttpClient.get<ResultList<User[]>>(this.baseEndpoint, {
			params: queryParams,
		});

	getOwnTally() {
		return this.protectedHttpClient.get<UserTally>(this.endpoint('/score'), {
			transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
				this.protectedHttpClient.defaults.transformResponse,
				[Users.userTallyFromDto]
			),
		});
	}

	createUser = (userConfig: CreateUserConfig) =>
		this.protectedHttpClient.post(
			this.baseEndpoint,
			Users.createUserDtoFromCreateUserConfig(userConfig)
		);

	updateUser = (userConfig: UpdateUserConfig) =>
		this.protectedHttpClient.put(
			this.baseEndpoint,
			Users.updateUserDtoFromUpdateUserConfig(userConfig)
		);

	updatePassword = (userConfig: ChangePassConfig) =>
		this.protectedHttpClient.patch(this.endpoint('/password'), userConfig);

	resetForgottenPassword = (email: string) =>
		this.httpClient.post(this.endpoint('/password/forgot'), {
			email,
		});

	updateSettings = (userId: string, settings: Object) =>
		this.protectedHttpClient.patch(
			this.endpoint(`/${userId}/settings`),
			settings
		);

	awardPointsToUsers = (userPointPairs: UserPointRewardDto[]) =>
		this.protectedHttpClient.post(this.endpoint('/points'), userPointPairs);

	getTransactionHistoryByUserId = (
		userId: string,
		pagination?: Pick<QueryParams<any>, 'page' | 'limit'>
	) =>
		this.protectedHttpClient.get<ResultList<Transaction[]>>(
			this.endpoint(`/${userId}/transaction/history`),
			{
				params: pagination,
				transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
					this.protectedHttpClient.defaults.transformResponse,
					[Users.transactionsFromDto]
				),
			}
		);

	static userTallyFromDto(dto: UserTallyDto): UserTally {
		return {
			score: dto.points,
			badges: dto.badges,
		};
	}

	static userFromDto(dto: UserDto): SingleUser {
		return {
			id: dto.id.toString(),
			name: dto.name,
			position: dto.position,
			username: dto.username,
			email: dto.email,
			points: dto.points,
			active: dto.active,
			firstAccess: dto.firstAccess,
			userRoles: Array.isArray(dto.userRoles) ? (dto.userRoles as Roles[]) : [],
			managers: dto.managers.map(({ idManager }) => ({
				...idManager,
				id: idManager.id.toString(),
			})),
			requests: Array.isArray(dto.requests) ? dto.requests : [],
			settings: dto.settings,
		};
	}

	static createUserDtoFromCreateUserConfig(
		config: CreateUserConfig
	): CreateUserDto {
		return {
			password: '',
			managerUserId: config.managerUserId,
			firstName: config.firstName,
			lastName: config.lastName,
			position: config.position,
			email: config.email,
			isManager: config.isManager,
			rolesIds: config.rolesIds,
		};
	}

	static updateUserDtoFromUpdateUserConfig(
		config: UpdateUserConfig
	): UpdateUserDto {
		return {
			password: '',
			id: config.id,
			managerUserId: config.managerUserId || '0',
			position: config.position,
			email: config.email,
			active: config.active,
			firstAccess: config.firstAccess,
			points: config.points,
			firstName: config.firstName,
			lastName: config.lastName,
			rolesIds: config.rolesIds,
		};
	}

	static transactionsFromDto(
		response: ResultList<TransactionDto[]>
	): ResultList<Transaction[]> {
		return {
			total: response.total,
			results: response.results.map(Users.transactionFromDto),
		};
	}

	static transactionFromDto(dto: TransactionDto): Transaction {
		return {
			id: dto.id,
			description: dto.description,
			points: dto.points,
			timestamp: new Date(dto.timestamp),
		};
	}

	private endpoint(subpath: string) {
		return this.baseEndpoint.concat(subpath);
	}
}
