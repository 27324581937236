import { AxiosResponseTransformer } from 'axios';
import { Color } from '../../types/Color';
import { extendAxiosTransformer } from '../../utils/extendAxiosTransformer.helper';
import { ApiClient } from '../ApiClient';
import { ColorDto } from './Colors.type';

export class Colors extends ApiClient {
	private baseEndpoint = '/colors';

	getAllColors = () =>
		this.protectedHttpClient.get<Color[]>(this.baseEndpoint, {
			transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
				this.protectedHttpClient.defaults.transformResponse,
				[Colors.transformResponse]
			),
		});

	static transformResponse(data: ColorDto[]) {
		if (!(data && Array.isArray(data))) return [];
		return data.map(Colors.colorFromDto);
	}

	static colorFromDto(dto: ColorDto): Color {
		const gradient = JSON.parse(dto.gradientColorHex);

		return {
			id: String(dto.id),
			name: dto.name,
			shade: dto.shadeColorHex,
			prominent: dto.prominentColorHex,
			medalBorder: dto.medalBorderColorHex,
			separator: dto.separatorColorHex,
			ribbonSeparator: dto.ribbonSeparatorColorHex,
			gradient: {
				from: gradient.from,
				to: gradient.to,
			},
		};
	}
}
