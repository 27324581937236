import { Box, Button, Typography, styled } from '@mui/material';
import { OverridableComponentProps } from '../../../../utils/types';
import paperAirplane from '../../../../assets/img/paper-plane.svg';

export type SendBadgeConfirmationProps = {
	onContinue: () => void;
};

export function SentBadgeConfirmation({
	onContinue,
}: SendBadgeConfirmationProps) {
	return (
		<Wrapper>
			<Content>
				<Text variant='h2' component='span'>
					Your Badge has been sent
				</Text>
				<ImgPaper src={paperAirplane} alt='' />
				<ContinueButton variant='contained' onClick={onContinue}>
					Send another
				</ContinueButton>
			</Content>
		</Wrapper>
	);
}

const Wrapper = styled(Box)({
	width: '100%',
	aspectRatio: '21/9',
	display: 'flex',
	alignItems: 'center',
});

const Content = styled(Box)(({ theme }) => ({
	width: '100%',
	aspectRatio: '21/7',
	position: 'relative',
	display: 'flex',
	justifyContent: 'flex-end',
	flexDirection: 'column',
	alignItems: 'center',
	padding: 16,

	[theme.breakpoints.down('md')]: {
		justifyContent: 'space-evenly',
	},
}));

const Text = styled(Typography)<OverridableComponentProps<typeof Typography>>({
	maxWidth: '24ch',
	left: -12,
	position: 'relative',
	textTransform: 'uppercase',
	paddingRight: 12,
	textAlign: 'center',
});

const ContinueButton = styled(Button)(({ theme }) => ({
	maxWidth: 199,
	marginTop: '14.635%',
	[theme.breakpoints.down('md')]: {
		marginTop: '20px',
	},
}));

const ImgPaper = styled('img')(({ theme }) => ({
	marginTop: '20px',
	maxWidth: '100%',
	[theme.breakpoints.up('md')]: {
		marginTop: '0',
		position: 'absolute',
		right: '17%',
	},
}));
