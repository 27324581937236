import { AxiosError } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { usePrize } from '../../../../hooks/usePrize';
import { PrizeUpdate } from '../../../../types/Prize';
import { PrizeEditorView, PrizeEditorViewProps } from './PrizeEditorView';

export type UpdatePrizeEditorProps = {
	prizeId: string;
	onCloseWindowClick: () => void;
	onPrizeUpdate?: () => void;
	onPrizeUpdateError?: (e: AxiosError) => void;
	onPrizeDelete?: () => void;
	onPrizeDeleteError?: (e: AxiosError) => void;
};

export function UpdatePrizeEditor({
	prizeId,
	onCloseWindowClick,
	onPrizeUpdate,
	onPrizeUpdateError,
	onPrizeDelete,
	onPrizeDeleteError,
}: UpdatePrizeEditorProps) {
	const usePrizeConfig = useMemo(
		() => ({
			onCloseWindowClick,
			onPrizeUpdate,
			onPrizeUpdateError,
			onPrizeDelete,
			onPrizeDeleteError,
		}),
		[onPrizeUpdate, onPrizeUpdateError, onPrizeDelete, onPrizeDeleteError]
	);
	const [editing, setEditing] = useState(false);
	const { prize, loading, deleting, updating, deletePrize, updatePrize } =
		usePrize(prizeId, usePrizeConfig);

	const update: NonNullable<PrizeEditorViewProps['onSubmit']> = useCallback(
		values => {
			const prizeUpdate: PrizeUpdate = {};

			if (values.name !== prize?.name) prizeUpdate.name = values.name;
			if (values.points !== prize?.points) prizeUpdate.points = values.points;
			if (values.donable !== prize?.donable) prizeUpdate.donable = values.donable;

			if (Object.keys(prizeUpdate).length) updatePrize(prizeUpdate);
		},
		[prize]
	);

	const handleEditButtonClick = useCallback(() => setEditing(true), []);

	return (
		<PrizeEditorView
			name={prize?.name}
			points={prize?.points}
			donable={prize?.donable}
			processing={updating || deleting}
			loading={loading}
			removing={deleting}
			saving={updating}
			editing={editing}
			onSubmit={update}
			onEditButtonClick={handleEditButtonClick}
			onRemoveButtonClick={deletePrize}
			onCloseWindowClick={onCloseWindowClick}
		/>
	);
}
