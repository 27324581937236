import { AxiosError } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { PrizeRedemption } from '../../api/PrizeRedemption';
import {
	PrizeRedemptionFilterQueryParams,
	PrizeRedemptionQueryParams,
	PrizeRedemptionSortQueryParams,
} from '../../api/PrizeRedemption/PrizeRedemption.type';
import { PaginationQueryParams } from '../../types/PaginationDto';
import { useApiCall } from '../useApiCall';
import { RedemptionRequest } from '../../types/RedemptionRequest';

export type UsePrizeRedemptionConfig = {
	prizeRedemptionClient?: PrizeRedemption;
	pagination?: PaginationQueryParams;
	sort?: PrizeRedemptionSortQueryParams;
	filters?: PrizeRedemptionFilterQueryParams;
	onRejection?: (e: AxiosError) => void;
};

export type UsePrizeRedemptionState = {
	redemptions: RedemptionRequest[];
	pagination: {
		page: number | undefined;
		limit: number | undefined;
		total: number | undefined;
	};
	loading: boolean;
	refetch: () => void;
};

export function usePrizeRedemption({
	prizeRedemptionClient,
	pagination,
	sort,
	filters,
	onRejection,
}: UsePrizeRedemptionConfig = {}): UsePrizeRedemptionState {
	const [requestCount, setRequestCount] = useState(1);
	const client = useMemo(
		() => prizeRedemptionClient ?? new PrizeRedemption(),
		[prizeRedemptionClient]
	);
	const requestParams: [queryParams: PrizeRedemptionQueryParams] = useMemo(
		() => [
			{
				page: pagination?.page ?? 0,
				limit: pagination?.limit ?? 100,
				order: sort?.order,
				orderBy: sort?.orderBy,
				...filters,
			},
		],
		[pagination, sort, filters, requestCount]
	);
	const useApiCallConfig = useMemo(
		() => ({
			onRejection,
		}),
		[onRejection]
	);
	const { data, loading } = useApiCall(
		client.fetchRedemptionRequests,
		requestParams,
		useApiCallConfig
	);

	const redemptions = useMemo(() => data?.results ?? [], [data]);
	const paginationInfo = useMemo(
		() => ({
			page: data?.page,
			limit: data?.limit,
			total: data?.total,
		}),
		[data]
	);
	const refetch = useCallback(() => setRequestCount(count => count + 1), []);

	return {
		pagination: paginationInfo,
		redemptions,
		loading,
		refetch,
	};
}
