import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Users } from '../../api/Users/Users';
import { UserTally } from '../../types/UserTally';
import { OwnTallyContext } from './OwnTallyContext';

export const TALLY_FETCH_REJECTION_MESSAGE =
	'Could not fetch your score, please try again later!';

export type OwnTallyProviderProps = {
	children: ReactNode;
	_usersClient?: Users;
};

export function OwnTallyProvider({
	children,
	_usersClient: usersClient = new Users(),
}: OwnTallyProviderProps) {
	const { enqueueSnackbar } = useSnackbar();
	const [tally, setTally] = useState<UserTally>({
		score: 0,
		badges: 0,
	});
	const [error, setError] = useState<AxiosError>();
	const [loading, setLoading] = useState(true);

	const fetchTallyUpdates = useCallback(() => {
		setError(undefined);
		usersClient
			.getOwnTally()
			.then(({ data }) => {
				setTally(data);
			})
			.catch(e => {
				enqueueSnackbar(TALLY_FETCH_REJECTION_MESSAGE, { variant: 'error' });
				setError(e);
			})
			.finally(() => setLoading(false));
	}, []);

	useEffect(() => {
		usersClient
			.getOwnTally()
			.then(({ data }) => {
				setTally(data);
			})
			.catch(e => {
				enqueueSnackbar(TALLY_FETCH_REJECTION_MESSAGE, { variant: 'error' });
				setError(e);
			})
			.finally(() => setLoading(false));
	}, []);

	const state = useMemo(
		() => ({
			tally,
			error,
			loading,
			fetchTallyUpdates,
		}),
		[tally, loading, error]
	);

	return (
		<OwnTallyContext.Provider value={state}>{children}</OwnTallyContext.Provider>
	);
}
