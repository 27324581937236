import { ReactNode } from 'react';
import { useBrand } from '../../hooks/useBrand';
import { SplashScreen } from '../SplashScreen';

export type BrandGuardProps = {
	children: ReactNode;
	onUnauthorized?: () => void;
};

export function BrandGuard({ children, onUnauthorized }: BrandGuardProps) {
	const { brandId, loading } = useBrand();

	if (!loading && !brandId && onUnauthorized) {
		onUnauthorized();
	}

	return (
		<>
			{loading && <SplashScreen />}
			{!loading && brandId && children}
		</>
	);
}