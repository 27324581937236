import { Box, Button, styled } from '@mui/material';
import { Image } from '../../../../components/Image';
import { Icon } from '../../../../types/Icon';

export type IconTrayProps = {
	icons: Icon[];
	onIconSelect: (iconId: string) => void;
	selectedIconId?: string;
};

export function IconTray({
	icons,
	selectedIconId,
	onIconSelect,
}: IconTrayProps) {
	return (
		<Wrapper>
			{icons.map(icon => (
				<IconButton
					onClick={() => onIconSelect(icon.id)}
					aria-selected={selectedIconId === icon.id}
					aria-label={icon.name}
					key={icon.id}
				>
					<Image src={icon.assets.thumbnail} />
				</IconButton>
			))}
		</Wrapper>
	);
}

const Wrapper = styled(Box)({
	maxHeight: 472,
	overflowY: 'auto',
	padding: '17px 9px',
	backgroundColor: '#F0F1F399',
});

const IconButton = styled(Button)(({ theme }) => ({
	width: 66,
	height: 66,
	margin: 11,
	borderRadius: 10,
	border: '1px solid #F3F3F3',
	boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)',
	backgroundColor: theme.palette.common.white,
	'&:after': {
		content: '""',
		width: '100%',
		display: 'block',
		position: 'absolute',
		backgroundColor: 'transparent',
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		height: 8,
		bottom: 0,
		transition:
			'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
	},
	'&:hover, &[aria-selected="true"]': {
		borderColor: theme.palette.primary.main,
		'&:after': {
			backgroundColor: theme.palette.primary.main,
		},
	},
}));
