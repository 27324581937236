import { createContext, useContext } from 'react';
import { BrandSettingsUpdateDto } from '../../api/Brands/Brands.type';
import { Settings } from '../../types/Settings';

export type ThemeContextObj = {
	loadBrandSettings: () => void;
	updateBrandSettings: (values: BrandSettingsUpdateDto) => void;
	settings: Settings;
	updatingSettings: boolean;
	loading: boolean;
};

export const ThemeContext = createContext<ThemeContextObj | undefined>(
	undefined
);

export function useThemeContext() {
	const ctx = useContext(ThemeContext);
	if (!ctx)
		throw new Error('useThemeContext() cant be used ouside ThemeContextProvider');

	return ctx;
}
