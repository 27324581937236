import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { Badges } from '../../api/Badges';
import { Badge } from '../../types/Badge';
import { UserRelationship } from '../../types/UserRelationship';
import { GiveABadgeView } from './types';
import { Recipients } from '../../api/Recipients';
import { Recipient } from '../../types/Recipient';
import { Awards } from '../../api/Awards';
import { BadgeAwardRequest } from '../../types/BadgeAwardRequest';
import { RecipientRelationships } from '../../api/RecipientRelationships';

export type GiveABadgeControllerProps = {
	badges: Badges;
	recipients: Recipients;
	awards: Awards;
	relationships: RecipientRelationships;
	children?: ReactNode;
};

type GiveABadgeControllerObj = {
	badges: Badge[];
	view: GiveABadgeView;
	recipients: Recipient[];
	fetchingBadges: boolean;
	fetchingRecipients: boolean;
	submittingAwardRequest: boolean;
	userRelationships: UserRelationship[];
	fetchingRelationships: boolean;
	resetView: () => void;
	findRecipientsByName: (partialName: string) => void;
	submitAwardBadge: (awardRequestData: BadgeAwardRequest) => void;
};

const FAILED_RECIPIENT_FETCH_MESSAGE =
	'We could not get recipient data, please try again in a few minutes!';
const FAILED_RELATIONSHIP_FETCH_MESSAGE =
	'We could not get relationship data, please try again in a few minutes!';
const FAILED_BADGE_REQUEST =
	'We could not get the badges, please try again in a few minutes!';
const FAILED_SUBMISSION_MESSAGE =
	'We could not process your request, please try again in a few minutes!';

export const GiveABadgeControllerContext = createContext<
	GiveABadgeControllerObj | undefined
>(undefined);

export function useGiveABadgeController(): GiveABadgeControllerObj {
	const result = useContext(GiveABadgeControllerContext);
	if (!result) {
		throw new Error(
			'useGiveABadgeController() needs to be wrapped in a GiveABadgeController'
		);
	}

	return result;
}

export function GiveABadgeController({
	badges: badgesClient,
	recipients: recipientsClient,
	awards: awardsClient,
	relationships: relationshipsClient,
	children,
}: GiveABadgeControllerProps) {
	const { enqueueSnackbar } = useSnackbar();
	const [badges, setBadges] = useState<Badge[]>([]);
	const [fetchingBadges, setFetchingBadges] = useState(true);
	const [recipients, setRecipients] = useState<Recipient[]>([]);
	const [fetchingRecipients, setFetchingRecipients] = useState(false);
	const [view, setView] = useState<GiveABadgeView>(GiveABadgeView.Form);
	const [submittingAwardRequest, setSubmittingAwardRequest] = useState(false);
	const [relationships, setRelationships] = useState<UserRelationship[]>([]);
	const [fetchingRelationships, setFetchingRelationships] = useState(true);

	useEffect(() => {
		const req = badgesClient.findAll();
		req
			.then(res => {
				setBadges(res.data);
			})
			.catch(() => {
				enqueueSnackbar(FAILED_BADGE_REQUEST, {
					variant: 'error',
				});
			})
			.finally(() => {
				setFetchingBadges(false);
			});
	}, []);

	useEffect(() => {
		const req = relationshipsClient.getAvailableRelationships();
		req
			.then(({ data }) => setRelationships(data))
			.catch(() => {
				enqueueSnackbar(FAILED_RELATIONSHIP_FETCH_MESSAGE, {
					variant: 'error',
				});
			})
			.finally(() => {
				setFetchingRelationships(false);
			});
	}, []);

	const submitAwardBadge: GiveABadgeControllerObj['submitAwardBadge'] =
		useCallback(awardRequestData => {
			setSubmittingAwardRequest(true);
			awardsClient
				.send(awardRequestData)
				.then(() => {
					setView(GiveABadgeView.Confirmation);
				})
				.catch(() => {
					enqueueSnackbar(FAILED_SUBMISSION_MESSAGE, {
						variant: 'error',
					});
				})
				.finally(() => {
					setSubmittingAwardRequest(false);
				});
		}, []);

	const findRecipientsByName = useCallback((partialName: string) => {
		setFetchingRecipients(true);

		recipientsClient
			.searchByName(partialName)
			.then(({ data }) => setRecipients(data))
			.catch(() => {
				enqueueSnackbar(FAILED_RECIPIENT_FETCH_MESSAGE, {
					variant: 'error',
				});
			})
			.finally(() => {
				setFetchingRecipients(false);
			});
	}, []);

	const resetView = useCallback(() => setView(GiveABadgeView.Form), []);

	const ctrl: GiveABadgeControllerObj = useMemo(
		() => ({
			badges,
			view,
			fetchingBadges,
			recipients,
			fetchingRecipients,
			submittingAwardRequest,
			resetView,
			submitAwardBadge,
			findRecipientsByName,
			userRelationships: relationships,
			fetchingRelationships,
		}),
		[
			badges,
			fetchingBadges,
			view,
			relationships,
			fetchingRelationships,
			recipients,
			fetchingRecipients,
			submittingAwardRequest,
		]
	);

	return (
		<GiveABadgeControllerContext.Provider value={ctrl}>
			{children}
		</GiveABadgeControllerContext.Provider>
	);
}
