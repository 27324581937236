import {
	Box,
	BoxProps,
	DialogContentProps,
	DialogTitleProps,
	IconButton,
	IconButtonProps,
	Dialog as MuiDialog,
	DialogContent as MuiDialogContent,
	DialogProps as MuiDialogProps,
	DialogTitle as MuiDialogTitle,
	PaperProps,
	styled,
} from '@mui/material';
import {
	MouseEvent,
	SyntheticEvent,
	useCallback,
	ReactNode,
	useMemo,
} from 'react';
import { CloseIcon } from '../../assets/Icons/Close';
import { Ribbon } from '../Ribbon';
import { OverridableComponentProps } from '../../utils/types';

export type DialogProps = Omit<MuiDialogProps, 'onClose'> & {
	HeaderProps?: BoxProps;
	ContentProps?: DialogContentProps;
	TitleProps?: DialogTitleProps;
	LeftColumnProps?: BoxProps;
	CloseBtnProps?: IconButtonProps;
	titleElement?: ReactNode;
	leftColumn?: ReactNode;
	topBanner?: boolean;
	ContainerProps?: BoxProps;
	onClose?: (e: SyntheticEvent, reason: DialogCloseReason) => void;
};

// eslint-disable-next-line no-shadow
export enum DialogCloseReason {
	CloseButton = 'closeButton',
	BackdropClick = 'backdropClick',
	EscapeKeyDown = 'escapeKeyDown',
}

export function Dialog({
	title,
	children,
	HeaderProps,
	ContentProps,
	LeftColumnProps,
	CloseBtnProps,
	titleElement,
	leftColumn,
	ContainerProps,
	TitleProps,
	topBanner = false,
	onClose,
	...props
}: DialogProps) {
	const handleCloseButtonClick = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			if (onClose) onClose(e, DialogCloseReason.CloseButton);
		},
		[onClose]
	);

	const handleNativeDialogOnClose: NonNullable<MuiDialogProps['onClose']> =
		useCallback(
			(e, reason) => {
				if (onClose) onClose(e as SyntheticEvent, reason as DialogCloseReason);
			},
			[onClose]
		);

	const MuiDialogSX = useMemo(() => {
		const result: PaperProps['sx'] = [
			theme => ({
				borderRadius: 0,
				[theme.breakpoints.down('md')]: {
					width: '100vw',
					height: '100vh',
					maxHeight: '100vh',
					margin: 0,
				},
			}),
		];

		if (props.PaperProps?.sx) {
			// @ts-ignore
			result.push(props.PaperProps.sx);
		}

		return result;
	}, [props.PaperProps]);

	return (
		<MuiDialog
			PaperProps={{
				...props?.PaperProps,
				sx: MuiDialogSX,
			}}
			maxWidth='md'
			onClose={handleNativeDialogOnClose}
			fullWidth
			{...props}
		>
			<Ribbon
				height={14}
				sx={{
					flex: '0 0 auto',
				}}
				color='linear-gradient(136.26deg, #323232 8%, #000000 99.41%)'
				data-testid='top-ribbon'
			/>
			<Box
				sx={theme => ({
					flex: '1 auto',
					width: '100%',
					display: 'flex',
					flexWrap: 'nowrap',
					overflowY: 'auto',
					[theme.breakpoints.down('md')]: {
						flexDirection: 'column',
					},
				})}
			>
				{leftColumn && !topBanner && <Box {...LeftColumnProps}>{leftColumn}</Box>}
				<Box sx={{ flex: 1 }} {...ContainerProps}>
					<DialogHeader component='header' {...HeaderProps}>
						{(title != null || titleElement != null) && (
							<DialogTitle {...TitleProps}>{titleElement || title}</DialogTitle>
						)}
						<CloseButton
							{...CloseBtnProps}
							size='small'
							aria-label='Close dialog'
							onClick={handleCloseButtonClick}
						>
							<CloseIcon sx={{ fontSize: theme => theme.typography.pxToRem(14) }} />
						</CloseButton>
					</DialogHeader>
					{leftColumn && topBanner && <Box {...LeftColumnProps}>{leftColumn}</Box>}
					<DialogContent {...ContentProps}>{children}</DialogContent>
				</Box>
			</Box>
		</MuiDialog>
	);
}

const DialogHeader = styled(Box)<OverridableComponentProps<typeof Box>>({
	position: 'relative',
	paddingLeft: 40,
	paddingRight: 40,
});

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
	fontWeight: 700,
	paddingTop: 68,
	paddingLeft: 0,
	paddingRight: 0,
	lineHeight: 1.22,
	paddingBottom: 16,
	textTransform: 'uppercase',
	fontSize: theme.typography.pxToRem(22),
	[theme.breakpoints.up('sm')]: {
		paddingTop: 25,
		paddingBottom: 28,
	},
}));

const CloseButton = styled(IconButton)({
	top: 16,
	right: 25,
	position: 'absolute',
});

const DialogContent = styled(MuiDialogContent)({
	paddingTop: 0,
	paddingLeft: 40,
	paddingRight: 40,
	paddingBottom: 54,
});
