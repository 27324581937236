import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { Badges } from '../../../../api/Badges';
import { useBadgeIcons } from '../../../../hooks/useBadgeIcons';
import { useColors } from '../../../../hooks/useColors';
import { CreateBadgeConfig } from '../../../../types/Badge';
import { Color } from '../../../../types/Color';
import { BadgeEditorView } from './BadgeEditorView';

export const DEFAULT_COLOR: Color = {
	name: 'Black',
	id: 'default_black',
	shade: '#000000',
	prominent: '#000000',
	medalBorder: '#000000',
	separator: '#000000',
	ribbonSeparator: '#000000',
	gradient: {
		from: '#000000',
		to: '#000000',
	},
};

export const BADGE_SAVE_SUCCESS_MESSAGE = 'Your badge was saved successfully';

export const BADGE_SAVE_ERROR_MESSAGE =
	'Could not save your badge, please try again later!';

export type BadgeEditorProps = {
	onBadgeSaved?: () => void;
	badgesClient?: Badges;
};

export function BadgeEditor({
	onBadgeSaved,
	badgesClient = new Badges(),
}: BadgeEditorProps) {
	const { enqueueSnackbar } = useSnackbar();
	const { colors: fetchedColors } = useColors();
	const { icons } = useBadgeIcons();
	const [saving, setSaving] = useState(false);

	const colors = useMemo(() => {
		if (!fetchedColors.length) return [DEFAULT_COLOR];
		return fetchedColors;
	}, [fetchedColors]);

	const createBadge = useCallback(
		(config: CreateBadgeConfig) => {
			if (saving) return;

			setSaving(true);
			badgesClient
				.createBadge(config)
				.then(() => {
					if (onBadgeSaved) onBadgeSaved();
					enqueueSnackbar(BADGE_SAVE_SUCCESS_MESSAGE, { variant: 'success' });
				})
				.catch(() =>
					enqueueSnackbar(BADGE_SAVE_ERROR_MESSAGE, {
						variant: 'error',
					})
				)
				.finally(() => setSaving(false));
		},
		[saving]
	);

	return (
		<BadgeEditorView
			icons={icons}
			colors={colors}
			saving={saving}
			onSave={createBadge}
		/>
	);
}
