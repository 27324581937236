import { ReactNode, useMemo } from 'react';
import { PrizeRedemption } from '../../api/PrizeRedemption';
import {
	PrizeRedemptionContext,
	PrizeRedemptionContextObj,
} from './PrizeRedemptionContext';

export type PrizeRedemptionProviderProps = {
	children?: ReactNode;
	redemptionClient: PrizeRedemption;
};

export function PrizeRedemptionProvider({
	children,
	redemptionClient,
}: PrizeRedemptionProviderProps) {
	const value: PrizeRedemptionContextObj = useMemo(
		() => ({
			redeemPrizes: redemptionClient.redeemPrizes,
		}),
		[redemptionClient]
	);
	return (
		<PrizeRedemptionContext.Provider value={value}>
			{children}
		</PrizeRedemptionContext.Provider>
	);
}
