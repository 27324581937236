import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { Fetcher, useApiCall } from '../../hooks/useApiCall';
import { ResultList } from '../../types/ResultList';
import { SortOrder } from '../../types/SortDto';

export type AdminFeedProps<
	Result extends Record<string, any> = Record<string, any>,
	Filters extends Record<string, any> = Record<string, any>,
	FetcherParameters extends any[] = any[]
> = Omit<DataGridProps, 'rows' | 'onPageChange'> & {
	page?: number;
	pageSize?: number;
	filters?: Filters;
	defaultSortModel?: SortModel;
	fetcher: Fetcher<FetcherParameters, ResultList<Result[]>>;
	mapSortField?: (field: string) => string;
};

type SortModel = {
	order: SortOrder;
	orderBy: string;
};

export function AdminFeed<
	Result extends Record<string, any> = Record<string, any>,
	Filters extends Record<string, any> = Record<string, any>,
	FetcherParameters extends any[] = any[]
>({
	filters,
	defaultSortModel,
	page: defaultPage = 1,
	pageSize: defaultPageSize = 100,
	fetcher,
	mapSortField = (field: string) => field,
	...props
}: AdminFeedProps<Result, Filters, FetcherParameters>) {
	const [page, setPage] = useState(defaultPage);
	const [pageSize, setPageSize] = useState(defaultPageSize);
	const [sortModel, setSortModel] = useState<SortModel | undefined>(
		defaultSortModel
	);

	const apiParams = useMemo(
		() =>
			[{ page, limit: pageSize, ...sortModel, ...filters }] as FetcherParameters,
		[page, pageSize, sortModel, filters]
	);
	const { data: response, loading } = useApiCall<
		ResultList<Result[]>,
		FetcherParameters
	>(fetcher, apiParams as any);

	const handlePageChange = useCallback((newPage: number) => {
		setPage(newPage);
	}, []);

	const handlePageSizeChange = useCallback((newPageSize: number) => {
		setPageSize(newPageSize);
	}, []);

	const resetSortModel = useCallback(() => {
		setSortModel(defaultSortModel ?? undefined);
	}, [defaultSortModel]);

	const handleSortModelChange: NonNullable<
		ComponentProps<typeof DataGrid>['onSortModelChange']
	> = useCallback(model => {
		if (!model.length) {
			resetSortModel();
			return;
		}

		setSortModel({
			order: model[0].sort as SortOrder,
			orderBy: mapSortField(model[0].field),
		});
	}, []);

	const rows = useMemo(() => {
		if (!response?.results) return [];
		return response.results.map((entry, i: number) => {
			if (entry.id != null) return entry;
			return { id: i, ...entry };
		});
	}, [response]);

	return (
		<DataGrid
			paginationMode='server'
			rows={rows}
			rowCount={response?.total}
			page={page}
			loading={loading}
			pageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onSortModelChange={handleSortModelChange}
			{...props}
		/>
	);
}
