import { ComponentThemeOverride } from '../../utils/types';

export const MuiAutocomplete: ComponentThemeOverride['MuiAutocomplete'] = {
	styleOverrides: {
		inputRoot: {
			'&.MuiInputBase-sizeSmall': {
				padding: '11px 16px 10px',
				'.MuiAutocomplete-input': {
					padding: 0,
				},
			},
		},
	},
};
