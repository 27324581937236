import axios from 'axios';
import { Environment } from '../env';
import { authentication } from './interceptors/authentication';
import { brandIdStorage, tokenStorage } from '../globalStores';
import { brand } from './interceptors/brand';

const baseConfig = {
	baseURL: Environment.BRAG_BADGES_API_HOST,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
};

export const publicClient = axios.create(baseConfig);
publicClient.interceptors.request.use(brand(brandIdStorage));

export const privateClient = axios.create(baseConfig);
privateClient.interceptors.request.use(
	authentication({
		tokenStorage,
	}) as any
);
privateClient.interceptors.request.use(brand(brandIdStorage));
