import { FormValues } from './PrizeRedemptionForm.type';

export const DEFAULT_SELECTED_PRIZE = {
	id: 'none',
	name: 'None',
};

export const INITIAL_VALUES: FormValues = {
	prizes: [DEFAULT_SELECTED_PRIZE.id, DEFAULT_SELECTED_PRIZE.id],
	donateToCharity: false,
};

export const NO_PRIZE_SELECTED_MESSAGE =
	'You must select at least 1 item to redeem and have the correct number of points in order to continue.';
