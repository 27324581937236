import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Auth } from '../../api/Auth';
import { privateClient } from '../../api/clients';
import { PersistentStorage } from '../../classes/PersistentStorage';
import { tokenStorage } from '../../globalStores';
import { LoginRequest } from '../../types/LoginRequest';
import { UserLogin } from '../../types/User';
import { encryptStorage } from '../../utils/storage';

export type AuthProps = {
  children: ReactNode;
  onLoginRequired?: (redirectTo?: string) => void;
  onAfterLogin?: (firstAccess: boolean) => void;
  storage?: PersistentStorage;
  authClient?: Auth;
};

export type AuthObj = {
  user: UserLogin | undefined;
  bootingUp: boolean;
  loggedIn: boolean;
  login: (params: LoginRequest) => void;
  requireLogin: () => void;
  logout: () => void;
  loginInProcess: boolean;
};

const FAILED_LOGIN_REQUEST = 'Incorrect Username or Password';

export const AuthContext = createContext<AuthObj | undefined>(undefined);

export function useAuth(): AuthObj {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('Needs to be wrapped in AuthContext');
  }

  return context;
}

export function AuthProvider({
  children,
  onLoginRequired,
  onAfterLogin,
  storage = new PersistentStorage('bb_auth'),
  authClient = new Auth(),
}: AuthProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [bootingUp, setBootingUp] = useState(true);
  const [selfLoggedOut, setSelfLoggedOut] = useState(false);
  const [user, setUser] = useState<UserLogin | undefined>();
  const [loginInProcess, setLoginInProcess] = useState(false);

  const loggedIn = useMemo(() => !bootingUp && Boolean(user), [bootingUp, user]);

  const logUserOut = useCallback(() => {
    storage.delete('user');
    tokenStorage.removeToken('accessToken');
    setUser(undefined);
  }, []);

  const handleUnauthenticatedRequest = useCallback((error: AxiosError<any>) => {
    if (error.response?.status === 401) {
      logUserOut();
      return new Promise(() => { });
    }
    return Promise.reject(error);
  }, []);

  // Unauthenticated request handler setup
  useEffect(() => {
    privateClient.interceptors.response.use(
      response => response,
      handleUnauthenticatedRequest
    );
  }, []);

  // Authentication state boot-up
  useEffect(() => {
    const persistedUser = storage.get<UserLogin | undefined>('user');
    const accessToken = tokenStorage.getToken('accessToken');

    if (persistedUser && accessToken) setUser(persistedUser);
    setBootingUp(false);
  }, []);

  const doLogin = (params: LoginRequest) => {
    setLoginInProcess(true);
    authClient
      .login(params)
      .then(({ data }) => {
        if (data?.access_token) {
          setUser(data);
          storage.set('user', data);
          tokenStorage.setToken('accessToken', data.access_token);
          if (onAfterLogin) onAfterLogin(Boolean(data.firstAccess));
          if (params.rememberMe) {
            const fakepwd = 'xxno-passwordxx';
            storage.set('remember', {
              ...params,
              password: 'xxno-passwordxx',
            });
            if (params.password !== fakepwd) {
              encryptStorage.setItem('hsencriptedkey', params.password);
            }

          } else {
            storage.delete('remember');
            encryptStorage.removeItem('hsencriptedkey');
          }
        }
      })
      .catch(err => {
        enqueueSnackbar(FAILED_LOGIN_REQUEST, {
          variant: 'error',
        });
      })
      .finally(() => setLoginInProcess(false));
  };

  const selfLogout = useCallback(() => {
    setSelfLoggedOut(true);
    logUserOut();
  }, []);

  const requireLogin = useCallback(() => {
    setSelfLoggedOut(false);
    if (onLoginRequired) onLoginRequired(!selfLoggedOut ? pathname : undefined);
  }, [pathname, selfLoggedOut, onLoginRequired]);

  const ctrl: AuthObj = useMemo(
    () => ({
      user,
      loggedIn,
      bootingUp,
      loginInProcess,
      login: doLogin,
      logout: selfLogout,
      requireLogin,
    }),
    [user, bootingUp, loggedIn, requireLogin, loginInProcess]
  );

  return <AuthContext.Provider value={ctrl}>{children}</AuthContext.Provider>;
}
