import { AxiosResponseTransformer } from 'axios';
import { extendAxiosTransformer } from '../../utils/extendAxiosTransformer.helper';
import { ApiClient } from '../ApiClient';
import {
	UpdateUserRelationship,
	UpdateUserRelationshipItem,
	UserRelationship,
} from '../../types/UserRelationship';
import {
	RecipientRelationshipDto,
	UpdateUserRelationshipItemDto,
} from './RecipientRelationships.type';

export class RecipientRelationships extends ApiClient {
	private baseEndpoint = '/recipient-relationships';

	getAvailableRelationships = () =>
		this.httpClient.get<UserRelationship[]>(this.endpoint(), {
			transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
				this.httpClient.defaults.transformResponse,
				[RecipientRelationships.transformResponse]
			),
		});

	updateRelationship = (id: string, config: UpdateUserRelationship) =>
		this.protectedHttpClient.patch(this.endpoint(id), config);

	updateRelationships = (updates: UpdateUserRelationshipItem[]) =>
		this.protectedHttpClient.patch(
			this.endpoint(),
			updates.map(RecipientRelationships.updateItemDtoFromUpdateItem)
		);

	static transformResponse(data: RecipientRelationshipDto[]) {
		if (!(data && Array.isArray(data))) return [];
		return data.map(RecipientRelationships.relationshipFromDto);
	}

	static relationshipFromDto(dto: RecipientRelationshipDto): UserRelationship {
		const result: UserRelationship = {
			id: String(dto.id),
			name: dto.name,
		};

		if (dto.modifier != null) result.modifier = dto.modifier;

		return result;
	}

	private endpoint(id?: string) {
		if (!id) return this.baseEndpoint;
		return `${this.baseEndpoint}/${id}`;
	}

	static updateItemDtoFromUpdateItem = (
		updateItem: UpdateUserRelationshipItem
	): UpdateUserRelationshipItemDto => ({
		id: updateItem.id,
		modifier: updateItem.modifier,
	});
}
