import { AxiosError } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { Badges } from '../../api/Badges';
import { useApiCall } from '../useApiCall';
import { Badge, UpdateBadgeConfig } from '../../types/Badge';

export type UseBadgeConfig = {
	badgesClient?: Badges;
	onBadgeUpdated?: () => void;
	onBadgeUpdateError?: (e: AxiosError) => void;
	onBadgeDeleted?: () => void;
	onBadgeDeleteError?: (e: AxiosError) => void;
};

export type UseBadgeState = {
	badge: Required<Badge> | undefined;
	loading: boolean;
	deleting: boolean;
	deleteBadge: () => void;
	updating: boolean;
	updateBadge: (update: UpdateBadgeConfig) => void;
};

export function useBadge(
	id: string,
	config: UseBadgeConfig = {}
): UseBadgeState {
	const [deleting, setDeleting] = useState(false);
	const [updating, setUpdating] = useState(false);

	const client: Badges = useMemo(
		() => config.badgesClient ?? new Badges(),
		[config.badgesClient]
	);
	const fetchParams: Parameters<typeof client.getBadgeById> = useMemo(
		() => [id],
		[id]
	);
	const { data: badge, loading } = useApiCall(client.getBadgeById, fetchParams);

	const deleteBadge = useCallback(() => {
		setDeleting(true);

		client
			.deleteBadgeById(id)
			.then(() => {
				if (config.onBadgeDeleted) config.onBadgeDeleted();
			})
			.catch(e => {
				console.error('useBadge.deleteBadge()', e);
				if (config.onBadgeDeleteError) config.onBadgeDeleteError(e);
			})
			.finally(() => setDeleting(false));
	}, [id, config.onBadgeDeleted, config.onBadgeDeleteError]);

	const updateBadge = useCallback(
		(update: UpdateBadgeConfig) => {
			setUpdating(true);
			client
				.updateBadgeById(id, update)
				.then(() => {
					if (config.onBadgeUpdated) config.onBadgeUpdated();
				})
				.catch(e => {
					console.error('useBadge.updateBadge()', e);
					if (config.onBadgeUpdateError) config.onBadgeUpdateError(e);
				})
				.finally(() => setUpdating(false));
		},
		[id, config.onBadgeUpdated, config.onBadgeUpdateError]
	);

	return {
		badge,
		loading,
		deleting,
		deleteBadge,
		updating,
		updateBadge,
	};
}
