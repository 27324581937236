import { Button, Box, CircularProgress } from '@mui/material';
import { useCallback, useState } from 'react';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { AdminPage } from '../../templates/AdminPage';
import {
	BadgeEditorDialog,
	BadgeEditorDialogCloseReason,
	BadgeEditorDialogProps,
} from './components/BadgeEditorDialog';
import { Badge } from '../../types/Badge';
import { BadgeDetailsDialog } from './components/BadgeDetailsDialog';
import { Can } from '../../components/Can';
import { Action, ActionSubject } from '../../utils/enums/abilities';

export type BadgeManagementViewProps = {
	badges?: Badge[];
	loading?: boolean;
	onFeedUpdateNeeded?: () => void;
};

const COLUMNS: GridColDef[] = [
	{
		headerName: 'Badge',
		field: 'assets',
		renderCell: params => (
			<img width='90' src={params.value.small_svg} alt='Badge' />
		),
		sortable: false,
		align: 'center',
		headerAlign: 'center',
	},
	{
		headerName: 'Name',
		field: 'name',
		flex: 1,
		align: 'center',
		headerAlign: 'center',
		sortable: false,
	},
	{
		headerName: 'Points',
		field: 'points',
		flex: 1,
		align: 'center',
		headerAlign: 'center',
		sortable: false,
	},
];

const DATA_GRID_STYLE = {
	'& .MuiDataGrid-row:hover': {
		cursor: 'pointer',
	},
};

export function BadgeManagementView({
	badges = [],
	loading,
	onFeedUpdateNeeded,
}: BadgeManagementViewProps) {
	const [showNewBadgeDialog, setShowNewBadgeDialog] = useState(false);
	const [selectedBadgeId, setSelectedBadgeId] = useState<string>();
	const [showBadgeDetailsDialog, setShowBadgeDetailsDialog] = useState(false);

	const openNewBadgeDialog = useCallback(() => setShowNewBadgeDialog(true), []);
	const closeNewBadgeDialog: NonNullable<BadgeEditorDialogProps['onClose']> =
		useCallback(reason => {
			setShowNewBadgeDialog(false);
			if (
				reason === BadgeEditorDialogCloseReason.BadgeSaved &&
				onFeedUpdateNeeded
			) {
				onFeedUpdateNeeded();
			}
		}, []);

	const handleRowClick: GridEventListener<'rowClick'> = useCallback(
		({ row }) => {
			setSelectedBadgeId(row.id);
			setShowBadgeDetailsDialog(true);
		},
		[]
	);

	const closeDetailsDialog = useCallback(() => {
		setShowBadgeDetailsDialog(false);
		setTimeout(() => setSelectedBadgeId(undefined), 600);
	}, []);

	const handleBadgeUpdatedOrDeletion = useCallback(() => {
		if (onFeedUpdateNeeded) onFeedUpdateNeeded();
		closeDetailsDialog();
	}, []);

	return (
		<>
			<AdminPage
				title='Here you can view existing badges in your system and create a new custom badge'
				titleComponent='span'
				actions={
					<Can on={ActionSubject.Badge} do={Action.Create}>
						<Button
							sx={{ width: '207px' }}
							variant='contained'
							color='primary'
							onClick={openNewBadgeDialog}
						>
							Add badge
						</Button>
					</Can>
				}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						width: '100%',
						paddingTop: '40px',
					}}
				>
					{loading && <CircularProgress />}
					{!loading && Boolean(badges.length) && (
						<DataGrid
							pageSize={100}
							headerHeight={47}
							rowHeight={140}
							columns={COLUMNS}
							sx={DATA_GRID_STYLE}
							rows={badges}
							onRowClick={handleRowClick}
							disableSelectionOnClick
							disableColumnSelector
							disableColumnFilter
							disableColumnMenu
							autoHeight
							hideFooter
						/>
					)}
				</Box>
			</AdminPage>
			<BadgeEditorDialog open={showNewBadgeDialog} onClose={closeNewBadgeDialog} />
			<BadgeDetailsDialog
				badgeId={selectedBadgeId}
				open={showBadgeDetailsDialog}
				onBadgeUpdate={handleBadgeUpdatedOrDeletion}
				onBadgeDeleted={handleBadgeUpdatedOrDeletion}
				onClose={closeDetailsDialog}
			/>
		</>
	);
}
