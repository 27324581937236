import { LoadingButton } from '@mui/lab';
import {
	Box,
	Grid,
	InputLabel,
	MenuItem,
	TextField,
	styled,
} from '@mui/material';
import { Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { Section } from '../../../../components/Section';
import { CreateBadgeConfig } from '../../../../types/Badge';
import { Color } from '../../../../types/Color';
import { Icon } from '../../../../types/Icon';
import { BadgePreview } from '../BadgePreview';
import { IconTray } from '../IconTray';
import { validationSchema } from './BadgeEditor.schema';

const NAME_PLACEHOLDER = 'Wow Amazing Work!';

export type BadgeEditorViewProps = {
	colors: Color[];
	icons: Icon[];
	onSave: (badgeConfig: FormValues) => void;
	saving?: boolean;
};

type FormValues = Omit<CreateBadgeConfig, 'slug'>;

export function BadgeEditorView({
	colors,
	icons,
	saving,
	onSave,
}: BadgeEditorViewProps) {
	const initialValues: FormValues = useMemo(
		() => ({
			name: '',
			points: 0,
			iconId: '',
			colorId: colors[0].id,
		}),
		[colors]
	);

	// Key to force formik to reinitialize after new colors are available
	const formikKey = useMemo(
		() => JSON.stringify(initialValues),
		[initialValues]
	);

	const handleSubmit = useCallback(
		(value: FormValues) => {
			onSave(value);
		},
		[onSave]
	);

	return (
		<Formik<FormValues>
			isInitialValid={false}
			key={formikKey}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({
				values,
				isValid,
				setFieldValue,
				handleSubmit,
				getFieldMeta,
				getFieldProps,
			}) => {
				const getFieldFeedbackProps = (field: string) => {
					const { error, touched } = getFieldMeta(field);
					const hasError = touched && Boolean(error);

					return {
						error: hasError,
						helperText: hasError ? error : undefined,
					};
				};

				const handleIconSelection = (id: string) => {
					setFieldValue('iconId', id);
				};

				const selectedColor = colors.find(
					({ id }) => values.colorId === id
				) as Color;
				const selectedIcon = icons.find(({ id }) => values.iconId === id) as Icon;

				return (
					<form onSubmit={handleSubmit}>
						<Grid container>
							<PreviewColumn xs={12} md item>
								<Section
									ContentProps={{ sx: { paddingRight: '32px' } }}
									title='Step 1'
									subtitle='Select color'
								>
									<Preview>
										<BadgePreview
											title={values.name || NAME_PLACEHOLDER}
											color={selectedColor}
											iconSrc={selectedIcon?.assets?.full}
										/>
									</Preview>
									<Fieldset>
										<FieldLabel id='badge-editor-color-input'>Ribbon Color</FieldLabel>
										<TextField
											SelectProps={{
												inputProps: {
													'aria-labelledby': 'badge-editor-color-input',
												},
												SelectDisplayProps: {
													'aria-labelledby': 'badge-editor-color-input',
												},
											}}
											{...getFieldProps('colorId')}
											{...getFieldFeedbackProps('colorId')}
											fullWidth
											select
										>
											{colors.map(({ id, name }) => (
												<MenuItem key={id} value={id}>
													{name}
												</MenuItem>
											))}
										</TextField>
									</Fieldset>
									<Fieldset>
										<FieldLabel id='badge-editor-name-input'>Add Name</FieldLabel>
										<TextField
											InputProps={{
												inputProps: {
													'aria-labelledby': 'badge-editor-name-input',
												},
											}}
											placeholder={NAME_PLACEHOLDER}
											type='text'
											{...getFieldProps('name')}
											{...getFieldFeedbackProps('name')}
											fullWidth
										/>
									</Fieldset>
									<Fieldset sx={{ width: '100%', maxWidth: '97px' }}>
										<FieldLabel id='badge-editor-points-input'>Point Value</FieldLabel>
										<TextField
											InputProps={{
												inputProps: {
													'aria-labelledby': 'badge-editor-points-input',
													min: 0,
													max: 100,
												},
											}}
											type='number'
											{...getFieldProps('points')}
											{...getFieldFeedbackProps('points')}
											fullWidth
										/>
									</Fieldset>
								</Section>
							</PreviewColumn>
							<IconColumn xs={12} md item>
								<Section title='Step 2' subtitle='Pick icon'>
									<IconTray
										icons={icons}
										selectedIconId={values.iconId}
										onIconSelect={handleIconSelection}
									/>
									<SaveButton
										type='submit'
										color='primary'
										variant='contained'
										disabled={!isValid}
										loading={saving}
									>
										Save
									</SaveButton>
								</Section>
							</IconColumn>
						</Grid>
					</form>
				);
			}}
		</Formik>
	);
}

const PreviewColumn = styled(Grid)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		flex: '1 auto',
		width: '100%',
		maxWidth: 389,
	},
}));

const Preview = styled(Box)({
	width: '100%',
	maxWidth: 207,
	marginLeft: 'auto',
	marginRight: 'auto',
	marginTop: 34,
	marginBottom: 24,
});

const IconColumn = styled(Grid)({
	flex: 1,
});

const Fieldset = styled('fieldset')({
	padding: 0,
	margin: 0,
	border: 0,
});

const FieldLabel = styled(InputLabel)(({ theme }) => ({
	left: 0,
	marginBottom: 8,
	lineHeight: 1.167,
	color: 'inherit',
	textTransform: 'uppercase',
	fontSize: theme.typography.pxToRem(12),
}));

const SaveButton = styled(LoadingButton)({
	width: '100%',
	maxWidth: 118,
	marginTop: 32,
	display: 'flex',
	marginLeft: 'auto',
	marginRight: 'auto',
});
