import { styled } from '@mui/material';
import { ComponentProps } from 'react';
import leftColumnDottedBackground from '../../assets/img/left-column-dotted-background.svg';
import { Page as BasePage } from '../Page';

export type LeftDottedPageProps = ComponentProps<typeof BasePage>;

export function LeftDottedPage({ children, ...props }: LeftDottedPageProps) {
	return <Page {...props}>{children}</Page>;
}

const Page = styled(BasePage)({
	'&:before': {
		content: '""',
		left: 0,
		zIndex: -1,
		position: 'absolute',
		width: '100%',
		height: '100vh',
		display: 'block',
		pointerEvents: process.env.NODE_ENV === 'production' ? 'none' : 'all',
		background: `url(${leftColumnDottedBackground})`,
		backgroundRepeat: 'no-repeat',
		backgroundPositionY: '48px',
	},
});
