import { PureAbility } from '@casl/ability';

export type Permission = {
	/** The action or actions the user is expected be allowed to do. It evaluates as a logical OR. */
	do: string | string[];
	/** The subject or subjects the user is expected be allowed to perform an action upon. It evaluates as a logical OR. */
	on: string | string[];
};

export function evaluatePermissions<A extends PureAbility>(
	ability: A,
	permissions: Permission[]
) {
	return permissions.some(permission =>
		evaluateAbilities(ability, permission.do, permission.on)
	);
}

export function evaluateAbilities<A extends PureAbility>(
	ability: A,
	actionOrActions: string | string[],
	subjectOrSubjects: string | string[]
) {
	const actions = ([] as string[]).concat(actionOrActions);
	const subjects = ([] as string[]).concat(subjectOrSubjects);

	return subjects.some(subject =>
		// eslint-disable-next-line react/destructuring-assignment
		actions.some(action => ability.can(action, subject))
	);
}
