import { Button } from '@mui/material';

export type DashboardPrizesTableProps = {
	startRedemption: () => void;
};

export function DashboardPrizesTable({
	startRedemption,
}: DashboardPrizesTableProps) {
	return (
		<Button variant='contained' color='success' onClick={startRedemption}>
			Redeem Points
		</Button>
	);
}
