import { createContext, useContext } from 'react';
import { Users } from '../../api/Users';
import { Recipients } from '../../api/Recipients';

export type UsersContextObj = {
	getAllUsers: Users['getAllUsers'];
	awardPointsToUsers: Users['awardPointsToUsers'];
	getTransactionHistoryByUserId: Users['getTransactionHistoryByUserId'];
	searchByName: Recipients['searchByName'];
};

export const UsersContext = createContext<UsersContextObj | undefined>(
	undefined
);

export function useUsersContext() {
	const result = useContext(UsersContext);

	if (!result) throw new OutsideUsersContextError();

	return result;
}

export class OutsideUsersContextError extends Error {
	constructor(
		message = 'useUsersContext() cannot be used outside a UsersContext'
	) {
		super(message);
	}
}
