import { Navigate } from 'react-router-dom';
import { useAdminLinks } from '../../hooks/useAdminLinks';

export function IndexAdminPage() {
	const { links } = useAdminLinks();

	if (!links.length) return null;

	return <Navigate to={links[0].path} replace />;
}
