import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	MenuItem,
	TextField,
} from '@mui/material';
import { Formik } from 'formik';
import { MouseEvent, useCallback, useMemo } from 'react';
import { EditButton } from '../../../../components/EditButton';
import { FieldLabel } from '../../../../components/FieldLabel';
import { Fieldset } from '../../../../components/Fieldset';
import { UpdateBadgeConfig } from '../../../../types/Badge';
import { Color } from '../../../../types/Color';
import { RemoveButton } from '../../../../components/RemoveButton';
import { badgeEditingValidationSchema } from '../../schemas/badgeEditingSchema.schema';
import { BadgePreview } from '../BadgePreview';
import { Can } from '../../../../components/Can';
import { Action, ActionSubject } from '../../../../utils/enums/abilities';

export type BadgeDetailsViewProps = {
	name?: string;
	points?: number;
	color?: Color;
	iconSrc?: string;
	colors?: Color[];
	editing?: boolean;
	saving?: boolean;
	removing?: boolean;
	loading?: boolean;
	onSave?: (values: FormValues) => void;
	onEditButtonClick?: () => void;
	onRemoveButtonClick?: () => void;
	onCloseWindowButtonClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

type FormValues = Required<Omit<UpdateBadgeConfig, 'slug'>>;

export function BadgeDetailsView({
	color,
	iconSrc,
	editing,
	saving,
	removing,
	loading,
	name = '',
	points = 0,
	colors = [],
	onSave,
	onEditButtonClick,
	onRemoveButtonClick,
	onCloseWindowButtonClick,
}: BadgeDetailsViewProps) {
	const initialValues = useMemo(
		() => ({
			colorId: color?.id ?? '',
			name,
			points,
		}),
		[name, points, color]
	);

	// Key to force formik to reinitialize after new colors are available
	const formikKey = useMemo(
		() => JSON.stringify(initialValues),
		[initialValues]
	);

	const handleFormSubmit = useCallback(
		(values: FormValues) => {
			if (onSave) onSave(values);
		},
		[onSave]
	);

	if (loading)
		return (
			<Box
				sx={{
					width: '100%',
					minHeight: '440px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
				data-testid='loading-screen'
			>
				<CircularProgress />
			</Box>
		);

	return (
		<Formik<FormValues>
			isInitialValid={false}
			key={formikKey}
			initialValues={initialValues}
			validationSchema={badgeEditingValidationSchema}
			onSubmit={handleFormSubmit}
			enableReinitialize
		>
			{({ values, isValid, getFieldProps, getFieldMeta, handleSubmit }) => {
				const getFieldFeedbackProps = (field: string) => {
					const { error, touched } = getFieldMeta(field);
					const hasError = touched && Boolean(error);

					return {
						error: hasError,
						helperText: hasError ? error : undefined,
					};
				};

				const selectedColor: Color =
					colors.find(({ id }) => id === values.colorId) ?? colors[0];

				return (
					<Grid component='form' onSubmit={handleSubmit} container>
						<Can do={Action.Update} on={ActionSubject.Badge}>
							<Grid sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={12} item>
								<EditButton onClick={onEditButtonClick} />
							</Grid>
						</Can>
						<Grid xs={12} md={4} item>
							<BadgePreview
								title={values.name}
								color={selectedColor}
								iconSrc={iconSrc}
							/>
						</Grid>
						<Grid
							sx={theme => ({
								[theme.breakpoints.up('md')]: {
									paddingLeft: '92px',
								},
							})}
							xs={12}
							md={8}
							item
						>
							<Fieldset>
								<FieldLabel id='badge-editor-color-input'>Ribbon Color</FieldLabel>
								<TextField
									SelectProps={{
										inputProps: {
											'aria-labelledby': 'badge-editor-color-input',
											'data-testid': 'color-input',
										},
										SelectDisplayProps: {
											'aria-labelledby': 'badge-editor-color-input',
										},
										readOnly: !editing,
									}}
									{...getFieldProps('colorId')}
									{...getFieldFeedbackProps('colorId')}
									fullWidth
									select
								>
									{colors.map(({ id, name }) => (
										<MenuItem key={id} value={id}>
											{name}
										</MenuItem>
									))}
								</TextField>
							</Fieldset>
							<Fieldset>
								<FieldLabel id='badge-editor-name-input'>Add Name</FieldLabel>
								<TextField
									InputProps={{
										inputProps: {
											'aria-labelledby': 'badge-editor-name-input',
										},
										readOnly: !editing,
									}}
									{...getFieldProps('name')}
									{...getFieldFeedbackProps('name')}
									type='text'
									fullWidth
								/>
							</Fieldset>
							<Fieldset sx={{ width: '100%', maxWidth: '97px' }}>
								<FieldLabel id='badge-editor-points-input'>Point Value</FieldLabel>
								<TextField
									InputProps={{
										inputProps: {
											'aria-labelledby': 'badge-editor-points-input',
											min: 0,
											max: 100,
										},
										readOnly: !editing,
									}}
									type='number'
									{...getFieldProps('points')}
									{...getFieldFeedbackProps('points')}
									fullWidth
								/>
							</Fieldset>
							<Can do={Action.Delete} on={ActionSubject.Badge}>
								{editing && (
									<RemoveButton
										loading={removing}
										disabled={saving}
										onClick={onRemoveButtonClick}
									/>
								)}
							</Can>
						</Grid>
						<Grid
							sx={{ display: 'flex', justifyContent: 'center', marginTop: '64px' }}
							xs={12}
							item
						>
							<Button
								variant='contained'
								color={editing ? 'secondary' : 'primary'}
								onClick={onCloseWindowButtonClick}
							>
								Close Window
							</Button>
							{editing && (
								<LoadingButton
									sx={{ marginLeft: '48px' }}
									variant='contained'
									color='primary'
									type='submit'
									disabled={!isValid || removing}
									loading={saving}
								>
									Save
								</LoadingButton>
							)}
						</Grid>
					</Grid>
				);
			}}
		</Formik>
	);
}
