import { Box, Grid, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { LoginPageTemplate } from '../../templates/LoginPage';
import { LoginRequest } from '../../types/LoginRequest';
import { LoginForm, LoginFormProps } from './components/LoginForm';
import { LoginPageText } from './LoginPageText';

export type LoginPageViewProps = Pick<
	LoginFormProps,
	| 'isSubmitting'
	| 'isResettingPassword'
	| 'passwordReset'
	| 'onPasswordReset'
	| 'onGoBackToLogin'
	| 'onForgotPasswordClick'
> & {
	onLogin: (params: LoginRequest) => void;
};

export function LoginPageView({
	isSubmitting,
	passwordReset,
	isResettingPassword,
	onLogin,
	onPasswordReset,
	onGoBackToLogin,
	onForgotPasswordClick,
}: LoginPageViewProps) {
	const [forgotPassword, setForgotPassword] = useState(false);

	const handleForgotPasswordClick = useCallback(() => {
		setForgotPassword(true);
		if (onForgotPasswordClick) onForgotPasswordClick();
	}, [onForgotPasswordClick]);
	const handleGoBackToLogin = useCallback(() => {
		setForgotPassword(false);
		if (onGoBackToLogin) onGoBackToLogin();
	}, [onGoBackToLogin]);
	return (
		<LoginPageTemplate>
			<Box style={{ padding: 50 }}>
				<Grid container direction='column' spacing={3}>
					<Grid item>
						<Typography variant='h1' color='primary'>
							{forgotPassword
								? LoginPageText.forgotPasswordTitle
								: LoginPageText.loginTitle}
						</Typography>
						{forgotPassword && (
							<Typography sx={{ marginTop: '16px' }}>
								{passwordReset
									? LoginPageText.passwordResetEmailSent
									: LoginPageText.forgotPasswordCta}
							</Typography>
						)}
					</Grid>
					<Grid item>
						<LoginForm
							isSubmitting={isSubmitting}
							passwordReset={passwordReset}
							isResettingPassword={isResettingPassword}
							handleSubmit={onLogin}
							onPasswordReset={onPasswordReset}
							onGoBackToLogin={handleGoBackToLogin}
							onForgotPasswordClick={handleForgotPasswordClick}
						/>
					</Grid>
				</Grid>
			</Box>
		</LoginPageTemplate>
	);
}
