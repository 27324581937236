const nodeEnv = process.env.NODE_ENV;
const dynamicDeploy = process.env.REACT_APP_DYNAMIC;
const isDynamicProd =
	Boolean(nodeEnv === 'production') && Boolean(dynamicDeploy);

// @ts-ignore
const windowEnv = window._ENV;

const BRAG_BADGES_API_HOST = isDynamicProd
	? windowEnv.BRAG_BADGES_API_HOST
	: process.env.REACT_APP_BRAG_BADGES_API_HOST;
const ENCRYPTION_KEY = isDynamicProd
	? windowEnv.REACT_APP_ENCRYPTION_KEY
	: process.env.REACT_APP_ENCRYPTION_KEY;
const DEFAULT_BRAND_PATH = isDynamicProd
	? windowEnv.REACT_APP_DEFAULT_BRAND_PATH
	: process.env.REACT_APP_DEFAULT_BRAND_PATH;

export const Environment = {
	BRAND_ID: 'ae01b05c-62a2-49f0-a37a-e84b619cdf43',
	BRAG_BADGES_API_HOST,
	ENCRYPTION_KEY,
	DEFAULT_BRAND_PATH,
};
