import { CircularProgress, styled, Box } from '@mui/material';
import { ComponentType, useCallback, useState } from 'react';
import { Badge } from '../../../../types/Badge';
import { BadgeCard } from '../BadgeCard';

export type BadgeFeedProps = {
	badges: Badge[];
	loading?: boolean;
	onChange?: (badge: Badge) => void;
	LoadingIndicator?: ComponentType;
};

export function BadgeFeed({
	badges,
	loading,
	onChange,
	LoadingIndicator = CircularProgress,
}: BadgeFeedProps) {
	const [selectedBadgeId, setSelectedBadgeId] = useState<string>();

	const handleBadgeClick = useCallback(
		(badgeId: string) => {
			setSelectedBadgeId(badgeId);

			if (!onChange) return;

			const badge = badges.find(({ id }) => id === badgeId) as Badge;
			onChange(badge);
		},
		[badges, onChange]
	);

	if (loading) {
		return (
			<Box display='flex' justifyContent='center' alignItems='center'>
				<LoadingIndicator />
			</Box>
		);
	}

	return (
		<List>
			{badges.map(badge => (
				<ListItem key={badge.id}>
					<BadgeCard
						onClick={() => handleBadgeClick(badge.id)}
						active={badge.id === selectedBadgeId}
						badge={badge}
					/>
				</ListItem>
			))}
		</List>
	);
}

const List = styled('ul')({
	width: '100%',
	display: 'flex',
	flexWrap: 'wrap',
	padding: 0,
	margin: 0,
});

const ListItem = styled('li')({
	width: '100%',
	maxWidth: 115,
	listStyle: 'none',
	display: 'inline block',
	margin: '0px 10px 10px 8px',
});
