import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { Awards } from '../../api/Awards';
import { Award, AwardStatuses } from '../../types/Award';
import { useApiCall } from '../useApiCall';

export type UseAwardDetailsConfig = {
	awardsClient?: Awards;
	onAwardStatusUpdate?: () => void;
	onAwardStatusUpdateError?: (error: AxiosError) => void;
	onRejection?: (reason: any) => void;
};

export type UseAwardDetailsState = {
	award: Award | null | undefined;
	loading: boolean;
	updatingStatus: boolean;
	updateAwardStatus: (newStatus: AwardStatuses) => void;
};

export function useAwardDetails(
	awardId: string | undefined,
	config: UseAwardDetailsConfig = {}
): UseAwardDetailsState {
	const [updatingStatus, setUpdatingStatus] = useState(false);
	const apiClient = useMemo(() => config?.awardsClient ?? new Awards(), []);
	const callParams: [id: string | undefined] = useMemo(
		() => [awardId],
		[awardId]
	);
	const apiCallConfig = useMemo(
		() => ({
			onRejection: config.onRejection,
		}),
		[config.onRejection]
	);
	const fetcher = useCallback(
		async (id?: string) => {
			if (id == null) {
				return {
					data: null,
				} as unknown as AxiosResponse<Award | null, any>;
			}

			return apiClient.getById(id);
		},
		[apiClient]
	);
	const { data: award, loading } = useApiCall(
		fetcher,
		callParams,
		apiCallConfig
	);

	const updateAwardStatus = useCallback(
		(newStatus: AwardStatuses) => {
			if (!awardId) return;
			setUpdatingStatus(true);
			apiClient
				.updateAwardStatus(awardId, newStatus)
				.then(() => {
					if (config.onAwardStatusUpdate) config.onAwardStatusUpdate();
				})
				.catch(e => {
					if (config.onAwardStatusUpdateError) config.onAwardStatusUpdateError(e);
				})
				.finally(() => setUpdatingStatus(false));
		},
		[awardId, config.onAwardStatusUpdate, config.onAwardStatusUpdateError]
	);

	return {
		award,
		loading,
		updatingStatus,
		updateAwardStatus,
	};
}
