import { AxiosResponseTransformer } from 'axios';
import { CreatePrize, Prize, PrizeUpdate } from '../../types/Prize';
import { extendAxiosTransformer } from '../../utils/extendAxiosTransformer.helper';
import { ApiClient } from '../ApiClient';

export type PrizeDto = {
	id: string;
	name: string;
	points: number;
	charity: boolean;
	active: boolean;
};

export type CreatePrizeDto = {
	name: string;
	points: number;
	charity?: boolean;
};

export type UpdatePrizeDto = Partial<CreatePrizeDto>;

export class Prizes extends ApiClient {
	private baseEndpoint = '/prizes';

	getAllPrizes = () =>
		this.protectedHttpClient.get<Prize[]>(this.baseEndpoint, {
			transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
				this.protectedHttpClient.defaults.transformResponse,
				[Prizes.transformResponse]
			),
		});

	getById = (id: string) =>
		this.protectedHttpClient.get<Prize>(this.singlePrizeEndpoint(id), {
			transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
				this.protectedHttpClient.defaults.transformResponse,
				[Prizes.transformSingleResponse]
			),
		});

	createPrize = (prize: CreatePrize) =>
		this.protectedHttpClient.post(
			this.baseEndpoint,
			Prizes.createPrizeDtoFromCreationObject(prize)
		);

	updatePrize = (id: string, updatedFields: PrizeUpdate) =>
		this.protectedHttpClient.patch(
			this.singlePrizeEndpoint(id),
			Prizes.updatePrizeFromUpdateObject(updatedFields),
			{
				transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
					this.protectedHttpClient.defaults.transformResponse,
					[Prizes.transformSingleResponse]
				),
			}
		);

	remove = (id: string) =>
		this.protectedHttpClient.delete(this.singlePrizeEndpoint(id));

	static transformResponse(data: PrizeDto[]): Prize[] {
		if (!(data && Array.isArray(data))) return [];
		return data.map(Prizes.prizeFromDto);
	}

	static transformSingleResponse(data: PrizeDto) {
		if (!data) return data;
		return Prizes.prizeFromDto(data);
	}

	static prizeFromDto(dto: PrizeDto) {
		return {
			id: String(dto.id),
			name: dto.name,
			points: dto.points,
			donable: dto.charity,
			active: dto.active,
		};
	}

	static createPrizeDtoFromCreationObject(prize: CreatePrize) {
		return {
			name: prize.name,
			points: prize.points,
			charity: prize.donable,
		};
	}

	static updatePrizeFromUpdateObject(prize: PrizeUpdate) {
		const result: UpdatePrizeDto = {};

		if (prize.name) result.name = prize.name;
		if (prize.points != null) result.points = prize.points;
		if (prize.donable != null) result.charity = prize.donable;

		return result;
	}

	private singlePrizeEndpoint(id: string) {
		return `${this.baseEndpoint}/${id}`;
	}
}
