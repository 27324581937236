import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';

export type BrandSlugState = {
	brandSlug?: string;
	setBrandSlug: (brandSlug: string | undefined) => void;
};

export const BrandSlugContext = createContext<BrandSlugState | undefined>(
	undefined
);

export function useBrandSlug() {
	const ctx = useContext(BrandSlugContext);

	if (!ctx) {
		throw new Error('Cannot use useBrandSlug() outside BrandSlugProvider');
	}

	return ctx;
}

export type BrandSlugProviderProps = {
	children: ReactNode;
};
export function BrandSlugProvider({ children }: BrandSlugProviderProps) {
	const [slug, setSlug] = useState<string | undefined>(undefined);

	const setBrandSlug = useCallback(
		(brandSlug: string | undefined) => setSlug(brandSlug),
		[]
	);

	const state: BrandSlugState = useMemo(
		() => ({
			brandSlug: slug,
			setBrandSlug,
		}),
		[slug]
	);
	return (
		<BrandSlugContext.Provider value={state}>
			{children}
		</BrandSlugContext.Provider>
	);
}
