import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import { SnackbarNotificationProvider } from './SnackbarNotificationProvider';
import { ThemeProvider } from './context/Theme/ThemeProvider';
import { BrandSlugProvider } from './context/BrandSlug';

function App() {
	return (
		<SnackbarNotificationProvider>
			<BrandSlugProvider>
				<ThemeProvider>
					<LocalizationProvider adapterLocale='en' dateAdapter={AdapterDayjs}>
						<CssBaseline />
						<BrowserRouter>
							<AppRoutes />
						</BrowserRouter>
					</LocalizationProvider>
				</ThemeProvider>
			</BrandSlugProvider>
		</SnackbarNotificationProvider>
	);
}

export default App;
