import { useCallback } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { BrandGuard } from '../components/BrandGuard';
import { DynamicRoutesProps } from '../components/DynamicRoutes';
import { PageAuthorizationGuard } from '../components/PageAuthorizationGuard';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { BrandProvider } from '../context/Brand';
import { useBrandSlug } from '../context/BrandSlug';
import { PageNotFound } from '../pages/404';
import { AdminPortal } from '../pages/AdminPortal/AdminPortal';
import { BadgeAwardHistoryPage } from '../pages/BadgeAwardHistory';
import { BadgeAwardManagementPage } from '../pages/BadgeAwardManagement';
import { BadgeManagementPage } from '../pages/BadgeManagement';
import { DashboardPage } from '../pages/Dashboard';
import { GiveABadgePage } from '../pages/GiveABadge';
import { IndexAdminPage } from '../pages/IndexAdminPage';
import { LoginPage } from '../pages/Login';
import { ManagerReportPage } from '../pages/ManagerReport';
import { PrizeManagementPage } from '../pages/PrizeManagement';
import { RedemptionRequests } from '../pages/RedemptionRequests';
import { SettingsPage } from '../pages/Settings';
import { UserManagementPage } from '../pages/UserManagement';
import { AdminRoutePermissions } from './routePermissions';
import { Environment } from '../env';

export const defaultAppRoute: DynamicRoutesProps['defaultRoute'] = {
	component: () => {
		const { brandSlug } = useBrandSlug();
		return (
			<Navigate to={`/${Environment.DEFAULT_BRAND_PATH ?? brandSlug}`} replace />
		);
	},
};

const adminPortalPermissions = Object.values(AdminRoutePermissions).flat();

export const appRoutes: DynamicRoutesProps['routes'] = [
	{
		path: '/NotFound',
		component: PageNotFound,
	},
	{
		path: '/:brandSlug',
		component: () => {
			const navigate = useNavigate();
			const goToNotFound = useCallback(() => navigate('/NotFound'), []);

			return (
				<BrandProvider>
					<BrandGuard onUnauthorized={goToNotFound}>
						<Outlet />
					</BrandGuard>
				</BrandProvider>
			);
		},
		routes: [
			{
				path: 'give-a-badge',
				component: GiveABadgePage,
			},
			{
				path: 'login',
				component: LoginPage,
			},
			{
				index: true,
				component: () => (
					<ProtectedRoute>
						<Navigate to='dashboard' />
					</ProtectedRoute>
				),
			},
			{
				path: 'dashboard',
				component: () => (
					<ProtectedRoute>
						<DashboardPage />
					</ProtectedRoute>
				),
			},
			{
				path: 'admin',
				component: () => {
					const { brandSlug } = useBrandSlug();
					return (
						<ProtectedRoute>
							<PageAuthorizationGuard
								redirectTo={`/${brandSlug}`}
								permissions={adminPortalPermissions}
							>
								<AdminPortal>
									<Outlet />
								</AdminPortal>
							</PageAuthorizationGuard>
						</ProtectedRoute>
					);
				},
				routes: [
					{
						path: 'badge-award-approval/:id?',
						component: () => {
							const { brandSlug } = useBrandSlug();
							return (
								<PageAuthorizationGuard
									redirectTo={`/${brandSlug}/admin`}
									permissions={AdminRoutePermissions.BadgeAwardApproval}
								>
									<BadgeAwardManagementPage />
								</PageAuthorizationGuard>
							);
						},
					},
					{
						path: 'redemption-requests',
						component: () => {
							const { brandSlug } = useBrandSlug();
							return (
								<PageAuthorizationGuard
									redirectTo={`/${brandSlug}/admin`}
									permissions={AdminRoutePermissions.PrizeRedemptionManagement}
								>
									<RedemptionRequests />
								</PageAuthorizationGuard>
							);
						},
					},
					{
						path: 'badge-award-history',
						component: () => {
							const { brandSlug } = useBrandSlug();
							return (
								<PageAuthorizationGuard
									redirectTo={`/${brandSlug}/admin`}
									permissions={AdminRoutePermissions.BadgeAwardHistory}
								>
									<BadgeAwardHistoryPage />
								</PageAuthorizationGuard>
							);
						},
					},
					{
						path: 'badges',
						component: () => {
							const { brandSlug } = useBrandSlug();
							return (
								<PageAuthorizationGuard
									redirectTo={`/${brandSlug}/admin`}
									permissions={AdminRoutePermissions.BadgeManagement}
								>
									<BadgeManagementPage />
								</PageAuthorizationGuard>
							);
						},
					},
					{
						path: 'prizes',
						component: () => {
							const { brandSlug } = useBrandSlug();
							return (
								<PageAuthorizationGuard
									redirectTo={`/${brandSlug}/admin`}
									permissions={AdminRoutePermissions.PrizeManagement}
								>
									<PrizeManagementPage />
								</PageAuthorizationGuard>
							);
						},
					},
					{
						path: 'users',
						component: () => {
							const { brandSlug } = useBrandSlug();
							return (
								<PageAuthorizationGuard
									redirectTo={`/${brandSlug}/admin`}
									permissions={AdminRoutePermissions.UserManagement}
								>
									<UserManagementPage />
								</PageAuthorizationGuard>
							);
						},
					},
					{
						path: 'create-manager-report',
						component: () => {
							const { brandSlug } = useBrandSlug();
							return (
								<PageAuthorizationGuard
									redirectTo={`/${brandSlug}/admin`}
									permissions={AdminRoutePermissions.ManagerReport}
								>
									<ManagerReportPage />
								</PageAuthorizationGuard>
							);
						},
					},
					{
						path: 'settings',
						component: () => {
							const { brandSlug } = useBrandSlug();
							return (
								<PageAuthorizationGuard
									redirectTo={`/${brandSlug}/admin`}
									permissions={AdminRoutePermissions.Settings}
								>
									<SettingsPage />
								</PageAuthorizationGuard>
							);
						},
					},
					{
						component: IndexAdminPage,
						index: true,
					},
				],
			},
		],
	},
];
