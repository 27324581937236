import { ArrowDownIcon } from '../../assets/Icons/ArrowDown';
import { ArrowUpIcon } from '../../assets/Icons/ArrowUp';
import { SortIcon } from '../../assets/Icons/Sort';
import { ComponentThemeOverride } from '../../utils/types';

export const MuiDataGrid: ComponentThemeOverride['MuiDataGrid'] = {
	defaultProps: {
		rowSpacingType: 'margin',
		getRowSpacing: ({ isLastVisible }) => ({
			bottom: isLastVisible ? 0 : 3,
		}),
		components: {
			ColumnSortedDescendingIcon: ArrowDownIcon,
			ColumnSortedAscendingIcon: ArrowUpIcon,
			ColumnUnsortedIcon: SortIcon,
		},
	},
	styleOverrides: {
		root: {
			border: 0,
		},
		columnHeaders: ({ theme }) => ({
			borderRadius: 0,
			color: theme.palette.common.white,
			backgroundColor: theme.palette.common.black,
		}),
		columnHeader: {
			'&:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon': {
				opacity: '1 !important',
			},
			'&:hover .MuiDataGrid-iconButtonContainer': {
				maxWidth: 200,
			},
		},
		columnSeparator: {
			display: 'none',
		},
		columnHeaderTitle: ({ theme }) => ({
			fontWeight: 700,
			lineHeight: 1,
			textTransform: 'uppercase',
			fontSize: theme.typography.pxToRem(14),
		}),
		iconButtonContainer: ({ theme }) => ({
			overflow: 'hidden',
			visibility: 'visible',
			maxWidth: 0,
			transition: theme.transitions.create(['max-width'], {
				duration: theme.transitions.duration.complex,
				easing: theme.transitions.easing.easeIn,
				delay: 0,
			}),
		}),
		sortIcon: ({ theme }) => ({
			color: theme.palette.primary.main,
		}),
		row: ({ theme }) => ({
			border: '1px solid transparent',
			borderBottomWidth: 8,
			width: 'calc(100% - 2px)',
			backgroundColor: '#F6F7F8',
			boxSizing: 'border-box',
			transition: theme.transitions.create('border-color', {
				duration: theme.transitions.duration.shorter,
				easing: theme.transitions.easing.easeIn,
				delay: 0,
			}),
			'&:hover': {
				backgroundColor: '#F6F7F8',
				borderColor: theme.palette.primary.main,
			},
		}),
		cell: {
			border: 0,
			'&:not(:focus-visible):focus': {
				outline: 0,
			},
		},
	},
};
