import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { SettingsView, FormValues } from './SettingsView';
import { useThemeContext } from '../../context/Theme';
import { useAuth } from '../../context/Auth';
import { Users } from '../../api/Users';

export function SettingsPage() {
  const { settings, loading, updatingSettings, updateBrandSettings } =
    useThemeContext();

  const usersClient = new Users();
  const { user } = useAuth();
  const [receiveRedemptionNotification, setReceiveRedemptionNotification] =
    useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getUser = async () => {
      if (user) {
        try {
          const result = await usersClient.getUserById(user.id);

          setReceiveRedemptionNotification(
            Boolean(
              result.data.settings
                ?.sendRequestedRedemptionNotification
            )
          );
        } catch (error) {
          console.log('Could not load user settings');
        }
      }
    };

    getUser();
  }, [user]);

  const handleSubmit = useCallback((values: FormValues) => {
    const { companyName: name, ...restValues } = values;
    const update = {
      ...restValues,
      name,
    };
    updateBrandSettings(update);
  }, []);

  const handleSetRedemptionNotification = () => {
    if (user) {
      usersClient
        .updateSettings(user.id, {
          sendRequestedRedemptionNotification: !receiveRedemptionNotification,
        })
        .then(() => {
          enqueueSnackbar('Notification settings updated', {
            variant: 'success',
          });
          setReceiveRedemptionNotification(!receiveRedemptionNotification);
        })
        .catch(err => {
          enqueueSnackbar('Could not update notification settings', {
            variant: 'error',
          });
        });
    }
  };

  return (
    <SettingsView
      loading={loading}
      settings={settings}
      updatingSettings={updatingSettings}
      onSubmit={handleSubmit}
      handleSetRedemptionNotification={handleSetRedemptionNotification}
      receiveRedemptionNotification={receiveRedemptionNotification}
    />
  );
}
