import { LoadingButton } from '@mui/lab';
import {
	Autocomplete,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	TextField,
	TextFieldProps,
	debounce,
	styled,
} from '@mui/material';
import { Formik, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
} from 'react';
import * as yup from 'yup';
import { Badge } from '../../../../types/Badge';
import { BadgeAwardRequest } from '../../../../types/BadgeAwardRequest';
import { Recipient } from '../../../../types/Recipient';
import { BadgeFeed } from '../BadgeFeed/BadgeFeed';
import { Section } from '../../../../components/Section';
import { validationSchema as baseValidationSchema } from './schema';
import { InteractionSettings } from '../../../../config/interactionSettings';

const MESSAGE_CTA = 'Tell us what prompted you to nominate this colleague...';
const MESSAGE_LENGTH_LIMIT = 2500;
const FIELDSET_LEGEND_FONT_SIZE = 12;
const SECTION_CONTENT_HORIZONTAL_PADDING = {
	paddingLeft: '21px',
	paddingRight: '58px',
};
const SAME_EMAIL_ADDRESS_MESSAGE = 'The email addresses can not be the same';
const FIELDS_ALERT = 'One or more fields have not been properly filled';

type GiveABadgeFormContextObj = {
	loadingBadges?: boolean;
	availableBadges: Badge[];
	submitting: boolean;
	loadingRecipients?: boolean;
	availableRecipients: Recipient[];
	availableRelationships: Array<{
		text: string;
		value: string;
	}>;
	handleRecipientNameInputChange: (_: unknown, value: string) => void;
	Feed: typeof BadgeFeed;
};

const GiveABadgeFormContext = createContext<
	GiveABadgeFormContextObj | undefined
>(undefined);

function useGiveABadgeFormContext() {
	const result = useContext(GiveABadgeFormContext);
	if (!result)
		throw new Error(
			'useGiveABadgeFormContext() needs to be called inside a provider of GiveABadgeFormContext'
		);
	return result;
}

export type GiveABadgeFormProps = Omit<
	FormProps,
	'Feed' | 'handleRecipientNameInputChange'
> & {
	submitting?: boolean;
	onSubmit: (values: BadgeAwardRequest) => void;
	onRecipientQueryChange?: (query: string) => void;
	BadgeFeedComponent?: FormProps['Feed'];
};

type FormValues = BadgeAwardRequest & {
	recipients: Recipient[];
	recipientFullName: string;
	recipientEmailAddress: string;
};

export function GiveABadgeForm({
	availableRelationships,
	availableBadges,
	loadingBadges,
	submitting = false,
	loadingRecipients = false,
	availableRecipients = [],
	onSubmit,
	onRecipientQueryChange,
	BadgeFeedComponent: Feed = BadgeFeed,
}: GiveABadgeFormProps) {
	const initialValues: FormValues = useMemo(
		() => ({
			badgeId: '',
			recipientIds: [],
			recipients: [],
			recipientFullName: '',
			recipientEmailAddress: '',
			senderFullName: '',
			senderEmailAddress: '',
			senderRelationShipToRecipient: '',
			message: '',
		}),
		[]
	);

	const handleRecipientNameInputChange = useCallback(
		debounce((_: unknown, value: string) => {
			if (onRecipientQueryChange) onRecipientQueryChange(value);
		}, InteractionSettings.searchDebounceTime),
		[onRecipientQueryChange]
	);

	const handleFormSubmit = useCallback(
		(values: FormValues) => {
			onSubmit({
				badgeId: values.badgeId,
				recipientIds: values.recipients.map(({ id }) => id),
				senderFullName: values.senderFullName,
				senderEmailAddress: values.senderEmailAddress,
				senderRelationShipToRecipient: values.senderRelationShipToRecipient,
				message: values.message,
			});
		},
		[onSubmit]
	);

	const availableRecipientEmails = useMemo(
		() => availableRecipients.map(({ email }) => email),
		[availableRecipients]
	);

	const validationSchema = useMemo(
		() =>
			baseValidationSchema.concat(
				yup.object().shape({
					recipientEmailAddress: yup
						.string()
						.email('You must provide a valid email address')
						.oneOf(
							availableRecipientEmails,
							'The email address must be of a registered user'
						)
						.required("You must provide the recipient's email address"),
					senderEmailAddress: yup
						.string()
						.email('You must provide a valid email address')
						.when('recipientEmailAddress', (value, schema) =>
							schema.notOneOf([value], SAME_EMAIL_ADDRESS_MESSAGE)
						)
						.required('You must provide your email address'),
				})
			),
		[availableRecipientEmails]
	);

	const contextValue = useMemo(
		() => ({
			submitting,
			loadingBadges,
			availableBadges,
			loadingRecipients,
			availableRecipients,
			availableRelationships,
			handleRecipientNameInputChange,
			Feed,
		}),
		[
			availableBadges,
			availableRecipients,
			availableRelationships,
			loadingRecipients,
			loadingBadges,
			submitting,
			handleRecipientNameInputChange,
		]
	);

	return (
		<GiveABadgeFormContext.Provider value={contextValue}>
			<Formik<FormValues>
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleFormSubmit}
				component={Form}
			/>
		</GiveABadgeFormContext.Provider>
	);
}

type FormProps = {
	availableBadges: Badge[];
	availableRecipients: Recipient[];
	availableRelationships: Array<{
		text: string;
		value: string;
	}>;
	loadingBadges?: boolean;
	loadingRecipients?: boolean;
	handleRecipientNameInputChange: (_: unknown, value: string) => void;
	Feed: typeof BadgeFeed;
};

function Form({
	errors,
	values,
	submitCount,
	setTouched,
	handleSubmit,
	getFieldMeta,
	setFieldValue,
	getFieldProps,
}: FormikProps<FormValues>) {
	const { enqueueSnackbar } = useSnackbar();
	const {
		loadingBadges,
		submitting,
		availableBadges,
		loadingRecipients,
		availableRecipients,
		availableRelationships,
		handleRecipientNameInputChange,
		Feed,
	} = useGiveABadgeFormContext();

	useEffect(() => {
		if (submitCount && Object.keys(errors).length) {
			enqueueSnackbar(FIELDS_ALERT, {
				variant: 'error',
			});
		}
	}, [submitCount, errors]);

	const handleRecipientNameChange = useCallback(
		(_: unknown, recipients: Recipient[]) => {
			setFieldValue(
				'recipientEmailAddress',
				recipients.length ? recipients[0].email : ''
			);
			setFieldValue('recipients', recipients);
		},
		[setFieldValue]
	);

	const handleBadgeSelection = useCallback(
		(badge: Badge) => {
			setFieldValue('badgeId', badge.id);
		},
		[setFieldValue]
	);

	const getFieldFeedbackProps = useCallback(
		(field: string) => {
			const { error, touched } = getFieldMeta(field);
			const hasError = touched && Boolean(error);

			return {
				error: hasError,
				helperText: hasError ? error : undefined,
			};
		},
		[getFieldMeta]
	);

	const renderRecipientNameInput = useCallback(
		(props: TextFieldProps) => (
			<TextField
				{...props}
				label='Enter Name'
				// @ts-ignore
				onBlur={() => setTouched({ recipients: true })}
				InputProps={{ ...props.InputProps, endAdornment: null }}
				{...getFieldFeedbackProps('recipients')}
				fullWidth
			/>
		),
		[getFieldFeedbackProps]
	);

	const isOptionEqualToValue = useCallback(
		(option: Recipient, value: Recipient) => option.id === value.id,
		[]
	);

	return (
		<form onSubmit={handleSubmit}>
			<Grid container>
				<Column xs={12} sm={12} md={6} item>
					<Section
						ContentProps={{
							sx: {
								paddingTop: '20px',
							},
						}}
						title='Step 1'
						subtitle='Select a badge'
					>
						{getFieldFeedbackProps('badgeId').error && (
							<FormHelperText error>
								{getFieldFeedbackProps('badgeId').helperText}
							</FormHelperText>
						)}
						<Feed
							badges={availableBadges}
							loading={loadingBadges}
							onChange={handleBadgeSelection}
						/>
					</Section>
				</Column>
				<Column xs={12} sm={12} md={6} item>
					<Section
						ContentProps={{
							sx: {
								...SECTION_CONTENT_HORIZONTAL_PADDING,
								paddingTop: '14px',
								paddingBottom: '26px',
							},
						}}
						title='Step 2'
						subtitle='Sender'
					>
						<Fieldset>
							<Legend>From</Legend>
							<TextField
								label='Your Full Name'
								{...getFieldProps('senderFullName')}
								{...getFieldFeedbackProps('senderFullName')}
								fullWidth
							/>
							<TextField
								label='Your Email'
								{...getFieldProps('senderEmailAddress')}
								{...getFieldFeedbackProps('senderEmailAddress')}
								fullWidth
							/>
							<TextField
								label='Relationship to Recipient'
								defaultValue=''
								{...getFieldProps('senderRelationShipToRecipient')}
								{...getFieldFeedbackProps('senderRelationShipToRecipient')}
								fullWidth
								select
							>
								{availableRelationships.map(({ text, value }) => (
									<MenuItem key={value} value={value}>
										{text}
									</MenuItem>
								))}
							</TextField>
						</Fieldset>
					</Section>
					<Section
						ContentProps={{
							sx: {
								...SECTION_CONTENT_HORIZONTAL_PADDING,
								paddingTop: '8px',
								paddingBottom: '20px',
							},
						}}
						title='Step 3'
						subtitle='Recipient(s)'
					>
						<Fieldset>
							<Legend>My badge goes to</Legend>
							<RecipientAutocomplete
								options={availableRecipients}
								loading={loadingRecipients}
								onChange={handleRecipientNameChange}
								onInputChange={handleRecipientNameInputChange}
								getOptionLabel={getRecipientOptionLabel}
								renderInput={renderRecipientNameInput}
								isOptionEqualToValue={isOptionEqualToValue}
								multiple
							/>
							{values.recipients.length < 2 && (
								<TextField
									type='email'
									label='Email'
									{...getFieldProps('recipientEmailAddress')}
									{...getFieldFeedbackProps('recipientEmailAddress')}
									fullWidth
								/>
							)}
						</Fieldset>
						<MessageLabel id='reason_message'>Message</MessageLabel>
						<TextField
							InputProps={{
								inputProps: {
									maxLength: MESSAGE_LENGTH_LIMIT,
									'aria-labelledby': 'reason_message',
									style: {
										minHeight: 92,
									},
								},
							}}
							{...getFieldProps('message')}
							{...getFieldFeedbackProps('message')}
							placeholder={MESSAGE_CTA}
							multiline
							fullWidth
						/>
						<LoadingButton
							sx={{
								width: '100%',
								display: 'flex',
								maxWidth: 167,
								margin: '26px auto 0',
							}}
							type='submit'
							variant='contained'
							loading={submitting}
						>
							Send badge
						</LoadingButton>
					</Section>
				</Column>
			</Grid>
		</form>
	);
}

function getRecipientOptionLabel(option: Recipient) {
	return option.name;
}

const Column = styled(Grid)(({ theme }) => ({
	padding: '10px 15px 10px 15px',
	[theme.breakpoints.up('md')]: {
		padding: '0 8px 0 80px',
	},
}));

const Fieldset = styled('fieldset')({
	padding: 0,
	margin: 0,
	border: 0,
});

const Legend = styled('legend')(({ theme }) => ({
	marginBottom: 16,
	lineHeight: 1.167,
	color: 'inherit',
	textTransform: 'uppercase',
	fontSize: theme.typography.pxToRem(FIELDSET_LEGEND_FONT_SIZE),
}));

const MessageLabel = Legend.withComponent(InputLabel);

const RecipientAutocomplete = styled(Autocomplete)(({ theme }) => ({
	'& .MuiAutocomplete-tag': {
		height: 24,
		backgroundColor: '#D9D9D9',
	},
	'& .MuiChip-label': {
		whiteSpace: 'normal',
		wordBreak: 'break-word',
		fontSize: theme.typography.pxToRem(10),
	},
	'&& .MuiChip-deleteIcon': {
		color: '#fff',
		fontSize: theme.typography.pxToRem(16),
	},
	'& .MuiInputBase-root': {
		height: 'auto !important',
	},
})) as typeof Autocomplete;
