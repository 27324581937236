import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	CreateUserConfig,
	UpdateUserConfig,
	User,
} from '../../../../types/User';
import { Users } from '../../../../api/Users';
import { useAuth } from '../../../../context/Auth';
import { useUsers } from '../../../../hooks/useUsers';
import { ROLE_HIERARCHY, Roles } from '../../../../utils/enums/Roles';
import { AddUserView } from './AddUserView';
import { assignableRoles } from './AddUser.config';
import { UserFilters } from '../../../../utils/helpers/UserFilters';

export const USER_EMAIL_ALREADY_USED_ERROR_MESSAGE =
	'User email is already used, please use another';

export const USER_SAVE_SUCCESS_MESSAGE = 'User was created successfully';

export const USER_SAVE_ERROR_MESSAGE =
	'Could not create the user, please try again later!';

export const USER_UPDATED_SUCCESS_MESSAGE = 'User was updated successfully';

export const USER_UPDATED_ERROR_MESSAGE =
	'Could not update the user, please try again later!';

export const USER_DELETED_SUCCESS_MESSAGE = 'User was deleted successfully';

export const USER_DELETED_ERROR_MESSAGE =
	'Could not delete the user, please try again later!';

export type AddUserProps = {
	onUserCreated?: () => void;
	onUserDeleted?: () => void;
	onCloseWindowClick?: () => void;
	usersClient?: Users;
	userId?: string;
};

export function AddUser({
	onUserCreated,
	onUserDeleted,
	onCloseWindowClick,
	usersClient = new Users(),
	userId,
}: AddUserProps) {
	const { enqueueSnackbar } = useSnackbar();
	const [saving, setSaving] = useState(false);
	const [managerQuery, setManagerQuery] = useState<string>('');
	const { users, loading: loadingUsers, setParams } = useUsers();
	const { user } = useAuth();
	const [selectedUserData, setSelectedUserData] = useState<User>();

	const managerFilters = useMemo(() => {
		const filters = new UserFilters();
		filters.set('usertype', Roles.Manager);

		if (managerQuery.length >= 3) {
			filters.set('name', managerQuery);
		}

		return filters.build();
	}, [managerQuery]);

	useEffect(() => {
		setParams({
			sort: 'name',
			order: 'asc',
			filters: managerFilters,
		});
	}, [managerFilters]);

	const managers = useMemo(
		() =>
			users.map(user => ({
				id: user.id,
				managerName: user.name,
			})),
		[users]
	);

	useEffect(() => {
		if (userId == null) return;
		usersClient
			.getUserById(userId)
			.then((res: any) => {
				setSelectedUserData(res.data);
			})
			.catch(err => console.log(err));
	}, [userId]);

	const handleSave = useCallback(
		(config: CreateUserConfig) => {
			if (!selectedUserData) createUser(config);
			else updateUser(config);
		},
		[selectedUserData]
	);

	const createUser = useCallback(
		(config: CreateUserConfig) => {
			if (saving) return;

			setSaving(true);
			usersClient
				.createUser(config)
				.then(() => {
					if (onUserCreated) onUserCreated();
					enqueueSnackbar(USER_SAVE_SUCCESS_MESSAGE, { variant: 'success' });
				})
				.catch(err => {
					enqueueSnackbar(
						err.response.data.message === 'User already exists'
							? USER_EMAIL_ALREADY_USED_ERROR_MESSAGE
							: USER_SAVE_ERROR_MESSAGE,
						{
							variant: 'error',
						}
					);
				})
				.finally(() => setSaving(false));
		},
		[saving]
	);

	const updateUser = useCallback(
		(config: CreateUserConfig) => {
			if (saving) return;

			const customData: UpdateUserConfig = {
				isManager: config.rolesIds.includes(Roles.Manager),
				id: selectedUserData?.id || '',
				position: config.position ?? selectedUserData?.position ?? '',
				email: config.email,
				active: selectedUserData?.active || false,
				firstAccess: selectedUserData?.firstAccess || false,
				points: selectedUserData?.points || 0,
				firstName: config.firstName,
				lastName: config.lastName,
				rolesIds: config.rolesIds,
				managerUserId: config.managerUserId,
			};

			setSaving(true);
			usersClient
				.updateUser(customData)
				.then(() => {
					if (onUserCreated) onUserCreated();
					enqueueSnackbar(USER_UPDATED_SUCCESS_MESSAGE, { variant: 'success' });
				})
				.catch(err => {
					enqueueSnackbar(USER_UPDATED_ERROR_MESSAGE, {
						variant: 'error',
					});
					console.log(err);
				})
				.finally(() => {
					setSaving(false);
				});
		},
		[saving, selectedUserData]
	);

	const removeUser = useCallback((id: string) => {
		console.log('deleting user:', id);
		usersClient
			.deleteUser(id)
			.then(() => {
				if (onUserDeleted) onUserDeleted();
				enqueueSnackbar(USER_DELETED_SUCCESS_MESSAGE, { variant: 'success' });
			})
			.catch(() => {
				enqueueSnackbar(USER_DELETED_ERROR_MESSAGE, {
					variant: 'error',
				});
			});
	}, []);

	const handleManagerQueryChange = useCallback((query: string) => {
		setManagerQuery(query);
	}, []);

	const handleOnRemoveClick = useCallback(() => {
		if (userId) removeUser(userId);
	}, [userId]);

	return (
		<AddUserView
			canManageRoles={user?.role.includes(Roles.SysAdmin)}
			assignableRoles={
				user
					? assignableRolesByRole(highestRole(user.role as unknown as Roles[]))
					: []
			}
			saving={saving}
			managers={managers}
			loadingManagers={loadingUsers}
			selectedUserData={selectedUserData}
			onSave={handleSave}
			onRemoveClick={handleOnRemoveClick}
			onCloseWindowClick={onCloseWindowClick}
			onManagerQueryChange={handleManagerQueryChange}
		/>
	);
}

function highestRole(roles: Roles[]) {
	let highestIndex = -1;

	roles.forEach(role => {
		const index = ROLE_HIERARCHY.indexOf(role);
		if (index > highestIndex) highestIndex = index;
	});

	return ROLE_HIERARCHY[highestIndex];
}

function assignableRolesByRole(role: Roles) {
	const roles = ROLE_HIERARCHY.slice(0, ROLE_HIERARCHY.indexOf(role) + 1);
	return assignableRoles.filter(({ role }) => roles.includes(role));
}
