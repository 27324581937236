import { useCallback } from 'react';
import {
	Dialog,
	DialogCloseReason,
	DialogProps,
} from '../../../../components/Dialog';
import { BadgeEditor } from '../BadgeEditor';

export type BadgeEditorDialogProps = Omit<
	DialogProps,
	'children' | 'onClose'
> & {
	onClose?: (reason: DialogCloseReason | BadgeEditorDialogCloseReason) => void;
};

// eslint-disable-next-line no-shadow
export enum BadgeEditorDialogCloseReason {
	BadgeSaved = 'BadgeSaved',
}

export function BadgeEditorDialog({
	onClose,
	...props
}: BadgeEditorDialogProps) {
	const handleBadgeSaved = useCallback(() => {
		if (onClose) onClose(BadgeEditorDialogCloseReason.BadgeSaved);
	}, [onClose]);
	const handleDialogClose = useCallback(
		(_: any, reason: DialogCloseReason) => {
			if (onClose) onClose(reason);
		},
		[onClose]
	);

	return (
		<Dialog
			title='Add new badge'
			PaperProps={{
				sx: {
					maxWidth: 940,
				},
			}}
			HeaderProps={{
				sx: {
					paddingTop: '12px',
					paddingBottom: '14px',
					paddingLeft: '34px',
					paddingRight: '34px',
				},
			}}
			ContentProps={{
				sx: {
					paddingLeft: '34px',
					paddingRight: '34px',
				},
			}}
			onClose={handleDialogClose}
			{...props}
		>
			<BadgeEditor onBadgeSaved={handleBadgeSaved} />
		</Dialog>
	);
}
