import { createContext } from 'react';
import {
	UpdateUserRelationshipItem,
	UserRelationship,
} from '../../types/UserRelationship';

export type UseRelationshipManagerState = {
	loading: boolean;
	updating: boolean;
	relationships: Array<Required<UserRelationship>>;
	updateRelationships: (updates: UpdateUserRelationshipItem[]) => void;
};

export const RelationshipManagerContext = createContext<
	UseRelationshipManagerState | undefined
>(undefined);
