import { ReactNode, useContext, useMemo } from 'react';
import { adminLinks } from '../../config/adminLinks';
import { useInternalLink } from '../../hooks/useInternalLink';
import { evaluatePermissions } from '../../utils/helpers/authorization';
import { AbilityContext } from '../Authorization';
import { AdminLinksContext } from './AdminLinksContext';

export type AdminLinksProviderProps = {
	children: ReactNode;
};

export function AdminLinksProvider({ children }: AdminLinksProviderProps) {
	const ability = useContext(AbilityContext);
	const internalLink = useInternalLink();
	const state = useMemo(
		() => ({
			links: adminLinks
				.filter(({ permissions }) => evaluatePermissions(ability, permissions))
				.map(link => ({
					...link,
					path: internalLink(link.path).replace(/\/\//g, '/'),
				})),
		}),
		[ability, internalLink]
	);

	return (
		<AdminLinksContext.Provider value={state}>
			{children}
		</AdminLinksContext.Provider>
	);
}
