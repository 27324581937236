import { Box, Container as MuiContainer, styled } from '@mui/material';
import { useMemo } from 'react';
import { AppLogo } from '../../../../components/AppLogo';
import { AppNavbar } from '../../../../components/AppNavbar';
import { SignOutButton } from '../../../../components/SignOutButton';
import { useAdminLinks } from '../../../../hooks/useAdminLinks';
import { MenuItem } from '../../../../types/MenuItem';
import { OverridableComponentProps } from '../../../../utils/types';
import { UserHomeButton } from '../UserHomeButton';
import { SettingsButton } from '../SettingsButton';

export function AdminPortalHeader() {
	const { links } = useAdminLinks();

	const menuItems: MenuItem[] = useMemo(
		() => links.map(({ path, title }) => ({ text: title, path })),
		[links]
	);

	return (
		<Header component='header'>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					paddingBottom: '20px',
				}}
			>
				<AppLogo
					sx={theme => ({
						[theme.breakpoints.down('sm')]: {
							flexDirection: 'column',
						},
					})}
				/>
				<Box>
					<UserHomeButton />
					<SettingsButton />
					<SignOutButton />
				</Box>
			</Box>
			<Container>
				<AppNavbar
					sx={{ display: 'flex', justifyContent: 'center' }}
					menuItems={menuItems}
				/>
			</Container>
		</Header>
	);
}

const Header = styled(Box)<OverridableComponentProps<typeof Box>>(
	({ theme }) => ({
		width: '100%',
		padding: '28px 42px 0px',
		boxShadow: '0px 14px 24px rgba(0, 0, 0, 0.25)',
		borderBottom: `4px solid ${theme.palette.primary.main}`,
		backgroundColor: theme.palette.common.white,
	})
);

const Container = styled(MuiContainer)({
	'&&&': {
		maxWidth: 1400,
	},
});
