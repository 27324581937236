import { AwardUser } from '../../../types/Award';

export function managersFormatter(managers: AwardUser[]) {
	const results = new Set();

	managers.forEach(manager => {
		if (!manager || results.has(manager.name)) return;
		results.add(manager.name);
	});

	return Array.from(results.values()).sort().join(', ');
}
