import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	InputLabel,
	TextField,
	Typography,
	styled,
} from '@mui/material';
import _ from 'lodash';
import { Formik } from 'formik';
import { MouseEvent, useCallback, useMemo } from 'react';
import { UserRelationship } from '../../../../types/UserRelationship';
import { Can } from '../../../../components/Can';
import { Action, ActionSubject } from '../../../../utils/enums/abilities';

export type RelationshipManagerViewProps = {
	relationships: Array<Required<UserRelationship>>;
	loading?: boolean;
	saving?: boolean;
	onSave: (updatedRelationships: Array<Required<UserRelationship>>) => void;
	onCloseWindowClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

type FormValues = {
	relationships: Array<Required<UserRelationship>>;
};

type FormErrors = {
	relationships: Array<
		Partial<Record<keyof Required<UserRelationship>, string>>
	>;
};

export function RelationshipManagerView({
	relationships,
	loading,
	saving,
	onSave,
	onCloseWindowClick,
}: RelationshipManagerViewProps) {
	const initialValues = useMemo(
		() => ({
			relationships: _.cloneDeep(relationships),
		}),
		[relationships]
	);

	const initialErrors = useMemo(() => {
		const result: FormErrors = { relationships: [] };

		relationships.forEach((_, i) => {
			result.relationships[i] = {
				modifier: 'Cannot save without modifying the value',
			};
		});

		return result;
	}, [relationships]);

	const handleFormikSubmit = useCallback(
		(values: FormValues) => onSave(values.relationships),
		[onSave]
	);

	return (
		<Can do={Action.Update} on={ActionSubject.RecipientRelationship} passThrough>
			{canUpdate => (
				<Formik<FormValues>
					key={JSON.stringify(initialValues)}
					initialValues={initialValues}
					initialErrors={initialErrors}
					onSubmit={handleFormikSubmit}
					enableReinitialize
					validateOnMount
				>
					{({ isValid, getFieldProps, handleSubmit }) => (
						<form onSubmit={canUpdate ? handleSubmit : undefined}>
							{loading && (
								<Box
									sx={{
										width: '100%',
										height: '250px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<CircularProgress />
								</Box>
							)}
							{!loading && (
								<>
									<Box
										sx={{
											width: '100%',
											maxWidth: 522,
										}}
									>
										{relationships.map((relationship, i) => {
											const nameFieldId = `relationship-editor_relationship-${relationship.id}_name-input`;
											const multiplierFieldId = `relationship-editor_relationship-${relationship.id}_modifier-input`;

											return (
												<Grid key={relationship.id} container>
													<Grid xs={12} md={8} item>
														<Fieldset key={relationship.id}>
															<FieldLabel htmlFor={nameFieldId}>Type</FieldLabel>
															<TextField
																id={nameFieldId}
																value={relationship.name}
																fullWidth
																disabled
															/>
														</Fieldset>
													</Grid>
													<Grid
														sx={theme => ({
															[theme.breakpoints.up('md')]: {
																paddingLeft: '40px',
															},
														})}
														xs={12}
														md={4}
														item
													>
														<Fieldset key={relationship.id}>
															<FieldLabel htmlFor={multiplierFieldId}>
																Multiplier
																<AccessibilityText> for {relationship.name}</AccessibilityText>
															</FieldLabel>
															<TextField
																type='number'
																inputProps={{
																	min: 1,
																	step: 0.2,
																}}
																id={multiplierFieldId}
																{...getFieldProps(`relationships[${i}].modifier`)}
																fullWidth
															/>
														</Fieldset>
													</Grid>
												</Grid>
											);
										})}
									</Box>
									<Typography sx={{ marginTop: '28px', marginBottom: '68px' }}>
										If a badge is submitted by a client on behalf of an employee, there is
										a modifier applied to the points.
									</Typography>
								</>
							)}
							<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								<Button
									variant='contained'
									color='secondary'
									onClick={onCloseWindowClick}
								>
									Close Window
								</Button>
								{canUpdate && !loading && (
									<LoadingButton
										sx={{ marginLeft: '48px' }}
										variant='contained'
										color='primary'
										type='submit'
										disabled={!isValid}
										loading={saving}
									>
										Save
									</LoadingButton>
								)}
							</Box>
						</form>
					)}
				</Formik>
			)}
		</Can>
	);
}

export const Fieldset = styled('fieldset')({
	padding: 0,
	margin: 0,
	border: 0,
});

export const FieldLabel = styled(InputLabel)(({ theme }) => ({
	left: 0,
	marginBottom: 8,
	lineHeight: 1.167,
	color: 'inherit',
	textTransform: 'uppercase',
	fontSize: theme.typography.pxToRem(12),
}));

const AccessibilityText = styled('span')({
	fontSize: 0,
	width: 1,
	height: 1,
	left: -1,
	display: 'inline-block',
});
