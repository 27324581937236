import { Box } from '@mui/material';
import { LoginPageTemplate } from '../../templates/LoginPage';
import { LoginRequest } from '../../types/LoginRequest';
import { FirstAccessForm } from './components/FirstAccessForm';

export type FirstAccessPageViewProps = {
	useremail: string;
	onUpdate: (params: LoginRequest) => void;
	isSubmitting: boolean;
};

export function FirstAccessPageView({
	useremail,
	onUpdate,
	isSubmitting,
}: FirstAccessPageViewProps) {
	return (
		<LoginPageTemplate>
			<Box style={{ padding: 50 }}>
				<FirstAccessForm
					useremail={useremail}
					handleSubmit={onUpdate}
					isSubmitting={isSubmitting}
				/>
			</Box>
		</LoginPageTemplate>
	);
}
