/* eslint-disable react/no-array-index-key */
import { useCallback, ComponentProps, useState } from 'react';
import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { AdminPage } from '../../templates/AdminPage';
import { RequestDialog } from './components/RequestDialog';
import { useRedemptionRequestsController } from './RedemptionRequestsController';
import { PrizeRequestFilters } from './components/RequestFilters';
import { DateTimeFormatter } from '../../utils/DateTimeFormatter';
import {
	RedemptionRequest,
	RedemptionRequestPrize,
} from '../../types/RedemptionRequest';

const PAGE_TITLE =
	'The list below are requests to redeem points for the rewards listed on your points table. You can approve or deny these requests';

const defaultColumnProps: Partial<GridColDef> = {
	sortable: true,
	align: 'center',
	headerAlign: 'center',
	flex: 1,
};

const COLUMNS: GridColDef[] = [
	{
		headerName: 'From',
		field: 'from',
		...defaultColumnProps,
	},
	{
		headerName: 'Manager',
		field: 'manager',
		...defaultColumnProps,
	},
	{
		headerName: 'Reward',
		field: 'prizes',
		...defaultColumnProps,
		sortable: false,
		renderCell: params => (
			<div>
				{params.value.map((prize: any, i: number) => (
					<p style={{ margin: 0 }} key={i}>
						{prizeDisplay(prize)}
					</p>
				))}
			</div>
		),
	},
	{
		headerName: 'Points',
		field: 'totalPoints',
		...defaultColumnProps,
		sortable: false,
	},
	{
		headerName: 'Date Request',
		field: 'dateSent',
		...defaultColumnProps,
		sortable: true,
		valueFormatter: params => DateTimeFormatter.format(params.value),
	},
	{
		headerName: 'Status',
		field: 'confirmed',
		...defaultColumnProps,
		valueFormatter: params => (params.value ? 'Approved' : 'Pending'),
	},
];

type GridComponentProps = ComponentProps<typeof DataGrid>;

export function RedemptionRequestsView() {
	const {
		fetchingRedemptions,
		redemptions,
		handleSortModelChange,
		handlePaginationModelChange,
		paginationModel,
		total,
		setFilters,
	} = useRedemptionRequestsController();
	const [selectedPrizeRedemptionId, setSelectedPrizeRedemptionId] = useState<
		string | undefined
	>();
	const closeDialog = useCallback(
		() => setSelectedPrizeRedemptionId(undefined),
		[]
	);
	const handleRowClick: GridEventListener<'rowClick'> = params => {
		setSelectedPrizeRedemptionId(params.row.id);
	};

	const onPageSizeChange: NonNullable<GridComponentProps['onPageSizeChange']> =
		useCallback(
			(pageSize, details) => {
				if (handlePaginationModelChange)
					handlePaginationModelChange(
						{
							page: 0,
							...paginationModel,
							pageSize,
						},
						details
					);
			},
			[paginationModel, handlePaginationModelChange]
		);

	const onPageChange: NonNullable<GridComponentProps['onPageChange']> =
		useCallback(
			(page, details) => {
				if (handlePaginationModelChange)
					handlePaginationModelChange(
						{
							pageSize: 100,
							...paginationModel,
							page,
						},
						details
					);
			},
			[paginationModel, handlePaginationModelChange]
		);

	return (
		<AdminPage title={PAGE_TITLE}>
			<PrizeRequestFilters onFiltersChange={setFilters} />
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					width: '100%',
				}}
			>
				<DataGrid
					rowCount={total}
					paginationMode='server'
					loading={fetchingRedemptions}
					headerHeight={47}
					rows={redemptions || []}
					columns={COLUMNS}
					onRowClick={handleRowClick}
					onSortModelChange={handleSortModelChange}
					page={paginationModel?.page}
					pageSize={paginationModel?.pageSize}
					onPageSizeChange={onPageSizeChange}
					onPageChange={onPageChange}
					disableColumnSelector
					disableColumnFilter
					disableColumnMenu
					disableSelectionOnClick
					sx={{
						'& .MuiDataGrid-row:hover': {
							cursor: 'pointer',
						},
					}}
					autoHeight
				/>
			</Box>
			<RequestDialog
				open={selectedPrizeRedemptionId !== undefined}
				prizeRedemptionId={selectedPrizeRedemptionId}
				onClose={closeDialog}
			/>
		</AdminPage>
	);
}

function prizeDisplay(prize: RedemptionRequestPrize): string {
	let result = '';

	if (prize.quantity > 1) result += `${prize.quantity}x `;
	result += `${prize.name} - ${prize.points}`;

	return result;
}
