import { Box, Button, Typography, styled } from '@mui/material';
import { MouseEvent } from 'react';
import paperAirplane from '../../../../assets/img/paper-plane.svg';
import { OverridableComponentProps } from '../../../../utils/types';
import { Image } from '../../../../components/Image';

export type PrizeRedemptionConfirmationProps = {
	onContinue: (e: MouseEvent<HTMLButtonElement>) => void;
};

export function PrizeRedemptionConfirmation({
	onContinue,
}: PrizeRedemptionConfirmationProps) {
	return (
		<Wrapper>
			<Content>
				<Text
					sx={theme => ({
						[theme.breakpoints.down('sm')]: {
							width: '100%',
							textAlign: 'center',
							marginBottom: '22px',
						},
					})}
					variant='h2'
					component='span'
				>
					Your request has been sent
				</Text>
				<AirplaneBackground src={paperAirplane} />
				<ContinueButton variant='contained' onClick={onContinue}>
					Close window
				</ContinueButton>
			</Content>
		</Wrapper>
	);
}

const Wrapper = styled(Box)({
	width: '100%',
	aspectRatio: '21/9',
	display: 'flex',
	alignItems: 'center',
});

const Content = styled(Box)(({ theme }) => ({
	width: '100%',
	aspectRatio: '21/9',
	position: 'relative',
	display: 'flex',
	justifyContent: 'flex-end',
	flexDirection: 'column',
	alignItems: 'flex-start',
	padding: 16,
	[theme.breakpoints.down('md')]: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	[theme.breakpoints.down('sm')]: {
		justifyContent: 'center',
		background: 'none',
	},
}));

const AirplaneBackground = styled(Image)(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		right: 0,
		top: '9%',
		width: '40%',
		position: 'absolute',
	},
}));

const Text = styled(Typography)<OverridableComponentProps<typeof Typography>>(
	({ theme }) => ({
		maxWidth: '28ch',
		position: 'relative',
		textTransform: 'uppercase',
		[theme.breakpoints.up('sm')]: {
			left: '4.5%',
		},
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	})
);

const ContinueButton = styled(Button)({
	maxWidth: 199,
	marginTop: '14.635%',
	marginLeft: 'auto',
	marginRight: 'auto',
});
