import { useCallback, useEffect, useState } from 'react';
import { useUsersContext } from '../../context/Users';
import { User } from '../../types/User';

export type UseUsersState = {
	users: User[];
	loading: boolean;
	totalRows: number;
	setParams: (params: Record<string, any>) => void;
};

export const useUsers = () => {
	const { getAllUsers } = useUsersContext();
	const [users, setUsers] = useState<User[]>([]);
	const [totalRows, setTotalRows] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [params, setParams] = useState<any>({});

	const getUsers = useCallback(async () => {
		try {
			setLoading(true);
			const result = await getAllUsers(params);
			setUsers(result.data.results);
			setTotalRows(result.data.total);
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [params]);

	useEffect(() => {
		if (Object.keys(params).length) {
			getUsers();
		}
	}, [params]);

	return {
		users,
		loading,
		totalRows,
		setParams,
	};
};
