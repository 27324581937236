import { ApiClient } from '../ApiClient';
import { LoginRequest } from '../../types/LoginRequest';
import { LoginUserDto } from './Auth.type';
import { encryptStorage } from '../../utils/storage';

export class Auth extends ApiClient {
  private endpoint = 'auth/login';

  login(params: LoginRequest) {
    return this.httpClient.post(
      this.endpoint,
      Auth.loginUserDtoFromRequest(params)
    );
  }

  static loginUserDtoFromRequest(request: LoginRequest): LoginUserDto {
    return {
      email: request.email,
      password:
        request.password === 'xxno-passwordxx'
          ? (encryptStorage.getItem('hsencriptedkey') as string)
          : request.password,
    };
  }
}
