import { Grid } from '@mui/material';
import { AwardTextSearchableFields } from '../../api/Awards/Awards.type';
import { DateSearchParameters } from '../../types/DateSearchParameters';
import { DateSearchParameter } from '../../utils/enums/DateSearchParameter';
import { DatePicker } from '../DatePicker';
import { TextSearch, TextSearchProps } from '../TextSearch';

export type BadgeAwardRequestFiltersViewProps = {
	suggestions: string[];
	searchableFields: TextSearchProps['fields'];
	handleFieldChange: (field: string) => void;
	handleTextTermChange: (term: string) => void;
	handleTextSearchSubmit: (values: {
		term: string;
		field: AwardTextSearchableFields;
	}) => void;
	datePickerFieldHandler: (
		field: keyof DateSearchParameters
	) => (date: Date | null) => void;
};

export function BadgeAwardRequestFiltersView({
	suggestions,
	searchableFields,
	handleFieldChange,
	handleTextTermChange,
	handleTextSearchSubmit,
	datePickerFieldHandler,
}: BadgeAwardRequestFiltersViewProps) {
	return (
		<Grid sx={{ marginTop: '8px', marginBottom: '26px' }} container>
			<Grid xs={12} md={6} item>
				<TextSearch<AwardTextSearchableFields>
					minimumTermLength={3}
					fields={searchableFields}
					autocompleteOptions={suggestions}
					onFieldChange={handleFieldChange}
					onTermChange={handleTextTermChange}
					onSubmit={handleTextSearchSubmit}
				/>
			</Grid>
			<Grid
				sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}
				xs={12}
				md={6}
				item
			>
				<DatePicker
					label='From'
					onChange={datePickerFieldHandler(DateSearchParameter.DateFrom)}
					disableFuture
				/>
				<DatePicker
					label='To'
					WrapperProps={{
						sx: {
							marginLeft: '12px',
						},
					}}
					onChange={datePickerFieldHandler(DateSearchParameter.DateTo)}
					disableFuture
				/>
			</Grid>
		</Grid>
	);
}
