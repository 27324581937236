import { SvgIcon, SvgIconProps } from '@mui/material';

export function ArrowUpIcon(props?: SvgIconProps) {
	return (
		<SvgIcon
			width='14'
			height='8'
			viewBox='0 0 14 8'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='M7 1.22392e-06L13.0622 6L0.937821 6L7 1.22392e-06Z' />
		</SvgIcon>
	);
}
