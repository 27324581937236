import { ReportScheduleFrequencyOption } from '../../types/ReportScheduleFrequencyOption';
import {
	ReportSettings,
	ReportSettingsUpdate,
} from '../../types/ReportSettings';
import { extendAxiosTransformer } from '../../utils/extendAxiosTransformer.helper';
import { ApiClient } from '../ApiClient';
import {
	SchedulerSettingsDto,
	SchedulerSettingsUpdateDto,
} from './Reports.type';

export class Reports extends ApiClient {
	private baseEndpoint = '/requests/reports';

	fetchSchedulingOptions = async () =>
		this.protectedHttpClient.get<ReportScheduleFrequencyOption[]>(
			this.endpoint('schedule/options'),
			{
				transformResponse: extendAxiosTransformer(
					this.protectedHttpClient.defaults.transformResponse,
					[Reports.schedulingOptionsFromSettingsDto]
				),
			}
		);

	fetchReportSettings = async () =>
		this.protectedHttpClient.get<ReportSettings>(
			this.endpoint('schedule/options'),
			{
				transformResponse: extendAxiosTransformer(
					this.protectedHttpClient.defaults.transformResponse,
					[Reports.settingsFromDto]
				),
			}
		);

	updateSettings = (update: ReportSettingsUpdate) => {
		const updateDto: SchedulerSettingsUpdateDto = {
			currentOptionId: update.reportSchedule,
		};
		return this.protectedHttpClient.put(this.endpoint('schedule'), updateDto);
	};

	static schedulingOptionsFromSettingsDto(
		dto: SchedulerSettingsDto
	): ReportScheduleFrequencyOption[] {
		return dto.options.map(option => ({
			id: option.id,
			name: option.name,
		}));
	}

	static settingsFromDto(dto: SchedulerSettingsDto): ReportSettings {
		return {
			reportSchedule: dto.options.find(option => option.active)?.id,
		};
	}

	private endpoint(path?: string) {
		if (path == null) return this.baseEndpoint;
		return `${this.baseEndpoint}/${path}`;
	}
}
