import { ComponentThemeOverride } from '../../utils/types';

export const MuiOutlinedInput: ComponentThemeOverride['MuiOutlinedInput'] = {
	styleOverrides: {
		root: {
			borderRadius: 0,
		},
		inputSizeSmall: {
			'&:not(.MuiInputBase-inputMultiline)': {
				padding: '11px 16px 10px',
			},
		},
		multiline: {
			padding: '14px 4px 6px 16px',
		},
		inputMultiline: {
			resize: 'vertical',
			paddingRight: 12,
			paddingBottom: 10,
		},
		notchedOutline: {
			borderColor: '#545454',
			'& > legend': {
				fontSize: '0.8rem',
			},
		},
	},
	defaultProps: {
		sx: theme => ({
			fontSize: theme.typography.pxToRem(12),
		}),
	},
};
