import { ReactNode, useEffect } from 'react';
import { useAuth } from '../../context/Auth';

export type ProtectedRouteProps = {
	children: ReactNode;
};

export function ProtectedRoute({ children }: ProtectedRouteProps) {
	const { bootingUp, loggedIn, requireLogin } = useAuth();

	useEffect(() => {
		if (!(bootingUp || loggedIn)) requireLogin();
	}, [bootingUp, loggedIn]);

	if (bootingUp || !loggedIn) return null;

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
}
