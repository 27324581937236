import { DataGrid } from '@mui/x-data-grid';
import {
	ComponentProps,
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import { PrizeRedemptionSortableFields } from '../../api/PrizeRedemption/PrizeRedemption.type';
import {
	UsePrizeRedemptionConfig,
	usePrizeRedemption,
} from '../../hooks/usePrizeRedemption';
import { RedemptionRequest } from '../../types/RedemptionRequest';
import { SortOrder } from '../../types/SortDto';
import { Filters } from './RedemptionRequests.type';

export type RedemptionRequestsObj = {
	redemptions: RedemptionRequest[] | undefined;
	fetchingRedemptions: boolean;
	setFilters: (filters: Filters) => void;
	handleSortModelChange?: GridComponentProps['onSortModelChange'];
	handlePaginationModelChange?: (
		model: GridPaginationModel,
		details: any
	) => void;
	paginationModel?: GridPaginationModel;
	total: number;
	refetch: () => void;
	filters?: Filters;
	sort?: UsePrizeRedemptionConfig['sort'];
};

type GridPaginationModel = { page: number; pageSize: number };

type GridComponentProps = ComponentProps<typeof DataGrid>;

export const DEFAULT_SORT_ORDER = {
	order: SortOrder.Descending,
	orderBy: PrizeRedemptionSortableFields.SENT_AT,
};

export const RedemptionRequestsContext = createContext<
	RedemptionRequestsObj | undefined
>(undefined);

export function useRedemptionRequestsController(): RedemptionRequestsObj {
	const ctx = useContext(RedemptionRequestsContext);
	if (!ctx) {
		throw new Error(
			'useRedemptionRequestsController() has to be wrapped in RedemptionRequestsController'
		);
	}

	return ctx;
}

export type RedemptionRequestsControllerProps = {
	children: ReactNode;
};

export function RedemptionRequestsController({
	children,
}: RedemptionRequestsControllerProps) {
	const [sort, setSort] =
		useState<UsePrizeRedemptionConfig['sort']>(DEFAULT_SORT_ORDER);
	const [pagination, setPagination] = useState<
		NonNullable<UsePrizeRedemptionConfig['pagination']>
	>({
		page: 0,
		limit: 100,
	});
	const [filters, setFiltersState] = useState<Filters>();

	const usePrizeRedemptionConfig = useMemo(
		() => ({
			sort,
			filters,
			pagination,
		}),
		[sort, pagination, filters]
	);
	const {
		redemptions,
		loading,
		pagination: redemptionsPaginationInfo,
		refetch,
	} = usePrizeRedemption(usePrizeRedemptionConfig);

	const handleSortModelChange: NonNullable<
		ComponentProps<typeof DataGrid>['onSortModelChange']
	> = useCallback(model => {
		setSort(
			model.length
				? {
						order: model[0].sort as SortOrder,
						orderBy: GridFieldToSortableField.get(model[0].field),
				  }
				: DEFAULT_SORT_ORDER
		);
	}, []);

	const handlePaginationModelChange = useCallback(
		(model: { page: number; pageSize: number }) => {
			setPagination({
				page: model.page,
				limit: model.pageSize,
			});
		},
		[]
	);

	const paginationModel = useMemo(
		() => ({
			page: pagination.page as number,
			pageSize: pagination.limit as number,
		}),
		[pagination]
	);

	const setFilters = useCallback((update: Partial<Filters>) => {
		setFiltersState(current => ({
			...current,
			...update,
		}));
	}, []);

	const values: RedemptionRequestsObj = useMemo(
		() => ({
			redemptions,
			fetchingRedemptions: loading,
			setFilters,
			handleSortModelChange,
			handlePaginationModelChange,
			paginationModel,
			total: redemptionsPaginationInfo?.total ?? 0,
			refetch,
			filters,
			sort,
		}),
		[redemptions, loading, filters, sort]
	);

	return (
		<RedemptionRequestsContext.Provider value={values}>
			{children}
		</RedemptionRequestsContext.Provider>
	);
}

const GridFieldToSortableField = new Map([
	['from', PrizeRedemptionSortableFields.FROM_NAME],
	['manager', PrizeRedemptionSortableFields.MANAGER_NAME],
	['dateSent', PrizeRedemptionSortableFields.SENT_AT],
	['confirmed', PrizeRedemptionSortableFields.STATUS],
]);
