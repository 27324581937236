import { AxiosError, AxiosPromise } from 'axios';
import { useCallback, useEffect, useState } from 'react';

export type Fetcher<Parameters extends any[], ResponseData> = (
	...params: Parameters
) => AxiosPromise<ResponseData>;

export type UseApiCallConfig = {
	onRejection?: (reason: AxiosError) => void;
};

const DEFAULT_PARAMS: unknown = [];
const DEFAULT_CONFIG = {};

/**
 * This hook follows the best practice documented for data fetching in projects
 * not using libraries like useSWR or frameworks like NextJS or Remix. It also
 * creates another layer of abstraction making it easier to later implement useSWR
 * as an optimization
 *
 * @link https://beta.reactjs.org/learn/you-might-not-need-an-effect#fetching-data
 *
 * @param config
 * @returns
 */

export function useApiCall<ResponseData, FetcherParameters extends any[]>(
	fetcher: Fetcher<FetcherParameters, ResponseData>,
	params: FetcherParameters = DEFAULT_PARAMS as FetcherParameters,
	config: UseApiCallConfig = DEFAULT_CONFIG
) {
	const [data, setData] = useState<ResponseData>();
	const [loading, setLoading] = useState(true);
	const [refetchCount, setRefetchCount] = useState(0);

	useEffect(() => {
		setLoading(true);
		let ignore = false; // Flag to prevent state update after hook is unmounted

		(async () => {
			try {
				const response = await fetcher(...params);
				if (!ignore) setData(response.data);
			} catch (e) {
				if (!ignore && config.onRejection) config.onRejection(e as any); // cast as any to preserve current behavior, instead of checking if error is instance of AxiosError
			} finally {
				if (!ignore) setLoading(false);
			}
		})();

		return () => {
			ignore = true;
		};
	}, [params, config, refetchCount]);

	const refetch = useCallback(() => setRefetchCount(count => count + 1), []);

	return {
		data,
		loading,
		refetch,
	};
}
