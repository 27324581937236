import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Prizes } from '../../api/Prizes';
import { useApiCall } from '../../hooks/useApiCall';
import { PrizesContext, PrizesContextObj } from './PrizesContext';

export const PRIZE_FETCH_REJECTION_MESSAGE =
	'Could not get the available prizes, please try again later!';

export const PRIZE_SUCCESSFULLY_DELETED = 'The prize was deleted successfully!';

export const COULD_NOT_DELETE_PRIZE_MESSAGE =
	'Could not delete the given prize, please try again later!';

export type PrizesProviderProps = {
	children?: ReactNode;
	prizesClient?: Prizes;
};

export function PrizesProvider({
	children,
	prizesClient = new Prizes(),
}: PrizesProviderProps) {
	const { enqueueSnackbar } = useSnackbar();
	const [error, setError] = useState<AxiosError>();
	const [apiParams, setApiParams] = useState<[]>([]);

	const apiConfig = useMemo(
		() => ({
			onRejection: (e: AxiosError) => {
				enqueueSnackbar(PRIZE_FETCH_REJECTION_MESSAGE, { variant: 'error' });
				setError(e);
			},
		}),
		[]
	);
	const { data: prizes = [], loading } = useApiCall(
		prizesClient.getAllPrizes,
		apiParams,
		apiConfig
	);

	const refetch = useCallback(() => setApiParams([]), []);

	const deleteById = useCallback(
		(id: string) => {
			prizesClient
				.remove(id)
				.then(() => {
					enqueueSnackbar(PRIZE_SUCCESSFULLY_DELETED, { variant: 'success' });
				})
				.catch(() => {
					enqueueSnackbar(COULD_NOT_DELETE_PRIZE_MESSAGE, { variant: 'error' });
				})
				.finally(() => {
					refetch();
				});
		},
		[prizesClient]
	);

	const value: PrizesContextObj = useMemo(
		() => ({
			prizes,
			error,
			loading,
			refetch,
			deleteById,
		}),
		[prizes, loading, error]
	);
	return (
		<PrizesContext.Provider value={value}>{children}</PrizesContext.Provider>
	);
}
