import { ComponentThemeOverride } from '../../utils/types';

export const MuiTextField: ComponentThemeOverride['MuiTextField'] = {
	styleOverrides: {
		root: {
			marginBottom: 16,
		},
	},
	defaultProps: {
		size: 'small',
	},
};
