import { CreatePrizeEditor, CreatePrizeEditorProps } from './CreatePrizeEditor';
import { UpdatePrizeEditor, UpdatePrizeEditorProps } from './UpdatePrizeEditor';

export type PrizeEditorProps =
	| UpdatePrizeEditorProps
	| (CreatePrizeEditorProps & {
			prizeId?: undefined;
	  });

export function PrizeEditor({ prizeId, ...props }: PrizeEditorProps) {
	if (prizeId != null) return <UpdatePrizeEditor prizeId={prizeId} {...props} />;
	return <CreatePrizeEditor {...props} />;
}
