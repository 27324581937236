import type { AxiosError } from 'axios';
import { createContext } from 'react';
import { Prize } from '../../types/Prize';

export type PrizesContextObj = {
	prizes: Prize[];
	loading: boolean;
	error: AxiosError | undefined;
	refetch: () => void;
	deleteById: (id: string) => void;
};

export const PrizesContext = createContext<PrizesContextObj | undefined>(
	undefined
);
