export function parseCsv(csv: string, separator = ','): Array<Record<string, string>> {
	const rows:string[] = csv.split('\n');
	const result: Array<Record<string, string>> = [];
	const headers = rows[0].split(separator);

	for (let i = 1; i < rows.length; i += 1) {
		const entry: Record<string, string> = {};

		rows[i].split(separator).forEach((value, index) => {
			entry[headers[index]] = value;
		});

		result.push(entry);
	}

	return result;
}