import { InternalAxiosRequestConfig } from 'axios';
import { TokenStorage } from '../../../classes/TokenStorage';

export type AuthenticationInterceptorConfig = {
	tokenStorage: TokenStorage;
};

export function authentication({
	tokenStorage,
}: AuthenticationInterceptorConfig) {
	return (config: InternalAxiosRequestConfig) => {
		const accessToken = tokenStorage.getToken('accessToken');

		if (!accessToken) throw new UserNotAuthenticatedError();

		config.headers.set('Authorization', `Bearer ${accessToken}`);

		return config;
	};
}

export class UserNotAuthenticatedError extends Error {
	constructor(message = 'User is not authenticated') {
		super(message);
	}
}
