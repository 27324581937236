import { AwardStatuses } from '../../types/Award';
import { DateSearchParameters } from '../../types/DateSearchParameters';
import { PaginationQueryParams } from '../../types/PaginationDto';
import { SortQueryParams } from '../../types/SortDto';
import { TextSearchQueryParameters } from '../../types/TextSearchQueryParameters';

export type AwardsQueryParams = AwardsSortQueryParams &
	PaginationQueryParams &
	AwardsTextSearchParams &
	DateSearchParameters &
	AwardFilterQueryParams;

export type AwardsReportQueryStringDto = DateSearchParameters &
	AwardFilterQueryParams &
	AwardsSortQueryParams;

export type AwardsTextSearchParams =
	TextSearchQueryParameters<AwardTextSearchableFields>;

export type AwardsSortQueryParams = SortQueryParams<AwardSortableFields>;

export type AwardFilterQueryParams = {
	[AwardFilterableFields.STATUS]?: AwardStatuses;
	[AwardFilterableFields.MANAGER_ID]?: string;
};

// eslint-disable-next-line no-shadow
export enum AwardSortableFields {
	FROM_NAME = 'fromName',
	TO_NAME = 'toName',
	MANAGER_NAME = 'managerName',
	BADGE_NAME = 'badgeName',
	SENT_AT = 'sentAt',
	UPDATED_AT = 'updatedAt',
	STATUS = 'status',
}

// eslint-disable-next-line no-shadow
export enum AwardTextSearchableFields {
	FROM_NAME = 'fromName',
	TO_NAME = 'toName',
	MANAGER_NAME = 'managerName',
	BADGE_NAME = 'badgeName',
}

// eslint-disable-next-line no-shadow
export enum AwardFilterableFields {
	STATUS = 'status',
	MANAGER_ID = 'managerId',
}

export type AwardsSendDto = {
	badgeId: string;
	recipientUserIds: string[];
	fromName: string;
	fromEmail: string;
	message: string;
	recipientRelationshipId: string;
};

export type AwardDto = {
	id: string;
	fromName: string;
	fromEmail: string;
	message: string;
	status: AwardStatuses;
	manager: AwardsUserDto;
	users: AwardsUserDto[];
	badge: {
		id: string;
		name: string;
		points: number;
		active: boolean;
		assets: {
			svg: string;
			png: string;
			smallSvg: string;
		};
	};
	recipientRelationship: {
		id: string;
		name: string;
		bonusPoints: number;
		active: boolean;
	};
	sentAt: string;
};

export type AwardsUserDto = {
	id: string;
	name: string;
	position: string;
	email: string;
	manager?: Omit<AwardsUserDto, 'manager'>;
};
