import { AxiosResponseTransformer } from 'axios';
import { Award, AwardStatuses, AwardUser } from '../../types/Award';
import { BadgeAwardRequest } from '../../types/BadgeAwardRequest';
import { extendAxiosTransformer } from '../../utils/extendAxiosTransformer.helper';
import { ApiClient } from '../ApiClient';
import { Badges } from '../Badges';
import {
	AwardDto,
	AwardTextSearchableFields,
	AwardsQueryParams,
	AwardsReportQueryStringDto,
	AwardsSendDto,
	AwardsUserDto,
} from './Awards.type';
import { ResultList } from '../../types/ResultList';

export class Awards extends ApiClient {
	private baseEndpoint = '/requests';

	find = (queryParams?: AwardsQueryParams) =>
		this.protectedHttpClient.get<ResultList<Award[]>>(this.baseEndpoint, {
			params: queryParams,
			transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
				this.protectedHttpClient.defaults.transformResponse,
				[Awards.transformAwardsResponse]
			),
		});

	send = (requestData: BadgeAwardRequest) =>
		this.httpClient.post(
			this.baseEndpoint,
			Awards.awardSendDtoFromRequest(requestData)
		);

	getById = (id: string) =>
		this.protectedHttpClient.get<Award>(this.endpoint(id), {
			transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
				this.protectedHttpClient.defaults.transformResponse,
				[Awards.awardFromDto]
			),
		});

	updateAwardStatus = (id: string, newStatus: AwardStatuses) =>
		this.protectedHttpClient.post(this.endpoint(`${id}/status`), {
			status: newStatus,
		});

	getOwnAwards = () =>
		this.protectedHttpClient.get<Award[]>(this.endpoint('findRequestsByUser'), {
			transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
				this.protectedHttpClient.defaults.transformResponse,
				[Awards.transformOwnAwardsResponse]
			),
		});

	autocomplete = (field: AwardTextSearchableFields, term: string) =>
		this.protectedHttpClient.get<string[]>(this.endpoint('autocomplete'), {
			params: {
				field,
				term,
			},
		});

	report = (params: AwardsReportQueryStringDto) =>
		this.protectedHttpClient.get<string>(this.endpoint('report'), {
			params,
		});

	static transformAwardsResponse(data: ResultList<AwardDto[]>) {
		return {
			...data,
			results: data.results.map(Awards.awardFromDto),
		};
	}

	static transformOwnAwardsResponse(data: AwardDto[]) {
		if (!(data && Array.isArray(data))) return [];
		return data.map(Awards.awardFromDto);
	}

	static awardSendDtoFromRequest(
		awardRequest: BadgeAwardRequest
	): AwardsSendDto {
		return {
			recipientUserIds: awardRequest.recipientIds,
			badgeId: awardRequest.badgeId,
			fromName: awardRequest.senderFullName,
			fromEmail: awardRequest.senderEmailAddress,
			message: awardRequest.message,
			recipientRelationshipId: awardRequest.senderRelationShipToRecipient,
		};
	}

	static awardFromDto(dto: AwardDto): Award {
		const result: Award = {
			sender: {
				email: dto.fromEmail,
				name: dto.fromName,
			},
			users: dto.users ? dto.users.map(user => Awards.formatUser(user, true)) : [],
			id: String(dto.id),
			sentAt: new Date(dto.sentAt),
			updatedAt: new Date(dto.sentAt),
			badge: Badges.badgeFromDto(dto.badge),
			message: dto.message,
			status: dto.status,
		};

		result.managers = Awards.managersFromUsers(dto.users);

		return result;
	}

	static managersFromUsers(users: AwardsUserDto[]): AwardUser[] {
		const result: Record<string, AwardUser> = {};

		users.forEach(user => {
			if (!user.manager || result[user.manager.email]) return;
			result[user.manager.email] = Awards.formatUser(user.manager);
		});

		return Object.values(result);
	}

	static formatUser(user: AwardsUserDto, includeManager = false): AwardUser {
		const result: AwardUser = {
			email: user.email,
			name: user.name,
		};

		if (includeManager) {
			result.manager = user.manager ? Awards.formatUser(user.manager) : null;
		}

		return result;
	}

	private endpoint(subpath?: string) {
		if (subpath == null) return this.baseEndpoint;
		return `${this.baseEndpoint}/${subpath}`;
	}
}
