import { Box, styled, Typography, lighten, BoxProps } from '@mui/material';

export type CounterBoxProps = BoxProps & {
	value: number | undefined;
	subtitle: string;
};

export function CounterBox({ value, subtitle, ...props }: CounterBoxProps) {
	return (
		<Box
			sx={theme => ({
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				height: '107px',
				width: '150px',
				background: `linear-gradient(0deg, ${lighten(
					theme.palette.primary.light,
					0.2
				)} 0%, ${theme.palette.primary.main} 100%)`,
				borderRadius: '25px',
				':first-of-type': { marginRight: '10px' },
			})}
			{...props}
		>
			<NumberValue>{value}</NumberValue>
			<SubtitleValue>{subtitle}</SubtitleValue>
		</Box>
	);
}

const NumberValue = styled(Typography)({
	fontWeight: 700,
	lineHeight: '1',
	display: 'inline-block',
	fontSize: '64px',
	color: 'white',
});
const SubtitleValue = styled(Typography)({
	fontWeight: 700,
	display: 'inline-block',
	fontSize: '12px',
	lineHeight: '15px',
	color: 'white',
	textTransform: 'uppercase',
});
