import { SvgIcon, SvgIconProps } from '@mui/material';

export function AdminIcon(props?: SvgIconProps) {
	return (
		<SvgIcon
			width='19'
			height='21'
			viewBox='0 0 19 21'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M13.7084 15.7083C14.1424 15.7083 14.5112 15.5562 14.8147 15.2521C15.1188 14.9486 15.2709 14.5798 15.2709 14.1458C15.2709 13.7118 15.1188 13.343 14.8147 13.0396C14.5112 12.7354 14.1424 12.5833 13.7084 12.5833C13.2744 12.5833 12.9056 12.7354 12.6022 13.0396C12.298 13.343 12.1459 13.7118 12.1459 14.1458C12.1459 14.5798 12.298 14.9486 12.6022 15.2521C12.9056 15.5562 13.2744 15.7083 13.7084 15.7083ZM13.7084 18.8333C14.2466 18.8333 14.7414 18.7073 15.1928 18.4552C15.6442 18.2038 16.0088 17.8698 16.2865 17.4531C15.9046 17.2274 15.4966 17.0538 15.0626 16.9323C14.6286 16.8107 14.1772 16.75 13.7084 16.75C13.2397 16.75 12.7883 16.8107 12.3542 16.9323C11.9202 17.0538 11.5122 17.2274 11.1303 17.4531C11.4081 17.8698 11.7727 18.2038 12.224 18.4552C12.6754 18.7073 13.1702 18.8333 13.7084 18.8333ZM13.7084 20.9166C12.2674 20.9166 11.0393 20.409 10.024 19.3937C9.00807 18.3778 8.50008 17.1493 8.50008 15.7083C8.50008 14.2673 9.00807 13.0389 10.024 12.0229C11.0393 11.0076 12.2674 10.5 13.7084 10.5C15.1494 10.5 16.3779 11.0076 17.3938 12.0229C18.4091 13.0389 18.9167 14.2673 18.9167 15.7083C18.9167 17.1493 18.4091 18.3778 17.3938 19.3937C16.3779 20.409 15.1494 20.9166 13.7084 20.9166ZM8.50008 20.9166C6.08689 20.309 4.09487 18.9243 2.52404 16.7625C0.952512 14.6014 0.166748 12.2014 0.166748 9.56248V3.20831L8.50008 0.083313L16.8334 3.20831V9.11977C16.382 8.89408 15.8744 8.72047 15.3105 8.59894C14.7459 8.47741 14.2119 8.41665 13.7084 8.41665C11.6945 8.41665 9.97578 9.12845 8.55216 10.5521C7.12855 11.9757 6.41675 13.6944 6.41675 15.7083C6.41675 16.7847 6.62091 17.7569 7.02925 18.625C7.43689 19.493 7.95321 20.2482 8.57821 20.8906C8.56084 20.8906 8.548 20.8951 8.53966 20.9041C8.53064 20.9125 8.51744 20.9166 8.50008 20.9166Z'
				fill='white'
			/>
		</SvgIcon>
	);
}
