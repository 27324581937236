import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { RoleGuard } from '../../../../components/RoleGuard/RoleGuard';
import { Roles } from '../../../../utils/enums/Roles';
import { useBrandSlug } from '../../../../context/BrandSlug';

export function SettingsButton() {
  const { brandSlug } = useBrandSlug();

  return (
    <RoleGuard roles={[Roles.SysAdmin, Roles.Manager, Roles.Authorizer]}>
      <Button to={`/${brandSlug}/admin/settings`} component={Link}>
        Settings
      </Button>
    </RoleGuard>
  );
}
