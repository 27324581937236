import { DataGrid } from '@mui/x-data-grid';
import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	AwardFilterQueryParams,
	AwardFilterableFields,
	AwardSortableFields,
	AwardsTextSearchParams,
} from '../../api/Awards/Awards.type';
import { UseAwardsConfig, useAwards } from '../../hooks/useAwards';
import { AwardStatuses } from '../../types/Award';
import { SortOrder } from '../../types/SortDto';
import { BadgeAwardManagementView } from './BadgeAwardManagementView';

type Filters = AwardsTextSearchParams & AwardFilterQueryParams;

export const DEFAULT_SORT_ORDER = {
	order: SortOrder.Descending,
	orderBy: AwardSortableFields.SENT_AT,
};

export function BadgeAwardManagementPage() {
	const params = useParams();
	const [sort, setSort] = useState<UseAwardsConfig['sort']>(DEFAULT_SORT_ORDER);
	const [filters, setFiltersState] = useState<Filters>({
		[AwardFilterableFields.STATUS]: AwardStatuses.Pending,
	});
	const [pagination, setPagination] = useState<
		NonNullable<UseAwardsConfig['pagination']>
	>({
		page: 0,
		limit: 100,
	});

	const useAwardsConfig = useMemo(
		() => ({
			sort,
			filters,
			pagination,
		}),
		[sort, pagination, filters]
	);
	const {
		awards,
		loading,
		pagination: awardsPaginationInfo,
		refetch,
	} = useAwards(useAwardsConfig);

	const handleSortModelChange: NonNullable<
		ComponentProps<typeof DataGrid>['onSortModelChange']
	> = useCallback(model => {
		setSort(
			model.length
				? {
						order: model[0].sort as SortOrder,
						orderBy: GridFieldToSortableField.get(model[0].field),
				  }
				: DEFAULT_SORT_ORDER
		);
	}, []);

	const handlePaginationModelChange = useCallback(
		(model: { page: number; pageSize: number }) => {
			setPagination({
				page: model.page,
				limit: model.pageSize,
			});
		},
		[]
	);

	const handleAwardStatusUpdate = useCallback(() => refetch(), []);

	const paginationModel = useMemo(
		() => ({
			page: pagination.page as number,
			pageSize: pagination.limit as number,
		}),
		[pagination]
	);

	const setFilters = useCallback((update: Partial<Filters>) => {
		setFiltersState(current => ({
			...current,
			...update,
		}));
	}, []);

	const currentAwardId = useMemo(() => {
		if (!params?.id || !awards.length) return undefined;
		const current = awards.find(({ id }) => id === params?.id);
		return current?.id;
	}, [awards]);

	return (
		<BadgeAwardManagementView
			total={awardsPaginationInfo.total ?? 0}
			awards={awards}
			loading={loading}
			filters={filters}
			paginationModel={paginationModel}
			setFilters={setFilters}
			onSortModelChange={handleSortModelChange}
			onAwardStatusUpdate={handleAwardStatusUpdate}
			onPaginationModelChange={handlePaginationModelChange}
			awardId={currentAwardId}
		/>
	);
}

const GridFieldToSortableField = new Map([
	['sender', AwardSortableFields.FROM_NAME],
	['user', AwardSortableFields.TO_NAME],
	['manager', AwardSortableFields.MANAGER_NAME],
	['badge', AwardSortableFields.BADGE_NAME],
	['sentAt', AwardSortableFields.SENT_AT],
	['status', AwardSortableFields.STATUS],
]);
