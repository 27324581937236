import { Box, Grid, Button, styled } from '@mui/material';
import { Badge } from '../../../../types/Badge';

type BadgeProps = {
	badge: Badge;
	onViewDetails: () => void;
};

export function BadgeCard({ badge, onViewDetails }: BadgeProps) {
	return (
		<BadgeBox>
			<Grid container direction='column' alignItems='center' spacing={1}>
				<Grid item>
					<img width='100%' src={badge.assets.full_png} alt={badge.name} />
				</Grid>
				<Grid item>
					<CustomButton color='primary' variant='contained' onClick={onViewDetails}>
						VIEW DETAILS
					</CustomButton>
				</Grid>
			</Grid>
		</BadgeBox>
	);
}

const BadgeBox = styled(Box)({
	maxWidth: 153,
	backgroundColor: '#d9d9d94d',
	borderRadius: 10,
	boxShadow: '0px 4px 4px #00000040',
	padding: 10,
});

const CustomButton = styled(Button)({
	fontSize: '0.8rem',
});
