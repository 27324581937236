import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState, MouseEvent } from 'react';
import { UseBadgeConfig, useBadge } from '../../../../hooks/useBadge';
import { useColors } from '../../../../hooks/useColors';
import { UpdateBadgeConfig } from '../../../../types/Badge';
import { BadgeDetailsView, BadgeDetailsViewProps } from './BadgeDetailsView';

export const BADGE_SAVE_SUCCESS_MESSAGE = 'Badge successfully updated';
export const BADGE_SAVE_ERROR_MESSAGE =
	'Could not update your badge, please try again later!';
export const BADGE_DELETE_SUCCESS_MESSAGE = 'Badge successfully updated';
export const BADGE_DELETE_ERROR_MESSAGE =
	'Could not delete your badge, please try again later!';

export type BadgeDetailsProps = {
	badgeId: string;
	onBadgeUpdate?: () => void;
	onBadgeDeleted?: () => void;
	onCloseWindowButtonClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

export function BadgeDetails({
	badgeId,
	onBadgeUpdate,
	onBadgeDeleted,
	onCloseWindowButtonClick,
}: BadgeDetailsProps) {
	const { enqueueSnackbar } = useSnackbar();
	const [editing, setEditing] = useState(false);
	const { colors, loading: loadingColors } = useColors();

	const handleUpdateSuccess = useCallback(() => {
		enqueueSnackbar(BADGE_SAVE_SUCCESS_MESSAGE, { variant: 'success' });
		if (onBadgeUpdate) onBadgeUpdate();
	}, [onBadgeUpdate]);

	const handleDeleteSuccess = useCallback(() => {
		enqueueSnackbar(BADGE_DELETE_SUCCESS_MESSAGE, { variant: 'success' });
		if (onBadgeDeleted) onBadgeDeleted();
	}, [onBadgeDeleted]);

	const useBadgeConfig: NonNullable<UseBadgeConfig> = useMemo(
		() => ({
			onBadgeDeleteError: () => {
				enqueueSnackbar(BADGE_DELETE_ERROR_MESSAGE, { variant: 'error' });
			},
			onBadgeUpdateError: () => {
				enqueueSnackbar(BADGE_SAVE_ERROR_MESSAGE, { variant: 'error' });
			},
			onBadgeUpdated: handleUpdateSuccess,
			onBadgeDeleted: handleDeleteSuccess,
		}),
		[handleUpdateSuccess, handleDeleteSuccess]
	);

	const {
		badge,
		loading: loadingBadge,
		updating,
		deleting,
		deleteBadge,
		updateBadge,
	} = useBadge(badgeId, useBadgeConfig);
	const handleEditButtonClick = useCallback(() => setEditing(true), []);

	const loading = useMemo(
		() => loadingColors || loadingBadge,
		[loadingBadge, loadingColors]
	);

	const handleSave: NonNullable<BadgeDetailsViewProps['onSave']> = useCallback(
		values => {
			const updates: UpdateBadgeConfig = {};

			if (values.colorId !== badge?.color.id) updates.colorId = values.colorId;
			if (values.name !== badge?.name) updates.name = values.name;
			if (values.points !== badge?.points) updates.points = values.points;

			updateBadge(updates);
		},
		[badge]
	);

	return (
		<BadgeDetailsView
			name={badge?.name}
			points={badge?.points}
			color={badge?.color}
			iconSrc={badge?.icon.assets.full}
			colors={colors}
			loading={loading}
			editing={editing}
			saving={updating}
			removing={deleting}
			onSave={handleSave}
			onRemoveButtonClick={deleteBadge}
			onEditButtonClick={handleEditButtonClick}
			onCloseWindowButtonClick={onCloseWindowButtonClick}
		/>
	);
}
