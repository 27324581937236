import { createContext } from 'react';
import { Permission } from '../../utils/helpers/authorization';

export type AdminLinksContextState = {
	links: AdminLink[];
};

export type AdminLink = {
	path: string;
	title: string;
	permissions: Permission[];
};

export const AdminLinksContext = createContext<
	AdminLinksContextState | undefined
>(undefined);
