import { Box, BoxProps, Typography, styled } from '@mui/material';
import { ReactNode } from 'react';

export type AdminPageProps = {
	title: string;
	children?: ReactNode;
	actions?: ReactNode;
	titleComponent?: TitleComponent;
	HeaderProps?: BoxProps;
};

type TitleComponent = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p';

export function AdminPage({
	title,
	children,
	actions,
	titleComponent = 'h1',
	HeaderProps,
}: AdminPageProps) {
	return (
		<>
			<Header {...HeaderProps}>
				<Box sx={{ flex: 1 }}>
					<Typography variant='h3' component={titleComponent}>
						{title}
					</Typography>
				</Box>
				{Boolean(actions) && <Actions>{actions}</Actions>}
			</Header>
			{children}
		</>
	);
}

const Header = styled(Box)({
	width: '100%',
	display: 'flex',
	flexWrap: 'nowrap',
	alignItems: 'center',
	paddingBottom: 20,
});

const Actions = styled(Box)({
	paddingLeft: 16,
	paddingRight: 4,
});
