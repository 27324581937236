import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { RemoveIcon } from '../../assets/Icons/Remove';

export type RemoveButtonProps = Omit<
	LoadingButtonProps,
	'children' | 'variant' | 'startIcon'
>;

export function RemoveButton(props: RemoveButtonProps) {
	return (
		<LoadingButton
			variant='text'
			startIcon={<RemoveIcon htmlColor='#F26A25' />}
			{...props}
		>
			Remove
		</LoadingButton>
	);
}
