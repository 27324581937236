import { Box, Button, CircularProgress } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { AxiosError } from 'axios';
import {
	ComponentType,
	MouseEvent,
	useCallback,
	useMemo,
	useState,
} from 'react';
import { Dialog, DialogProps } from '../../components/Dialog';
import { AdminPage } from '../../templates/AdminPage';
import { Prize } from '../../types/Prize';
import { PrizeEditor } from './components/PrizeEditor';
import { RemoveButton } from '../../components/RemoveButton';
import { RelationshipManager } from './components/RelationshipManager';
import { Can } from '../../components/Can';
import { Action, ActionSubject } from '../../utils/enums/abilities';

export const MULTIPLIERS_BUTTON_TEXT = 'Multipliers';
export const NEW_PRIZE_BUTTON_TEXT = 'Add Reward';

export const PAGE_TITLE =
	'Each brag badges system allows you to customize your points table from the defaults listed below.';

const GRID_COLUMNS: GridColDef[] = [
	{
		field: 'points',
		headerName: 'Points',
		sortable: false,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'name',
		headerName: ' Badge Reward',
		sortable: false,
		flex: 1,
		headerAlign: 'center',
		align: 'center',
	},
];

export type PrizeManagementViewProps = {
	prizes: Prize[];
	loading?: boolean;
	showRelationshipManager?: boolean;
	onRemoveListItem?: (prizeId: string) => void;
	onPrizeUpdate?: () => void;
	onPrizeUpdateError?: (e: AxiosError) => void;
	onPrizeDelete?: () => void;
	onPrizeDeleteError?: (e: AxiosError) => void;
	onPrizeCreated?: () => void;
	onPrizeCreationError?: (e: AxiosError) => void;
	openRelationshipManager?: () => void;
	closeRelationshipManager?: () => void;
	LoadingIndicator?: ComponentType;
};

const DATA_GRID_STYLE = {
	'& .MuiDataGrid-row:hover': {
		cursor: 'pointer',
	},
	'& .MuiDataGrid-cell.deleteButtonWrapper': {
		padding: 0,
	},
};

export function PrizeManagementView({
	prizes,
	loading,
	showRelationshipManager,
	onRemoveListItem,
	onPrizeUpdate,
	onPrizeUpdateError,
	onPrizeDelete,
	onPrizeDeleteError,
	onPrizeCreated,
	onPrizeCreationError,
	openRelationshipManager,
	closeRelationshipManager,
	LoadingIndicator = CircularProgress,
}: PrizeManagementViewProps) {
	const [currentPrizeId, setCurrentPrizeId] = useState<string | undefined>();
	const [showPrizeCreationDialog, setShowPrizeCreationDialog] = useState(false);

	const openPrizeCreationDialog = useCallback(
		() => setShowPrizeCreationDialog(true),
		[]
	);

	const closePrizeCreationDialog = useCallback(
		() => setShowPrizeCreationDialog(false),
		[]
	);

	const closePrizeEditing = useCallback(() => setCurrentPrizeId(undefined), []);

	const handleRowClick: GridEventListener<'rowClick'> = useCallback(
		({ row }) => {
			setCurrentPrizeId(row.id);
		},
		[]
	);

	const handleOnPrizeCreated = useCallback(() => {
		if (onPrizeCreated) onPrizeCreated();
		closePrizeCreationDialog();
	}, []);

	const handleOnPrizeUpdated = useCallback(() => {
		if (onPrizeUpdate) onPrizeUpdate();
		closePrizeEditing();
	}, []);

	const handleOnPrizeDeleted = useCallback(() => {
		if (onPrizeDelete) onPrizeDelete();
		closePrizeEditing();
	}, []);

	const gridColumns = useMemo(
		() =>
			GRID_COLUMNS.concat([
				{
					field: 'remove',
					headerName: 'Remove',
					sortable: false,
					headerAlign: 'center',
					align: 'center',
					width: 148,
					cellClassName: 'deleteButtonWrapper',
					renderCell: ({ row }) => {
						const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
							e.stopPropagation();
							if (onRemoveListItem) onRemoveListItem(row.id);
						};

						return (
							<Can do={Action.Delete} on={ActionSubject.Prize}>
								<RemoveButton
									aria-describedby={`prize_${row.id}_delete_button_description`}
									onClick={handleButtonClick}
								/>
								<span
									style={{ display: 'none' }}
									id={`prize_${row.id}_delete_button_description`}
								>
									Removes {row.name}
								</span>
							</Can>
						);
					},
				},
			]),
		[onRemoveListItem]
	);

	return (
		<>
			<AdminPage
				actions={
					<>
						<Can do={Action.Update} on={ActionSubject.RecipientRelationship}>
							<Button
								variant='contained'
								color='primary'
								onClick={openRelationshipManager}
							>
								{MULTIPLIERS_BUTTON_TEXT}
							</Button>
						</Can>
						<Can do={Action.Create} on={ActionSubject.Prize}>
							<Button
								variant='contained'
								color='primary'
								sx={{ marginLeft: '24px' }}
								onClick={openPrizeCreationDialog}
							>
								{NEW_PRIZE_BUTTON_TEXT}
							</Button>
						</Can>
					</>
				}
				title={PAGE_TITLE}
			>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						paddingTop: '42px',
						paddingBottom: '120px',
						justifyContent: 'center',
					}}
					component='main'
				>
					{loading && <LoadingIndicator />}
					{!loading && Boolean(prizes.length) && (
						<DataGrid
							pageSize={100}
							headerHeight={47}
							rowHeight={78}
							rows={prizes}
							sx={DATA_GRID_STYLE}
							columns={gridColumns}
							onRowClick={handleRowClick}
							disableSelectionOnClick
							disableColumnSelector
							disableColumnFilter
							disableColumnMenu
							autoHeight
							hideFooter
						/>
					)}
				</Box>
			</AdminPage>
			<EditorDialog
				title='Add Reward'
				open={showPrizeCreationDialog}
				onClose={closePrizeCreationDialog}
			>
				<PrizeEditor
					onPrizeCreated={handleOnPrizeCreated}
					onPrizeCreationError={onPrizeCreationError}
					onCloseWindowClick={closePrizeCreationDialog}
				/>
			</EditorDialog>
			<EditorDialog
				title='Edit Reward'
				open={currentPrizeId != null}
				onClose={closePrizeEditing}
			>
				<PrizeEditor
					prizeId={currentPrizeId}
					onPrizeUpdate={handleOnPrizeUpdated}
					onPrizeUpdateError={onPrizeUpdateError}
					onPrizeDelete={handleOnPrizeDeleted}
					onPrizeDeleteError={onPrizeDeleteError}
					onCloseWindowClick={closePrizeEditing}
				/>
			</EditorDialog>
			<EditorDialog
				title='Mange Bonus Modifiers'
				open={Boolean(showRelationshipManager)}
				onClose={closeRelationshipManager}
			>
				<RelationshipManager onCloseWindowClick={closeRelationshipManager} />
			</EditorDialog>
		</>
	);
}

function EditorDialog({ children, ...props }: DialogProps) {
	return (
		<Dialog
			PaperProps={{
				// @ts-ignore
				'data-testid': 'prize-creation-dialog',
			}}
			HeaderProps={{
				sx: {
					paddingLeft: '64px',
				},
			}}
			ContentProps={{
				sx: {
					paddingLeft: '64px',
					paddingRight: '64px',
				},
			}}
			{...props}
		>
			{children}
		</Dialog>
	);
}
