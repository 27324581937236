import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Badges } from '../../api/Badges';
import { Badge } from '../../types/Badge';
import { BadgeManagementView } from './BadgeManagementView';

const ERROR_BADGES_LIST = 'Error on getting the badges, please try again later';

type BadgeManagementProps = {
	client?: Badges;
};

export function BadgeManagementPage({
	client: badgesClient = new Badges(),
}: BadgeManagementProps) {
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState<boolean>(false);
	const [badges, setBadges] = useState<Badge[]>();

	const fetchBadges = useCallback(() => {
		setLoading(true);
		badgesClient
			.findAll()
			.then(res => setBadges(res.data))
			.catch(() => enqueueSnackbar(ERROR_BADGES_LIST, { variant: 'error' }))
			.finally(() => setLoading(false));
	}, []);

	useEffect(() => fetchBadges(), []);

	return (
		<BadgeManagementView
			badges={badges}
			loading={loading}
			onFeedUpdateNeeded={fetchBadges}
		/>
	);
}
