import { Container as MuiContainer, styled } from '@mui/material';
import { ReactNode } from 'react';
import { LeftDottedPage } from '../../templates/LeftDottedPage';
import { AdminPortalHeader } from './components/AdminPortalHeader';

export type AdminPortalProps = {
	children?: ReactNode;
};

export function AdminPortal({ children }: AdminPortalProps) {
	return (
		<LeftDottedPage>
			<AdminPortalHeader />
			<Container>{children}</Container>
		</LeftDottedPage>
	);
}

const Container = styled(MuiContainer)({
	'&&&': {
		maxWidth: 1128,
		paddingTop: 28,
	},
});
