import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { PaletteOptions } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { BrandsClient } from '../../api/Brands';
import { BrandSettingsUpdateDto } from '../../api/Brands/Brands.type';
import { brandIdStorage as globalBrandIdStorage } from '../../globalStores';
import { getTheme } from '../../theme';
import { Settings } from '../../types/Settings';
import { ThemeContext, ThemeContextObj } from './ThemeContext';
import { useGlobalBrandId } from './utils';

export type ThemeProviderProps = {
	children?: ReactNode;
	client?: BrandsClient;
};

export const ERROR_UPDATING_SETTINGS =
	'Could not update settings, please try again later!';
export const SUCCESS_UPDATING_SETTINGS = 'Settings have been updated!';
const PRODUCT_NAME = 'Brag Badges';
const DEFAULT_SETTINGS: Settings = {
	companyName: 'Tonic3',
	logo: 'logo.png',
	primaryColor: '#F26A25',
	secondaryColor: '#545454',
};

export function ThemeProvider({
	children,
	client = new BrandsClient(),
}: ThemeProviderProps) {
	const { enqueueSnackbar } = useSnackbar();
	const [settings, setSettings] = useState<Settings>(DEFAULT_SETTINGS);
	const [loadingSettings, setLoadingSettings] = useState<boolean>(false);
	const [updatingSettings, setUpdatingSettings] = useState<boolean>(false);
	const brandId = useGlobalBrandId();

	const loadBrandSettings = useCallback(() => {
		if (!brandId) return;

		setLoadingSettings(true);
		client
			.getBrandSettings(brandId)
			.then(res => setSettings(res.data))
			.finally(() => setLoadingSettings(false));
	}, [brandId]);

	useEffect(() => loadBrandSettings(), [brandId]);

	const updateBrandSettings = useCallback(
		(values: BrandSettingsUpdateDto) => {
			if (!brandId) return;

			setUpdatingSettings(true);
			client
				.updateBrandSettings(brandId, values)
				.then(() => {
					enqueueSnackbar(SUCCESS_UPDATING_SETTINGS, { variant: 'success' });
					loadBrandSettings();
				})
				.catch(() => enqueueSnackbar(ERROR_UPDATING_SETTINGS, { variant: 'error' }))
				.finally(() => setUpdatingSettings(false));
		},
		[brandId]
	);

	useEffect(() => {
		if (settings) document.title = `${settings.companyName} - ${PRODUCT_NAME}`;
	}, [settings]);

	const theme = useMemo(() => {
		const palette: PaletteOptions = {};
		if (settings) {
			palette.primary = {
				main: settings.primaryColor,
				contrastText: '#fff',
			};
			palette.secondary = {
				main: settings.secondaryColor,
				contrastText: '#fff',
			};
		}
		return getTheme(palette);
	}, [settings]);

	const value: ThemeContextObj = useMemo(
		() => ({
			settings: settings ?? ({} as unknown as Settings),
			updatingSettings,
			loading: loadingSettings,
			updateBrandSettings,
			loadBrandSettings,
		}),
		[
			settings,
			updatingSettings,
			loadingSettings,
			updateBrandSettings,
			loadBrandSettings,
		]
	);

	return (
		<ThemeContext.Provider value={value}>
			<MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
		</ThemeContext.Provider>
	);
}
