import { AdminRoutePermissions } from './routePermissions';

export const adminLinks = [
	{
		path: '/admin/badge-award-approval',
		title: 'Badge Award Approval',
		permissions: AdminRoutePermissions.BadgeAwardApproval,
	},
	{
		path: '/admin/redemption-requests',
		title: 'Redemption Requests',
		permissions: AdminRoutePermissions.PrizeRedemptionManagement,
	},
	{
		path: '/admin/badge-award-history',
		title: 'Badge History',
		permissions: AdminRoutePermissions.BadgeAwardHistory,
	},
	{
		path: '/admin/badges',
		title: 'Badge Management',
		permissions: AdminRoutePermissions.BadgeManagement,
	},
	{
		path: '/admin/prizes',
		title: 'Points Table',
		permissions: AdminRoutePermissions.PrizeManagement,
	},
	{
		path: '/admin/users',
		title: 'Manage Users',
		permissions: AdminRoutePermissions.UserManagement,
	},
	{
		path: '/admin/create-manager-report',
		title: 'Create Manager Report',
		permissions: AdminRoutePermissions.ManagerReport,
	},
];
