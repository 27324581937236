/* eslint-disable no-shadow */
export enum Roles {
	SysAdmin = '83fca6cb-7439-4292-8f36-755d8c5c747a',
	TenantAdmin = '91387f32-309b-4c37-8dcc-cc048a2ac7d2',
	Authorizer = '382cb9d2-ed3f-4563-9a31-f46fbfcf9b0c',
	Manager = 'b8c9b5a5-f4b4-435f-93a2-becbb711c6e0',
	Employee = '4fc9b6ab-c094-4066-b397-dce0d76d4188',
}

export const ROLE_HIERARCHY = [
	Roles.Employee,
	Roles.Manager,
	Roles.Authorizer,
	Roles.SysAdmin,
];
