import { Box, Grid, Typography } from '@mui/material';
import { ComponentType, useCallback, useMemo } from 'react';
import {
	Dialog,
	DialogCloseReason,
	DialogProps,
} from '../../../../components/Dialog';
import { PrizeTable } from '../../../../components/PrizeTable';
import {
	PrizeRedemptionConfirmation,
	PrizeRedemptionConfirmationProps,
} from '../PrizeRedemptionConfirmation';
import { PrizeRedemptionForm } from '../PrizeRedemptionForm/PrizeRedemptionForm';
import { VerboseScoreDisplay } from '../VerboseScoreDisplay';
import { usePrizeRedemptionDialogContext } from './PrizeRedemptionDialog.context';
import { PrizeRedemptionDialogViews } from './PrizeRedemptionDialog.type';

export type PrizeRedemptionDialogViewProps = DialogProps & {
	view?: PrizeRedemptionDialogViews;
	_FormView?: ComponentType;
	_ZeroPointsView?: ComponentType;
	_ConfirmationView?: ComponentType<PrizeRedemptionConfirmationProps>;
};

export function PrizeRedemptionDialogView({
	view = PrizeRedemptionDialogViews.Form,
	_FormView: Form = FormView,
	_ZeroPointsView: ZeroPoints = ZeroPointsView,
	_ConfirmationView: Confirmation = PrizeRedemptionConfirmation,
	...props
}: PrizeRedemptionDialogViewProps) {
	const handleConfirmationContinue: PrizeRedemptionConfirmationProps['onContinue'] =
		useCallback(
			e => {
				if (props.onClose) props.onClose(e, DialogCloseReason.CloseButton);
			},
			[props.onClose]
		);

	const HeaderProps: DialogProps['HeaderProps'] = useMemo(() => {
		if (view !== PrizeRedemptionDialogViews.Confirmation) return undefined;
		return {
			sx: {
				paddingBottom: '58px',
			},
		};
	}, [view]);

	const ContentProps: DialogProps['ContentProps'] = useMemo(() => {
		if (view !== PrizeRedemptionDialogViews.Confirmation) return undefined;
		return {
			sx: theme => ({
				paddingBottom: '22px',
				[theme.breakpoints.down('sm')]: {
					paddingLeft: '20px',
					paddingRight: '20px',
				},
			}),
		};
	}, [view]);

	const viewMap = useMemo(
		() => ({
			[PrizeRedemptionDialogViews.Confirmation]: {
				View: Confirmation,
				props: {
					onContinue: handleConfirmationContinue,
				},
			},
			[PrizeRedemptionDialogViews.Form]: {
				View: Form,
			},
			[PrizeRedemptionDialogViews.ZeroPoints]: {
				View: ZeroPoints,
			},
		}),
		[handleConfirmationContinue]
	);

	return (
		<Dialog
			title={
				view === PrizeRedemptionDialogViews.Form ? 'Redeem your points' : undefined
			}
			HeaderProps={HeaderProps}
			ContentProps={ContentProps}
			{...props}
		>
			<ViewMap view={view} map={viewMap} />
		</Dialog>
	);
}

type ViewMapProps = {
	view: PrizeRedemptionDialogViews;
	map: Record<
		PrizeRedemptionDialogViews,
		{ View: ComponentType<any>; props?: Record<string, any> }
	>;
};

function ViewMap({ view, map }: ViewMapProps) {
	const { View, props } = map[view];
	return <View {...props} />;
}

function FormView() {
	const { prizes, score, handleSubmit } = usePrizeRedemptionDialogContext();

	return (
		<Grid container>
			<Grid md={6} xs={12} sm={6} item>
				<Box
					sx={theme => ({
						width: '100%',
						[theme.breakpoints.up('md')]: {
							maxWidth: 342,
						},
						[theme.breakpoints.down('sm')]: {
							marginBottom: '20px',
						},
					})}
				>
					<PrizeTable prizes={prizes} />
				</Box>
			</Grid>
			<Grid
				sx={theme => ({ [theme.breakpoints.up('sm')]: { paddingLeft: '18px' } })}
				md={6}
				xs={12}
				sm={6}
				item
			>
				<VerboseScoreDisplay
					sx={theme => ({
						marginBottom: '28px',
						[theme.breakpoints.up('sm')]: {
							maxWidth: 324,
						},
					})}
					score={score}
				/>
				<Box
					sx={theme => ({
						[theme.breakpoints.up('sm')]: {
							maxWidth: 307,
						},
					})}
				>
					<PrizeRedemptionForm prizes={prizes} onSubmit={handleSubmit} />
				</Box>
			</Grid>
		</Grid>
	);
}

function ZeroPointsView() {
	return (
		<Box sx={{ paddingTop: '64px', paddingBottom: '10px' }}>
			<Typography
				sx={{ textAlign: 'center', fontWeight: 600 }}
				variant='h2'
				component='p'
			>
				Sorry, you currently have no points to redeem.
				<br />
				Please check back later!
			</Typography>
		</Box>
	);
}
