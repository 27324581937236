import { SvgIcon, SvgIconProps } from '@mui/material';

export function SearchIcon(props?: SvgIconProps) {
	return (
		<SvgIcon
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path d='M16.0038 17.3L10.4038 11.7C9.90381 12.1 9.3288 12.4167 8.67881 12.65C8.02881 12.8833 7.33714 13 6.60381 13C4.78714 13 3.24981 12.371 1.99181 11.113C0.733139 9.85433 0.103806 8.31667 0.103806 6.5C0.103806 4.68333 0.733139 3.14567 1.99181 1.887C3.24981 0.629 4.78714 0 6.60381 0C8.42047 0 9.95814 0.629 11.2168 1.887C12.4748 3.14567 13.1038 4.68333 13.1038 6.5C13.1038 7.23333 12.9871 7.925 12.7538 8.575C12.5205 9.225 12.2038 9.8 11.8038 10.3L17.4288 15.925C17.6121 16.1083 17.7038 16.3333 17.7038 16.6C17.7038 16.8667 17.6038 17.1 17.4038 17.3C17.2205 17.4833 16.9871 17.575 16.7038 17.575C16.4205 17.575 16.1871 17.4833 16.0038 17.3ZM6.60381 11C7.85381 11 8.91647 10.5627 9.79181 9.688C10.6665 8.81267 11.1038 7.75 11.1038 6.5C11.1038 5.25 10.6665 4.18733 9.79181 3.312C8.91647 2.43733 7.85381 2 6.60381 2C5.35381 2 4.29114 2.43733 3.41581 3.312C2.54114 4.18733 2.10381 5.25 2.10381 6.5C2.10381 7.75 2.54114 8.81267 3.41581 9.688C4.29114 10.5627 5.35381 11 6.60381 11Z' />
		</SvgIcon>
	);
}
