import * as yup from 'yup';

export const validationSchema = yup.object({
	firstName: yup.string().min(3).max(50).required('You must provide a name'),
	lastName: yup.string().min(3).max(50).required('You must provide a last name'),
	email: yup
		.string()
		.email()
		.min(6)
		.max(50)
		.required('You must provide a valid email address'),
	position: yup.string().min(3).max(50).required('You must provide a position'),
	rolesIds: yup.array().of(yup.string()),
	managerUserId: yup.string().nullable(),
	isManager: yup.boolean(),
});
