import { useCallback, useEffect, useState } from 'react';
import { Awards } from '../../api/Awards';
import { useUsers } from '../../hooks/useUsers';
import { User } from '../../types/User';
import { UserFilters } from '../../utils/helpers/UserFilters';
import { ManagerReportView } from './ManagerReportView';
import { Roles } from '../../utils/enums/Roles';

export type ManagerReportProps = {
	awardsClient?: Awards;
};

const MANAGER_PARAMS = {
	sort: 'name',
	order: 'asc',
	limit: 10,
	page: 0,
};

export function ManagerReport({
	awardsClient = new Awards(),
}: ManagerReportProps) {
	const [manager, setManager] = useState<User | undefined>();
	const { users, setParams } = useUsers();

	useEffect(() => {
		const filters = new UserFilters();
		filters.set('usertype', Roles.Manager);

		setParams({
			...MANAGER_PARAMS,
			filters: filters.build(),
		});
	}, []);

	const handleManagerQueryChange = useCallback((term: string) => {
		const filters = new UserFilters();
		filters.set('name', term);
		filters.set('usertype', Roles.Manager);

		setParams({
			...MANAGER_PARAMS,
			filters: filters.build(),
		});
	}, []);

	const handleManagerChange = useCallback((manager: User | null) => {
		setManager(manager ?? undefined);
	}, []);

	return (
		<ManagerReportView
			managerOptions={users}
			selectedManager={manager}
			feedDataFetcher={awardsClient.find}
			reportFetcher={awardsClient.report}
			onManagerChange={handleManagerChange}
			onManagerQueryChange={handleManagerQueryChange}
		/>
	);
}
