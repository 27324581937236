import { Awards } from '../../api/Awards';
import { Badges } from '../../api/Badges';
import { RecipientRelationships } from '../../api/RecipientRelationships';
import { Recipients } from '../../api/Recipients';
import {
	GiveABadgeController,
	useGiveABadgeController,
} from './GiveABadgeController';
import { GiveABadgePageView } from './GiveABadgeView';

const badges = new Badges();
const recipients = new Recipients();
const awards = new Awards();
const relationships = new RecipientRelationships();

export function GiveABadgePage() {
	return (
		<GiveABadgeController
			badges={badges}
			recipients={recipients}
			awards={awards}
			relationships={relationships}
		>
			<View />
		</GiveABadgeController>
	);
}

function View() {
	const controller = useGiveABadgeController();

	return (
		<GiveABadgePageView
			availableRelationships={controller.userRelationships.map(({ id, name }) => ({
				text: name,
				value: id,
			}))}
			view={controller.view}
			availableBadges={controller.badges}
			availableRecipients={controller.recipients}
			submittingForm={controller.submittingAwardRequest}
			loadingRecipients={controller.fetchingRecipients}
			loadingBadges={controller.fetchingBadges}
			resetFlow={controller.resetView}
			handleFormSubmit={controller.submitAwardBadge}
			handleRecipientQueryChange={controller.findRecipientsByName}
		/>
	);
}
