import { useCallback } from 'react';
import { useRelationshipManager } from '../../../../hooks/useRelationshipManager';
import {
	UpdateUserRelationshipItem,
	UserRelationship,
} from '../../../../types/UserRelationship';
import {
	RelationshipManagerView,
	RelationshipManagerViewProps,
} from './RelationshipManagerView';

export type RelationshipManagerProps = Pick<
	RelationshipManagerViewProps,
	'onCloseWindowClick'
>;

export function RelationshipManager({
	onCloseWindowClick,
}: RelationshipManagerProps) {
	const { relationships, loading, updating, updateRelationships } =
		useRelationshipManager();

	const handleSave: RelationshipManagerViewProps['onSave'] = useCallback(
		updatedRelationships => {
			const updates: UpdateUserRelationshipItem[] = getUpdates(
				updatedRelationships,
				relationships
			);
			if (updates.length) updateRelationships(updates);
		},
		[]
	);

	return (
		<RelationshipManagerView
			loading={loading}
			saving={updating}
			relationships={relationships}
			onSave={handleSave}
			onCloseWindowClick={onCloseWindowClick}
		/>
	);
}

function getUpdates(
	candidateRelationships: Array<Required<UserRelationship>>,
	originalRelationships: Array<Required<UserRelationship>>
): UpdateUserRelationshipItem[] {
	return candidateRelationships
		.filter(({ id, modifier }) => {
			const relationship = originalRelationships.find(rel => id === rel.id);
			return relationship?.modifier !== modifier;
		})
		.map(({ id, modifier }) => ({ id, modifier }));
}
