import { useContext } from 'react';
import { OwnTallyContext } from '../../context/OwnTally';

export function useOwnUserTally() {
	const ctx = useContext(OwnTallyContext);

	if (!ctx)
		throw new Error('useOwnUserTally cannot be used outside a OwnTally context');

	return ctx;
}
