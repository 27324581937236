import { useEffect, useMemo, useState } from 'react';
import { Awards } from '../../api/Awards';
import { Award } from '../../types/Award';

export type UseOwnReceivedAwardsConfig = {
	awardsClient?: Awards;
	onRejection?: (reason: any) => void;
};

export function useOwnReceivedAwards(config: UseOwnReceivedAwardsConfig = {}) {
	const [awards, setAwards] = useState<Award[]>([]);
	const [loading, setLoading] = useState(true);

	const apiClient = useMemo(() => config.awardsClient ?? new Awards(), []);

	useEffect(() => {
		(async function () {
			try {
				const response = await apiClient.getOwnAwards();
				setAwards(response.data);
			} catch (e) {
				console.error('useOwnReceivedAwards', e);
				if (config.onRejection) config.onRejection(e);
			} finally {
				setLoading(false);
			}
		})();
	}, []);

	return {
		awards,
		loading,
	};
}
