import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	CircularProgress,
	FormControlLabel,
	Grid,
	InputLabel,
	TextField,
	styled,
} from '@mui/material';
import { Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { PencilIcon } from '../../../../assets/Icons/Pencil';
import { Checkbox } from '../../../../components/Checkbox';
import { RemoveButton } from '../../../../components/RemoveButton';
import { validationSchema } from './PrizeEditor.schema';
import { Can } from '../../../../components/Can';
import { Action, ActionSubject } from '../../../../utils/enums/abilities';

export const DONABLE_LABEL = 'Reward Can Be Donated';

export type PrizeEditorViewProps = {
	name?: string;
	points?: number;
	donable?: boolean;
	saving?: boolean;
	editing?: boolean;
	loading?: boolean;
	removing?: boolean;
	processing?: boolean;
	showRemoveButton?: boolean;
	onSubmit?: (values: FormValues) => void;
	onEditButtonClick?: () => void;
	onCloseWindowClick?: () => void;
	onRemoveButtonClick?: () => void;
};

type FormValues = {
	name: string;
	points: number;
	donable: boolean;
};

export function PrizeEditorView({
	saving,
	editing,
	loading,
	removing,
	processing,
	showRemoveButton,
	donable,
	name = '',
	points = 0,
	onSubmit,
	onEditButtonClick,
	onCloseWindowClick,
	onRemoveButtonClick,
}: PrizeEditorViewProps) {
	const initialValues: FormValues = useMemo(
		() => ({
			name,
			points,
			donable: Boolean(donable),
		}),
		[name, points, donable]
	);

	const handleSubmit = useCallback(
		(values: FormValues) => {
			if (onSubmit) onSubmit(values);
		},
		[onSubmit]
	);

	const initialErrors = useMemo(() => {
		if (name.length)
			return {
				name: 'You can not save a prize without changes',
				points: 'You can not save a prize without changes',
				donable: 'You can not save a prize without changes',
			};

		return undefined;
	}, [name, points, donable]);

	if (loading)
		return (
			<Box
				sx={{
					width: '100%',
					height: '404px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<CircularProgress />
			</Box>
		);

	return (
		<Can do={Action.Update} on={ActionSubject.Prize} passThrough>
			{canUpdate => (
				<Formik<FormValues>
					initialErrors={initialErrors}
					validateOnMount={!initialErrors}
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					{({
						isValid,
						// eslint-disable-next-line no-shadow
						handleSubmit,
						getFieldMeta,
						getFieldProps,
					}) => {
						const getFieldFeedbackProps = (field: string) => {
							const { error, touched } = getFieldMeta(field);
							const hasError = touched && Boolean(error);

							return {
								error: hasError,
								helperText: hasError ? error : undefined,
							};
						};

						return (
							<form onSubmit={canUpdate ? handleSubmit : undefined}>
								<Grid sx={{ minHeight: '404px', flexDirection: 'column' }} container>
									<Can do={Action.Update} on={ActionSubject.Prize}>
										{!editing && (
											<Grid
												sx={{ display: 'flex', justifyContent: 'flex-end' }}
												xs={12}
												item
											>
												<Button
													variant='text'
													size='small'
													sx={theme => ({
														fontSize: theme.typography.pxToRem(14),
													})}
													startIcon={<PencilIcon htmlColor='#F26A25' />}
													onClick={onEditButtonClick}
												>
													Edit
												</Button>
											</Grid>
										)}
									</Can>
									<Grid xs={12} item>
										<Grid container>
											<Grid
												sx={theme => ({
													[theme.breakpoints.up('sm')]: {
														paddingRight: '27px',
													},
												})}
												xs={12}
												sm={3}
												item
											>
												<Fieldset>
													<FieldLabel id='prize-editor-points'>Points</FieldLabel>
													<TextField
														InputProps={{
															inputProps: {
																'aria-labelledby': 'prize-editor-points',
																min: 0,
															},
															readOnly: !editing,
														}}
														type='number'
														{...getFieldProps('points')}
														{...getFieldFeedbackProps('points')}
														fullWidth
													/>
												</Fieldset>
											</Grid>
											<Grid
												sx={theme => ({
													[theme.breakpoints.up('sm')]: {
														paddingLeft: '27px',
														paddingRight: '14px',
													},
												})}
												xs={12}
												sm={9}
												item
											>
												<Fieldset>
													<FieldLabel id='prize-editor-name'>Badge Reward</FieldLabel>
													<TextField
														InputProps={{
															inputProps: {
																'aria-labelledby': 'prize-editor-name',
															},
															readOnly: !editing,
														}}
														{...getFieldProps('name')}
														{...getFieldFeedbackProps('name')}
														fullWidth
													/>
												</Fieldset>
											</Grid>
										</Grid>
									</Grid>
									<Grid xs={12} item>
										<FormControlLabel
											label={DONABLE_LABEL}
											control={<Checkbox {...getFieldProps('donable')} />}
											disabled={!editing}
											checked={donable}
										/>
									</Grid>
									<Grid sx={{ marginTop: 'auto' }} xs={12} item>
										<Can do={Action.Delete} on={ActionSubject.Prize}>
											{editing && showRemoveButton && (
												<RemoveButton
													sx={{ marginBottom: '19px' }}
													onClick={onRemoveButtonClick}
													disabled={processing}
													loading={removing}
												/>
											)}
										</Can>
									</Grid>
									<Grid sx={{ display: 'flex', justifyContent: 'center' }} xs={12} item>
										<Button
											variant='contained'
											color='secondary'
											onClick={onCloseWindowClick}
										>
											Close Window
										</Button>
										{canUpdate && (
											<LoadingButton
												sx={{
													marginLeft: '26px',
												}}
												variant='contained'
												color='primary'
												type='submit'
												disabled={processing || !isValid}
												loading={saving}
											>
												Save
											</LoadingButton>
										)}
									</Grid>
								</Grid>
							</form>
						);
					}}
				</Formik>
			)}
		</Can>
	);
}

const Fieldset = styled('fieldset')({
	padding: 0,
	margin: 0,
	border: 0,
});

const FieldLabel = styled(InputLabel)(({ theme }) => ({
	left: 0,
	marginBottom: 8,
	lineHeight: 1.167,
	color: 'inherit',
	textTransform: 'uppercase',
	fontSize: theme.typography.pxToRem(12),
}));
