import { Box, styled, Typography } from '@mui/material';

export function DashboardFooter() {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				color: 'white',
				padding: '16px 42px',
				alignItems: 'center',
			}}
		>
			<Typography variant='subtitle1'>Tonic3</Typography>
			<CopyText>2023 COPYRIGHT. ALL RIGHTS RESERVED</CopyText>
		</Box>
	);
}
const CopyText = styled(Typography)({
	lineHeight: '12px',
	fontSize: '0.62em',
	fontWeight: 400,
});
