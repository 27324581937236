import {
	CardActionArea,
	CardContent,
	Card as MuiCard,
	styled,
} from '@mui/material';
import { ComponentProps } from 'react';
import { Badge } from '../../../../types/Badge';

export type BadgeCardProps = {
	badge: Badge;
	active?: boolean;
	onClick?: () => void;
};

export function BadgeCard({ badge, active, onClick }: BadgeCardProps) {
	return (
		<Card active={active}>
			<CardActionArea onClick={onClick} disableRipple>
				<CardContent>
					<BadgeImage src={badge.assets.full_svg} alt={`${badge.name} badge`} />
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

const Card = styled(MuiCard, {
	shouldForwardProp: propName => propName !== 'active',
})<ComponentProps<typeof MuiCard> & { active?: boolean }>(
	({ active, theme }) => {
		const activeStyle = {
			borderColor: theme.palette.primary.main,
			backgroundColor: theme.palette.primary.main,
		};

		return {
			borderRadius: 10,
			boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
			border: '1px solid rgba(239, 239, 239, 0.4)',
			paddingBottom: 10,
			transition: theme.transitions.create(['background-color', 'border'], {
				duration: theme.transitions.duration.short,
				easing: theme.transitions.easing.easeIn,
			}),
			'& .MuiCardActionArea-focusHighlight': {
				display: 'none',
			},
			'& .MuiCardContent-root': {
				paddingLeft: 8,
				paddingRight: 8,
				paddingTop: 24,
				paddingBottom: 8,
				backgroundColor: theme.palette.background.paper,
			},
			'&:hover, &:has(:focus-visible)': activeStyle,
			'@supports not selector(:has(a, b))': {
				'&:hover, &:focus-within': activeStyle,
			},
			...(active ? activeStyle : null),
		};
	}
);

const BadgeImage = styled('img')({
	width: '100%',
	height: 'auto',
	display: 'block',
	aspectRatio: '97/141',
});
