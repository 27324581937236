import { InputLabel, styled } from '@mui/material';

export const FieldLabel = styled(InputLabel)(({ theme }) => ({
	left: 0,
	marginBottom: 8,
	lineHeight: 1.167,
	color: 'inherit',
	textTransform: 'uppercase',
	fontSize: theme.typography.pxToRem(12),
}));
