import { useCallback, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';
import {
	Dialog,
	DialogCloseReason,
	DialogProps,
} from '../../../../components/Dialog';
import { AddUser } from '../AddUser/AddUser';

export type AddUserDialogProps = Omit<DialogProps, 'children' | 'onClose'> & {
	onClose?: (refresh: boolean) => void;
	userId?: string;
};

export function AddUserDialog({
	onClose,
	userId,
	...props
}: AddUserDialogProps) {
	const handleUserCreated = useCallback(() => {
		if (onClose) onClose(true);
	}, [onClose]);

	const handleUserDeleted = useCallback(() => {
		if (onClose) onClose(true);
	}, [onClose]);

	const handleDialogClose = useCallback(() => {
		if (onClose) onClose(false);
	}, [onClose]);

	const handleClose = useCallback(() => {
		if (onClose) onClose(false);
	}, [onClose]);

	return (
		<Dialog
			title='Add User'
			PaperProps={{
				sx: {
					maxWidth: 940,
				},
			}}
			HeaderProps={{
				sx: {
					paddingTop: '12px',
					paddingBottom: '14px',
					paddingLeft: '34px',
					paddingRight: '34px',
				},
			}}
			ContentProps={{
				sx: {
					paddingLeft: '34px',
					paddingRight: '34px',
				},
			}}
			onClose={handleDialogClose}
			{...props}
		>
			<AddUser
				userId={userId}
				onUserCreated={handleUserCreated}
				onUserDeleted={handleUserDeleted}
				onCloseWindowClick={handleClose}
			/>
		</Dialog>
	);
}
