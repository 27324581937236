import { ComponentThemeOverride } from '../../utils/types';

export const MuiInputLabel: ComponentThemeOverride['MuiInputLabel'] = {
	defaultProps: {
		sx: theme => ({
			top: 1,
			left: 3,
			fontSize: theme.typography.pxToRem(12),
		}),
	},
	styleOverrides: {
		shrink: {
			fontSize: '1rem !important',
			top: 2,
			left: -1,
		},
	},
};
