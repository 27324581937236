import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BrandContext, BrandContextState } from './BrandContext';
import { BrandsClient } from '../../api/Brands';
import { brandIdStorage as globalBrandIdStorage } from '../../globalStores';
import { BrandIdStorage } from '../../classes/BrandIdStorage';
import { useBrandSlug } from '../BrandSlug';

export type BrandProviderProps = {
	children: ReactNode;
	client?: BrandsClient;
	brandIdStorage?: BrandIdStorage;
};

export function BrandProvider({
	children,
	client = new BrandsClient(),
	brandIdStorage = globalBrandIdStorage,
}: BrandProviderProps) {
	const { brandSlug } = useParams();
	const { setBrandSlug } = useBrandSlug();
	const [brandId, setBrandId] = useState<string>();
	const [loading, setLoading] = useState(true);

	const getBrandId = useCallback(() => {
		console.log('BrandProvider');
		console.log('Brand slug:', brandSlug);

		if (!brandSlug) return;
		setLoading(true);
		client
			.getBrandIdBySubpath(brandSlug)
			.then(res => {
				brandIdStorage.set(res.data);
				setBrandId(res.data);
			})
			.finally(() => setLoading(false));
	}, []);

	useEffect(() => {
		getBrandId();
	}, [brandSlug]);

	useEffect(() => {
		setBrandSlug(brandSlug);
	}, [brandSlug]);

	const state: BrandContextState = useMemo(
		() => ({ brandId, loading }),
		[brandId, loading]
	);
	return <BrandContext.Provider value={state}>{children}</BrandContext.Provider>;
}
