import { Box, BoxProps, styled } from '@mui/material';
import { ReactNode } from 'react';
import { StepHeader } from '../StepHeader';

export type SectionProps = Omit<BoxProps, 'component'> & {
	title: string;
	subtitle: string;
	children?: ReactNode;
	ContentProps?: BoxProps;
};

export function Section({
	title,
	subtitle,
	ContentProps,
	children,
	...props
}: SectionProps) {
	return (
		<Box {...props} component='section'>
			<SectionHeader>
				<StepHeader title={title} subtitle={subtitle} />
			</SectionHeader>
			<Content {...ContentProps}>{children}</Content>
		</Box>
	);
}

const SectionHeader = styled('header')(({ theme }) => ({
	paddingRight: 32,
	marginBottom: 12,
	[theme.breakpoints.down('md')]: {
		padding: 0,
	},
}));

const Content = styled(Box)({
	width: '100%',
});
