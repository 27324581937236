/* eslint-disable react/no-array-index-key */
import { Grid, Typography, styled, lighten } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { Dialog, DialogProps } from '../../../../components/Dialog';
import { useOwnReceivedAwards } from '../../../../hooks/useOwnReceivedAwards/useOwnReceivedAwards';
import { useDashboardController } from '../../DashboardController';
import { BadgeCard } from '../BadgeCard';

const ERROR_LOADING_USER_BADGES =
	'Failed on fetching your badges, try again later';

export function EarnedBadgesDialog(props: DialogProps) {
	const { enqueueSnackbar } = useSnackbar();
	const { setCurrentAwardId } = useDashboardController();
	const { awards, loading } = useOwnReceivedAwards({
		onRejection: () =>
			enqueueSnackbar(ERROR_LOADING_USER_BADGES, { variant: 'error' }),
	});

	const handleViewDetails = useCallback((id: string) => {
		setCurrentAwardId(id);
	}, []);

	return (
		<Dialog
			{...props}
			titleElement={
				<CustomTitle caption='your badges earned' totalBadges={awards.length} />
			}
			ContentProps={{
				sx: theme => ({
					[theme.breakpoints.down('md')]: {
						paddingLeft: '20px',
						paddingRight: '20px',
					},
				}),
			}}
		>
			{!loading && !awards.length ? (
				<Typography>You dont have any badges at this moment</Typography>
			) : (
				<Grid container justifyContent='center' spacing={3}>
					{awards.map(award => (
						<Grid key={award.id} item>
							<BadgeCard
								badge={award.badge}
								onViewDetails={() => handleViewDetails(award.id)}
							/>
						</Grid>
					))}
				</Grid>
			)}
		</Dialog>
	);
}

type CustomTitleProps = {
	caption: string;
	totalBadges: number;
};

function CustomTitle({ caption, totalBadges }: CustomTitleProps) {
	return (
		<Grid
			sx={theme => ({ [theme.breakpoints.down('md')]: { paddingBottom: '20px' } })}
			container
			alignItems='center'
			spacing={2}
		>
			<Grid item>{caption}</Grid>
			<Grid item>
				<TotalBadges
					sx={theme => ({
						background: `linear-gradient(0deg, ${lighten(
							theme.palette.primary.light,
							0.2
						)} 0%, ${theme.palette.primary.main} 100%)`,
					})}
				>
					{totalBadges}
				</TotalBadges>
			</Grid>
		</Grid>
	);
}

const TotalBadges = styled('div')({
	borderRadius: 50,
	color: '#fff',
	width: 40,
	height: 40,
	fontSize: '1.7rem',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});
