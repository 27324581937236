import { Box, Grid, Typography, styled } from '@mui/material';
import { OverridableComponentProps } from '../../utils/types';

export type AwardDetailsProps = {
	sender: {
		name: string;
		email: string;
	};
	sentAt: Date;
	message: string;
	status?: string;
	manager?: string;
	receiver?: string;
	showHistoryDetail?: boolean | undefined;
};

export function AwardDetails({
	sender,
	message,
	sentAt,
	status,
	manager,
	receiver,
	showHistoryDetail,
}: AwardDetailsProps) {
	return (
		<Box component='article'>
			{showHistoryDetail ? (
				<HistoryDetail
					sender={sender}
					status={status}
					manager={manager}
					sentAt={sentAt}
					receiver={receiver}
				/>
			) : (
				<BasicDetails sender={sender} sentAt={sentAt} />
			)}

			<Box>
				<SectionTitle sx={{ marginBottom: '18px' }} component='span'>
					Reasons:
				</SectionTitle>
				<Detail
					sx={{
						whiteSpace: 'pre-wrap',
					}}
				>
					{message}
				</Detail>
			</Box>
		</Box>
	);
}

type BasicDetailsProps = Pick<AwardDetailsProps, 'sender' | 'sentAt'>;

function BasicDetails({ sender, sentAt }: BasicDetailsProps) {
	return (
		<Box
			sx={{
				display: 'flex',
				paddingBottom: '18px',
				justifyContent: 'space-between',
			}}
			component='header'
		>
			<Box
				sx={{
					fontStyle: 'normal',
					flexDirection: 'column',
					paddingBottom: '16px',
				}}
				component='address'
			>
				<SectionTitle component='span'>From:</SectionTitle>
				<Detail component='span'>{sender.name}</Detail>
				{/* @ts-ignore */}
				<Detail component='a' href={`mailto:${sender.email}`}>
					{sender.email}
				</Detail>
			</Box>
			<Box>
				<SectionTitle component='span'>Date issued:</SectionTitle>
				{/* @ts-ignore */}
				<Detail component='time' dateTime={sentAt.toISOString()}>
					{dateTimeFormatter.format(sentAt)}
				</Detail>
			</Box>
		</Box>
	);
}

type HistoryDetailProps = Pick<
	AwardDetailsProps,
	'sender' | 'receiver' | 'status' | 'manager' | 'sentAt'
>;

function HistoryDetail({
	sender,
	status,
	receiver,
	sentAt,
}: HistoryDetailProps) {
	return (
		<Box sx={{ paddingBottom: '24px' }} component='header'>
			<Grid
				sx={{
					paddingBottom: '18px',
				}}
				container
			>
				<Grid xs={12} sm={4} item>
					<SectionTitle component='span'>Status:</SectionTitle>
					<Detail component='span'>{status}</Detail>
				</Grid>
				<Grid xs={12} sm={4} item>
					<SectionTitle component='span'>Date issued:</SectionTitle>
					{/* @ts-ignore */}
					<Detail component='time' dateTime={sentAt.toISOString()}>
						{dateTimeFormatter.format(sentAt)}
					</Detail>
				</Grid>
			</Grid>
			<Grid
				sx={{
					display: 'flex',
				}}
				container
			>
				<Grid xs={12} sm={4} item>
					<SectionTitle component='span'>From:</SectionTitle>
					<Detail component='span'>{sender.name}</Detail>
				</Grid>
				<Grid xs={12} sm={8} item>
					<SectionTitle component='span'>To:</SectionTitle>
					<Detail component='span'>{receiver}</Detail>
				</Grid>
			</Grid>
		</Box>
	);
}

const SectionTitle = styled(Typography)<
	OverridableComponentProps<typeof Typography>
>(({ theme }) => ({
	fontWeight: 700,
	marginBottom: 3,
	display: 'block',
	lineHeight: 1.214,
	textTransform: 'uppercase',
	fontSize: theme.typography.pxToRem(14),
}));

const Detail = styled(Typography)<OverridableComponentProps<typeof Typography>>(
	({ theme }) => ({
		display: 'block',
		lineHeight: 1.214,
		color: 'inherit',
		fontSize: theme.typography.pxToRem(14),
	})
);

const dateTimeFormatter = new Intl.DateTimeFormat('en-US', {
	day: '2-digit',
	month: '2-digit',
	year: 'numeric',
});
