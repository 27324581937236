import { CalendarIcon } from '../../assets/Icons/CalendarIcon';
import { ComponentThemeOverride } from '../../utils/types';

export const MuiDatePicker: ComponentThemeOverride['MuiDatePicker'] = {
	defaultProps: {
		slots: {
			openPickerIcon: CalendarIcon,
		},
		slotProps: {
			openPickerIcon: {
				style: {
					fontSize: 21,
				},
			},
		},
	},
};
