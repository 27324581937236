import { AxiosResponseTransformer } from 'axios';
import {
	NewPrizeRedemptionRequest,
	PrizeRedemptionRequest,
} from '../../types/PrizeRedemptionRequest';
import { ApiClient } from '../ApiClient';
import { RedemptionRequest } from '../../types/RedemptionRequest';
import {
	PrizeRedemptionTextSearchableFields,
	PrizeRedemptionTextSearchParams,
	PrizeRedemptionFilterQueryParams,
} from './PrizeRedemption.type';
import { ResultList } from '../../types/ResultList';
import { extendAxiosTransformer } from '../../utils/extendAxiosTransformer.helper';

export type NewPrizeRedemptionRequestDto = {
	prizeIds: string[];
	charity: boolean;
};

export type RedemptionRequestDto = Omit<RedemptionRequest, 'dateSent'> & {
	dateSent: string;
};
type FetchParams = PrizeRedemptionTextSearchParams &
	PrizeRedemptionFilterQueryParams;

export class PrizeRedemption extends ApiClient {
	private baseEndpoint = '/prizes/redemption';

	redeemPrizes = (newRequest: NewPrizeRedemptionRequest) =>
		this.protectedHttpClient.post<NewPrizeRedemptionRequestDto>(
			this.baseEndpoint,
			PrizeRedemption.newRequestDtoFromNewRequest(newRequest)
		);

	fetchRedemptionRequests = (params?: FetchParams) =>
		this.protectedHttpClient.get<ResultList<RedemptionRequest[]>>(
			this.endpoint('/requests'),
			{
				transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
					this.protectedHttpClient.defaults.transformResponse,
					[PrizeRedemption.transformRedemptionRequestDtos]
				),
				params,
			}
		);

	fetchRedemptionRequest = (id: string) =>
		this.protectedHttpClient.get(this.endpoint(`/requests/${id}`), {
			transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
				this.protectedHttpClient.defaults.transformResponse,
				[PrizeRedemption.redemptionRequestFromDto]
			),
		});

	updateRedemptionRequest = (
		id: string,
		data: Partial<PrizeRedemptionRequest>
	) => this.protectedHttpClient.patch(this.endpoint(`/${id}`), data);

	approveRedemptionRequest = (id: string) =>
		this.protectedHttpClient.post(this.endpoint(`/approve/${id}`));

	static transformRedemptionRequestDtos(
		data: ResultList<RedemptionRequestDto[]>
	) {
		if (!(data?.results && Array.isArray(data?.results))) return {};
		return {
			...data,
			results: data.results.map(PrizeRedemption.redemptionRequestFromDto),
		};
	}

	autocomplete = (field: PrizeRedemptionTextSearchableFields, term: string) =>
		this.protectedHttpClient.get<string[]>(`${this.baseEndpoint}/autocomplete`, {
			params: {
				field,
				term,
			},
		});

	private static newRequestDtoFromNewRequest(
		newRequest: NewPrizeRedemptionRequest
	): NewPrizeRedemptionRequestDto {
		return {
			prizeIds: newRequest.prizeIds,
			charity: Boolean(newRequest.donateToCharity),
		};
	}

	private static redemptionRequestFromDto(
		dto: RedemptionRequestDto
	): RedemptionRequest {
		return {
			...dto,
			dateSent: new Date(dto.dateSent),
		};
	}

	private endpoint(subpath: string): string {
		return this.baseEndpoint.concat(subpath);
	}
}
