import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { Awards } from '../../api/Awards';
import { AwardTextSearchableFields } from '../../api/Awards/Awards.type';
import { useApiCall } from '../useApiCall';

export type UseAwardsAutocompleteConfig = {
	awardsClient?: Awards;
};

export function useAwardsAutocomplete(
	field: AwardTextSearchableFields | undefined,
	term: string,
	{ awardsClient }: UseAwardsAutocompleteConfig = {}
) {
	const client = useMemo(() => awardsClient ?? new Awards(), [awardsClient]);
	const fetcher = useMemo(() => {
		if (field == null || !term || term.length < 3) return nullFetcher;
		return client.autocomplete;
	}, [field, term]);

	const requestParams: Parameters<typeof fetcher> = useMemo(
		() => [field, term],
		[field, term]
	);

	const { data: suggestions = [], loading } = useApiCall<
		string[] | undefined,
		Parameters<typeof fetcher>
	>(fetcher as any, requestParams as Parameters<typeof fetcher>);

	return {
		suggestions,
		loading,
	};
}

export async function nullFetcher(
	_field: AwardTextSearchableFields | undefined,
	_term: string
) {
	return {
		data: undefined,
	} as AxiosResponse;
}
