import { LoadingButton } from '@mui/lab';
import {
	Autocomplete,
	Box,
	Button,
	DialogTitleProps,
	Grid,
	TextField,
	TextFieldProps,
	Typography,
	debounce,
	styled,
} from '@mui/material';
import dayjs from 'dayjs';
import { throttle } from 'lodash';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Awards } from '../../api/Awards';
import {
	AwardFilterQueryParams,
	AwardFilterableFields,
	AwardSortableFields,
	AwardsReportQueryStringDto,
} from '../../api/Awards/Awards.type';
import { AdminFeed, AdminFeedProps } from '../../components/AdminFeed';
import { DatePicker } from '../../components/DatePicker';
import { useReportDownload } from '../../hooks/useReportDownload';
import { AdminPage, AdminPageProps } from '../../templates/AdminPage';
import { Award, AwardStatuses } from '../../types/Award';
import { DateSearchParameters } from '../../types/DateSearchParameters';
import { User } from '../../types/User';
import { DateSearchParameter } from '../../utils/enums/DateSearchParameter';
import { BADGE_REPORT_COLUMNS, mapFieldSort } from './ManagerReport.config';
import { SortOrder } from '../../types/SortDto';
import { Dialog } from '../../components/Dialog';
import { ReportScheduling } from './components/ReportScheduling';

const PAGE_TITLE =
	'Generate a report to provide to managers based on the set properties';

export const AUTOMATIC_REPORTING_BUTTON_TEXT = 'Automatic Reporting';

type FeedFilters = DateSearchParameters &
	AwardFilterQueryParams & {
		managerId: string;
	};

type FeedProps = AdminFeedProps<Award, FeedFilters>;

const reportSchedulingDialogTitle: DialogTitleProps = {
	sx: theme => ({
		'&&&': {
			paddingBottom: '18px',
			fontSize: theme.typography.pxToRem(22),
		},
	}),
};

const adminHeaderProps: AdminPageProps['HeaderProps'] = {
	sx: {
		paddingBottom: 0,
	},
};

export type ManagerReportViewProps = {
	managerOptions?: User[];
	selectedManager?: User;
	feedDataFetcher: FeedProps['fetcher'];
	reportFetcher: Awards['report'];
	onManagerChange?: (manager: User | null) => void;
	onManagerQueryChange?: (query: string) => void;
	onDateFilterChange?: (filter: DateSearchParameter, date: Date | null) => void;
};

export function ManagerReportView({
	selectedManager,
	managerOptions = [],
	reportFetcher,
	feedDataFetcher,
	onManagerChange,
	onManagerQueryChange,
}: ManagerReportViewProps) {
	const [showReportScheduling, setShowReportScheduling] = useState(false);
	const handleInputChange = useCallback(
		debounce((_: unknown, term: string) => {
			if (onManagerQueryChange) onManagerQueryChange(term);
		}, 300),
		[onManagerQueryChange]
	);

	const renderManagerInput = useCallback(
		(params: TextFieldProps) => <TextField {...params} label='Select Manager' />,
		[]
	);

	const handleChange = useCallback(
		(_: unknown, user: User | null) => {
			if (onManagerChange) onManagerChange(user);
		},
		[onManagerChange]
	);

	const openReportScheduling = useCallback(() => {
		setShowReportScheduling(true);
	}, []);

	const closeReportScheduling = useCallback(() => {
		setShowReportScheduling(false);
	}, []);

	const pageActions = useMemo(() => {
		if (!selectedManager) return undefined;
		return (
			<ReportScheduleButton
				variant='text'
				color='primary'
				onClick={openReportScheduling}
			>
				{AUTOMATIC_REPORTING_BUTTON_TEXT}
			</ReportScheduleButton>
		);
	}, [selectedManager]);

	return (
		<>
			<AdminPage
				title={PAGE_TITLE}
				actions={pageActions}
				HeaderProps={adminHeaderProps}
			>
				{!selectedManager && (
					<NoSelectionView
						managerOptions={managerOptions}
						handleChange={handleChange}
						handleInputChange={handleInputChange}
						renderManagerInput={renderManagerInput}
					/>
				)}
				{selectedManager != null && (
					<ReportView
						selectedManager={selectedManager}
						managerOptions={managerOptions}
						handleInputChange={handleInputChange}
						handleChange={handleChange}
						reportFetcher={reportFetcher}
						feedDataFetcher={feedDataFetcher}
						renderManagerInput={renderManagerInput}
					/>
				)}
			</AdminPage>
			<Dialog
				title='Automatic Reporting'
				TitleProps={reportSchedulingDialogTitle}
				open={showReportScheduling}
				onClose={closeReportScheduling}
			>
				<ReportScheduling
					onUpdate={closeReportScheduling}
					onCloseButtonClick={closeReportScheduling}
				/>
			</Dialog>
		</>
	);
}

function NoSelectionView({
	managerOptions,
	renderManagerInput,
	handleChange,
	handleInputChange,
}: {
	managerOptions: User[];
	renderManagerInput: (params: TextFieldProps) => ReactElement;
	handleChange: (_: unknown, user: User | null) => void;
	handleInputChange: (_: unknown, term: string) => void;
}) {
	return (
		<PageContent>
			<Autocomplete
				sx={{
					maxWidth: '310px',
				}}
				options={managerOptions}
				renderInput={renderManagerInput}
				onChange={handleChange}
				onInputChange={handleInputChange}
				getOptionLabel={getManagerOptionLabel}
				fullWidth
			/>
			<NoSelectionMessageBox>
				<Typography sx={{ textTransform: 'uppercase' }} variant='h2' component='p'>
					No current selection has been made
				</Typography>
			</NoSelectionMessageBox>
		</PageContent>
	);
}

function ReportView({
	managerOptions,
	selectedManager,
	reportFetcher,
	feedDataFetcher,
	renderManagerInput,
	handleChange,
	handleInputChange,
}: {
	managerOptions: User[];
	selectedManager: User;
	feedDataFetcher: FeedProps['fetcher'];
	reportFetcher: Awards['report'];
	renderManagerInput: (params: TextFieldProps) => ReactElement;
	handleChange: (_: unknown, user: User | null) => void;
	handleInputChange: (_: unknown, term: string) => void;
}) {
	const [dateFrom, setDateFrom] = useState<Date | undefined>();
	const [dateTo, setDateTo] = useState<Date | undefined>();

	const filters: FeedFilters = useMemo(
		() => ({
			managerId: selectedManager.id,
			[AwardFilterableFields.STATUS]: AwardStatuses.Approved,
			[DateSearchParameter.DateFrom]: dateFrom,
			[DateSearchParameter.DateTo]: dateTo,
		}),
		[selectedManager, dateFrom, dateTo]
	);

	const defaultSortModel = useMemo(
		() => ({
			order: SortOrder.Descending,
			orderBy: AwardSortableFields.UPDATED_AT,
		}),
		[]
	);

	const reportDownloadParams: [AwardsReportQueryStringDto] = useMemo(
		() => [
			{
				...filters,
				...defaultSortModel,
				order: SortOrder.Ascending,
			},
		],
		[filters]
	);
	const { download: downloadReport, downloading: downloadingReport } =
		useReportDownload(reportFetcher, reportDownloadParams);

	const onDateFromChange = useCallback((date: Date | null) => {
		setDateFrom(date ? dayjs(date).startOf('day').toDate() : undefined);
	}, []);

	const onDateToChange = useCallback((date: Date | null) => {
		setDateTo(date ? dayjs(date).endOf('day').toDate() : undefined);
	}, []);

	const handleDownloadButtonClick = useCallback(throttle(downloadReport, 300), [
		downloadReport,
	]);

	return (
		<>
			<Grid sx={{ marginTop: '8px', marginBottom: '26px' }} spacing={2} container>
				<Grid xs={12} md={3} item>
					<Autocomplete
						sx={{
							maxWidth: '310px',
						}}
						options={managerOptions}
						value={selectedManager}
						renderInput={renderManagerInput}
						onChange={handleChange}
						onInputChange={handleInputChange}
						getOptionLabel={getManagerOptionLabel}
						fullWidth
					/>
				</Grid>
				<Grid
					sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}
					xs={12}
					md={9}
					item
				>
					<DatePicker label='From' onChange={onDateFromChange} disableFuture />
					<DatePicker
						label='To'
						WrapperProps={{
							sx: {
								marginLeft: '12px',
								marginRight: '18px',
							},
						}}
						onChange={onDateToChange}
						disableFuture
					/>
					<LoadingButton
						variant='contained'
						color='primary'
						loading={downloadingReport}
						onClick={handleDownloadButtonClick}
					>
						Download Report
					</LoadingButton>
				</Grid>
			</Grid>
			<Box sx={{ paddingBottom: '120px' }}>
				<AdminFeed<Award, FeedFilters>
					rowHeight={60}
					headerHeight={47}
					filters={filters}
					columns={BADGE_REPORT_COLUMNS}
					defaultSortModel={defaultSortModel}
					fetcher={feedDataFetcher}
					mapSortField={mapFieldSort}
					disableSelectionOnClick
					disableColumnSelector
					disableColumnFilter
					disableColumnMenu
					autoHeight
				/>
			</Box>
		</>
	);
}

function getManagerOptionLabel(manager: User) {
	return manager.name;
}

const PageContent = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	paddingTop: 'clamp(32px, 18vh,187px)',
});

const NoSelectionMessageBox = styled(Box)(({ theme }) => ({
	paddingTop: 46,
	display: 'flex',
	marginTop: 'clamp(32px, 18vh,187px)',
	borderTopWidth: 12,
	borderTopStyle: 'solid',
	borderTopColor: theme.palette.common.black,
}));

const ReportScheduleButton = styled(Button)(({ theme }) => ({
	textTransform: 'uppercase',
	color: theme.palette.primary.main,
}));
