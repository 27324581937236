import { Button, ButtonProps } from '@mui/material';
import { useMemo } from 'react';
import { PencilIcon } from '../../assets/Icons/Pencil';
import { mergeSxProps } from '../../utils/helpers/mergeSxProps';

export type EditButtonProps = ButtonProps;

export function EditButton({
	sx,
	children = 'Edit',
	...props
}: EditButtonProps) {
	const sxProp: ButtonProps['sx'] = useMemo(
		() =>
			mergeSxProps([
				theme => ({
					fontSize: theme.typography.pxToRem(14),
				}),
				sx,
			]),
		[sx]
	);

	return (
		<Button
			variant='text'
			size='small'
			startIcon={<PencilIcon htmlColor='#F26A25' />}
			sx={sxProp}
			{...props}
		>
			{children}
		</Button>
	);
}
