import { Container as MuiContainer, styled } from '@mui/material';
import { ComponentProps, ReactNode } from 'react';
import { Ribbon } from '../../components/Ribbon';
import { LeftDottedPage } from '../LeftDottedPage';

export type ContainedPageTemplateProps = {
	children: ReactNode;
	disableTopRibbon?: boolean;
	disableBottomRibbon?: boolean;
};

export function ContainedPageTemplate({
	children,
	disableTopRibbon,
	disableBottomRibbon,
}: ContainedPageTemplateProps) {
	return (
		<LeftDottedPage>
			<Container
				data-testid='page-container'
				bottomRibbon={!disableBottomRibbon}
				disableGutters
			>
				{!disableTopRibbon && (
					<Ribbon
						height={14}
						color='linear-gradient(136.26deg, #323232 8%, #000000 99.41%)'
						data-testid='top-ribbon'
					/>
				)}
				{children}
			</Container>
		</LeftDottedPage>
	);
}

const Container = styled(MuiContainer, {
	shouldForwardProp: propName => propName !== 'bottomRibbon',
})<
	ComponentProps<typeof MuiContainer> & {
		bottomRibbon?: boolean;
	}
>(({ theme, bottomRibbon }) => ({
	'&&&': {
		maxWidth: 975,
	},
	position: 'relative',
	backgroundColor: 'white',
	boxShadow: '0px 14px 24px rgba(0, 0, 0, 0.25)',
	...(bottomRibbon && {
		borderBottomStyle: 'solid',
		borderBottomWidth: 16,
		borderBottomColor: theme.palette.primary.main,
	}),
}));
