import { Box, CircularProgress, Grid, Typography, styled } from '@mui/material';
import { useCallback, useState } from 'react';
import { AwardDetailsDialog } from '../../components/AwardDetailsDialog';
import { PrizeTable } from '../../components/PrizeTable';
import { useAuth } from '../../context/Auth';
import { UsersProvider } from '../../context/Users/UsersProvider';
import { ContainedPageTemplate } from '../../templates/ContainedPage';
import { OverridableComponentProps } from '../../utils/types';
import { useDashboardController } from './DashboardController';
import { BadgesCarousel } from './components/BadgeCarousel';
import { DashboardFooter } from './components/DashboardFooter';
import { DashboardHeader } from './components/DashboardHeader';
import { DashboardHero } from './components/DashboardHero';
import { DashboardPrizesTable } from './components/DashboardPrizesTable/DashboardPrizesTable';
import { EarnedBadgesDialog } from './components/EarnedBadgesDialog';
import { HowItWorksSection } from './components/HowItWorksSection';
import { PrizeRedemptionDialog } from './components/PrizeRedemptionDialog';
import { TransactionHistoryDialog } from './components/TransactionHistoryDialog';

export function DashboardView() {
	const { user } = useAuth();
	const { prizes, fetchingPrizes, currentAwardId, setCurrentAwardId } =
		useDashboardController();
	const [showRedemptionDialog, setShowRedemptionDialog] = useState(false);
	const [showEarnedBadgesDialog, setShowEarnedBadgesDialog] = useState(false);
	const [showTransactionHistoryDialog, setShowTransactionHistoryDialog] =
		useState(false);

	const openRedemptionDialog = useCallback(
		() => setShowRedemptionDialog(true),
		[]
	);
	const closeRedemptionDialog = useCallback(
		() => setShowRedemptionDialog(false),
		[]
	);

	const openEarnedBadgesDialog = useCallback(
		() => setShowEarnedBadgesDialog(true),
		[]
	);
	const closeEarnedBadgesDialog = useCallback(
		() => setShowEarnedBadgesDialog(false),
		[]
	);

	const closeAwardDetailsDialog = useCallback(
		() => setCurrentAwardId(undefined),
		[]
	);

	const openTransactionHistoryDialog = useCallback(
		() => setShowTransactionHistoryDialog(true),
		[]
	);

	const closeTransactionHistoryDialog = useCallback(
		() => setShowTransactionHistoryDialog(false),
		[]
	);

	return (
		<ContainedPageTemplate disableTopRibbon disableBottomRibbon>
			<DashboardHeader />
			<DashboardHero onPointsCounterClick={openTransactionHistoryDialog} />
			<Main
				component='main'
				sx={{ backgroundColor: '#C1C1C114', padding: '20px' }}
				container
			>
				<CarouselColumn xs={12} sm={12} md={8} item>
					<BadgesCarousel openEarnedBadgesDialog={openEarnedBadgesDialog} />
				</CarouselColumn>
				<TableColumn xs={12} sm={12} md={4} item>
					<Typography mb={3} variant='h2'>
						POINTS
					</Typography>
					{!fetchingPrizes && (
						<PrizeTable
							prizes={prizes}
							chunkyBorder
							footer={
								<Box sx={{ textAlign: 'center', padding: '22px' }}>
									<DashboardPrizesTable startRedemption={openRedemptionDialog} />
								</Box>
							}
						/>
					)}

					{fetchingPrizes && (
						<Box
							display='flex'
							justifyContent='center'
							alignItems='center'
							sx={{ width: '100%', height: '100%' }}
						>
							<CircularProgress />
						</Box>
					)}
				</TableColumn>
			</Main>
			<Box
				sx={{
					background: 'linear-gradient(70deg, #323232 8%, #000000 99.41%)',
				}}
			>
				<HowItWorksSection />
				<DashboardFooter />
			</Box>
			<PrizeRedemptionDialog
				open={showRedemptionDialog}
				onClose={closeRedemptionDialog}
			/>
			<EarnedBadgesDialog
				open={showEarnedBadgesDialog}
				onClose={closeEarnedBadgesDialog}
			/>
			<AwardDetailsDialog
				open={currentAwardId != null}
				awardId={currentAwardId}
				onClose={closeAwardDetailsDialog}
			/>
			<UsersProvider>
				{user && (
					<TransactionHistoryDialog
						open={showTransactionHistoryDialog}
						userId={user.id}
						onClose={closeTransactionHistoryDialog}
					/>
				)}
			</UsersProvider>
		</ContainedPageTemplate>
	);
}

const Main = styled(Grid)<OverridableComponentProps<typeof Box>>(
	({ theme }) => ({
		flexDirection: 'column',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
			flexWrap: 'nowrap',
		},
	})
);

const CarouselColumn = styled(Grid)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		paddingRight: 58,
		maxWidth: 'calc(100% - 375px)',
	},
}));

const TableColumn = styled(Grid)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		maxWidth: '375px',
	},
}));
