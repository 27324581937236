import { useSnackbar } from 'notistack';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { RecipientRelationships } from '../../api/RecipientRelationships';
import {
	UseRelationshipsConfig,
	useRelationships,
} from '../../hooks/useRelationships';
import {
	UpdateUserRelationshipItem,
	UserRelationship,
} from '../../types/UserRelationship';
import {
	RelationshipManagerContext,
	UseRelationshipManagerState,
} from './RelationshipManagerContext';

export const RELATIONSHIPS_UPDATE_ERROR =
	'Could not update the relationships, please try again later!';

export const RELATIONSHIPS_UPDATE_SUCCESS =
	'Relationships updated successfully!';

export type RelationshipManagerProviderProps = UseRelationshipsConfig & {
	children: ReactNode;
	onUpdate?: () => void;
};

export function RelationshipManagerProvider({
	children,
	relationshipsClient,
	onRejection,
	onUpdate,
}: RelationshipManagerProviderProps) {
	const { enqueueSnackbar } = useSnackbar();
	const [updating, setUpdating] = useState(false);
	const apiClient = useMemo(
		() => relationshipsClient ?? new RecipientRelationships(),
		[]
	);
	const useRelationshipsConfig = useMemo(() => {
		const result: UseRelationshipsConfig = {
			relationshipsClient: apiClient,
		};

		if (onRejection) {
			result.onRejection = onRejection;
		}

		return result;
	}, [onRejection, apiClient]);
	const { relationships, loading, refetch } = useRelationships(
		useRelationshipsConfig
	);

	const updateRelationships = useCallback(
		(updates: UpdateUserRelationshipItem[]) => {
			setUpdating(true);
			apiClient
				.updateRelationships(updates)
				.then(() => {
					enqueueSnackbar(RELATIONSHIPS_UPDATE_SUCCESS, { variant: 'success' });
					if (onUpdate) onUpdate();
					refetch();
				})
				.catch(e => {
					console.error('useRelationshipManager.updateRelationships', e);
					enqueueSnackbar(RELATIONSHIPS_UPDATE_ERROR, { variant: 'error' });
				})
				.finally(() => setUpdating(false));
		},
		[onUpdate]
	);

	const state: UseRelationshipManagerState = useMemo(
		() => ({
			loading,
			updating,
			relationships: relationships as Array<Required<UserRelationship>>,
			updateRelationships,
		}),
		[loading, updating, relationships, updateRelationships]
	);

	return (
		<RelationshipManagerContext.Provider value={state}>
			{children}
		</RelationshipManagerContext.Provider>
	);
}
