import {
	Checkbox as MuiCheckbox,
	CheckboxProps as MuiCheckboxProps,
	styled,
} from '@mui/material';
import { ComponentProps } from 'react';
import { CheckboxIcon } from '../../assets/Icons/Checkbox';
import { CheckboxCheckedIcon } from '../../assets/Icons/CheckboxChecked';

export type CheckboxProps = MuiCheckboxProps;

export function Checkbox({ disabled, ...props }: MuiCheckboxProps) {
	return (
		<MuiCheckbox
			sx={{
				marginLeft: '2px',
			}}
			icon={
				<Icon
					sx={{ fontSize: theme => theme.typography.pxToRem(17) }}
					disabled={disabled}
				/>
			}
			checkedIcon={
				<CheckboxCheckedIcon
					sx={{ fontSize: theme => theme.typography.pxToRem(17) }}
				/>
			}
			disabled={disabled}
			{...props}
		/>
	);
}

const Icon = styled(CheckboxIcon, {
	shouldForwardProp: propName => propName !== 'disabled',
})<ComponentProps<typeof CheckboxIcon> & { disabled?: boolean }>(
	({ disabled }) => ({
		fill: disabled ? '#D9D9D9' : 'none',
		...(disabled
			? {
					stroke: '#D9D9D9',
			  }
			: null),
	})
);
