import { ComponentType } from 'react';
import {
	Route as RouteComponent,
	RouteProps,
	Routes,
	RoutesProps,
} from 'react-router-dom';

export type DynamicRoutesProps = RoutesProps & {
	routes: Route[];
	defaultRoute?: DefaultRoute;
};

export type DefaultRoute = Omit<Route, 'path'>;

export type Route = Omit<RouteProps, 'element' | 'children'> & {
	component: ComponentType;
	routes?: Route[];
};

export function DynamicRoutes({
	routes,
	defaultRoute,
	...props
}: DynamicRoutesProps) {
	return (
		<Routes {...props}>
			{renderRoutes(routes)}
			{renderDefaultRoute(defaultRoute)}
		</Routes>
	);
}

function renderRoutes(routes: Route[]) {
	return routes.map(
		({ component: Component, index, path, routes: childRoutes, ...props }) => (
			// @ts-ignore
			<RouteComponent
				key={path}
				element={<Component />}
				path={path}
				index={index}
				{...props}
			>
				{childRoutes && renderRoutes(childRoutes)}
			</RouteComponent>
		)
	);
}

function renderDefaultRoute(defaultRoute?: DefaultRoute) {
	const { component: Component, ...defaultRouteProps } = defaultRoute || {};

	if (Component == null) return null;

	return (
		<RouteComponent
			key='default_route'
			{...defaultRouteProps}
			path='*'
			element={<Component />}
		/>
	);
}
