/* eslint-disable no-shadow */
import { TextSearchQueryParameters } from '../../types/TextSearchQueryParameters';
import { RedemptionRequestStatus } from '../../types/RedemptionRequest';
import { PaginationQueryParams } from '../../types/PaginationDto';
import { DateSearchParameters } from '../../types/DateSearchParameters';
import { SortQueryParams } from '../../types/SortDto';

export type PrizeRedemptionQueryParams = PrizeRedemptionSortQueryParams &
	PaginationQueryParams &
	PrizeRedemptionTextSearchParams &
	DateSearchParameters &
	PrizeRedemptionFilterQueryParams;

export type PrizeRedemptionTextSearchParams =
	TextSearchQueryParameters<PrizeRedemptionTextSearchableFields>;

export type PrizeRedemptionSortQueryParams =
	SortQueryParams<PrizeRedemptionSortableFields>;

export type PrizeRedemptionFilterQueryParams = {
	[PrizeRequestFilterableFields.STATUS]?: RedemptionRequestStatus;
};

export enum PrizeRedemptionSortableFields {
	FROM_NAME = 'fromName',
	MANAGER_NAME = 'managerName',
	SENT_AT = 'sentAt',
	STATUS = 'status',
}

export enum PrizeRequestFilterableFields {
	STATUS = 'status',
}

export enum PrizeRedemptionTextSearchableFields {
	FROM_NAME = 'fromName',
	MANAGER_NAME = 'managerName',
	PRIZE_NAME = 'prizeName',
}
