import { createContext, useContext } from 'react';
import { Prize } from '../../../../types/Prize';
import { PrizeRedemptionFormProps } from '../PrizeRedemptionForm/PrizeRedemptionForm';
import { PrizeRedemptionDialogViews } from './PrizeRedemptionDialog.type';

export type PrizeRedemptionDialogContextObj = {
	prizes: Prize[];
	score: number;
	view: PrizeRedemptionDialogViews;
	submittingRedemption: boolean;
	fetchingTally: boolean;
	handleSubmit: NonNullable<PrizeRedemptionFormProps['onSubmit']>;
	reset: () => void;
};

export const PrizeRedemptionDialogContext =
	createContext<PrizeRedemptionDialogContextObj>(createContextObject());

export function usePrizeRedemptionDialogContext() {
	return useContext(PrizeRedemptionDialogContext);
}

export function createContextObject(
	attrs?: Partial<PrizeRedemptionDialogContextObj>
): PrizeRedemptionDialogContextObj {
	return {
		prizes: [],
		score: 0,
		view: PrizeRedemptionDialogViews.Form,
		submittingRedemption: false,
		fetchingTally: false,
		handleSubmit: () => {},
		reset: () => {},
		...attrs,
	};
}
