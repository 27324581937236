import { ComponentThemeOverride } from '../../utils/types';

export const MuiTableCell: ComponentThemeOverride['MuiTableCell'] = {
	styleOverrides: {
		root: {
			fontSize: 12,
			lineHeight: 1.22,
			paddingBottom: 10,
		},
		head: {
			fontWeight: 700,
			paddingTop: 12,
			borderBottom: 'none',
			textTransform: 'uppercase',
		},
		body: {
			paddingTop: 10,
		},
	},
};
