import { LoadingButton } from '@mui/lab';
import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	MenuItem,
	DialogTitle as MuiDialogTitle,
	TextField,
	TextFieldProps,
	debounce,
	styled,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { FieldLabel } from '../../../../components/FieldLabel';
import { Fieldset } from '../../../../components/Fieldset';
import { CreateUserConfig, SingleUser } from '../../../../types/User';
import { Roles } from '../../../../utils/enums/Roles';
import { AssignableRole } from './AddUser.config';
import { validationSchema } from './AddUser.schema';
import { RemoveButton } from '../../../../components/RemoveButton';
import { Can } from '../../../../components/Can';
import { Action, ActionSubject } from '../../../../utils/enums/abilities';

export type AddUserViewProps = {
	managers: ManagerOption[];
	selectedUserData: SingleUser | undefined;
	saving?: boolean;
	deleting?: boolean;
	canManageRoles?: boolean;
	loadingManagers?: boolean;
	assignableRoles?: AssignableRole[];
	onSave: (userConfig: FormValues) => void;
	onRemoveClick?: () => void;
	onCloseWindowClick?: () => void;
	onManagerQueryChange?: (query: string) => void;
};

type ManagerOption = { id: string; managerName: string };

type FormValues = CreateUserConfig;

export function AddUserView({
	saving,
	deleting,
	managers,
	loadingManagers,
	selectedUserData,
	assignableRoles = [],
	onSave,
	onRemoveClick,
	onCloseWindowClick,
	onManagerQueryChange,
}: AddUserViewProps) {
	const initialValues: FormValues = useMemo(
		() => ({
			firstName:
				(selectedUserData?.name.split(' ').length === 1
					? selectedUserData?.name
					: selectedUserData?.name.split(' ').slice(0, -1).join(' ')) || '',
			lastName:
				(selectedUserData?.name.split(' ').length === 1
					? ''
					: selectedUserData?.name.split(' ').slice(-1).join(' ')) || '',
			position: selectedUserData?.position || '',
			email: selectedUserData?.email || '',
			managerUserId: selectedUserData?.managers[0]?.id || '0',
			isManager: selectedUserData?.userRoles.includes(Roles.Manager) || false,
			rolesIds: selectedUserData?.userRoles || [],
		}),
		[selectedUserData]
	);

	const initialManagerOption: ManagerOption | undefined = useMemo(() => {
		if (selectedUserData?.managers[0] == null) return undefined;

		return {
			id: selectedUserData.managers[0].id,
			managerName: selectedUserData.managers[0].name,
		};
	}, [selectedUserData]);

	const handleFormSubmit = useCallback(
		(value: FormValues) => {
			onSave(value);
		},
		[onSave]
	);

	const handleManagerInputChange = useCallback(
		debounce((_: unknown, value: string) => {
			if (onManagerQueryChange) onManagerQueryChange(value);
		}, 300),
		[onManagerQueryChange]
	);

	return (
		<Formik<FormValues>
			key={selectedUserData?.id}
			isInitialValid={false}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleFormSubmit}
			enableReinitialize
			validateOnBlur
		>
			{({
				values,
				handleSubmit,
				setFieldValue,
				setTouched,
				getFieldMeta,
				getFieldProps,
			}) => {
				const getFieldFeedbackProps = (field: string) => {
					const { error, touched } = getFieldMeta(field);
					const hasError = touched && Boolean(error);
					return {
						error: hasError,
						helperText: hasError ? error : undefined,
					};
				};

				const managerInputId = 'user-editor-manager-input';

				const renderManagerInput = (props: TextFieldProps) => (
					<TextField
						{...props}
						onBlur={() => setTouched({ managerUserId: true })}
						InputProps={{ ...props.InputProps, endAdornment: null }}
						{...getFieldFeedbackProps('managerUserId')}
						fullWidth
					/>
				);

				const handleManagerChange = (_: unknown, manager: ManagerOption | null) => {
					setFieldValue('managerUserId', manager?.id ?? null);
				};

				const getRoleCheckboxProps = (value: Roles) => {
					const props = getFieldProps('rolesIds');

					return {
						...props,
						checked: props.value.includes(value),
					};
				};

				const currentManagerOption = managers.find(
					({ id }) => values.managerUserId === id
				);

				return (
					<form onSubmit={handleSubmit}>
						<Grid container flexDirection='column'>
							<Grid container spacing={2}>
								<FieldsetGrid xs={12} sm={6} item>
									<Fieldset>
										<FieldLabel htmlFor='user-editor_first-name_input'>
											First Name
										</FieldLabel>
										<TextField
											id='user-editor_first-name_input'
											placeholder='First name'
											type='text'
											{...getFieldProps('firstName')}
											{...getFieldFeedbackProps('firstName')}
											fullWidth
										/>
									</Fieldset>
								</FieldsetGrid>
								<FieldsetGrid xs={12} sm={6} item>
									<Fieldset>
										<FieldLabel htmlFor='user-editor_last-name_input'>
											Last Name
										</FieldLabel>
										<TextField
											id='user-editor_last-name_input'
											placeholder='Last name'
											type='text'
											{...getFieldProps('lastName')}
											{...getFieldFeedbackProps('lastName')}
											fullWidth
										/>
									</Fieldset>
								</FieldsetGrid>
								<FieldsetGrid xs={12} sm={6} item>
									<Fieldset>
										<FieldLabel htmlFor='user-editor_email_input'>Email</FieldLabel>
										<TextField
											id='user-editor_email_input'
											placeholder='Email'
											type='text'
											{...getFieldProps('email')}
											{...getFieldFeedbackProps('email')}
											fullWidth
										/>
									</Fieldset>
								</FieldsetGrid>

								<FieldsetGrid xs={12} sm={6} item>
									<Fieldset>
										<FieldLabel htmlFor='user-editor_position_input'>Position</FieldLabel>
										<TextField
											id='user-editor_position_input'
											placeholder='Position'
											type='text'
											{...getFieldProps('position')}
											{...getFieldFeedbackProps('position')}
											fullWidth
										/>
									</Fieldset>
								</FieldsetGrid>

								<FieldsetGrid xs={12} sm={6} item>
									<Fieldset>
										<FieldLabel htmlFor={managerInputId}>Manager</FieldLabel>
										<Autocomplete
											key={selectedUserData?.id}
											id={managerInputId}
											options={managers}
											loading={loadingManagers}
											value={currentManagerOption}
											defaultValue={initialManagerOption}
											renderInput={renderManagerInput}
											onChange={handleManagerChange}
											onInputChange={handleManagerInputChange}
											getOptionLabel={getManagerOptionLabel}
											isOptionEqualToValue={isManagerOptionEqualToValue}
										/>
									</Fieldset>
								</FieldsetGrid>

								<FieldsetGrid xs={12} sm={6} item>
									<Fieldset>
										<FieldLabel id='badge-editor-name-input'>
											Is this user a manager?
										</FieldLabel>
										<FormControlLabel
											label='Yes'
											control={
												<Checkbox
													{...getRoleCheckboxProps(Roles.Manager)}
													value={Roles.Manager}
													data-testid='isManager'
												/>
											}
										/>
									</Fieldset>
								</FieldsetGrid>
							</Grid>

							{Boolean(assignableRoles.length) && (
								<>
									<Divider sx={{ marginTop: '20px', marginBottom: '12px' }} />
									<SectionTitle>
										How will this employee be using this system
									</SectionTitle>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'start',
											alignItems: 'flex-start',
											marginBottom: '20px',
											minHeight: '100px',
										}}
									>
										{assignableRoles.map(({ role, name }) => (
											<FormControlLabel
												key={role}
												control={<Checkbox {...getRoleCheckboxProps(role)} value={role} />}
												label={name}
											/>
										))}
									</Box>
								</>
							)}
							<Can do={Action.Delete} on={ActionSubject.User}>
								{Boolean(selectedUserData) && (
									<Box sx={{ left: '-6px', position: 'relative', marginBottom: '18px' }}>
										<RemoveButton
											disabled={saving}
											loading={deleting}
											onClick={onRemoveClick}
										/>
									</Box>
								)}
							</Can>
							<Grid sx={{ display: 'flex', justifyContent: 'center' }} xs={12} item>
								<Button
									variant='contained'
									color='secondary'
									onClick={onCloseWindowClick}
								>
									Close Window
								</Button>
								<LoadingButton
									variant='contained'
									color='primary'
									type='submit'
									sx={{
										marginLeft: '26px',
									}}
									loading={saving}
									disabled={deleting}
								>
									Save Data
								</LoadingButton>
							</Grid>
						</Grid>
					</form>
				);
			}}
		</Formik>
	);
}

const SectionTitle = styled(MuiDialogTitle)(({ theme }) => ({
	fontWeight: 700,
	paddingTop: 68,
	paddingLeft: 0,
	paddingRight: 0,
	lineHeight: 1.22,
	paddingBottom: 16,
	textTransform: 'uppercase',
	fontSize: theme.typography.pxToRem(22),
	[theme.breakpoints.up('sm')]: {
		paddingTop: 25,
		paddingBottom: 4,
	},
}));

const FieldsetGrid = styled(Grid)(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		paddingRight: 56,
	},
}));

function getManagerOptionLabel(option: ManagerOption) {
	return option.managerName;
}

function isManagerOptionEqualToValue(
	option: ManagerOption,
	value: ManagerOption
) {
	return option.id === value.id;
}
