import { ReactNode } from 'react';
import {
	Container as MuiContainer,
	styled,
	Grid,
	Typography,
	Box,
} from '@mui/material';
import whiteBubbles from '../../assets/images/white_bg_bubbles.png';
import orangeBubbles from '../../assets/images/orange_bg_bubbles.png';
import leftHandMedal from '../../assets/images/left_hand_medal.png';
import rightHandMedal from '../../assets/images/right_hand_medal.png';
import highFive from '../../assets/images/high-five.png';
import logo from '../../assets/images/logo.png';
import bragBadges from '../../assets/images/brag_badges.png';
import orangeBubblesMobile from '../../assets/images/orange_bg_bubbles_mobile.png';
import mobileHighFive from '../../assets/images/mobile_high_five.png';
import { AppLogo } from '../../components/AppLogo';

export type LoginPageProps = {
	children: ReactNode;
};

export function LoginPageTemplate({ children }: LoginPageProps) {
	return (
		<Page>
			<Container disableGutters>
				<GridContainer container>
					<Grid item xs={12} md={6}>
						{children}
					</Grid>
					<RightColumn item xs={12} md={6}>
						<Grid container direction='column' alignItems='center'>
							<Grid item>
								<AppLogo
									sx={theme => ({
										padding: '50px 0',
										[theme.breakpoints.up('md')]: {
											flexDirection: 'column',
										},
									})}
								/>
							</Grid>

							<Grid item>
								<Typography align='center'>
									We like showing our appreciation - High Five!
								</Typography>
							</Grid>
							<Grid item>
								<Box sx={{ padding: '20px 0' }} display={{ md: 'none' }}>
									<img src={mobileHighFive} alt='Medals high five' />
								</Box>
							</Grid>
						</Grid>
					</RightColumn>
				</GridContainer>
			</Container>
		</Page>
	);
}

const Page = styled('div')(({ theme }) => ({
	width: '100%',
	minHeight: '100vh',
	background: `linear-gradient(100deg, ${theme.palette.primary.main} 500px, white 500px 100%)`,
	[theme.breakpoints.down('md')]: {
		background: 'white',
	},
	'&:before': {
		content: '""',
		zIndex: 0,
		position: 'absolute',
		width: '100%',
		height: '100vh',
		background: `url(${whiteBubbles}), url(${orangeBubbles})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'left center, right center',
		[theme.breakpoints.down('md')]: {
			background: `url(${orangeBubblesMobile})`,
			backgroundPosition: 'bottom',
			backgroundRepeat: 'no-repeat',
		},
	},
}));

const Container = styled(MuiContainer)(({ theme }) => ({
	'&&&': {
		maxWidth: 975,
		height: 578,
		[theme.breakpoints.down('md')]: {
			maxWidth: 'auto',
			height: 'auto',
		},
	},
	zIndex: 1,
	borderTop: 'solid 14px black',
	position: 'absolute',
	left: 'calc(50% - 975px / 2)',
	top: 'calc(50% - 578px / 2)',
	backgroundColor: 'white',
	boxShadow: '0px 14px 24px rgba(0, 0, 0, 0.25)',
	[theme.breakpoints.down('md')]: {
		left: 0,
		top: 0,
		boxShadow: 'unset',
	},
}));

const RightColumn = styled(Grid)(({ theme }) => ({
	backgroundColor: '#F7F4F4',
	position: 'relative',
	'&:before': {
		content: '""',
		zIndex: 0,
		position: 'absolute',
		width: '100%',
		height: '100%',
		background: `url(${leftHandMedal}), url(${highFive}), url(${rightHandMedal})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'left 70%, center bottom, right center',
		[theme.breakpoints.down('md')]: {
			background: 'unset',
		},
	},
	'& p': {
		width: '60%',
		margin: 'auto',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
}));

const GridContainer = styled(Grid)(({ theme }) => ({
	height: '100%',
	[theme.breakpoints.down('md')]: {
		flexDirection: 'column-reverse',
	},
}));
