import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { PrizeRedemption } from '../../api/PrizeRedemption';
import { RedemptionRequest } from '../../types/RedemptionRequest';
import { useApiCall } from '../useApiCall';

export type UseRedemptionDetailsConfig = {
	prizeRedemptionClient?: PrizeRedemption;
	onRedemptionStatusUpdate?: () => void;
	onRedemptionStatusUpdateError?: (error: AxiosError) => void;
	onRejection?: (reason: any) => void;
};

export type UseRedemptionDetailsState = {
	redemption: RedemptionRequest | null | undefined;
	loading: boolean;
	updatingStatus: boolean;
	approveRedemptionRequest: () => void;
};

export function usePrizeRedemptionDetails(
	redemptionId: string | undefined,
	config: UseRedemptionDetailsConfig = {}
): UseRedemptionDetailsState {
	const [updatingStatus, setUpdatingStatus] = useState(false);
	const apiClient = useMemo(
		() => config?.prizeRedemptionClient ?? new PrizeRedemption(),
		[]
	);
	const callParams: [id: string | undefined] = useMemo(
		() => [redemptionId],
		[redemptionId]
	);
	const apiCallConfig = useMemo(
		() => ({
			onRejection: config.onRejection,
		}),
		[config.onRejection]
	);
	const fetcher = useCallback(
		async (id?: string) => {
			if (id == null) {
				return {
					data: null,
				} as unknown as AxiosResponse<RedemptionRequest | null, any>;
			}

			return apiClient.fetchRedemptionRequest(id);
		},
		[apiClient]
	);
	const { data: redemption, loading } = useApiCall(
		fetcher,
		callParams,
		apiCallConfig
	);

	const approveRedemptionRequest = useCallback(() => {
		if (!redemptionId) return;
		setUpdatingStatus(true);
		apiClient
			.approveRedemptionRequest(redemptionId)
			.then(() => {
				if (config.onRedemptionStatusUpdate) config.onRedemptionStatusUpdate();
			})
			.catch(e => {
				if (config.onRedemptionStatusUpdateError)
					config.onRedemptionStatusUpdateError(e);
			})
			.finally(() => setUpdatingStatus(false));
	}, [
		redemptionId,
		config.onRedemptionStatusUpdate,
		config.onRedemptionStatusUpdateError,
	]);

	return {
		redemption,
		loading,
		updatingStatus,
		approveRedemptionRequest,
	};
}
