import { useLocation } from 'react-router-dom';
import { Navbar, NavbarProps } from '../Navbar';

export type AppNavbarProps = NavbarProps & {
	_NavigationComponent?: typeof Navbar;
};

export function AppNavbar({
	menuItems,
	_NavigationComponent: NavigationComponent = Navbar,
	...props
}: AppNavbarProps) {
	const { pathname } = useLocation();
	return (
		<NavigationComponent {...props} menuItems={menuItems} activePath={pathname} />
	);
}
