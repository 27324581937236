export const LoginPageText = {
	loginTitle: 'Welcome!',
	forgotPasswordTitle: 'Forgot your password?',
	forgotPasswordCta: 'Fill-in your email address to reset your password.',
	passwordResetEmailSent:
		'We have sent you an email with steps to reset your password. Please check your inbox!',
	userNotFound: 'User not found! Please check your email address and try again!',
	couldNotResetUserPassword:
		'Could not reset your password, please try again later!',
};
