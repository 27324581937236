/* eslint-disable react/no-unused-prop-types */
import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { ComponentProps, useCallback, useState } from 'react';
import {
	AwardFilterQueryParams,
	AwardsTextSearchParams,
} from '../../api/Awards/Awards.type';
import { AwardDetailsDialog } from '../../components/AwardDetailsDialog';
import { BadgeAwardRequestFilters } from '../../components/BadgeAwardRequestFilters';
import { AdminPage } from '../../templates/AdminPage';
import { Award, AwardStatusesText, AwardUser } from '../../types/Award';
import { userListCellFormatter } from '../../utils/helpers/toCellFormatter';
import { managersFormatter } from '../../utils/helpers/managersFormatter/managersFormatter';

const PAGE_TITLE =
	'From here you can search for past badges awarded to recipients.';

export type BadgeAwardHistoryViewProps = {
	awards?: Award[];
	total?: number;
	loading?: boolean;
	filters?: AwardsTextSearchParams & AwardFilterQueryParams;
	sortModel?: GridComponentProps['sortModel'];
	paginationModel?: GridPaginationModel;
	setFilters?: (
		filters: AwardsTextSearchParams & AwardFilterQueryParams
	) => void;
	onPaginationModelChange?: (model: GridPaginationModel, details: any) => void;
	onSortModelChange?: GridComponentProps['onSortModelChange'];
};

type GridPaginationModel = { page: number; pageSize: number };

type GridComponentProps = ComponentProps<typeof DataGrid>;

const GRID_COLUMNS: GridColDef[] = [
	badgeAwardColumn({
		field: 'sender',
		headerName: 'From',
		valueFormatter: ({ value }) => value.name,
	}),
	badgeAwardColumn({
		field: 'users',
		headerName: ' To',
		valueFormatter: userListCellFormatter,
	}),
	badgeAwardColumn({
		field: 'managers',
		headerName: 'Manager(s)',
		valueFormatter: userListCellFormatter,
	}),
	badgeAwardColumn({
		field: 'badge',
		headerName: 'Badge',
		valueFormatter: ({ value }) => value.name,
	}),
	badgeAwardColumn({
		field: 'sentAt',
		headerName: 'Date',
		valueFormatter: ({ value }) => new Date(value).toLocaleDateString(),
	}),
	badgeAwardColumn({
		field: 'status',
		headerName: 'Status',
		valueFormatter: ({ value }) => AwardStatusesText.get(value),
	}),
];

function badgeAwardColumn(config?: GridColDef) {
	return {
		sortable: true,
		flex: 1,
		headerAlign: 'center',
		align: 'center',
		...config,
	} as GridColDef;
}

const DATA_GRID_STYLE = {
	'& .MuiDataGrid-row:hover': {
		cursor: 'pointer',
	},
};

export function BadgeAwardHistoryView({
	awards = [],
	total,
	sortModel,
	loading,
	paginationModel,
	setFilters,
	onSortModelChange,
	onPaginationModelChange,
}: BadgeAwardHistoryViewProps) {
	const [currentAwardId, setCurrentAwardId] = useState<string>();

	const handlePageSizeChange: NonNullable<
		GridComponentProps['onPageSizeChange']
	> = useCallback(
		(pageSize, details) => {
			if (onPaginationModelChange)
				onPaginationModelChange(
					{
						page: 0,
						...paginationModel,
						pageSize,
					},
					details
				);
		},
		[paginationModel, onPaginationModelChange]
	);

	const handlePageChange: NonNullable<GridComponentProps['onPageChange']> =
		useCallback(
			(page, details) => {
				if (onPaginationModelChange)
					onPaginationModelChange(
						{
							pageSize: 100,
							...paginationModel,
							page,
						},
						details
					);
			},
			[paginationModel, onPaginationModelChange]
		);

	const handleRowClick: GridEventListener<'rowClick'> = useCallback(
		({ row }) => {
			setCurrentAwardId(row.id);
		},
		[]
	);

	const closeManagementDialog = useCallback(
		() => setCurrentAwardId(undefined),
		[]
	);

	return (
		<>
			<AdminPage title={PAGE_TITLE}>
				<BadgeAwardRequestFilters onFiltersChange={setFilters} />
				<Box sx={{ paddingBottom: '120px' }}>
					<DataGrid
						paginationMode='server'
						headerHeight={47}
						rowHeight={60}
						rows={awards}
						rowCount={total}
						loading={loading}
						columns={GRID_COLUMNS}
						sx={DATA_GRID_STYLE}
						sortModel={sortModel}
						page={paginationModel?.page}
						pageSize={paginationModel?.pageSize}
						onRowClick={handleRowClick}
						onSortModelChange={onSortModelChange}
						onPageSizeChange={handlePageSizeChange}
						onPageChange={handlePageChange}
						disableSelectionOnClick
						disableColumnSelector
						disableColumnFilter
						disableColumnMenu
						autoHeight
					/>
				</Box>
			</AdminPage>
			<AwardDetailsDialog
				title='Badge'
				open={currentAwardId != null}
				awardId={currentAwardId}
				onClose={closeManagementDialog}
				withCloseBottomButton
				showHistoryDetail
			/>
		</>
	);
}
