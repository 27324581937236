import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { saveToFile } from '../../utils/helpers/saveToFile/saveToFile';

const DOWNLOAD_ERROR_MESSAGE = 'Could not download report!';

export type Fetcher<Parameters extends any[], ResponseData> = (
	...params: Parameters
) => AxiosPromise<ResponseData>;

export type UseApiCallConfig = {
	onRejection?: (reason: AxiosError) => void;
};

export type UseReportDownloadState = {
	downloading: boolean;
	download: () => void;
};

const DEFAULT_PARAMS: unknown = [];

export function useReportDownload<
	ResponseData,
	FetcherParameters extends any[]
>(
	downloader: Fetcher<FetcherParameters, ResponseData>,
	params: FetcherParameters = DEFAULT_PARAMS as FetcherParameters,
	filename = 'report.csv'
): UseReportDownloadState {
	const { enqueueSnackbar } = useSnackbar();
	const [downloading, setDownloading] = useState(false);

	const onDownloadSuccess = useCallback(
		(res: AxiosResponse<ResponseData>) =>
			saveToFile(res.data, filename, 'text/csv'),
		[filename]
	);

	const onDownloadError = useCallback(() => {
		enqueueSnackbar(DOWNLOAD_ERROR_MESSAGE, { variant: 'error' });
	}, []);

	const download = useCallback(() => {
		setDownloading(true);
		downloader(...params)
			.then(onDownloadSuccess)
			.catch(onDownloadError)
			.finally(() => {
				setDownloading(false);
			});
	}, [params]);

	return {
		downloading,
		download,
	};
}
