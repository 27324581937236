export type Strings = {
	plural: string;
	singular: string;
};

/**
 * Given a quantity and an object with singular and plural strings it returns the
 * appropriate string given the English plural/singular rules (Abs(1)=singular,Abs(x != 1)=plural)
 *
 * @param qnt
 * @param strings
 * @returns
 */
export function singularOrPlural(qnt: number, strings: Strings) {
	return Math.abs(qnt) === 1 ? strings.singular : strings.plural;
}
