import { ComponentThemeOverride } from '../../utils/types';

export const MuiInputBase: ComponentThemeOverride['MuiInputBase'] = {
	styleOverrides: {
		root: {
			'&.MuiInputBase-readOnly': {
				backgroundColor: '#F6F7F8',
				'& > .MuiOutlinedInput-input, & > .MuiOutlinedInput-notchedOutline': {
					borderColor: '#F6F7F8',
				},
				'&:hover > .MuiOutlinedInput-input, &:hover > .MuiOutlinedInput-notchedOutline':
					{
						borderColor: '#F6F7F8',
					},
			},
		},
		sizeSmall: ({ theme }) => ({
			fontSize: theme.typography.pxToRem(12),
			lineHeight: 1.218,
			'&:not(.MuiInputBase-multiline)': {
				height: '3em',
			},
		}),
		// @ts-ignore
		colorPrimary: ({ theme }) => ({
			'& .MuiInputAdornment-root .MuiSvgIcon-root': {
				color: theme.palette.primary.main,
			},
		}),
		inputSizeSmall: {
			height: '1.25em',
			padding: '11px 16px 10px',
		},
	},
};
