import {
	CircularProgress,
	Grid,
	styled,
	Typography,
	Box,
	Skeleton,
} from '@mui/material';
import heroCelebrationArt from '../../../../assets/img/dashboard-hero-celebration.svg';
import { useAuth } from '../../../../context/Auth';
import { useDashboardController } from '../../DashboardController';
import { CounterBox } from '../CounterBox';

export type DashboardHeroProps = {
	onPointsCounterClick?: () => void;
};

export function DashboardHero({ onPointsCounterClick }: DashboardHeroProps) {
	const { tally, fetchingTally } = useDashboardController();
	const { user } = useAuth();

	return (
		<Grid container direction='row' alignItems='center' mb={3}>
			<Grid xs={12} sm={8} md={5} item>
				<HeroArt src={heroCelebrationArt} />
			</Grid>
			<Grid
				xs={12}
				sm={4}
				md={3}
				item
				sx={theme => ({
					padding: '30px 4px 30px 25px',
					[theme.breakpoints.down('sm')]: {
						order: '-1',
					},
				})}
			>
				<WelcomeText variant='h3'>WELCOME</WelcomeText>
				<UserName variant='subtitle2'>
					{user ? (
						user.name
					) : (
						<Skeleton
							sx={{
								marginBottom: '0.36em',
							}}
							variant='rectangular'
							animation='wave'
							width={120}
							height='1em'
						/>
					)}
				</UserName>
				<Typography variant='body2' sx={{ textTransform: 'capitalize' }}>
					{user ? (
						user.position
					) : (
						<Skeleton
							sx={{
								marginBottom: '0.36em',
							}}
							variant='rectangular'
							animation='wave'
							width={100}
							height='1em'
						/>
					)}
				</Typography>
				<Typography variant='body2'>
					{user ? (
						user.email
					) : (
						<Skeleton
							sx={{
								marginBottom: '0.36em',
							}}
							variant='rectangular'
							animation='wave'
							width={150}
							height='1em'
						/>
					)}
				</Typography>
			</Grid>

			<Grid
				xs={12}
				sm={12}
				md={4}
				item
				sx={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				{!fetchingTally && (
					<>
						<CounterBox subtitle='badges' value={tally.badges} />
						<CounterBox
							subtitle='points'
							component='button'
							style={{
								border: 0,
								cursor: 'pointer',
							}}
							value={tally.score}
							onClick={onPointsCounterClick}
						/>
					</>
				)}
				{fetchingTally && (
					<Box
						display='flex'
						justifyContent='center'
						alignItems='center'
						sx={{ width: '100%' }}
					>
						<CircularProgress />
					</Box>
				)}
			</Grid>
		</Grid>
	);
}

const HeroArt = styled('img')({
	width: '100%',
	height: 'auto',
});

const WelcomeText = styled(Typography)(({ theme }) => ({
	color: theme.palette.primary.dark,
	fontSize: theme.typography.pxToRem(22),
	fontWeight: theme.typography.fontWeightBold,
}));

const UserName = styled(Typography)(({ theme }) => ({
	textTransform: 'capitalize',
	fontWeight: theme.typography.fontWeightBold,
}));
