import { PrizeRedemption } from '../../api/PrizeRedemption';
import { Prizes } from '../../api/Prizes';
import { OwnTallyProvider } from '../../context/OwnTally';
import { PrizeRedemptionProvider } from '../../context/PrizeRedemption';
import { PrizesProvider } from '../../context/Prizes';
import { DashboardController } from './DashboardController';
import { DashboardView } from './DashboardView';

const prizesClient = new Prizes();
const redemptionClient = new PrizeRedemption();

export function DashboardPage() {
	return (
		<PrizeRedemptionProvider redemptionClient={redemptionClient}>
			<PrizesProvider prizesClient={prizesClient}>
				<OwnTallyProvider>
					<DashboardController>
						<DashboardView />
					</DashboardController>
				</OwnTallyProvider>
			</PrizesProvider>
		</PrizeRedemptionProvider>
	);
}
