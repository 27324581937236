// eslint-disable-next-line no-shadow
export enum SortOrder {
	Ascending = 'asc',
	Descending = 'desc',
}

export type SortQueryParams<SortableField extends string> = {
	order?: SortOrder;
	orderBy?: SortableField;
};
