export const LoginFormText = {
	forgotPasswordButton: 'Forgot Password/Username?',
	loginButton: 'Login',
	rememberMe: 'Remember me',
	emailLabel: 'Username',
	passwordLabel: 'Password',
	emailPlaceholder: 'Enter your username or email',
	passwordPlaceholder: 'Enter your password',
	resetPasswordButton: 'Reset password',
	goBackToLoginButton: 'Go back to login',
};
