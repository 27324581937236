/* eslint-disable camelcase */
import { InternalAxiosRequestConfig } from 'axios';
import { BrandIdStorage } from '../../../classes/BrandIdStorage';

export function brand(brandIdStorage: BrandIdStorage) {
	return (config: InternalAxiosRequestConfig) => {
		const brandId = brandIdStorage.get();

		if (brandId && brandId.length) {
			config.headers.set('x-brand-id', brandId.toString());
		}

		return config;
	};
}
