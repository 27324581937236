import { AwardUser } from '../../types/Award';

export function userListCellFormatter({ value = [] }: { value: AwardUser[] }) {
	if (!value.length) return '';
	let result = value
		.slice(0, 2)
		.map(user => user.name)
		.join(', ');
	if (value.length > 2) result += ` +${value.length - 2}`;
	return result;
}
