import { ComponentThemeOverride } from '../../utils/types';

export const MuiSelect: ComponentThemeOverride['MuiSelect'] = {
	styleOverrides: {
		select: {
			'&.MuiOutlinedInput-input:not(.MuiInputBase-inputMultiline)': {
				paddingTop: 16,
			},
		},
		icon: ({ theme }) => ({
			color: theme.palette.primary.main,
		}),
	},
};
