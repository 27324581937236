import { Box } from '@mui/material';
import { MouseEvent, useCallback } from 'react';
import {
	Dialog,
	DialogCloseReason,
	DialogProps,
} from '../../../../components/Dialog';
import { BadgeDetails } from '../BadgeDetails';

export type BadgeDetailsDialogProps = Omit<DialogProps, 'children'> & {
	badgeId?: string;
	onBadgeUpdate?: () => void;
	onBadgeDeleted?: () => void;
};

export function BadgeDetailsDialog({
	badgeId,
	onClose,
	onBadgeUpdate,
	onBadgeDeleted,
	...props
}: BadgeDetailsDialogProps) {
	const handleDetailsCloseButtonClick = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			if (onClose) onClose(e, DialogCloseReason.CloseButton);
		},
		[]
	);

	return (
		<Dialog
			title='Badge Details'
			PaperProps={{
				sx: {
					maxWidth: 940,
				},
			}}
			HeaderProps={{
				sx: {
					paddingTop: 0,
					paddingBottom: 0,
					paddingLeft: '54px',
					paddingRight: '54px',
				},
			}}
			ContentProps={{
				sx: {
					paddingLeft: '54px',
					paddingRight: '54px',
				},
			}}
			onClose={onClose}
			{...props}
		>
			<Box sx={{ width: '100%', maxWidth: '598px', margin: '0 auto' }}>
				{badgeId && (
					<BadgeDetails
						badgeId={badgeId}
						onBadgeUpdate={onBadgeUpdate}
						onBadgeDeleted={onBadgeDeleted}
						onCloseWindowButtonClick={handleDetailsCloseButtonClick}
					/>
				)}
			</Box>
		</Dialog>
	);
}
