import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAwardDetails } from '../../hooks/useAwardDetails';
import { AwardStatuses, AwardStatusesText, AwardUser } from '../../types/Award';
import {
	AwardDetailsDialogView,
	AwardDetailsDialogViewProps,
} from './AwardDetailsDialogView';
import { managersFormatter } from '../../utils/helpers/managersFormatter/managersFormatter';

export const AWARD_STATUS_UPDATE_SUCCESS_MESSAGE =
	'Award status successfully updated!';
export const AWARD_STATUS_UPDATE_ERROR_MESSAGE =
	'Could not update the award status, please try again later!';

export type AwardDetailsDialogProps = Omit<
	AwardDetailsDialogViewProps,
	| 'sender'
	| 'sentAt'
	| 'message'
	| 'badgeName'
	| 'badgeSrc'
	| 'manager'
	| 'receiver'
	| 'status'
> & {
	awardId: string | undefined;
	editingMode?: boolean;
	onAwardStatusUpdate?: () => void;
};

export function AwardDetailsDialog({
	awardId,
	editingMode,
	onAwardStatusUpdate,
	...props
}: AwardDetailsDialogProps) {
	const { enqueueSnackbar } = useSnackbar();
	const [denialRequested, setDenialRequested] = useState(false);
	const [approvalRequested, setApprovalRequested] = useState(false);

	const handleAwardStatusUpdate = useCallback(() => {
		if (onAwardStatusUpdate) onAwardStatusUpdate();
		enqueueSnackbar(AWARD_STATUS_UPDATE_SUCCESS_MESSAGE, {
			variant: 'success',
		});
	}, [onAwardStatusUpdate]);

	const handleAwardStatusUpdateError = useCallback(() => {
		enqueueSnackbar(AWARD_STATUS_UPDATE_ERROR_MESSAGE, {
			variant: 'error',
		});
	}, []);

	const useAwardDetailsConfig = useMemo(
		() => ({
			onAwardStatusUpdate: handleAwardStatusUpdate,
			onAwardStatusUpdateError: handleAwardStatusUpdateError,
		}),
		[handleAwardStatusUpdate]
	);
	const { award, updatingStatus, updateAwardStatus } = useAwardDetails(
		awardId,
		useAwardDetailsConfig
	);

	// Reset approvalRequested and denialRequested statuses after request finishes
	useEffect(() => {
		if (updatingStatus) return;
		if (denialRequested) setDenialRequested(false);
		if (approvalRequested) setApprovalRequested(false);
	}, [updatingStatus]);

	const handleAwardApproval = useCallback(() => {
		setApprovalRequested(true);
		updateAwardStatus(AwardStatuses.Approved);
	}, [updateAwardStatus]);

	const handleAwardDenial = useCallback(() => {
		setDenialRequested(true);
		updateAwardStatus(AwardStatuses.Denied);
	}, [updateAwardStatus]);

	const approving = useMemo(
		() => updatingStatus && approvalRequested,
		[approvalRequested, updatingStatus]
	);

	const deleting = useMemo(
		() => updatingStatus && denialRequested,
		[denialRequested, updatingStatus]
	);

	const sender = useMemo(
		() => ({
			name: award?.sender.name ?? '',
			email: award?.sender.email ?? '',
		}),
		[award]
	);

	const awardStatus = useMemo(
		() =>
			award?.status !== undefined ? AwardStatusesText.get(award?.status) : '',
		[award]
	);

	const viewProps = {
		...props,
		badgeName: award?.badge.name ?? '',
		badgeSrc: award?.badge.assets.full_svg ?? '',
		sentAt: award?.sentAt ?? new Date(),
		message: award?.message ?? '',
		processing: updatingStatus,
		editing: editingMode,
		approving,
		deleting,
		sender,
		manager: award?.managers?.length ? managersFormatter(award.managers) : '',
		receiver: award?.users.map(formatReceiver).join(', '),
		status: awardStatus ?? '',
		onApproveClick: handleAwardApproval,
		onDeleteClick: handleAwardDenial,
	};

	return <AwardDetailsDialogView {...viewProps} />;
}

function formatReceiver(user: AwardUser) {
	let result = user.name;
	console.log('formatReceiver', user);
	if (user.manager) result += ` (${user.manager.name})`;

	return result;
}
