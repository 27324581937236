import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { ChangePassConfig, Users } from '../../api/Users';
import { LoginRequest } from '../../types/LoginRequest';
import { UserLogin } from '../../types/User';
import { FirstAccessPageView } from './FirstAccessPage.view';

export type FirstAccessPageProps = {
	user: UserLogin;
	isSubmitting: boolean;
	onFirstAccess: (params: LoginRequest) => void;
	usersClient?: Users;
};

export const USER_PASSWORD_UPDATE_SUCCESS_MESSAGE =
	'Password updated successfully!';

export const USER_PASSWORD_SAVE_ERROR_MESSAGE =
	'Password could not be updated, please try again later!';

export function FirstAccessPage({
	user,
	onFirstAccess,
	isSubmitting,
	usersClient = new Users(),
}: FirstAccessPageProps) {
	const { enqueueSnackbar } = useSnackbar();
	const [saving, setSaving] = useState(false);

	const handleUpdatePassword = useCallback((params: LoginRequest) => {
		if (saving) return;

		setSaving(true);

		const changePassData: ChangePassConfig = {
			id: user.id,
			password: params.password,
		};

		usersClient
			.updatePassword(changePassData)
			.then(() => {
				enqueueSnackbar(USER_PASSWORD_UPDATE_SUCCESS_MESSAGE, {
					variant: 'success',
				});
				onFirstAccess(params);
			})
			.catch(err => {
				enqueueSnackbar(USER_PASSWORD_SAVE_ERROR_MESSAGE, {
					variant: 'error',
				});
				console.log(err);
			})
			.finally(() => {
				setSaving(false);
			});
	}, []);

	return (
		<FirstAccessPageView
			useremail={user.email}
			onUpdate={handleUpdatePassword}
			isSubmitting={isSubmitting}
		/>
	);
}
