export class UserFilters {
	private filters: Record<string, string | number> = {};

	set(key: string, value: string | number) {
		this.filters[key] = value;
	}

	build() {
		return Object.entries(this.filters)
			.map(([key, value]) => `${key}:${value}`)
			.join(',');
	}
}
