/* eslint-disable no-empty-function, no-useless-constructor */
import { AxiosResponse, AxiosResponseTransformer } from 'axios';
import { Badge, CreateBadgeConfig, UpdateBadgeConfig } from '../../types/Badge';
import { ApiClient } from '../ApiClient';
import { extendAxiosTransformer } from '../../utils/extendAxiosTransformer.helper';
import { BadgeDto, CreateBadgeDto, UpdateBadgeDto } from './Badges.type';
import { BadgeIcons } from '../BadgeIcons';
import { Colors } from '../Colors';

export class Badges extends ApiClient {
	private baseEndpoint = '/badges';

	findAll = () =>
		this.httpClient.get<Badge[]>(this.baseEndpoint, {
			transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
				this.httpClient.defaults.transformResponse,
				[Badges.transformResponse]
			),
		});

	createBadge = (badgeConfig: CreateBadgeConfig) =>
		this.protectedHttpClient.post(
			this.getEndpoint(),
			Badges.createBadgeDtoFromCreateBadgeConfig(badgeConfig)
		);

	getBadgeById = (id: string): Promise<AxiosResponse<Required<Badge>>> =>
		this.protectedHttpClient.get<Required<Badge>>(this.getEndpoint(id), {
			transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
				this.protectedHttpClient.defaults.transformResponse,
				[Badges.singleBadgeFromSingleDto]
			),
		});

	deleteBadgeById = (id: string): Promise<any> =>
		this.protectedHttpClient.delete(this.getEndpoint(id));

	updateBadgeById = (
		id: string,
		update: UpdateBadgeConfig
	): Promise<AxiosResponse<Required<Badge>>> =>
		this.protectedHttpClient.patch<Required<Badge>>(
			this.getEndpoint(id),
			Badges.updateBadgeFromUpdateObject(update),
			{
				transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
					this.protectedHttpClient.defaults.transformResponse,
					[Badges.singleBadgeFromSingleDto]
				),
			}
		);

	static transformResponse(data: BadgeDto[]): Badge[] {
		if (!(data && Array.isArray(data))) return [];
		return data.map(Badges.badgeFromDto);
	}

	static singleBadgeFromSingleDto(dto: Required<BadgeDto>): Required<Badge> {
		return {
			...Badges.badgeFromDto(dto),
			icon: BadgeIcons.iconFromIconDto(dto.icon),
			color: Colors.colorFromDto(dto.color),
		};
	}

	static badgeFromDto(dto: BadgeDto): Badge {
		return {
			id: String(dto.id),
			name: dto.name,
			points: dto.points,
			active: dto.active,
			assets: Badges.badgeAssetsFromDto(dto),
		};
	}

	static createBadgeDtoFromCreateBadgeConfig(
		config: CreateBadgeConfig
	): CreateBadgeDto {
		return {
			name: config.name,
			colorId: config.colorId,
			iconId: config.iconId,
			points: config.points,
		};
	}

	static updateBadgeFromUpdateObject(badge: UpdateBadgeConfig) {
		const result: UpdateBadgeDto = {};

		if (badge.name) result.name = badge.name;
		if (badge.colorId) result.colorId = badge.colorId;
		if (badge.points != null) result.points = badge.points;
		if (badge.slug != null) result.slug = badge.slug;

		return result;
	}

	static badgeAssetsFromDto(dto: BadgeDto): Badge['assets'] {
		return {
			full_svg: dto.assets.svg,
			full_png: dto.assets.png,
			small_svg: dto.assets.smallSvg,
		};
	}

	private getEndpoint(id?: string) {
		return id ? this.baseEndpoint.concat(`/${id}`) : this.baseEndpoint;
	}
}
