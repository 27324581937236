import { SnackbarProvider } from 'notistack';
import { ReactNode } from 'react';

export type SnackbarNotificationProviderProps = {
	children: ReactNode;
};

export function SnackbarNotificationProvider({
	children,
}: SnackbarNotificationProviderProps) {
	return (
		<SnackbarProvider
			maxSnack={1}
			anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
		>
			{children}
		</SnackbarProvider>
	);
}
