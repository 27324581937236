import { Button, Grid, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { AdminIcon } from '../../../../assets/Icons/Admin';
import { RoleGuard } from '../../../../components/RoleGuard/RoleGuard';
import { adminDashboardAuthorizedRoles } from '../../../../config/adminDashboardAuthorizedRoles';
import { useAuth } from '../../../../context/Auth';
import { useInternalLink } from '../../../../hooks/useInternalLink';
import { OverridableComponentProps } from '../../../../utils/types';

export function ActionsPanel() {
	const { logout } = useAuth();
	const internalLink = useInternalLink();
	return (
		<Grid alignItems='center' justifyContent='flex-end' container>
			<RoleGuard roles={adminDashboardAuthorizedRoles}>
				<ActionButton
					// @ts-ignore
					to={internalLink('admin')}
					variant='text'
					color='secondary'
					startIcon={<AdminIcon />}
					component={Link}
				>
					Badge Admin
				</ActionButton>
			</RoleGuard>
			<ActionButton
				variant='text'
				color='secondary'
				// @ts-ignore
				to={internalLink('give-a-badge')}
				component={Link}
				target='_blank'
			>
				Award Badges
			</ActionButton>
			<ActionButton variant='text' color='secondary' onClick={logout}>
				Sign out
			</ActionButton>
		</Grid>
	);
}

const ActionButton = styled(Button)<OverridableComponentProps<typeof Button>>({
	marginRight: 26,
});
