export type RedemptionRequest = {
	id: string;
	confirmed: boolean;
	charity: boolean;
	dateSent: Date;
	from: string;
	manager: string;
	prizes: RedemptionRequestPrize[];
	totalPoints: number;
};

export type RedemptionRequestPrize = {
	name: string;
	points: number;
	quantity: number;
};
// eslint-disable-next-line no-shadow
export enum RedemptionRequestStatus {
	Pending = 'pending',
	Approved = 'approved',
}
