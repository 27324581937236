import { Box, BoxProps, styled } from '@mui/material';
import stars from '../../assets/img/stars_background.svg';

export type ReceivedBadgeProps = BoxProps & {
	badgeSrc: string;
	badgeName: string;
};

export function ReceivedBadge({
	badgeName,
	badgeSrc,
	...props
}: ReceivedBadgeProps) {
	return (
		<Wrapper {...props}>
			<Badge src={badgeSrc} alt={badgeName} />
		</Wrapper>
	);
}

const Wrapper = styled(Box)({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'flex-end',
	backgroundPositionY: 6,
	aspectRatio: '276 / 400',
	backgroundColor: '#F6F4F4',
	backgroundRepeat: 'no-repeat',
	backgroundImage: `url(${stars})`,
});

const Badge = styled('img')({
	width: '71%',
	bottom: '9%',
	position: 'relative',
});
