import { ReactNode, useMemo } from 'react';
import { UsersContext, UsersContextObj } from './UsersContext';
import { Users } from '../../api/Users';
import { Recipients } from '../../api/Recipients';

export type UsersProviderProps = {
	children?: ReactNode;
	usersClient?: Users;
	recipientsClient?: Recipients;
};

export function UsersProvider({
	children,
	usersClient = new Users(),
	recipientsClient = new Recipients(),
}: UsersProviderProps) {
	const value: UsersContextObj = useMemo(
		() => ({
			getAllUsers: usersClient.getAllUsers,
			awardPointsToUsers: usersClient.awardPointsToUsers,
			getTransactionHistoryByUserId: usersClient.getTransactionHistoryByUserId,
			searchByName: recipientsClient.searchByName,
		}),
		[usersClient]
	);

	return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
}
