import { SxProps, createTheme } from '@mui/material';

type Theme = ReturnType<typeof createTheme>;

type FlatSxProps = Extract<SxProps<Theme>, ReadonlyArray<any>>;

export function mergeSxProps(
	input: Array<SxProps<Theme> | undefined>
): FlatSxProps {
	const result: FlatSxProps = input.filter(item => item != null) as FlatSxProps;
	return result.flat();
}
