import { createMongoAbility } from '@casl/ability';
import { ReactNode, useMemo } from 'react';
import { AbilityContext } from './AuthorizationContext';
import { UserLogin } from '../../types/User';
import { useAuth } from '../Auth';

export type AbilityProviderProps = {
	children: ReactNode;
};

export function AbilityProvider({ children }: AbilityProviderProps) {
	const { user } = useAuth();

	const ability = useMemo(
		() =>
			createMongoAbility(
				user?.permissions != null
					? abilityArrayFromUserPermissions(user.permissions)
					: []
			),
		[user]
	);

	return (
		<AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
	);
}

function abilityArrayFromUserPermissions(
	permissions: NonNullable<UserLogin['permissions']>
) {
	return Object.entries(permissions).map(([subject, actions]) => ({
		action: actions,
		subject,
	}));
}
