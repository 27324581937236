import { useState, useCallback, useEffect } from 'react';
import { brandIdStorage as globalBrandIdStorage } from '../../globalStores';

export function useGlobalBrandId() {
	const [brandId, setBrandId] = useState<string | undefined>(undefined);

	const brandIdListener = useCallback((brandId: string) => {
		setBrandId(brandId);
	}, []);

	useEffect(() => {
		globalBrandIdStorage.subscribe(brandIdListener);
		return () => globalBrandIdStorage.unsubscribe(brandIdListener);
	}, []);

	return brandId;
}
