import { TypographyOptions } from '@mui/material/styles/createTypography';

const BASE_FONT_SIZE = 16;

function pxToRem(fontSize: number) {
	return `${(fontSize / BASE_FONT_SIZE).toFixed(3)}rem`;
}

export const typography: TypographyOptions = {
	fontFamily: 'Proxima Nova',
	htmlFontSize: BASE_FONT_SIZE,
	fontSize: BASE_FONT_SIZE,
	// @ts-ignore
	pxToRem,
	h1: {
		fontSize: pxToRem(42),
		fontWeight: 700,
	},
	h2: {
		fontSize: pxToRem(22),
		fontWeight: 700,
	},
	h3: {
		fontSize: pxToRem(18),
		fontWeight: 700,
	},
	button: {
		fontSize: pxToRem(16),
	},
};
