import { debounce } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import {
	AwardFilterQueryParams,
	AwardTextSearchableFields,
	AwardsTextSearchParams,
} from '../../api/Awards/Awards.type';
import { useAwardsAutocomplete } from '../../hooks/useAwardsAutocomplete';
import { DateSearchParameters } from '../../types/DateSearchParameters';
import { TextSearchQueryParam } from '../../utils/enums/TextSearchQueryParam';
import { NULL_FIELD, TextSearchProps } from '../TextSearch';
import { BadgeAwardRequestFiltersView } from './BadgeAwardRequestFiltersView';

export const SEARCHABLE_FIELDS = [
	{
		field: AwardTextSearchableFields.FROM_NAME,
		text: 'From',
	},
	{
		field: AwardTextSearchableFields.TO_NAME,
		text: 'To',
	},
	{
		field: AwardTextSearchableFields.MANAGER_NAME,
		text: 'Manager',
	},
	{
		field: AwardTextSearchableFields.BADGE_NAME,
		text: 'Badge',
	},
];

export type BadgeAwardRequestFiltersProps = {
	onFiltersChange?: (filters: Filters) => void;
};

type Filters = AwardsTextSearchParams & AwardFilterQueryParams;

export function BadgeAwardRequestFilters({
	onFiltersChange,
}: BadgeAwardRequestFiltersProps) {
	const [textSearchTerm, setTextSearchTerm] = useState<string>('');
	const [textSearchField, setTextSearchField] = useState<
		AwardTextSearchableFields | undefined
	>(undefined);
	const [filters, setFilters] = useState<Filters>({});
	const { suggestions } = useAwardsAutocomplete(textSearchField, textSearchTerm);

	// Call handler with updated filters
	useEffect(() => {
		if (onFiltersChange) onFiltersChange(filters);
	}, [filters]);

	const updateFilters = useCallback(
		(partialUpdate: Partial<Filters>) =>
			setFilters(currentFilters => ({
				...currentFilters,
				...partialUpdate,
			})),
		[]
	);

	const datePickerFieldHandler =
		(field: keyof DateSearchParameters) => (date: Date | null) => {
			updateFilters({
				[field]: date ? dayjs(date).startOf('day') : undefined,
			});
		};

	const handleTextSearchSubmit: TextSearchProps<AwardTextSearchableFields>['onSubmit'] =
		useCallback(
			debounce(({ field, term }) => {
				updateFilters({
					[TextSearchQueryParam.SearchBy]: field,
					[TextSearchQueryParam.SearchTerm]: term,
				});
			}, 500),
			[]
		);

	const handleFieldChange: TextSearchProps<AwardTextSearchableFields>['onFieldChange'] =
		useCallback((field: string) => {
			if (onFiltersChange && field === NULL_FIELD) {
				updateFilters({
					[TextSearchQueryParam.SearchBy]: undefined,
					[TextSearchQueryParam.SearchTerm]: undefined,
				});
			} else {
				setTextSearchField(field as AwardTextSearchableFields);
			}
		}, []);

	const handleTextTermChange: TextSearchProps<AwardTextSearchableFields>['onTermChange'] =
		useCallback(
			debounce((term: string) => {
				setTextSearchTerm(term);
			}, 200),
			[]
		);

	return (
		<BadgeAwardRequestFiltersView
			suggestions={suggestions}
			searchableFields={SEARCHABLE_FIELDS}
			handleFieldChange={handleFieldChange}
			handleTextTermChange={handleTextTermChange}
			handleTextSearchSubmit={handleTextSearchSubmit}
			datePickerFieldHandler={datePickerFieldHandler}
		/>
	);
}
