import { createContext } from 'react';

export type BrandContextState = {
	loading: boolean;
	brandId?: string;
};

export const BrandContext = createContext<BrandContextState | undefined>(
	undefined
);
