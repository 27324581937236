import * as yup from 'yup';

const ALLOWED_FILE_TYPES = [
	'image/jpeg',
	'image/jpg',
	'image/svg+xml',
	'image/png',
];

export const settingsValidationSchema = yup.object({
	companyName: yup.string().required('Company name cannot be blank'),
	primaryColor: yup.string().required('You need to select the Primary Color'),
	secondaryColor: yup.string().required('You need to select Secondary Color'),
	logo: yup
		.mixed()
		.test(
			'type',
			'Only the following formats are accepted: .jpeg, .jpg, .png and .svg',
			(value: File) => {
				if (value && !value.size) return true;
				return value.size > 0 && ALLOWED_FILE_TYPES.includes(value.type);
			}
		),
});
