import { AxiosError } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { Awards } from '../../api/Awards';
import {
	AwardFilterQueryParams,
	AwardsQueryParams,
	AwardsSortQueryParams,
} from '../../api/Awards/Awards.type';
import { PaginationQueryParams } from '../../types/PaginationDto';
import { useApiCall } from '../useApiCall';
import { Award } from '../../types/Award';

export type UseAwardsConfig = {
	awardsClient?: Awards;
	pagination?: PaginationQueryParams;
	sort?: AwardsSortQueryParams;
	filters?: AwardFilterQueryParams;
	onRejection?: (e: AxiosError) => void;
};

export type UseAwardsState = {
	awards: Award[];
	pagination: {
		page: number | undefined;
		limit: number | undefined;
		total: number | undefined;
	};
	loading: boolean;
	refetch: () => void;
};

export function useAwards({
	awardsClient,
	pagination,
	sort,
	filters,
	onRejection,
}: UseAwardsConfig = {}): UseAwardsState {
	const [requestCount, setRequestCount] = useState(1);
	const client = useMemo(() => awardsClient ?? new Awards(), [awardsClient]);
	const requestParams: [queryParams: AwardsQueryParams] = useMemo(
		() => [
			{
				page: pagination?.page ?? 0,
				limit: pagination?.limit ?? 100,
				order: sort?.order,
				orderBy: sort?.orderBy,
				...filters,
			},
		],
		[pagination, sort, filters, requestCount]
	);
	const useApiCallConfig = useMemo(
		() => ({
			onRejection,
		}),
		[onRejection]
	);
	const { data, loading } = useApiCall(
		client.find,
		requestParams,
		useApiCallConfig
	);

	const awards = useMemo(() => data?.results ?? [], [data]);
	const paginationInfo = useMemo(
		() => ({
			page: data?.page,
			limit: data?.limit,
			total: data?.total,
		}),
		[data]
	);
	const refetch = useCallback(() => setRequestCount(count => count + 1), []);

	return {
		pagination: paginationInfo,
		awards,
		loading,
		refetch,
	};
}
