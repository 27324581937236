export class PersistentStorage {
	readonly key: string;

	readonly storage = localStorage;

	constructor(key: string) {
		this.key = key;
	}

	set = (key: string, value: any) => this.saveToRegistry(key, value);

	get = <R = any>(key: string) => this.getRegistry()[key] as R;

	getAll = <R = Record<string, any>>() => this.getRegistry() as R;

	delete = (key: string) => {
		const registry = this.getRegistry();
		if (!Object.hasOwn(registry, key)) return;

		delete registry[key];
		this.updateRegistry(registry);
	};

	private saveToRegistry(key: string, value: any) {
		const registry = this.getRegistry();
		this.updateRegistry({
			...registry,
			[key]: value,
		});
	}

	private updateRegistry(updated: Record<string, any>) {
		this.storage.setItem(this.key, JSON.stringify(updated));
	}

	private getRegistry() {
		const original = this.storage.getItem(this.key);
		return original ? JSON.parse(original) : {};
	}
}
