/* eslint-disable react/no-array-index-key */
import { Box, Button, Grid, Skeleton, Typography, styled } from '@mui/material';
import { useCallback, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { useInternalLink } from '../../../../hooks/useInternalLink';
import { useOwnReceivedAwards } from '../../../../hooks/useOwnReceivedAwards/useOwnReceivedAwards';
import { Award } from '../../../../types/Award';
import { OverridableComponentProps } from '../../../../utils/types';
import { useDashboardController } from '../../DashboardController';
import { BadgeCard } from '../BadgeCard';

const cardWidth = 175;

export type BadgeCarouselProps = {
	openEarnedBadgesDialog: () => void;
};

export function BadgesCarousel({ openEarnedBadgesDialog }: BadgeCarouselProps) {
	const ref = useRef<HTMLDivElement>(null);
	const { awards, loading } = useOwnReceivedAwards();
	const { setCurrentAwardId } = useDashboardController();
	const internalLink = useInternalLink();

	const slides = useMemo(() => {
		if (loading || !ref.current?.offsetWidth) return [];
		const badgesPerSlide = Math.ceil(ref.current.offsetWidth / cardWidth);
		return getSlides(badgesPerSlide, awards);
	}, [loading]);

	const handleViewDetails = useCallback(
		(id: string) => setCurrentAwardId(id),
		[]
	);

	const pagination: SwiperProps['pagination'] = useMemo(
		() =>
			slides.length > 1
				? {
						clickable: true,
						renderBullet(_: number, className: string) {
							return `<button class='${className}'/>`;
						},
				  }
				: false,
		[slides]
	);
	return (
		<Grid container direction='column'>
			<Grid container direction='row'>
				<Grid xs={12} sm={12} item md={12}>
					<Typography variant='h2'>YOUR BADGES EARNED</Typography>
				</Grid>
				<Grid xs={12} sm={12} item md={12}>
					{Boolean(awards.length) && (
						<ButtonLink variant='text' onClick={openEarnedBadgesDialog}>
							View All
						</ButtonLink>
					)}
					{loading && (
						<Skeleton
							variant='rectangular'
							width={50}
							height={17}
							style={{ margin: 10 }}
						/>
					)}
					<ButtonLink
						variant='text'
						// @ts-ignore
						to={internalLink('give-a-badge')}
						component={Link}
						target='_blank'
					>
						Award Badges
					</ButtonLink>
				</Grid>
			</Grid>
			<Grid sx={{ width: '100%' }} item>
				<Wrapper
					sx={{
						display: 'block',
						overflow: 'hidden',
						maxWidth: '100%',
					}}
					ref={ref}
				>
					{!loading && Boolean(awards.length) && (
						<Swiper pagination={pagination} modules={[Pagination]}>
							{slides.map(slide => (
								<SwiperSlide key={slide.id}>
									<Slide awards={slide.items} onViewDetails={handleViewDetails} />
								</SwiperSlide>
							))}
						</Swiper>
					)}
					{loading && <LoadingSkeleton />}
				</Wrapper>
			</Grid>
		</Grid>
	);
}

function getSlides(
	badgesPerSlide: number,
	awards: Award[]
): Array<{ id: string; items: Award[] }> {
	const result = [];

	for (let i = 0; i < awards.length; i += badgesPerSlide) {
		result.push({
			id: String(i),
			items: awards.slice(i, i + badgesPerSlide),
		});
	}

	return result;
}

function LoadingSkeleton() {
	return (
		<Box display='flex'>
			<Skeleton
				variant='rectangular'
				width={153}
				height={271}
				style={{ margin: 11 }}
			/>
			<Skeleton
				variant='rectangular'
				width={153}
				height={271}
				style={{ margin: 11 }}
			/>
			<Skeleton
				variant='rectangular'
				width={153}
				height={271}
				style={{ margin: 11 }}
			/>
		</Box>
	);
}

type SlideProps = {
	awards: Award[];
	onViewDetails: (awardId: string) => void;
};

function Slide({ awards, onViewDetails }: SlideProps) {
	return (
		<Grid
			sx={{ width: 'auto', flexWrap: 'nowrap', justifyContent: 'center' }}
			justifyContent='center'
			container
		>
			{awards.map(award => (
				<SlideItem key={award.id} item>
					<BadgeCard
						badge={award.badge}
						onViewDetails={() => onViewDetails(award.id)}
					/>
				</SlideItem>
			))}
		</Grid>
	);
}
const SlideItem = styled(Grid)({
	padding: 11,
});

const Wrapper = styled(Box)(({ theme }) => ({
	'& .swiper-pagination-bullets': {
		display: 'flex',
		justifyContent: 'center',
		paddingTop: 38,
	},
	'& .swiper-pagination-bullet': {
		width: 15,
		height: 15,
		backgroundColor: '#D9D9D9',
		borderRadius: '100%',
		border: 0,
		cursor: 'pointer',
		margin: 6,
		transition: theme.transitions.create('background-color', {
			easing: theme.transitions.easing.easeIn,
			duration: theme.transitions.duration.shorter,
			delay: 0,
		}),
		'&:hover, &.swiper-pagination-bullet-active': {
			backgroundColor: theme.palette.primary.main,
		},
	},
}));

const ButtonLink = styled(Button)<OverridableComponentProps<typeof Button>>(
	({ theme }) => ({
		textDecoration: 'underline',
		fontSize: '0.875rem',
		cursor: 'pointer',
		display: 'inline-flex',
		alignItems: 'center',
		textAlign: 'center',
		color: theme.palette.primary.main,
	})
);
