import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { PrizesProvider } from '../../context/Prizes';
import { usePrizes } from '../../hooks/usePrizes';
import { PrizeManagementView } from './PrizeManagementView';
import { RelationshipManagerProvider } from '../../context/RelationshipManager';

export const PRIZE_UPDATE_SUCCESS_MESSAGE =
	'The prize has been successfully updated!';
export const PRIZE_UPDATE_ERROR_MESSAGE =
	'We could not update your prize, please try again later!';
export const PRIZE_CREATE_SUCCESS_MESSAGE =
	'The prize has been successfully created!';
export const PRIZE_CREATE_ERROR_MESSAGE =
	'We could not create your prize, please try again later!';
export const PRIZE_DELETE_SUCCESS_MESSAGE =
	'The prize has been successfully deleted!';
export const PRIZE_DELETE_ERROR_MESSAGE =
	'We could not delete your prize, please try again later!';

export function PrizeManagementPage() {
	return (
		<PrizesProvider>
			<PrizeManagementPageController />
		</PrizesProvider>
	);
}

export function PrizeManagementPageController() {
	const { enqueueSnackbar } = useSnackbar();
	const [showRelationshipManager, setShowRelationshipManager] = useState(false);
	const { prizes: fetchedPrizes, loading, refetch, deleteById } = usePrizes();

	const prizes = useMemo(
		// .concat() use to create a copy of the array and avoid sorting the original array
		() => fetchedPrizes.concat().sort((a, b) => a.points - b.points),
		[fetchedPrizes]
	);

	const handleOnPrizeUpdated = useCallback(() => {
		refetch();
		enqueueSnackbar(PRIZE_UPDATE_SUCCESS_MESSAGE, { variant: 'success' });
	}, []);

	const handleOnPrizeUpdateError = useCallback(() => {
		enqueueSnackbar(PRIZE_UPDATE_ERROR_MESSAGE, { variant: 'error' });
	}, []);

	const handleOnPrizeCreated = useCallback(() => {
		refetch();
		enqueueSnackbar(PRIZE_CREATE_SUCCESS_MESSAGE, { variant: 'success' });
	}, []);

	const handleOnPrizeCreationError = useCallback(() => {
		enqueueSnackbar(PRIZE_CREATE_ERROR_MESSAGE, { variant: 'error' });
	}, []);

	const handleOnPrizeDeleted = useCallback(() => {
		refetch();
		enqueueSnackbar(PRIZE_DELETE_SUCCESS_MESSAGE, { variant: 'success' });
	}, []);

	const handleOnPrizeDeleteError = useCallback(() => {
		enqueueSnackbar(PRIZE_DELETE_ERROR_MESSAGE, { variant: 'error' });
	}, []);

	const openRelationshipManager = useCallback(
		() => setShowRelationshipManager(true),
		[]
	);

	const closeRelationshipManager = useCallback(
		() => setShowRelationshipManager(false),
		[]
	);

	return (
		<RelationshipManagerProvider onUpdate={closeRelationshipManager}>
			<PrizeManagementView
				prizes={prizes}
				loading={loading}
				showRelationshipManager={showRelationshipManager}
				onRemoveListItem={deleteById}
				onPrizeUpdate={handleOnPrizeUpdated}
				onPrizeUpdateError={handleOnPrizeUpdateError}
				onPrizeDelete={handleOnPrizeDeleted}
				onPrizeDeleteError={handleOnPrizeDeleteError}
				onPrizeCreated={handleOnPrizeCreated}
				onPrizeCreationError={handleOnPrizeCreationError}
				openRelationshipManager={openRelationshipManager}
				closeRelationshipManager={closeRelationshipManager}
			/>
		</RelationshipManagerProvider>
	);
}
