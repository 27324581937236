import { Box } from '@mui/material';
import { ContainedPageTemplate } from '../../templates/ContainedPage';
import { BadgeFeed } from './components/BadgeFeed/BadgeFeed';
import { GiveABadgeForm } from './components/GiveABadgeForm';
import { GiveABadgeHero } from './components/GiveABadgeHero';
import { SentBadgeConfirmation } from './components/SentBadgeConfirmation/SentBadgeConfirmation';
import { Badge } from '../../types/Badge';
import { GiveABadgeView } from './types';
import { Recipient } from '../../types/Recipient';
import { BadgeAwardRequest } from '../../types/BadgeAwardRequest';

export type GiveABadgePageViewProps = {
	availableRelationships: Array<{
		text: string;
		value: string;
	}>;
	availableBadges: Badge[];
	availableRecipients: Recipient[];
	loadingBadges?: boolean;
	loadingRecipients?: boolean;
	submittingForm?: boolean;
	view?: GiveABadgeView;
	resetFlow: () => void;
	handleFormSubmit: (values: BadgeAwardRequest) => void;
	handleRecipientQueryChange: (query: string) => void;
	BadgeFeedComponent?: typeof BadgeFeed;
	ConfirmationView?: typeof SentBadgeConfirmation;
	FormView?: typeof GiveABadgeForm;
};

export function GiveABadgePageView({
	availableRelationships,
	availableRecipients,
	availableBadges,
	loadingBadges,
	loadingRecipients,
	submittingForm,
	resetFlow,
	handleFormSubmit,
	handleRecipientQueryChange,
	view = GiveABadgeView.Form,
	BadgeFeedComponent: Feed = BadgeFeed,
	ConfirmationView = SentBadgeConfirmation,
	FormView = GiveABadgeForm,
}: GiveABadgePageViewProps) {
	return (
		<ContainedPageTemplate>
			<GiveABadgeHero />
			<Box sx={{ paddingTop: '42px', paddingBottom: '16px' }} component='main'>
				{view === GiveABadgeView.Form ? (
					<FormView
						submitting={submittingForm}
						loadingBadges={loadingBadges}
						availableBadges={availableBadges}
						loadingRecipients={loadingRecipients}
						availableRecipients={availableRecipients}
						availableRelationships={availableRelationships}
						onSubmit={handleFormSubmit}
						onRecipientQueryChange={handleRecipientQueryChange}
						BadgeFeedComponent={Feed}
					/>
				) : (
					<Box sx={{ paddingBottom: '46px' }}>
						<ConfirmationView onContinue={resetFlow} />
					</Box>
				)}
			</Box>
		</ContainedPageTemplate>
	);
}
