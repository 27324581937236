import { AxiosResponseTransformer } from 'axios';
import { ApiClient } from '../ApiClient';
import { Settings } from '../../types/Settings';
import { extendAxiosTransformer } from '../../utils/extendAxiosTransformer.helper';
import { BrandSettingsDto, BrandSettingsUpdateDto } from './Brands.type';

export class BrandsClient extends ApiClient {
	private baseEndpoint = '/brands';

	updateBrandSettings = (id: string, values: BrandSettingsUpdateDto) =>
		this.protectedHttpClient.patch(this.settingsEndpoint(id), values, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});

	getBrandSettings = (id: string) =>
		this.httpClient.get<Settings>(this.settingsEndpoint(id), {
			transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
				this.httpClient.defaults.transformResponse,
				[BrandsClient.transformSettingsResponse]
			),
		});

	getBrandIdBySubpath = (subpath: string) =>
		this.httpClient.get<string>(this.endpoint(`/${subpath}`));

	private settingsEndpoint(brandId: string): string {
		return this.endpoint(`/${brandId}/settings`);
	}

	private endpoint(path: string): string {
		return this.baseEndpoint.concat(path);
	}

	static transformSettingsResponse(dto: BrandSettingsDto): Settings {
		if (!dto) return {} as unknown as Settings;

		return {
			companyName: dto.name,
			logo: dto.logo,
			primaryColor: dto.primaryColor,
			secondaryColor: dto.secondaryColor,
		};
	}
}
