import { SvgIcon, Typography, styled } from '@mui/material';
import { Fragment, HTMLProps, useCallback, useMemo } from 'react';
import { Color } from '../../../../types/Color';
import { OverridableComponentProps } from '../../../../utils/types';
import { Image } from '../../../../components/Image';

const LINE_BREAK_REGEX = /\r?\n|\r|\n/g;

export type BadgePreviewProps = {
	color: Color;
	iconSrc?: string;
	width?: number;
	height?: number;
	lineHeight?: number;
	title?: string;
};

export function BadgePreview({
	color,
	iconSrc,
	width = 504,
	height = 742,
	lineHeight = 51,
	title = '',
}: BadgePreviewProps) {
	const getId = useCallback((id: string) => `badge_preview-${id}`, []);
	const processedTitle = title.replace('\\n', '\n');

	const calculatedHeight = useMemo(() => {
		const numberOfLines = title.split('\\n').length;
		/**
		 * Calculates the original height + the height of extra lines
		 * Since the original height takes one line into account, it
		 * decreased the number of lines by 1 before multiplying by lineHeight
		 */
		return height + (numberOfLines - 1) * lineHeight;
	}, [lineHeight, height, title]);

	return (
		<SVG
			width={width}
			height={calculatedHeight}
			viewBox={`0 0 ${width} ${calculatedHeight}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='svg-preview'
		>
			<path
				d='M289.932 602.59L363.024 559.537L463.02 587.647L367.436 374L223.141 386.458L289.932 602.59Z'
				fill={color.shade}
			/>
			<path
				d='M300.303 393.591C301.215 393.512 315.694 429.809 332.646 474.655C349.597 519.502 362.605 555.926 361.692 556.005C360.78 556.083 346.301 519.787 329.349 474.94C312.398 430.094 299.39 393.67 300.303 393.591Z'
				fill={color.ribbonSeparator}
			/>
			<path
				d='M230.088 602.59L156.996 559.537L57 587.647L152.584 374L296.879 386.458L230.088 602.59Z'
				fill={color.prominent}
			/>
			<path
				d='M219.717 393.591C218.804 393.512 204.326 429.809 187.374 474.655C170.423 519.502 157.415 555.926 158.327 556.005C159.24 556.083 173.719 519.787 190.671 474.94C207.622 430.094 220.63 393.67 219.717 393.591Z'
				fill={color.prominent}
			/>
			<g filter={`url(#${getId('filter0_d_4_35233')})`}>
				<path
					d='M442.66 104.84L362.34 154.67L373.24 171.31L444.68 136.19L442.66 104.84Z'
					fill={color.prominent}
				/>
				<path
					d='M442.66 104.84L362.34 154.67L351.45 138.04L414.21 91.58L442.66 104.84Z'
					fill={color.shade}
				/>
				<path
					d='M359.67 29.7L313.59 110.89L331.3 120.45L375.17 56.27L359.67 29.7Z'
					fill={color.prominent}
				/>
				<path
					d='M359.67 29.7L313.59 110.89L295.88 101.33L328.2 31.27L359.67 29.7Z'
					fill={color.shade}
				/>
				<path
					d='M406.1 62.23L341.04 129.97L355.83 143.45L414.21 91.58L406.1 62.23Z'
					fill={color.prominent}
				/>
				<path
					d='M406.1 62.23L341.04 129.97L326.26 116.49L375.17 56.27L406.1 62.23Z'
					fill={color.shade}
				/>
				<path
					d='M251.41 0L245.73 92.67L265.93 93.88L277.11 17.82L251.41 0Z'
					fill={color.prominent}
				/>
				<path
					d='M251.41 0L245.73 92.67L225.53 91.46L223.73 14.61L251.41 0Z'
					fill={color.shade}
				/>
				<path
					d='M306.43 8.76001L283.27 98.78L302.9 103.6L328.2 31.27L306.43 8.76001Z'
					fill={color.prominent}
				/>
				<path
					d='M306.43 8.76001L283.27 98.78L263.64 93.95L277.11 17.82L306.43 8.76001Z'
					fill={color.shade}
				/>
				<path
					d='M403.81 364.1L335.83 298.9L321.65 312.94L373.18 371.24L403.81 364.1Z'
					fill={color.prominent}
				/>
				<path
					d='M403.81 364.1L335.83 298.9L350.02 284.87L410.66 334.14L403.81 364.1Z'
					fill={color.shade}
				/>
				<path
					d='M460.26 268.61L368.64 242.89L363.07 261.83L436.87 289.14L460.26 268.61Z'
					fill={color.prominent}
				/>
				<path
					d='M460.26 268.61L368.64 242.89L374.21 223.95L451.59 239.08L460.26 268.61Z'
					fill={color.shade}
				/>
				<path
					d='M438.19 319.92L355.75 273.19L345.59 290.23L410.66 334.14L438.19 319.92Z'
					fill={color.prominent}
				/>
				<path
					d='M438.19 319.92L355.75 273.19L365.9 256.16L436.87 289.14L438.19 319.92Z'
					fill={color.shade}
				/>
				<path
					d='M307.42 421.73L280.5 332.61L259.9 338.79L276.95 413.87L307.42 421.73Z'
					fill={color.prominent}
				/>
				<path
					d='M307.42 421.73L279.23 332.99L298.55 327.2L328.03 398.54L307.42 421.73Z'
					fill={color.shade}
				/>
				<path
					d='M359.21 398.75L309.28 319.57L292.05 329.87L328.03 398.54L359.21 398.75Z'
					fill={color.prominent}
				/>
				<path
					d='M359.21 398.75L309.28 319.57L326.52 309.28L373.25 371.29L359.21 398.75Z'
					fill={color.shade}
				/>
				<path
					d='M465.55 159.23L371.56 174.41L374.9 193.86L453.06 187.02L465.55 159.23Z'
					fill={color.prominent}
				/>
				<path
					d='M465.55 159.23L371.56 174.41L368.21 154.96L444.68 136.19L465.55 159.23Z'
					fill={color.shade}
				/>
				<path
					d='M469.2 213.42L373.91 211.41L373.51 231.12L451.59 239.08L469.2 213.42Z'
					fill={color.prominent}
				/>
				<path
					d='M469.2 213.42L373.91 211.41L374.32 191.7L453.06 187.02L469.2 213.42Z'
					fill={color.shade}
				/>
			</g>
			<g filter={`url(#${getId('filter1_d_4_35233')})`}>
				<path
					d='M61.16 324.9L139.9 274.92L129.2 258.31L60.79 294.2L61.16 324.9Z'
					fill={color.prominent}
				/>
				<path
					d='M61.1602 324.9L139.9 274.92L150.61 291.53L89.0802 338.11L61.1602 324.9Z'
					fill={color.shade}
				/>
				<path
					d='M142.67 399.89L187.78 318.62L170.39 309.09L127.42 373.35L142.67 399.89Z'
					fill={color.prominent}
				/>
				<path
					d='M142.67 399.89L187.78 318.62L205.17 328.15L173.54 398.27L142.67 399.89Z'
					fill={color.shade}
				/>
				<path
					d='M97.0701 367.44L160.83 299.59L146.3 286.13L89.0801 338.11L97.0701 367.44Z'
					fill={color.prominent}
				/>
				<path
					d='M97.0701 367.44L160.83 299.59L175.35 313.05L127.42 373.35L97.0701 367.44Z'
					fill={color.shade}
				/>
				<path
					d='M248.9 429.4L254.38 336.72L234.56 335.55L223.67 411.63L248.9 429.4Z'
					fill={color.prominent}
				/>
				<path
					d='M248.9 429.4L254.38 336.72L269.15 338.73L276.95 413.87L248.9 429.4Z'
					fill={color.shade}
				/>
				<path
					d='M194.92 420.74L217.55 330.68L198.28 325.89L173.54 398.27L194.92 420.74Z'
					fill={color.prominent}
				/>
				<path
					d='M194.92 420.74L217.55 330.68L236.81 335.47L223.67 411.63L194.92 420.74Z'
					fill={color.shade}
				/>
				<path
					d='M98.99 65.57L165.75 130.65L179.65 116.59L129.04 58.38L98.99 65.57Z'
					fill={color.prominent}
				/>
				<path
					d='M98.9901 65.57L165.75 130.65L151.85 144.71L92.3 95.54L98.9901 65.57Z'
					fill={color.shade}
				/>
				<path
					d='M43.71 161.15L133.63 186.72L139.07 167.77L66.34 140.49L43.71 161.15Z'
					fill={color.prominent}
				/>
				<path
					d='M43.71 161.15L133.63 186.72L128.19 205.67L51.85 190.64L43.71 161.15Z'
					fill={color.shade}
				/>
				<path
					d='M65.3101 109.8L146.25 156.39L156.19 139.34L92.3001 95.54L65.3101 109.8Z'
					fill={color.prominent}
				/>
				<path
					d='M65.3101 109.8L146.25 156.39L136.3 173.44L66.3401 140.49L65.3101 109.8Z'
					fill={color.shade}
				/>
				<path
					d='M193.5 7.77002L221.26 96.46L240.21 90.63L223.73 14.61L193.5 7.77002Z'
					fill={color.prominent}
				/>
				<path
					d='M193.5 7.77002L221.26 96.46L202.3 102.29L173.3 30.99L193.5 7.77002Z'
					fill={color.shade}
				/>
				<path
					d='M142.71 30.84L191.78 109.93L208.68 99.61L173.3 30.99L142.71 30.84Z'
					fill={color.prominent}
				/>
				<path
					d='M142.71 30.84L191.78 109.93L174.88 120.26L128.97 58.33L142.71 30.84Z'
					fill={color.shade}
				/>
				<path
					d='M38.6399 270.54L130.84 255.2L127.54 235.76L50.4099 242.16L38.6399 270.54Z'
					fill={color.prominent}
				/>
				<path
					d='M38.6399 270.54L130.84 255.2L132.88 267.18L60.7899 294.2L38.6399 270.54Z'
					fill={color.shade}
				/>
				<path
					d='M35 216.36L128.49 218.21L128.86 198.5L51.85 190.64L35 216.36Z'
					fill={color.prominent}
				/>
				<path
					d='M35 216.36L128.49 218.21L128.12 237.92L50.41 242.16L35 216.36Z'
					fill={color.shade}
				/>
			</g>
			<path
				d='M350.267 310.551C403.91 256.908 403.91 169.937 350.267 116.294C296.625 62.6519 209.653 62.6519 156.011 116.294C102.368 169.937 102.368 256.908 156.011 310.551C209.653 364.193 296.625 364.193 350.267 310.551Z'
				fill={`url(#${getId('gradient')})`}
			/>
			<path
				d='M346.99 119.57C334.8 107.38 320.61 97.81 304.8 91.13C288.44 84.21 271.06 80.7 253.14 80.7C235.22 80.7 217.84 84.21 201.48 91.13C185.68 97.81 171.48 107.38 159.3 119.57C147.11 131.76 137.54 145.95 130.86 161.76C123.94 178.12 120.43 195.5 120.43 213.42C120.43 231.34 123.94 248.72 130.86 265.08C137.54 280.88 147.11 295.08 159.3 307.26C171.49 319.45 185.68 329.02 201.48 335.7C217.84 342.62 235.22 346.13 253.14 346.13C271.06 346.13 288.44 342.62 304.8 335.7C320.6 329.02 334.8 319.45 346.99 307.26C359.18 295.07 368.75 280.88 375.43 265.08C382.35 248.72 385.86 231.34 385.86 213.42C385.86 195.5 382.35 178.12 375.43 161.76C368.75 145.96 359.18 131.76 346.99 119.57ZM253.15 344.36C180.95 344.36 122.21 285.62 122.21 213.42C122.21 141.22 180.95 82.48 253.15 82.48C325.35 82.48 384.09 141.22 384.09 213.42C384.09 285.62 325.35 344.36 253.15 344.36Z'
				fill={color.medalBorder}
			/>
			<g id={getId('text')}>
				<BadgeTitle y={689}>{processedTitle}</BadgeTitle>
			</g>
			{iconSrc != null && (
				<g id={getId('icon')}>
					<BadgeIcon y={123} iconSrc={iconSrc} />
				</g>
			)}
			<path
				d='M0.666667 647C0.666667 654.364 6.6362 660.333 14 660.333C21.3638 660.333 27.3333 654.364 27.3333 647C27.3333 639.636 21.3638 633.667 14 633.667C6.6362 633.667 0.666667 639.636 0.666667 647ZM476.667 647C476.667 654.364 482.636 660.333 490 660.333C497.364 660.333 503.333 654.364 503.333 647C503.333 639.636 497.364 633.667 490 633.667C482.636 633.667 476.667 639.636 476.667 647ZM14 649.5H490V644.5H14V649.5Z'
				fill={color.separator}
			/>
			<defs>
				<filter
					id={getId('filter0_d_4_35233')}
					x='219.73'
					y='0'
					width='253.47'
					height='429.73'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='4' />
					<feGaussianBlur stdDeviation='2' />
					<feComposite in2='hardAlpha' operator='out' />
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
					/>
					<feBlend
						mode='normal'
						in2='BackgroundImageFix'
						result='effect1_dropShadow_4_35233'
					/>
					<feBlend
						mode='normal'
						in='SourceGraphic'
						in2='effect1_dropShadow_4_35233'
						result='shape'
					/>
				</filter>
				<filter
					id={getId('filter1_d_4_35233')}
					x='31'
					y='7.77002'
					width='249.95'
					height='429.63'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='4' />
					<feGaussianBlur stdDeviation='2' />
					<feComposite in2='hardAlpha' operator='out' />
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
					/>
					<feBlend
						mode='normal'
						in2='BackgroundImageFix'
						result='effect1_dropShadow_4_35233'
					/>
					<feBlend
						mode='normal'
						in='SourceGraphic'
						in2='effect1_dropShadow_4_35233'
						result='shape'
					/>
				</filter>
				<filter
					id='badge_{{slug}}_filter2_d_4_35233'
					x='167.398'
					y='128.416'
					width='175.204'
					height='167.613'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='4' />
					<feGaussianBlur stdDeviation='2' />
					<feComposite in2='hardAlpha' operator='out' />
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
					/>
					<feBlend
						mode='normal'
						in2='BackgroundImageFix'
						result='effect1_dropShadow_4_35233'
					/>
					<feBlend
						mode='normal'
						in='SourceGraphic'
						in2='effect1_dropShadow_4_35233'
						result='shape'
					/>
				</filter>
				<filter
					id='badge_{{slug}}_filter3_d_4_35233'
					x='156.5'
					y='130.844'
					width='195.28'
					height='142.306'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='4' />
					<feGaussianBlur stdDeviation='2' />
					<feComposite in2='hardAlpha' operator='out' />
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
					/>
					<feBlend
						mode='normal'
						in2='BackgroundImageFix'
						result='effect1_dropShadow_4_35233'
					/>
					<feBlend
						mode='normal'
						in='SourceGraphic'
						in2='effect1_dropShadow_4_35233'
						result='shape'
					/>
				</filter>
				<radialGradient
					id={getId('gradient')}
					cx='0'
					cy='0'
					r='1'
					gradientUnits='userSpaceOnUse'
					gradientTransform='translate(253.139 213.422) rotate(45) scale(137.36)'
				>
					<stop stopColor={color.gradient.from} />
					<stop offset='1' stopColor={color.gradient.to} />
				</radialGradient>
			</defs>
		</SVG>
	);
}

export type BadgeTitleProps = HTMLProps<SVGForeignObjectElement> &
	CoordinateAttributes & {
		children: string;
	};

export function BadgeTitle({ children, ...props }: BadgeTitleProps) {
	const lines = children.split(LINE_BREAK_REGEX);

	return (
		<foreignObject
			width='100%'
			height='1'
			style={{
				overflow: 'visible',
				textAlign: 'center',
			}}
			data-testid='text-wrapper'
			{...props}
		>
			<Title component='span'>
				{lines.map((line, i) => (
					// eslint-disable-next-line react/no-array-index-key
					<Fragment key={i}>
						{line}
						<br />
					</Fragment>
				))}
			</Title>
		</foreignObject>
	);
}

type BadgeIconProps = HTMLProps<SVGForeignObjectElement> &
	CoordinateAttributes & {
		iconSrc: string;
	};

function BadgeIcon({ iconSrc, ...props }: BadgeIconProps) {
	return (
		<foreignObject
			width='100%'
			height='1'
			style={{
				overflow: 'visible',
			}}
			{...props}
		>
			<div style={{ width: 181, height: 181, margin: '0 auto' }}>
				<Image src={iconSrc} data-testid='icon' />
			</div>
		</foreignObject>
	);
}

const Title = styled(Typography)<OverridableComponentProps<typeof Typography>>(
	({ theme }) => ({
		fontWeight: 700,
		lineHeight: 1.218333,
		textTransform: 'uppercase',
		fontSize: theme.typography.pxToRem(44.5),
	})
);

const SVG = styled(SvgIcon)({
	width: '100%',
	height: 'auto',
});

type CoordinateAttributes = {
	x?: number | string;
	y?: number | string;
};
