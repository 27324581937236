import { debounce } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { PrizeRedemptionTextSearchableFields } from '../../../../api/PrizeRedemption/PrizeRedemption.type';
import { usePrizeRequestAutocomplete } from '../../../../hooks/usePrizeRequestAutocomplete';
import { DateSearchParameters } from '../../../../types/DateSearchParameters';
import { TextSearchQueryParam } from '../../../../utils/enums/TextSearchQueryParam';
import { NULL_FIELD, TextSearchProps } from '../../../../components/TextSearch';
import { RequestFiltersView } from './RequestFiltersView';
import { Filters } from '../../RedemptionRequests.type';
import { AwardStatuses } from '../../../../types/Award';
import { RedemptionRequestStatus } from '../../../../types/RedemptionRequest';

export const SEARCHABLE_FIELDS = [
	{
		field: PrizeRedemptionTextSearchableFields.FROM_NAME,
		text: 'From',
	},
	{
		field: PrizeRedemptionTextSearchableFields.MANAGER_NAME,
		text: 'Manager',
	},
	{
		field: PrizeRedemptionTextSearchableFields.PRIZE_NAME,
		text: 'Reward',
	},
];

export type PrizeRequestFiltersProps = {
	onFiltersChange?: (filters: Filters) => void;
};

export function PrizeRequestFilters({
	onFiltersChange,
}: PrizeRequestFiltersProps) {
	const [textSearchTerm, setTextSearchTerm] = useState<string>('');
	const [textSearchField, setTextSearchField] = useState<
		PrizeRedemptionTextSearchableFields | undefined
	>(undefined);
	const [filters, setFilters] = useState<Filters>({});
	const { suggestions } = usePrizeRequestAutocomplete(
		textSearchField,
		textSearchTerm
	);

	// Call handler with updated filters
	useEffect(() => {
		if (onFiltersChange) onFiltersChange(filters);
	}, [filters]);

	const updateFilters = useCallback(
		(partialUpdate: Partial<Filters>) =>
			setFilters(currentFilters => ({
				...currentFilters,
				...partialUpdate,
			})),
		[]
	);

	const datePickerFieldHandler =
		(field: keyof DateSearchParameters) => (date: Date | null) => {
			updateFilters({
				[field]: date ? dayjs(date).startOf('day') : undefined,
			});
		};

	const handleTextSearchSubmit: TextSearchProps<PrizeRedemptionTextSearchableFields>['onSubmit'] =
		useCallback(
			debounce(({ field, term }) => {
				updateFilters({
					[TextSearchQueryParam.SearchBy]: field,
					[TextSearchQueryParam.SearchTerm]: term,
				});
			}, 500),
			[]
		);

	const handleFieldChange: TextSearchProps<PrizeRedemptionTextSearchableFields>['onFieldChange'] =
		useCallback((field: string) => {
			if (onFiltersChange && field === NULL_FIELD) {
				updateFilters({
					[TextSearchQueryParam.SearchBy]: undefined,
					[TextSearchQueryParam.SearchTerm]: undefined,
				});
			} else {
				setTextSearchField(field as PrizeRedemptionTextSearchableFields);
			}
		}, []);

	const handleTextTermChange: TextSearchProps<PrizeRedemptionTextSearchableFields>['onTermChange'] =
		useCallback(
			debounce((term: string) => {
				setTextSearchTerm(term);
			}, 200),
			[]
		);

	const handleStatusChange = useCallback(
		(status: RedemptionRequestStatus | 'all') => {
			updateFilters({
				status: status !== 'all' ? status : undefined,
			});
		},
		[]
	);

	return (
		<RequestFiltersView
			suggestions={suggestions}
			searchableFields={SEARCHABLE_FIELDS}
			handleFieldChange={handleFieldChange}
			handleTextTermChange={handleTextTermChange}
			handleTextSearchSubmit={handleTextSearchSubmit}
			handleStatusChange={handleStatusChange}
			datePickerFieldHandler={datePickerFieldHandler}
		/>
	);
}
