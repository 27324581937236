import * as yup from 'yup';

export const validationSchema = yup.object({
	colorId: yup.string().required('You must select a color'),
	name: yup.string().min(3).max(50).required('You must give the badge a name'),
	points: yup
		.number()
		.min(0, 'The badge cannot be worth negative points')
		.max(100, 'The badge cannot exceed 100 points')
		.required('You must choose the badge value'),
});
