import { SvgIcon, SvgIconProps } from '@mui/material';

export function CheckboxCheckedIcon(props?: SvgIconProps) {
	return (
		<SvgIcon
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<rect
				width='19.8309'
				height='18.5215'
				transform='translate(0 0.521484)'
				fill='white'
			/>
			<rect y='0.521484' width='19.8309' height='18.5215' rx='2' fill='#F26A25' />
			<path
				d='M7.80164 13.3248L7.80298 13.3234L4.18824 9.67644C4.06622 9.54915 3.99862 9.3786 4.00002 9.20152C4.00142 9.02444 4.0717 8.85499 4.19573 8.72968C4.31975 8.60438 4.4876 8.53323 4.66311 8.53157C4.83862 8.5299 5.00776 8.59786 5.1341 8.7208L8.74884 12.3685L15.8491 5.20618C15.9109 5.14168 15.9847 5.09023 16.0664 5.05483C16.1481 5.01944 16.2359 5.0008 16.3248 5.00003C16.4137 4.99925 16.5018 5.01633 16.5841 5.05029C16.6663 5.08425 16.7411 5.1344 16.8039 5.19781C16.8668 5.26122 16.9165 5.33663 16.9502 5.41963C16.9838 5.50263 17.0007 5.59156 17 5.68124C16.9992 5.77091 16.9807 5.85953 16.9457 5.94193C16.9106 6.02433 16.8596 6.09885 16.7956 6.16115L9.22143 13.8023C9.0959 13.9289 8.92567 14 8.74817 14C8.57067 14 8.40043 13.9289 8.2749 13.8023L7.80164 13.3248Z'
				fill='white'
			/>
		</SvgIcon>
	);
}
