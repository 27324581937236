import { GridColDef } from '@mui/x-data-grid';
import { AwardStatusesText } from '../../types/Award';
import { DateTimeFormatter } from '../../utils/DateTimeFormatter';
import { AwardSortableFields } from '../../api/Awards/Awards.type';
import { userListCellFormatter } from '../../utils/helpers/toCellFormatter';

export const BADGE_REPORT_COLUMNS: GridColDef[] = [
	badgeReportColumn({
		field: 'sender',
		headerName: 'From',
		valueFormatter: ({ value }) => value.name,
	}),
	badgeReportColumn({
		field: 'users',
		headerName: ' To',
		valueFormatter: userListCellFormatter,
	}),
	badgeReportColumn({
		field: 'manager',
		headerName: 'Manager',
		valueFormatter: ({ value }) => value?.name,
	}),
	badgeReportColumn({
		field: 'badge',
		headerName: 'Badge',
		valueFormatter: ({ value }) => value.name,
	}),
	badgeReportColumn({
		field: 'updatedAt',
		headerName: 'Date',
		valueFormatter: ({ value }) => DateTimeFormatter.format(value),
	}),
	badgeReportColumn({
		field: 'status',
		headerName: 'Approved',
		sortable: false,
		valueFormatter: ({ value }) => AwardStatusesText.get(value),
	}),
];

const fieldSortMap = new Map([
	['sender', AwardSortableFields.FROM_NAME],
	['user', AwardSortableFields.TO_NAME],
	['manager', AwardSortableFields.MANAGER_NAME],
	['badge', AwardSortableFields.BADGE_NAME],
	['updatedAt', AwardSortableFields.UPDATED_AT],
]);

export function mapFieldSort(field: string): string {
	if (fieldSortMap.has(field)) return field;
	return fieldSortMap.get(field) as string;
}

function badgeReportColumn(config?: GridColDef) {
	return {
		sortable: true,
		flex: 1,
		headerAlign: 'center',
		align: 'center',
		...config,
	} as GridColDef;
}
