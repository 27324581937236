import { ComponentThemeOverride } from '../../utils/types';

export const MuiCheckbox: ComponentThemeOverride['MuiCheckbox'] = {
	styleOverrides: {
		root: {
			'&.Mui-disabled': {
				color: '#D9D9D9',
			},
		},
	},
};
