import { Box, BoxProps, Button, styled } from '@mui/material';
import { darken } from '@mui/material/styles';
import { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import { MenuItem } from '../../types/MenuItem';
import { OverridableComponentProps } from '../../utils/types';

export type NavbarProps = BoxProps & {
	menuItems: MenuItem[];
	activePath?: string;
};

export function Navbar({ menuItems, activePath = '', ...props }: NavbarProps) {
	return (
		<Box {...props} component='nav'>
			{menuItems.map(({ text, path }) => {
				const active = Boolean(activePath.length) && path === activePath;
				return (
					<MenuItemButton
						key={path}
						variant={active ? 'outlined' : 'contained'}
						color='primary'
						href={path}
						to={path}
						active={active}
						component={Link}
					>
						{text}
					</MenuItemButton>
				);
			})}
		</Box>
	);
}

const MenuItemButton = styled(Button, {
	shouldForwardProp: propName => propName !== 'active',
})<
	OverridableComponentProps<typeof Button> &
		ComponentProps<typeof Link> & { active?: boolean }
>(({ active, theme }) => ({
	paddingTop: 6,
	paddingBottom: 6,
	backgroundColor: !active
		? darken(theme.palette.primary.main, 0.15)
		: undefined,
	borderColor: !active ? darken(theme.palette.primary.main, 0.15) : undefined,
	'&:hover': {
		backgroundColor: !active
			? darken(theme.palette.primary.main, 0.3)
			: undefined,
		borderColor: !active ? darken(theme.palette.primary.main, 0.3) : undefined,
	},
	'&:not(:last-child)': {
		marginRight: 4,
	},
}));
