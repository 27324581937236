import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  styled,
} from '@mui/material';
import { Formik } from 'formik';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { LoginRequest } from '../../../../types/LoginRequest';
import { LoginFormText } from './LoginForm.text';
import { validationSchema } from './schema';
import { PersistentStorage } from '../../../../classes/PersistentStorage';
import { encryptStorage } from '../../../../utils/storage';

export type LoginFormProps = {
  isSubmitting?: boolean;
  passwordReset?: boolean;
  isResettingPassword?: boolean;
  handleSubmit: (params: LoginRequest) => void;
  onPasswordReset?: (email: string) => void;
  onGoBackToLogin?: () => void;
  onForgotPasswordClick?: () => void;
  storage?: PersistentStorage;
};

export function LoginForm({
  isSubmitting,
  passwordReset,
  isResettingPassword,
  handleSubmit: onSubmit,
  onPasswordReset,
  onGoBackToLogin,
  onForgotPasswordClick,
  storage = new PersistentStorage('bb_auth'),
}: LoginFormProps) {
  const remember = storage.get('remember');
  const [forgotPasswordView, setForgotPasswordView] = useState(false);

  const showForgotPasswordView = useCallback(() => {
    setForgotPasswordView(true);
    if (onForgotPasswordClick) onForgotPasswordClick();
  }, [onForgotPasswordClick]);

  const hideForgotPasswordView = useCallback(() => {
    setForgotPasswordView(false);
    if (onGoBackToLogin) onGoBackToLogin();
  }, [onGoBackToLogin]);
  const initialValues = useMemo(
    () => ({
      email: remember?.email ?? '',
      password: remember?.password ?? '',
      rememberMe: remember?.rememberMe ?? false,
    }),
    [remember]
  );

  return (
    <Formik<LoginRequest>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit, getFieldMeta, getFieldProps }) => {
        const getFieldFeedbackProps = (field: string) => {
          const { error, touched } = getFieldMeta(field);
          const isError = touched && Boolean(error);

          return {
            error: isError,
            helperText: isError ? error : undefined,
          };
        };
        const getFieldCheckBoxProps = (field: string) => {
          const fieldProps = getFieldProps(field);
          return {
            ...fieldProps,
            checked: fieldProps.value,
          };
        };

        const handleResetPasswordButton = (e: MouseEvent) => {
          e.preventDefault();
          if (!(onPasswordReset && values.email.length)) return;
          onPasswordReset(values.email);
        };

        return (
          <form onSubmit={handleSubmit}>
            <Grid container direction='column' spacing={1}>
              {!passwordReset && (
                <Grid item>
                  <TextField
                    type='text'
                    variant='standard'
                    label={LoginFormText.emailLabel}
                    placeholder={LoginFormText.emailPlaceholder}
                    fullWidth
                    {...getFieldProps('email')}
                    {...getFieldFeedbackProps('email')}
                  />
                </Grid>
              )}
              {!forgotPasswordView && (
                <>
                  <Grid item>
                    <TextField
                      type='password'
                      variant='standard'
                      label={LoginFormText.passwordLabel}
                      placeholder={LoginFormText.passwordPlaceholder}
                      fullWidth
                      {...getFieldProps('password')}
                      {...getFieldFeedbackProps('password')}
                    />
                  </Grid>
                  <Grid item>
                    <ForgotPasswordButton variant='text' onClick={showForgotPasswordView}>
                      {LoginFormText.forgotPasswordButton}
                    </ForgotPasswordButton>
                  </Grid>
                </>
              )}
              {!forgotPasswordView && (
                <Grid item>
                  <FormControlLabel
                    control={<Checkbox {...getFieldCheckBoxProps('rememberMe')} />}
                    label={LoginFormText.rememberMe}
                  />
                </Grid>
              )}
              <ButtonGrid style={{ paddingTop: passwordReset ? 0 : undefined }} item>
                {!forgotPasswordView && (
                  <LoadingButton
                    sx={{ maxWidth: 167 }}
                    type='submit'
                    variant='contained'
                    loading={isSubmitting}
                    fullWidth
                  >
                    {LoginFormText.loginButton}
                  </LoadingButton>
                )}
                {forgotPasswordView && !passwordReset && (
                  <LoadingButton
                    variant='contained'
                    loading={isResettingPassword}
                    disabled={
                      getFieldProps('email').value === null ||
                      !getFieldProps('email').value.length
                    }
                    onClick={handleResetPasswordButton}
                    fullWidth
                  >
                    {LoginFormText.resetPasswordButton}
                  </LoadingButton>
                )}
                {forgotPasswordView && (
                  <Button
                    sx={{
                      marginTop: '16px',
                    }}
                    onClick={hideForgotPasswordView}
                    fullWidth
                  >
                    {LoginFormText.goBackToLoginButton}
                  </Button>
                )}
              </ButtonGrid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
}

const ButtonGrid = styled(Grid)(({ theme }) => ({
  '&&': {
    paddingTop: '50px',
  },
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

const ForgotPasswordButton = styled(Button)(({ theme }) => ({
  padding: 0,
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.primary.main,
}));
