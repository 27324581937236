import { PersistentStorage } from '../PersistentStorage';

const STORAGE_TOKEN = 'bb_token_storage';

export class TokenStorage {
	private tokens: Record<string, string> = {};

	// eslint-disable-next-line no-useless-constructor
	constructor(private storage = new PersistentStorage(STORAGE_TOKEN)) {
		this.loadTokensFromStorage();
	}

	setToken = (name: string, value: string | undefined) => {
		if (value == null) {
			this.removeToken(name);
			return;
		}
		this.tokens[name] = value;
		this.storage.set(name, value);
	};

	getAllTokens = () => ({ ...this.tokens });

	getToken = (name: string) => this.tokens[name];

	removeToken = (name: string) => {
		delete this.tokens[name];
		this.storage.delete(name);
	};

	private loadTokensFromStorage() {
		this.tokens = this.storage.getAll();
	}
}
