import { lighten } from '@mui/material/styles';
import { ComponentThemeOverride } from '../../utils/types';

export const MuiButton: ComponentThemeOverride['MuiButton'] = {
	styleOverrides: {
		root: {
			borderRadius: 0,
			padding: 10,
			lineHeight: 1.1875,
			fontWeight: 400,
			boxShadow: 'none',
		},
		iconSizeSmall: ({ theme }) => ({
			'& > *:nth-of-type(1)': {
				fontSize: theme.typography.pxToRem(12),
			},
		}),
		contained: ({ theme }) => ({
			borderWidth: 1,
			borderStyle: 'solid',
			'&.Mui-disabled': {
				borderColor: lighten(theme.palette.primary.light, 0.5),
				backgroundColor: lighten(theme.palette.primary.light, 0.5),
				color: theme.palette.common.white,
			},
		}),
		containedPrimary: ({ theme }) => ({
			borderColor: theme.palette.primary.main,
			'&:hover': {
				borderColor: theme.palette.primary.dark,
			},
		}),
		containedSecondary: ({ theme }) => ({
			borderColor: theme.palette.secondary.main,
			'&:hover': {
				borderColor: theme.palette.secondary.dark,
			},
		}),
		containedError: ({ theme }) => ({
			borderColor: theme.palette.error.main,
			'&:hover': {
				borderColor: theme.palette.error.dark,
			},
			'&.Mui-disabled': {
				borderColor: theme.palette.error.light,
				backgroundColor: theme.palette.error.light,
				color: theme.palette.common.white,
			},
		}),
		textPrimary: ({ theme }) => ({
			textTransform: 'none',
			color: theme.palette.common.black,
			'&:hover': {
				textDecoration: 'underline',
			},
		}),
		textSecondary: ({ theme }) => ({
			textTransform: 'none',
			color: theme.palette.common.white,
			'&:hover': {
				textDecoration: 'underline',
			},
		}),
	},
};
