import { LoadingButton } from '@mui/lab';
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { FieldLabel } from '../../../../components/FieldLabel';
import { Fieldset } from '../../../../components/Fieldset';
import { useReportSettings } from '../../../../hooks/useReportSettings';
import { useScheduleReportFrequencies } from '../../../../hooks/useScheduleReportFrequencies';

export const SAVE_BUTTON_TEXT = 'Save automatic reporting';
export const CLOSE_BUTTON_TEXT = 'Close window';

export type ReportSchedulingProps = {
	onCloseButtonClick?: () => void;
	onUpdate?: () => void;
};

export function ReportScheduling({
	onCloseButtonClick,
	onUpdate,
}: ReportSchedulingProps) {
	const useReportSettingsConfig = useMemo(
		() => ({
			onUpdate,
		}),
		[onUpdate]
	);
	const { settings, updating, update } = useReportSettings(
		useReportSettingsConfig
	);
	const { schedulingFrequencies: frequencyOptions } =
		useScheduleReportFrequencies();
	const [reportSchedule, setReportSchedule] = useState<string>();

	const save = useCallback(() => update({ reportSchedule }), [reportSchedule]);

	const handleScheduleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setReportSchedule(e.target.value);
		},
		[]
	);

	const fieldValue: string = useMemo(
		() => reportSchedule ?? settings?.reportSchedule ?? frequencyOptions[0]?.id,
		[settings, frequencyOptions, reportSchedule]
	);

	const saveButtonEnabled = useMemo(
		() =>
			Boolean(
				reportSchedule &&
					settings?.reportSchedule &&
					reportSchedule !== settings.reportSchedule
			),
		[settings, reportSchedule]
	);

	return (
		<>
			<Typography
				sx={theme => ({
					fontSize: theme.typography.pxToRem(14),
				})}
			>
				The following view allows you to set reports to mailed automatically to all
				employees listed as managers.
			</Typography>
			<Fieldset sx={{ marginTop: '16px', marginBottom: '32px' }}>
				<FieldLabel id='report_scheduling-frequency_selector'>
					Report Frequency
				</FieldLabel>
				<TextField
					SelectProps={{
						id: 'report_scheduling-frequency_selector',
						inputProps: {
							'aria-labelledby': 'report_scheduling-frequency_selector',
						},
					}}
					sx={{
						maxWidth: '300px',
					}}
					key={settings?.reportSchedule}
					value={fieldValue}
					onChange={handleScheduleChange}
					fullWidth
					select
				>
					{frequencyOptions.map(({ id, name }) => (
						<MenuItem key={id} value={id}>
							{name}
						</MenuItem>
					))}
				</TextField>
			</Fieldset>
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<Button
					sx={{ marginRight: '24px' }}
					variant='contained'
					color='secondary'
					onClick={onCloseButtonClick}
				>
					{CLOSE_BUTTON_TEXT}
				</Button>
				<LoadingButton
					variant='contained'
					color='primary'
					disabled={!saveButtonEnabled}
					loading={updating}
					onClick={save}
				>
					{SAVE_BUTTON_TEXT}
				</LoadingButton>
			</Box>
		</>
	);
}
