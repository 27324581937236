import * as yup from 'yup';
import { DEFAULT_SELECTED_PRIZE } from './PrizeRedemptionForm.config';

const nullPrizeId = DEFAULT_SELECTED_PRIZE.id;

export const validationSchema = yup.object({
	prizes: yup
		.array()
		.of(yup.string())
		.test(
			'At least one selected prize',
			'At least one prize must be selected',
			value => Boolean(value && value.some(id => nullPrizeId !== id))
		),
	donateToCharity: yup.boolean(),
});
