/* eslint-disable no-shadow */

export enum Action {
	Manage = 'manage',
	Create = 'create',
	Read = 'read',
	Update = 'update',
	Delete = 'delete',
	ReadSelf = 'readSelf',
	Approve = 'approve',
	Deny = 'deny',
	Report = 'report',
}

export enum ActionSubject {
	Badge = 'badges',
	BadgeIcon = 'badgeIcons',
	Color = 'colors',
	Prize = 'prizes',
	RecipientRelationship = 'recipientsRelationship',
	PrizeRedemption = 'redeems',
	BadgeAward = 'requests',
	User = 'users',
	Score = 'score',
	All = 'all',
	Settings = 'settings',
}
