import * as yup from 'yup';

export const validationSchema = yup.object({
	badgeId: yup.string().required('You must select a badge'),
	recipients: yup.array().min(1, 'You must choose at least one recipient for the award').required(),
	senderFullName: yup.string().required('You must provide your name'),
	senderRelationShipToRecipient: yup
		.string()
		.required('Please select your relationship to the recipient'),
	message: yup.string().required('Please write a message justifying the award'),
});
