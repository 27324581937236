/* eslint-disable react/no-array-index-key */
import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	Grid,
	TextField,
	debounce,
} from '@mui/material';
import {
	DataGrid,
	GridCallbackDetails,
	GridColDef,
	GridEventListener,
	GridSortModel,
} from '@mui/x-data-grid';
import { ComponentType, useCallback, useState } from 'react';
import { Can } from '../../components/Can';
import { TextSearch, TextSearchProps } from '../../components/TextSearch';
import { AdminPage } from '../../templates/AdminPage';
import { Action, ActionSubject } from '../../utils/enums/abilities';
import { AddUserDialog } from './components/AddUserDialog';

export const PAGE_TITLE =
	'You can create, new users and specify roles for them inside of the system.';

function NameColumn(props: { value: string }) {
	const { value } = props;
	const names = value.split(' ');

	if (names.length > 1) {
		return (
			<div>
				{names.map((name: string, index: number) => (
					<Box key={index}>
						{name}
						<br />
					</Box>
				))}
			</div>
		);
	}

	return <div>{value}</div>;
}

const DATA_GRID_STYLE = {
	'& .MuiDataGrid-row:hover': {
		cursor: 'pointer',
	},
};

const GRID_COLUMNS: GridColDef[] = [
	{
		field: 'name',
		headerName: 'NAME',
		sortable: true,
		headerAlign: 'center',
		align: 'center',
		minWidth: 200,
		renderCell: params => <NameColumn value={params.value} />,
	},
	{
		field: 'email',
		headerName: 'EMAIL',
		sortable: true,
		flex: 1,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'position',
		headerName: 'POSITION',
		sortable: true,
		flex: 1,
		headerAlign: 'center',
		align: 'center',
	},
	{
		field: 'manager',
		headerName: 'MANAGER',
		sortable: true,
		flex: 1,
		headerAlign: 'center',
		align: 'center',
		renderCell: params => <NameColumn value={params.value} />,
	},
	{
		field: 'badgepoints',
		headerName: 'BADGE/POINTS',
		sortable: false,
		flex: 1,
		headerAlign: 'center',
		align: 'center',
	},
];

export type UserManagement = {
	id: string;
	name: string;
	email: string;
	position: string;
	manager: string;
	badgepoints: string;
};

export type UserManagementViewProps = {
	users: UserManagement[];
	page: number;
	totalRows: number;
	pageSize: number;
	userAutocompleteOptions: string[];
	searchInputValue: string;
	userTypeValue: string;
	loading?: boolean;
	LoadingIndicator?: ComponentType;
	handlePageChange: (page: number) => void;
	handleUserSearchTermChange: (term: string) => void;
	handleChangeUserTypeValue: (event: any, newInputValue: any) => void;
	handleColumnHeaderClick:
		| ((model: GridSortModel, details: GridCallbackDetails<any>) => void)
		| undefined;
	getSortModel: () => GridSortModel;
	onCloseDialog: (refresh: boolean) => void;
	onOpenManualUserPointsClick?: () => void;
};

export function UserManagementView({
	users,
	loading,
	page,
	totalRows,
	pageSize,
	userTypeValue,
	userAutocompleteOptions,
	LoadingIndicator = CircularProgress,
	handlePageChange,
	handleUserSearchTermChange,
	handleChangeUserTypeValue,
	handleColumnHeaderClick,
	getSortModel,
	onCloseDialog,
	onOpenManualUserPointsClick,
}: UserManagementViewProps) {
	const [showAddUserDialog, setShowAddUserDialog] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState<string | undefined>();

	const openAddUserDialog = useCallback(() => setShowAddUserDialog(true), []);

	const closeAddUserDialog = useCallback(
		(refresh: boolean) => {
			if (showAddUserDialog) setShowAddUserDialog(false);
			if (selectedUserId) setSelectedUserId(undefined);
			onCloseDialog(refresh);
		},
		[showAddUserDialog, selectedUserId]
	);

	const handleRowClick: GridEventListener<'rowClick'> = useCallback(
		({ row }) => {
			setSelectedUserId(row.id);
		},
		[]
	);

	const handleSearchTermChange: TextSearchProps['onTermChange'] = useCallback(
		debounce(handleUserSearchTermChange, 300),
		[handleUserSearchTermChange]
	);

	return (
		<>
			<AdminPage title={PAGE_TITLE}>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
					}}
					component='main'
				>
					<Grid container display='block' spacing={3}>
						<Grid style={{ height: 'fit-content'}} item>
							<Grid container spacing={2}>
								<Grid item xs={12} md={4}>
									<TextSearch
										onSubmit={() => {}}
										autocompleteOptions={userAutocompleteOptions}
										onTermChange={handleSearchTermChange}
										disableFieldSelection
									/>
								</Grid>
								<Grid item xs={12} md={3}>
									<Autocomplete
										options={[
											'Employee',
											'Manager',
											'Authorizer',
											'TenantAdmin',
											'Admin',
										]}
										loading={false}
										value={userTypeValue}
										onChange={handleChangeUserTypeValue}
										renderInput={params => <TextField {...params} label='Type' />}
									/>
								</Grid>
								<Grid item xs={12} md style={{ textAlign: 'right' }}>
									<Can do={Action.Create} on={ActionSubject.User}>
										<Button
											variant='contained'
											color='primary'
											sx={{ width: '207px' }}
											onClick={openAddUserDialog}
										>
											Add Person
										</Button>
									</Can>
								</Grid>
								{onOpenManualUserPointsClick && (
									<Grid item xs={12} md style={{ textAlign: 'right' }}>
										<Button
											variant='contained'
											color='primary'
											sx={{ width: '207px' }}
											onClick={onOpenManualUserPointsClick}
										>
											Award Points
										</Button>
									</Grid>
								)}
							</Grid>
						</Grid>
						<Grid item style={{ textAlign: 'center' }}>
							{loading && <LoadingIndicator />}
							{!loading && Boolean(users.length) && (
								<DataGrid
									sx={DATA_GRID_STYLE}
									pageSize={pageSize}
									headerHeight={47}
									rows={users}
									columns={GRID_COLUMNS}
									disableSelectionOnClick
									disableColumnSelector
									disableColumnFilter
									disableColumnMenu
									autoHeight
									page={page}
									onPageChange={handlePageChange}
									onRowClick={handleRowClick}
									rowCount={totalRows}
									rowsPerPageOptions={[2]}
									paginationMode='server'
									sortingMode='server'
									onSortModelChange={handleColumnHeaderClick}
									sortModel={getSortModel()}
								/>
							)}
						</Grid>
					</Grid>
				</Box>
			</AdminPage>
			<AddUserDialog
				title={showAddUserDialog ? 'Add User' : 'Edit User'}
				userId={selectedUserId}
				open={showAddUserDialog || selectedUserId != null}
				onClose={refresh => closeAddUserDialog(refresh)}
			/>
		</>
	);
}
