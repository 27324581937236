import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Users } from '../../api/Users';
import { useAuth } from '../../context/Auth';
import { LoginRequest } from '../../types/LoginRequest';
import { FirstAccessPage } from './FirstAccessPage';
import { LoginPageView } from './LoginPage.view';
import { LoginPageText } from './LoginPageText';
import { useBrandSlug } from '../../context/BrandSlug';

export type LoginPageProps = {
	usersClient?: Users;
};

export function LoginPage({ usersClient = new Users() }: LoginPageProps) {
	const { loggedIn, loginInProcess, user, login } = useAuth();
	const { brandSlug } = useBrandSlug();
	const [resettingPassword, setResettingPassword] = useState(false);
	const [passwordReset, setPasswordReset] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	useEffect(() => {
		if (!(loggedIn && user) || user.firstAccess) return;
		navigate(`/${brandSlug}`);
	}, [loggedIn, user, brandSlug]);

	const resetForgottenPassword = useCallback(
		(email: string) => {
			setResettingPassword(true);
			usersClient
				.resetForgottenPassword(email)
				.then(() => {
					setPasswordReset(true);
				})
				.catch((e: AxiosError) => {
					if (e.response?.status === 404) {
						enqueueSnackbar(LoginPageText.userNotFound, {
							variant: 'warning',
						});

						return;
					}

					enqueueSnackbar(LoginPageText.couldNotResetUserPassword, {
						variant: 'error',
					});
				})
				.finally(() => setResettingPassword(false));
		},
		[usersClient]
	);

	const handleGoBackToLoginClick = useCallback(
		() => setPasswordReset(false),
		[]
	);

	const handleLogin = useCallback((params: LoginRequest) => login(params), []);

	return (
		<>
			{!loggedIn && (
				<LoginPageView
					passwordReset={passwordReset}
					isSubmitting={loginInProcess}
					isResettingPassword={resettingPassword}
					onLogin={handleLogin}
					onPasswordReset={resetForgottenPassword}
					onGoBackToLogin={handleGoBackToLoginClick}
				/>
			)}
			{loggedIn && user?.firstAccess && (
				<FirstAccessPage
					user={user}
					isSubmitting={loginInProcess}
					onFirstAccess={handleLogin}
				/>
			)}
		</>
	);
}
