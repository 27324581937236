import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { RecipientRelationships } from '../../api/RecipientRelationships';
import { useApiCall } from '../useApiCall';

export const RELATIONSHIPS_FETCH_ERROR = 'Could not fetch relationships!';

export type UseRelationshipsConfig = {
	relationshipsClient?: RecipientRelationships;
	onRejection?: (e: AxiosError) => {};
};

const DEFAULT_CONFIG = {};

export function useRelationships(
	config: UseRelationshipsConfig = DEFAULT_CONFIG
) {
	const { enqueueSnackbar } = useSnackbar();
	const apiClient = useMemo(
		() => config.relationshipsClient ?? new RecipientRelationships(),
		[]
	);
	const apiParams: [] = useMemo(() => [], []);
	const handleRejection = useCallback(
		(e: AxiosError) => {
			if (config.onRejection) config.onRejection(e);
			enqueueSnackbar(RELATIONSHIPS_FETCH_ERROR, { variant: 'success' });
		},
		[config.onRejection]
	);

	const apiRequestConfig = useMemo(
		() => ({
			onRejection: handleRejection,
		}),
		[handleRejection]
	);
	const {
		data: relationships = [],
		loading,
		refetch,
	} = useApiCall(
		apiClient.getAvailableRelationships,
		apiParams,
		apiRequestConfig
	);

	return {
		loading,
		relationships,
		refetch,
	};
}
