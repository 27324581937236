import { AxiosError } from 'axios';
import { createContext } from 'react';
import { UserTally } from '../../types/UserTally';

export type OwnTallyContextObj = {
	tally: UserTally;
	loading: boolean;
	error: AxiosError | undefined;
	fetchTallyUpdates: () => void;
};

export const OwnTallyContext = createContext<OwnTallyContextObj | undefined>(
	undefined
);
