import { UsersProvider } from '../../context/Users/UsersProvider';
import { ManagerReport } from './ManagerReport';

export function ManagerReportPage() {
	return (
		<UsersProvider>
			<ManagerReport />
		</UsersProvider>
	);
}
