import { useState } from 'react';

export enum QueryOrder {
	ASCENDANT = 'asc',
	DESCENDANT = 'desc',
}

type UsePaginationProps<T> = {
	initialPage?: number;
	initialPageSize?: number;
	initialOrder?: QueryOrder | null;
	initialSortBy?: T | null;
	initialFilter?: any;
};

export type QueryParams<T> = {
	page?: number;
	limit?: number;
	order?: QueryOrder | null;
	sort?: T | null;
	filter?: string | null;
};

export function usePagination<T>({
	initialPage = 0,
	initialPageSize = 50,
	initialOrder = null,
	initialSortBy = null,
	initialFilter = {},
}: UsePaginationProps<T>) {
	const [page, setPage] = useState(initialPage);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [order, setOrder] = useState<QueryOrder | null>(initialOrder);
	const [sortBy, setSortBy] = useState<T | null>(initialSortBy);
	const [filter, setFilter] = useState<any>(initialFilter);

	const handlePageChange = (pageParam: number) => {
		setPage(pageParam);
	};

	const handleFilterChange = (newFilterParam: any) => {
		setPage(0);
		setFilter({
			...filter,
			...newFilterParam,
		});
	};

	const handleSortBy = (sortParam: T | null) => {
		setPage(0);
		setSortBy(sortParam);
	};

	const handleOrder = (orderParam: QueryOrder | null) => {
		setPage(0);
		setOrder(orderParam);
	};

	const getSortModel = () =>
		sortBy
			? [
					{
						field: sortBy,
						sort: order,
					},
			  ]
			: [];

	function convertObjectToFilter(obj: object): string | null {
		const pairs = Object.entries(obj)
			.filter(([key, value]) => value !== null)
			.map(([key, value]) => `${key}:${value}`);

		if (pairs.length === 0) {
			return null;
		}

		return pairs.join(',');
	}

	const getQueryParams = () => ({
		page,
		limit: pageSize,
		order,
		sort: sortBy,
		filters: filter ? convertObjectToFilter({ ...filter }) : null,
	});

	return {
		page,
		pageSize,
		order,
		sortBy,
		filter,
		getSortModel,
		handleSortBy,
		handleOrder,
		setOrder,
		setSortBy,
		setFilter,
		setPage,
		handleFilterChange,
		getQueryParams,
	};
}
