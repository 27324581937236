import { Box, styled } from '@mui/material';
import { CSSProperties } from 'react';

export type RibbonProps = {
	color?: CSSProperties['background'];
	height?: CSSProperties['height'];
};

export const Ribbon = styled(Box, {
	shouldForwardProp: prop => !['color', 'height'].includes(String(prop)),
})<RibbonProps>(({ color = 'black', height = 5 }) => ({
	flex: 1,
	height,
	width: '100%',
	minHeight: height,
	background: color,
}));
