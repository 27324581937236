import { useCallback } from 'react';
import { usePrizeRedemptionDialogContext } from './PrizeRedemptionDialog.context';
import { PrizeRedemptionDialogController } from './PrizeRedemptionDialogController';
import {
	PrizeRedemptionDialogView,
	PrizeRedemptionDialogViewProps,
} from './PrizeRedemptionDialogView';

export type PrizeRedemptionDialogProps = Omit<
	PrizeRedemptionDialogViewProps,
	'view'
>;

export function PrizeRedemptionDialog(props: PrizeRedemptionDialogProps) {
	return (
		<PrizeRedemptionDialogController>
			<View {...props} />
		</PrizeRedemptionDialogController>
	);
}

function View({ onClose, ...props }: PrizeRedemptionDialogProps) {
	const { view, reset } = usePrizeRedemptionDialogContext();

	const handleClose: NonNullable<PrizeRedemptionDialogProps['onClose']> =
		useCallback(
			(...params) => {
				if (onClose) {
					onClose(...params);
					setTimeout(reset, 800);
				}
			},
			[onClose]
		);

	return (
		<PrizeRedemptionDialogView view={view} onClose={handleClose} {...props} />
	);
}
