import { Typography, styled, darken } from '@mui/material';
import { useThemeContext } from '../../context/Theme';
import { OverridableComponentProps } from '../../utils/types';
import { Ribbon } from '../Ribbon';

export type StepHeaderProps = {
	title: string;
	subtitle: string;
};

export function StepHeader({ title, subtitle }: StepHeaderProps) {
	const { settings } = useThemeContext();
	return (
		<Heading variant='h3' component='h2'>
			<Title>{title}</Title>
			<SubtitleSection>
				<Subtitle>{subtitle}</Subtitle>
				<Ribbon
					sx={{
						marginLeft: '8px',
					}}
					color={darken(settings.primaryColor, 0.3)}
					height={5}
				/>
			</SubtitleSection>
		</Heading>
	);
}

const Heading = styled(Typography)<
	OverridableComponentProps<typeof Typography>
>({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
});

const Title = styled('span')(({ theme }) => ({
	marginBottom: 6,
	lineHeight: 1.167,
	color: theme.palette.primary.main,
	fontSize: 'inherit',
	textTransform: 'uppercase',
}));

const SubtitleSection = styled('div')({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
});

const Subtitle = styled('span')({
	fontSize: '0.667em',
	lineHeight: 1.167,
	textTransform: 'uppercase',
});
