import {
	Autocomplete,
	AutocompleteChangeReason,
	Grid,
	IconButton,
	IconButtonProps,
	InputAdornment,
	MenuItem,
	TextField,
	TextFieldProps,
} from '@mui/material';
import { Formik, FormikErrors } from 'formik';
import { ChangeEvent, useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { SearchIcon } from '../../assets/Icons/SearchIcon';
import { AutocompleteChangeReasonEnum } from '../../utils/enums/AutocompleteChangeReason';

type Field = {
	field: string;
	text: string;
};

type NullField = 'NullField';
export const NULL_FIELD: NullField = 'NullField';
const INITIAL_VALUES = {
	term: '',
	field: NULL_FIELD,
};

type FormValues<Fields = string> = {
	term: string;
	field: Fields | NullField;
};

type FormSubmitValues<Fields = string> = {
	term: string;
	field: Fields;
};

export type TextSearchProps<Fields = string> = {
	fields?: Field[];
	autocompleteOptions?: string[];
	minimumTermLength?: number;
	disableFieldSelection?: boolean;
	onSubmit: (values: FormSubmitValues<Fields>) => void;
	onFieldChange?: (field: string) => void;
	onTermChange?: (term: string) => void;
};

export function TextSearch<Fields>({
	fields = [],
	autocompleteOptions = [],
	minimumTermLength = 1,
	disableFieldSelection,
	onFieldChange,
	onSubmit,
	onTermChange,
}: TextSearchProps<Fields>) {
	const handleSubmit = useCallback(
		(values: FormValues<Fields>) => {
			onSubmit(values as FormSubmitValues<Fields>);
		},
		[onSubmit]
	);

	const validationSchema = useMemo(() => {
		const config: Partial<Record<keyof FormValues<Fields>, any>> = {
			term: yup
				.string()
				.min(minimumTermLength, minimumTermLengthMessage(minimumTermLength)),
		};

		if (!disableFieldSelection)
			config.field = yup.string().notOneOf([NULL_FIELD]).required();

		return yup.object(config);
	}, [minimumTermLength]);

	const initialErrors = useMemo(() => {
		const result: FormikErrors<FormValues<Fields>> = {};

		if (minimumTermLength > 0)
			result.term = minimumTermLengthMessage(minimumTermLength);

		return result;
	}, [minimumTermLength]);

	return (
		<Formik<FormValues<Fields>>
			key={minimumTermLength}
			initialValues={INITIAL_VALUES}
			validationSchema={validationSchema}
			initialErrors={initialErrors}
			onSubmit={handleSubmit}
			validateOnMount
			validateOnBlur
		>
			{({
				errors,
				setTouched,
				// eslint-disable-next-line no-shadow
				handleSubmit,
				getFieldMeta,
				getFieldProps,
				setFieldValue,
			}) => {
				const handleTermInputChange = (_: unknown, value: string) => {
					setFieldValue('term', value);
					if (onTermChange) onTermChange(value);
				};

				const getFieldFeedbackProps = (field: string) => {
					const { error, touched } = getFieldMeta(field);
					const hasError = touched && Boolean(error);

					return {
						error: hasError,
						helperText: hasError ? error : undefined,
					};
				};

				const renderRecipientNameInput = (props: TextFieldProps) => (
					<SearchInput
						{...props}
						FormHelperTextProps={{
							...props.FormHelperTextProps,
							style: {
								...props.FormHelperTextProps?.style,
								top: '100%',
								position: 'absolute',
							},
						}}
						{...getFieldFeedbackProps('term')}
						// @ts-ignore
						onBlur={() => setTouched({ field: true })}
						SearchButtonProps={{
							disabled: Boolean(errors.field),
						}}
						label='Search'
					/>
				);

				const handleFieldInputChange = (e: ChangeEvent<HTMLInputElement>) => {
					setFieldValue('field', e.target.value);
					if (onFieldChange) onFieldChange(e.target.value);
				};

				const handleTermChange = (
					_: unknown,
					__: string | null,
					reason: AutocompleteChangeReason
				) => {
					if (reason === AutocompleteChangeReasonEnum.SelectOption) {
						handleSubmit();
					}
				};

				return (
					<Grid component='form' onSubmit={handleSubmit} container>
						{!disableFieldSelection && (
							<Grid
								sx={theme => ({
									[theme.breakpoints.up('md')]: {
										flex: 1,
										paddingRight: '16px',
										maxWidth: '224px',
									},
								})}
								xs={12}
								item
							>
								<TextField
									{...getFieldProps('field')}
									onChange={handleFieldInputChange}
									fullWidth
									select
								>
									<MenuItem key={0} value={NULL_FIELD}>
										View All
									</MenuItem>
									{fields.map(({ field, text }) => (
										<MenuItem key={field} value={field}>
											{text}
										</MenuItem>
									))}
								</TextField>
							</Grid>
						)}
						<Grid
							sx={theme => ({
								[theme.breakpoints.up('md')]: {
									flex: 1,
								},
							})}
							xs={12}
							item
						>
							<Autocomplete
								options={autocompleteOptions}
								onChange={handleTermChange}
								onInputChange={handleTermInputChange}
								renderInput={renderRecipientNameInput}
								disabled={Boolean(errors.field)}
								freeSolo
							/>
						</Grid>
					</Grid>
				);
			}}
		</Formik>
	);
}

function minimumTermLengthMessage(length: number) {
	return `The search term needs to be at least ${length} characters long`;
}

function SearchInput({
	InputProps: ReceivedInputProps,
	SearchButtonProps,
	...props
}: TextFieldProps & {
	SearchButtonProps?: Partial<IconButtonProps>;
}) {
	const InputProps = useMemo(
		() => ({
			...ReceivedInputProps,
			endAdornment: (
				<InputAdornment
					sx={{ padding: 0 }}
					position='end'
					type='submit'
					aria-label='Submit search'
					component={IconButton}
					{...SearchButtonProps}
				>
					<SearchIcon
						sx={theme => ({
							fontSize: theme.typography.pxToRem(18),
							color: theme.palette.primary.main,
						})}
					/>
				</InputAdornment>
			),
		}),
		[ReceivedInputProps]
	);

	return <TextField {...props} InputProps={InputProps} fullWidth />;
}
