import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { Reports } from '../../api/Reports';
import { ReportSettingsUpdate } from '../../types/ReportSettings';
import { useApiCall } from '../useApiCall';

export const REPORT_SETTINGS_UPDATE_SUCCESS_MESSAGE =
	'Report settings updated!';
export const REPORT_SETTINGS_UPDATE_ERROR_MESSAGE =
	'Could not update the report settings';

export type UseReportSettingsConfig = {
	reportsClient?: Reports;
	onUpdate?: () => void;
};

const DEFAULT_CONFIG = {};

export function useReportSettings({
	reportsClient,
	onUpdate,
}: UseReportSettingsConfig = DEFAULT_CONFIG) {
	const { enqueueSnackbar } = useSnackbar();
	const [updating, setUpdating] = useState(false);
	const client = useMemo(() => reportsClient ?? new Reports(), [reportsClient]);
	const { data: settings, loading } = useApiCall(client.fetchReportSettings);

	const update = useCallback(
		(update: ReportSettingsUpdate) => {
			setUpdating(true);
			client
				.updateSettings(update)
				.then(() => {
					if (onUpdate) onUpdate();
					enqueueSnackbar(REPORT_SETTINGS_UPDATE_SUCCESS_MESSAGE, {
						variant: 'success',
					});
				})
				.catch(e => {
					console.error('useReportSettings.update()', e);
					enqueueSnackbar(REPORT_SETTINGS_UPDATE_ERROR_MESSAGE, {
						variant: 'error',
					});
				})
				.finally(() => setUpdating(false));
		},
		[client]
	);

	return {
		loading,
		updating,
		settings,
		update,
	};
}
