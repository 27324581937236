import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { Dialog, DialogProps } from '../../../../components/Dialog';
import { useUsersContext } from '../../../../context/Users';
import { UseApiCallConfig, useApiCall } from '../../../../hooks/useApiCall';
import { DateTimeFormatter } from '../../../../utils/DateTimeFormatter';

const TITLE = 'Transaction History';
const HEADER_HEIGHT = 47;

const ErrorMessages = {
	COULD_NOT_RETRIEVE_TRANSACTION_HISTORY_DATA:
		'Could not get transaction history data!',
};

const COLUMNS: GridColDef[] = [
	{
		field: 'timestamp',
		headerName: 'DATE',
		flex: 1,
		headerAlign: 'center',
		align: 'center',
		sortable: false,
		disableColumnMenu: true,
		valueFormatter: ({ value }) => DateTimeFormatter.format(value),
	},
	{
		field: 'description',
		headerName: 'DESCRIPTION',
		flex: 1,
		headerAlign: 'center',
		align: 'center',
		disableColumnMenu: true,
		sortable: false,
	},
	{
		field: 'points',
		headerName: 'POINTS',
		flex: 1,
		headerAlign: 'center',
		align: 'center',
		sortable: false,
		disableColumnMenu: true,
	},
];

export type TransactionHistoryDialogProps = Omit<DialogProps, 'title'> & {
	userId: string;
	pageSize?: number;
};

export function TransactionHistoryDialog({
	userId,
	pageSize = 25,
	...props
}: TransactionHistoryDialogProps) {
	return (
		<Dialog title={TITLE} {...props}>
			<TransactionHistory userId={userId} pageSize={pageSize} />
		</Dialog>
	);
}

type TransactionHistoryProps = {
	userId: string;
	pageSize?: number;
};

function TransactionHistory({
	userId,
	pageSize = 25,
}: TransactionHistoryProps) {
	const { transactions, loading, page, total, setPage } = useTransactionHistory(
		userId,
		pageSize
	);

	return (
		<DataGrid
			sortingMode='server'
			paginationMode='server'
			pageSize={pageSize}
			rowsPerPageOptions={[pageSize]}
			loading={loading}
			rows={transactions}
			rowCount={total}
			page={page}
			columns={COLUMNS}
			headerHeight={HEADER_HEIGHT}
			onPageChange={setPage}
			disableSelectionOnClick
			disableColumnSelector
			disableColumnFilter
			disableColumnMenu
			autoHeight
		/>
	);
}

function useTransactionHistory(userId: string, pageSize: number) {
	const { enqueueSnackbar } = useSnackbar();
	const { getTransactionHistoryByUserId } = useUsersContext();
	const [page, setPage] = useState(0);
	const apiCallParams: Parameters<typeof getTransactionHistoryByUserId> =
		useMemo(() => [userId, { limit: pageSize, page }], [userId, page, pageSize]);
	const apiCallConfig: UseApiCallConfig = useMemo(
		() => ({
			onRejection: () => {
				enqueueSnackbar(ErrorMessages.COULD_NOT_RETRIEVE_TRANSACTION_HISTORY_DATA);
			},
		}),
		[]
	);
	const { data, loading } = useApiCall(
		getTransactionHistoryByUserId,
		apiCallParams,
		apiCallConfig
	);

	return {
		transactions: data?.results ?? [],
		total: data?.total ?? 0,
		loading,
		page,
		setPage,
	};
}
