import { Box, Grid, MenuItem, TextField } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { PrizeRedemptionTextSearchableFields } from '../../../../api/PrizeRedemption/PrizeRedemption.type';
import { DatePicker } from '../../../../components/DatePicker';
import { TextSearch, TextSearchProps } from '../../../../components/TextSearch';
import { DateSearchParameters } from '../../../../types/DateSearchParameters';
import { RedemptionRequestStatus } from '../../../../types/RedemptionRequest';
import { DateSearchParameter } from '../../../../utils/enums/DateSearchParameter';

export type RequestFiltersViewProps = {
	suggestions: string[];
	searchableFields: TextSearchProps['fields'];
	handleStatusChange: (status: RedemptionRequestStatus | 'all') => void;
	handleFieldChange: (field: string) => void;
	handleTextTermChange: (term: string) => void;
	handleTextSearchSubmit: (values: {
		term: string;
		field: PrizeRedemptionTextSearchableFields;
	}) => void;
	datePickerFieldHandler: (
		field: keyof DateSearchParameters
	) => (date: Date | null) => void;
};

export function RequestFiltersView({
	suggestions,
	searchableFields,
	handleStatusChange,
	handleFieldChange,
	handleTextTermChange,
	handleTextSearchSubmit,
	datePickerFieldHandler,
}: RequestFiltersViewProps) {
	const handleStatusFieldChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			handleStatusChange(e.target.value as RedemptionRequestStatus | 'all');
		},
		[handleStatusChange]
	);

	return (
		<Grid sx={{ marginTop: '8px', marginBottom: '26px' }} container>
			<Grid sx={{ display: 'flex', flexWrap: 'nowrap' }} xs={12} md={6} item>
				<Box
					sx={theme => ({
						width: '100%',
						maxWidth: '120px',
						[theme.breakpoints.up('md')]: { marginRight: '16px' },
					})}
				>
					<TextField
						label='Status'
						defaultValue='all'
						onChange={handleStatusFieldChange}
						fullWidth
						select
					>
						<MenuItem key='all' value='all'>
							All
						</MenuItem>
						{Object.values(RedemptionRequestStatus).map(status => (
							<MenuItem
								sx={{
									textTransform: 'capitalize',
								}}
								key={status}
								value={status}
							>
								{status}
							</MenuItem>
						))}
					</TextField>
				</Box>
				<TextSearch<PrizeRedemptionTextSearchableFields>
					minimumTermLength={3}
					fields={searchableFields}
					autocompleteOptions={suggestions}
					onFieldChange={handleFieldChange}
					onTermChange={handleTextTermChange}
					onSubmit={handleTextSearchSubmit}
				/>
			</Grid>
			<Grid
				sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}
				xs={12}
				md={6}
				item
			>
				<DatePicker
					label='From'
					onChange={datePickerFieldHandler(DateSearchParameter.DateFrom)}
					disableFuture
				/>
				<DatePicker
					label='To'
					WrapperProps={{
						sx: {
							marginLeft: '12px',
						},
					}}
					onChange={datePickerFieldHandler(DateSearchParameter.DateTo)}
					disableFuture
				/>
			</Grid>
		</Grid>
	);
}
