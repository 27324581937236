import type { Badge } from '../Badge';

export type Award = {
	id: string;
	sentAt: Date;
	updatedAt: Date;
	badge: Badge;
	message: string;
	status: AwardStatuses;
	users: AwardUser[];
	sender: Omit<AwardUser, 'manager'>;
	managers?: Array<Omit<AwardUser, 'manager'>>;
};

export type AwardUser = {
	email: string;
	name: string;
	manager?: Omit<AwardUser, 'manager'> | null;
};

// eslint-disable-next-line no-shadow
export enum AwardStatuses {
	Pending,
	Approved,
	Denied,
}

export const AwardStatusesText = new Map([
	[AwardStatuses.Pending, 'Pending'],
	[AwardStatuses.Approved, 'Approved'],
	[AwardStatuses.Denied, 'Denied'],
]);
