import { createTheme, PaletteOptions } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { enUS } from '@mui/x-date-pickers';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import * as components from './components';
import { typography } from './config/typography';

export const getTheme = (paletteOptions: PaletteOptions) => {
	const palette = {
		primary: {
			main: '#F26A25',
		},
		secondary: {
			main: '#545454',
		},
		success: {
			main: '#268D44',
		},
		text: {
			primary: '#000000',
		},
		...paletteOptions,
	};

	return createTheme(
		{
			palette,
			typography,
			components,
		},
		enUS
	);
};
