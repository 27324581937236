import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { DynamicRoutes } from './components/DynamicRoutes';
import { appRoutes, defaultAppRoute } from './config/appRoutes';
import { AdminLinksProvider } from './context/AdminLinks';
import { AuthProvider } from './context/Auth';
import { AbilityProvider } from './context/Authorization';
import { useBrandSlug } from './context/BrandSlug';

export function AppRoutes() {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [searchParams] = useSearchParams();
	const { brandSlug } = useBrandSlug();

	const loginPath = useMemo(() => `/${brandSlug}/login`, [brandSlug]);

	const handleLoginRequired = useCallback(
		(redirectTo?: string) => {
			navigate(
				{
					pathname: loginPath,
					search: redirectTo && `redirectTo=${redirectTo}`,
				},
				{
					replace: true,
				}
			);
		},
		[pathname, loginPath]
	);

	const handleAfterLogin = useCallback(
		(firstAccess: boolean) => {
			const redirectTo = searchParams.get('redirectTo');
			navigate({
				pathname: (firstAccess ? loginPath : redirectTo) || `/${brandSlug}`,
				search: firstAccess && redirectTo ? `?redirectTo=${redirectTo}` : undefined,
			});
		},
		[searchParams, loginPath, brandSlug]
	);

	return (
		<AuthProvider
			onLoginRequired={handleLoginRequired}
			onAfterLogin={handleAfterLogin}
		>
			<AbilityProvider>
				<AdminLinksProvider>
					<DynamicRoutes defaultRoute={defaultAppRoute} routes={appRoutes} />
				</AdminLinksProvider>
			</AbilityProvider>
		</AuthProvider>
	);
}
