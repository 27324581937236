import { useMemo } from 'react';
import { BadgeIcons } from '../../api/BadgeIcons';
import { useApiCall } from '../useApiCall';

export type UseBadgeIconsConfig = {
	iconsClient?: BadgeIcons;
	onRejection?: (reason: any) => void;
};

const DEFAULT_CONFIG = {};

export function useBadgeIcons(config: UseBadgeIconsConfig = DEFAULT_CONFIG) {
	const apiClient = useMemo(() => config.iconsClient ?? new BadgeIcons(), []);
	const useApiCallConfig = useMemo(
		() => ({
			onRejection: config.onRejection,
		}),
		[config]
	);
	const params: [] = useMemo(() => [], []);
	const { data: icons = [], loading } = useApiCall(
		apiClient.findAll,
		params,
		useApiCallConfig
	);

	return {
		icons,
		loading,
	};
}
