import { AxiosError } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { Prizes } from '../../api/Prizes';
import { useApiCall } from '../useApiCall';
import { Prize, PrizeUpdate } from '../../types/Prize';

export type UsePrizeConfig = {
	prizesClient?: Prizes;
	onPrizeUpdate?: () => void;
	onPrizeUpdateError?: (e: AxiosError) => void;
	onPrizeDelete?: () => void;
	onPrizeDeleteError?: (e: AxiosError) => void;
};

export type PrizeContextObj = {
	prize: Prize | undefined;
	loading: boolean;
	deleting: boolean;
	updating: boolean;
	updatePrize: (update: PrizeUpdate) => void;
	deletePrize: () => void;
};

export function usePrize(
	prizeId: string,
	{
		prizesClient = new Prizes(),
		onPrizeUpdate,
		onPrizeUpdateError,
		onPrizeDelete,
		onPrizeDeleteError,
	}: UsePrizeConfig = {}
): PrizeContextObj {
	const [deleting, setDeleting] = useState(false);
	const [updating, setUpdating] = useState(false);
	const apiParams: [id: string] = useMemo(() => [prizeId], [prizeId]);
	const { data: prize, loading } = useApiCall(prizesClient.getById, apiParams);

	const deletePrize = useCallback(() => {
		setDeleting(true);
		prizesClient
			.remove(prizeId)
			.then(() => {
				if (onPrizeDelete) onPrizeDelete();
			})
			.catch(e => {
				if (onPrizeDeleteError) onPrizeDeleteError(e);
			})
			.finally(() => setDeleting(false));
	}, [prizeId]);

	const updatePrize = useCallback(
		(update: PrizeUpdate) => {
			setUpdating(true);
			prizesClient
				.updatePrize(prizeId, update)
				.then(() => {
					if (onPrizeUpdate) onPrizeUpdate();
				})
				.catch(e => {
					if (onPrizeUpdateError) onPrizeUpdateError(e);
				})
				.finally(() => setUpdating(false));
		},
		[prizeId]
	);

	return {
		prize,
		loading,
		deleting,
		updating,
		updatePrize,
		deletePrize,
	};
}
