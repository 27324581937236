import { GridSortModel } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UsersProvider } from '../../context/Users/UsersProvider';
import {
	QueryOrder,
	usePagination,
} from '../../hooks/usePagination/usePagination';
import { useUsers } from '../../hooks/useUsers/useUsers';
import { UserSortableFields } from '../../types/User';
import { Roles } from '../../utils/enums/Roles';
import { UserFilters } from '../../utils/helpers/UserFilters';
import { UserManagementView } from './UserManagementView';
import { ManualPointAwardDialog } from './components/ManualPointAwardDialog';

export function UserManagementPage() {
	return (
		<UsersProvider>
			<UserManagementPageController />
		</UsersProvider>
	);
}

export function UserManagementPageController() {
	const { users: fetchedUsers, loading, totalRows, setParams } = useUsers();
	const { users: searchUsers, setParams: setParamsSearch } = useUsers();
	const {
		setPage,
		handleFilterChange,
		handleOrder,
		handleSortBy,
		page,
		filter,
		sortBy,
		order,
		getQueryParams,
		pageSize,
		getSortModel,
	} = usePagination<UserSortableFields>({});
	const [searchInputValue, setSearchInputValue] = useState('');
	const [userTypeValue, setUserTypeValue] = useState('');
	const [showAutocomplete, setShowAutocomplete] = useState(false);
	const [manualPointsDialogVisible, setManualPointsDialogVisible] =
		useState(false);

	useEffect(() => {
		setParams(getQueryParams());
	}, [page, filter, sortBy, order]);

	const refreshUsers = useCallback(() => setParams(getQueryParams()), []);

	const onCloseDialog = useCallback((refresh: boolean) => {
		if (refresh) refreshUsers();
	}, []);

	const users = useMemo(
		() =>
			fetchedUsers.map(user => {
				const { id, name, email, position, managers, requests, points } = user;
				return {
					id,
					name,
					email,
					position,
					manager: managers.length ? managers[0].name : '-',
					badgepoints: `${requests.length}-${points}`,
				};
			}),
		[fetchedUsers]
	);

	const searchInputUsers = useMemo(
		() => (showAutocomplete ? searchUsers.map(user => user.name) : []),
		[searchUsers, showAutocomplete]
	);

	const handleSearchInputValue = (term: string) => {
		handleFilterChange({ name: term || null });
		setSearchInputValue(term);

		if (term.length >= 3) {
			setShowAutocomplete(true);

			const autocompleteFilters = new UserFilters();
			autocompleteFilters.set('name', term);

			setParamsSearch({
				sort: 'name',
				order: 'asc',
				limit: 10,
				page: 0,
				filters: autocompleteFilters.build(),
			});
		} else {
			setShowAutocomplete(false);
		}
	};

	const handleChangeUserTypeValue = (_: any, newInputValue: string) => {
		const roleValues: { [key: string]: string } = {
			Employee: Roles.Employee,
			Manager: Roles.Manager,
			Authorizer: Roles.Authorizer,
			TenantAdmin: Roles.TenantAdmin,
			Admin: Roles.SysAdmin,
		};
		const roleValue = roleValues[newInputValue] || null;
		handleFilterChange({ usertype: roleValue });
		setUserTypeValue(newInputValue);
	};

	const handleColumnHeaderClick = (model: GridSortModel) => {
		if (model.length) {
			handleSortBy(model[0].field as UserSortableFields);
			handleOrder(model[0].sort as QueryOrder);
		} else {
			handleSortBy(null);
			handleOrder(null);
		}
	};

	const openManualPointsDialog = useCallback(
		() => setManualPointsDialogVisible(true),
		[]
	);
	const closeManualPointsDialog = useCallback(
		() => setManualPointsDialogVisible(false),
		[]
	);

	const onUserPointsSent = useCallback(() => {
		refreshUsers();
		closeManualPointsDialog();
	}, []);

	return (
		<>
			<UserManagementView
				users={users}
				loading={loading}
				handlePageChange={setPage}
				page={page}
				totalRows={totalRows}
				pageSize={pageSize}
				userAutocompleteOptions={searchInputUsers}
				searchInputValue={searchInputValue}
				handleUserSearchTermChange={handleSearchInputValue}
				userTypeValue={userTypeValue}
				handleChangeUserTypeValue={handleChangeUserTypeValue}
				handleColumnHeaderClick={handleColumnHeaderClick}
				getSortModel={getSortModel}
				onCloseDialog={refresh => onCloseDialog(refresh)}
				onOpenManualUserPointsClick={openManualPointsDialog}
			/>
			<ManualPointAwardDialog
				open={manualPointsDialogVisible}
				onClose={closeManualPointsDialog}
				onPointsSent={onUserPointsSent}
			/>
		</>
	);
}
