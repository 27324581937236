import { AxiosResponseTransformer } from 'axios';
import { Recipient } from '../../types/Recipient';
import { ApiClient } from '../ApiClient';
import { extendAxiosTransformer } from '../../utils/extendAxiosTransformer.helper';

export type RecipientDto = {
	id: string;
	name: string;
	position: string;
	username: string;
	email: string;
	points: number;
};

export class Recipients extends ApiClient {
	private baseEndpoint = '/users/findRecipientsByTerm';

	searchByName = (partial: string) =>
		this.httpClient.get<Recipient[]>(this.baseEndpoint, {
			params: {
				term: partial,
			},
			transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
				this.httpClient.defaults.transformResponse,
				[Recipients.transformResponse]
			),
		});

	static transformResponse(data: RecipientDto[]): Recipient[] {
		if (!(data && Array.isArray(data))) return [];
		return data.map(Recipients.recipientFromDto);
	}

	static recipientFromDto(recipientDto: RecipientDto): Recipient {
		return {
			id: recipientDto.id,
			name: recipientDto.name,
			email: recipientDto.email,
		};
	}
}
