import { AxiosResponseTransformer } from 'axios';
import { Icon } from '../../types/Icon';
import { extendAxiosTransformer } from '../../utils/extendAxiosTransformer.helper';
import { ApiClient } from '../ApiClient';

export type BadgeIconDto = {
	id: string;
	name: string;
	assets: {
		full: string;
		thumbnail: string;
	};
};

export class BadgeIcons extends ApiClient {
	private baseEndpoint = '/badge-icons';

	findAll = () =>
		this.protectedHttpClient.get<Icon[]>(this.baseEndpoint, {
			transformResponse: extendAxiosTransformer<AxiosResponseTransformer>(
				this.protectedHttpClient.defaults.transformResponse,
				[BadgeIcons.transformResponse]
			),
		});

	static transformResponse(data: BadgeIconDto[]) {
		if (!(data && Array.isArray(data))) return [];
		return data.map(BadgeIcons.iconFromIconDto);
	}

	static iconFromIconDto(dto: BadgeIconDto): Icon {
		return {
			id: dto.id,
			name: dto.name,
			assets: {
				full: dto.assets.full,
				thumbnail: dto.assets.thumbnail,
			},
		};
	}
}
