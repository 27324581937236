import { Roles } from '../../../../utils/enums/Roles';

export const assignableRoles: AssignableRole[] = [
	{
		role: Roles.SysAdmin,
		name: 'System Admin',
	},
	{
		role: Roles.Authorizer,
		name: 'Authorizer',
	},
];

export type AssignableRole = {
	role: Roles;
	name: string;
};
