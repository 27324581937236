import { useSnackbar } from 'notistack';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { usePrizeRedemptionContext } from '../../../../context/PrizeRedemption';
import { useOwnUserTally } from '../../../../hooks/useOwnUserTally';
import { usePrizes } from '../../../../hooks/usePrizes';
import {
	PrizeRedemptionDialogContext,
	PrizeRedemptionDialogContextObj,
} from './PrizeRedemptionDialog.context';
import { PrizeRedemptionDialogViews } from './PrizeRedemptionDialog.type';

export const REDEMPTION_REJECTION_ERROR_MESSAGE =
	'Could not redeem your prizes, please try again later!';
export const PRIZES_EXCEED_SCORE_MESSAGE =
	"You don't have enough points to redeem the selected prizes";

const DEFAULT_VIEW = PrizeRedemptionDialogViews.Form;

export type PrizeRedemptionDialogControllerProps = {
	children?: ReactNode;
};

export function PrizeRedemptionDialogController({
	children,
}: PrizeRedemptionDialogControllerProps) {
	const { enqueueSnackbar } = useSnackbar();
	const { prizes, loading: fetchingPrizes } = usePrizes();
	const { tally, loading: fetchingTally, fetchTallyUpdates } = useOwnUserTally();
	const { redeemPrizes } = usePrizeRedemptionContext();
	const [submittingRedemption, setSubmittingRedemption] = useState(false);
	const [redemptionSubmitted, setRedemptionSubmitted] = useState(false);
	const [scoreModifier, setScoreModifier] = useState(0);

	useEffect(() => setScoreModifier(0), [tally]);

	const score = useMemo(
		() => tally.score - scoreModifier,
		[tally, scoreModifier]
	);

	const getTotalCost = useCallback(
		(prizeIds: string[]) =>
			prizes
				.filter(({ id }) => prizeIds.includes(id))
				.reduce((acc, prize) => acc + prize.points, 0),
		[prizes]
	);

	const userHasEnoughPoints = useCallback(
		(selectedPrizeIds: string[]) => getTotalCost(selectedPrizeIds) <= tally.score,
		[tally, getTotalCost]
	);

	const handleSubmit: PrizeRedemptionDialogContextObj['handleSubmit'] =
		useCallback(
			submitPayload => {
				setSubmittingRedemption(true);

				if (!userHasEnoughPoints(submitPayload.selectedPrizeIds)) {
					enqueueSnackbar(PRIZES_EXCEED_SCORE_MESSAGE, { variant: 'error' });
					return;
				}

				setScoreModifier(getTotalCost(submitPayload.selectedPrizeIds));

				redeemPrizes({
					prizeIds: submitPayload.selectedPrizeIds,
					donateToCharity: submitPayload.donateToCharity,
				})
					.then(() => {
						setRedemptionSubmitted(true);
						fetchTallyUpdates();
					})
					.catch(() => {
						enqueueSnackbar(REDEMPTION_REJECTION_ERROR_MESSAGE);
						setScoreModifier(0);
					})
					.finally(() => setSubmittingRedemption(false));
			},
			[userHasEnoughPoints]
		);

	const reset = useCallback(() => setRedemptionSubmitted(false), []);

	const view = useMemo(() => {
		if (redemptionSubmitted) return PrizeRedemptionDialogViews.Confirmation;
		if (!fetchingTally && tally.score <= 0)
			return PrizeRedemptionDialogViews.ZeroPoints;
		return DEFAULT_VIEW;
	}, [tally, fetchingTally, redemptionSubmitted]);

	const value: PrizeRedemptionDialogContextObj = useMemo(
		() => ({
			score,
			fetchingTally,
			fetchingPrizes,
			prizes,
			view,
			submittingRedemption,
			handleSubmit,
			reset,
		}),
		[
			prizes,
			fetchingPrizes,
			score,
			fetchingTally,
			view,
			submittingRedemption,
			handleSubmit,
		]
	);

	return (
		<PrizeRedemptionDialogContext.Provider value={value}>
			{children}
		</PrizeRedemptionDialogContext.Provider>
	);
}
