import { Grid, Typography, debounce, styled } from '@mui/material';
import {
	HTMLProps,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import greatJobArt from '../../../../assets/img/give-a-badge-hero-great-job.svg';
import { AppLogo } from '../../../../components/AppLogo';
import { OverridableComponentProps } from '../../../../utils/types';
import { fluidFontSize } from '../../../../utils/fluidFontSize';

const BASE_FONT_SIZE = 12;
const BASE_HERO_WIDTH = 975;

export function GiveABadgeHero() {
	const wrapper = useRef<HTMLElement | null>(null);
	const [baseFontSize, setBaseFontSize] = useState(12);

	const onResize = useCallback(
		debounce(entries => {
			const lastEntry = entries[entries.length - 1];

			setBaseFontSize(
				fluidFontSize(
					BASE_FONT_SIZE,
					BASE_HERO_WIDTH,
					lastEntry.borderBoxSize[0].inlineSize
				)
			);
		}, 10),
		[]
	);
	const resizeObserver = useMemo(() => new ResizeObserver(onResize), []);
	useEffect(() => () => resizeObserver.disconnect(), []);

	useEffect(() => {
		if (wrapper.current) resizeObserver.observe(wrapper.current);
	}, [wrapper.current]);

	return (
		<Wrapper ref={wrapper} fontSize={baseFontSize}>
			<Grid
				container
				sx={theme => ({
					alignItems: 'center',
					[theme.breakpoints.up('md')]: {
						paddingLeft: '4em',
					},
				})}
			>
				<Grid xs={12} sm={4} md={4} item>
					<AppLogo sx={{ marginLeft: '20px' }} />
				</Grid>
				<Grid
					xs={12}
					sm={4}
					md={4}
					item
					sx={theme => ({
						paddingLeft: '5.167em',
						[theme.breakpoints.down('sm')]: {
							fontSize: '0.625rem',
							paddingLeft: 'unset',
						},
					})}
				>
					<CallToAction fontSize={baseFontSize}>
						<Slogan>
							- Now it only takes a moment to let someone know about the great job they
							are doing -
						</Slogan>
						<CallToActionEmphasis fontSize='1.333em' component='b'>
							That kind of appreciation goes a long way!
						</CallToActionEmphasis>
					</CallToAction>
				</Grid>
				<Grid xs={12} sm={4} md={4} item>
					<Art src={greatJobArt} />
				</Grid>
			</Grid>
		</Wrapper>
	);
}

const Wrapper = styled('header', {
	shouldForwardProp: propName => propName !== 'fontSize',
})<HTMLProps<HTMLElement> & { fontSize: number }>(({ fontSize, theme }) => ({
	paddingTop: 10,
	paddingBottom: 10,
	fontSize: theme.typography.pxToRem(fontSize),
}));

const CallToAction = styled(Typography)(({ theme }) => ({
	width: '100%',
	maxWidth: '18.083em',
	lineHeight: 1.25,
	textAlign: 'center',
	textTransform: 'uppercase',
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	fontSize: 'inherit',
	[theme.breakpoints.down('sm')]: {
		maxWidth: 'none',
		margin: '0 auto',
		width: '80%',
	},
}));
const Slogan = styled('span')(({ theme }) => ({
	[theme.breakpoints.down('sm')]: {
		maxWidth: '42ch',
	},
}));

const CallToActionEmphasis = styled(Typography)<
	OverridableComponentProps<typeof Typography>
>(({ theme }) => ({
	marginTop: '1.125em',
	fontWeight: 700,
	display: 'inline-block',
	maxWidth: '17ch',
	[theme.breakpoints.down('sm')]: {
		maxWidth: '28ch',
	},
}));

const Art = styled('img')({
	width: '100%',
	height: 'auto',
});
