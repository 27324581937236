export class BrandIdStorage {
	private brandId?: string;

	private listeners = new Map<Function, (brandId: string) => void>();

	set(brandId: string) {
		this.brandId = brandId;

		this.listeners.forEach(listener => {
			listener(brandId);
		});
	}

	get() {
		return this.brandId;
	}

	subscribe(listener: (brandId: string) => void) {
		if (!this.listeners.has(listener)) {
			this.listeners.set(listener, listener);
		}
	}

	unsubscribe(listener: (brandId: string) => void) {
		if (this.listeners.has(listener)) {
			this.listeners.delete(listener);
		}
	}
}
