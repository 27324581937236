import { useMemo } from 'react';
import { Reports } from '../../api/Reports';
import { useApiCall } from '../useApiCall';

export type UseScheduleReportFrequenciesConfig = {
	reportsClient?: Reports;
};

const DEFAULT_CONFIG = {};

export function useScheduleReportFrequencies({
	reportsClient,
}: UseScheduleReportFrequenciesConfig = DEFAULT_CONFIG) {
	const client = useMemo(() => reportsClient ?? new Reports(), [reportsClient]);
	const { data: schedulingFrequencies = [], loading } = useApiCall(
		client.fetchSchedulingOptions
	);

	return {
		loading,
		schedulingFrequencies,
	};
}
