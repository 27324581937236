import { Typography, styled } from '@mui/material';
import { OverridableComponentProps } from '../../../../utils/types';
import { pluralOrSingularPoints } from '../../../../utils/helpers/pluralOrSingularPoints';

export type VerboseScoreDisplayProps = OverridableComponentProps<
	typeof Typography
> & {
	score?: number;
};

export function VerboseScoreDisplay({
	score = 0,
	...props
}: VerboseScoreDisplayProps) {
	return (
		<Indicator component='span' {...props} role='status'>
			<Part component='span' sx={{ textAlign: 'right' }}>
				You have{' '}
			</Part>
			<Score component='b'>{score}</Score>
			<Part component='span' sx={{ textAlign: 'left' }}>
				{' '}
				{pluralOrSingularPoints(score)}
			</Part>
		</Indicator>
	);
}

const Indicator = styled(Typography)<
	OverridableComponentProps<typeof Typography>
>(({ theme }) => ({
	width: '100%',
	borderRadius: 5,
	padding: '6px 8px',
	textAlign: 'center',
	fontWeight: 700,
	lineHeight: 1.22,
	textTransform: 'uppercase',
	fontSize: theme.typography.pxToRem(12),
	color: theme.palette.common.white,
	flexWrap: 'wrap',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: '#363534',
}));

const Score = styled(Typography)<OverridableComponentProps<typeof Typography>>({
	fontSize: '2.5em',
	fontWeight: 700,
	marginLeft: 8,
	marginRight: 8,
	lineHeight: 1,
});

const Part = styled(Typography)<OverridableComponentProps<typeof Typography>>({
	flex: 1,
	fontWeight: 'inherit',
	fontSize: 'inherit',
});
