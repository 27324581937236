import {
	Box,
	CircularProgress,
	SxProps,
	Theme,
	Typography,
} from '@mui/material';
import { CSSProperties, useMemo } from 'react';

export type SplashScreenProps = {
	opacity?: CSSProperties['opacity'];
	backgroundColor?: CSSProperties['backgroundColor'];
	textColor?: CSSProperties['color'];
};

export function SplashScreen({
	opacity = 1,
	backgroundColor = 'transparent',
	textColor,
}: SplashScreenProps) {
	const wrapperStyles = useMemo(() => {
		const styles: SxProps<Theme> = {
			width: '100vw',
			height: '100vh',
			top: 0,
			left: 0,
			position: 'fixed',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: 999999,
			opacity,
			backgroundColor,
		};

		if (textColor) styles.color = textColor;

		return styles;
	}, [opacity, backgroundColor, textColor]);

	return (
		<Box sx={wrapperStyles}>
			<CircularProgress size={64} />
			<Typography variant='h3' component='p' marginTop={2}>
				Loading...
			</Typography>
		</Box>
	);
}