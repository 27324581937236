import { ComponentThemeOverride } from '../../utils/types';

export const MuiTableRow: ComponentThemeOverride['MuiTableRow'] = {
	styleOverrides: {
		root: {
			'&:last-child > .MuiTableCell-body': {
				borderBottom: 'none',
			},
		},
	},
};
