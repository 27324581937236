import { Box, BoxProps, InputLabel, styled } from '@mui/material';
import {
	DatePickerProps as MuiDatePickerProps,
	DatePicker as MuiDatePicker,
} from '@mui/x-date-pickers';
import { useMemo } from 'react';

export type DatePickerProps = MuiDatePickerProps<Date> & {
	WrapperProps?: Partial<BoxProps>;
};

export function DatePicker({
	label,
	slotProps: receivedSlotProps,
	WrapperProps,
	...props
}: DatePickerProps) {
	const id = useMemo(() => `DatePicker_${Date.now()}`, []);

	const slotProps = useMemo(
		() => ({
			...receivedSlotProps,
			textField: {
				...receivedSlotProps?.textField,
				id,
				style: {
					marginBottom: 0,
				},
			},
		}),
		[receivedSlotProps, id]
	);

	return (
		<Fieldset {...WrapperProps} component='fieldset'>
			<Label htmlFor={id}>{label}</Label>
			<MuiDatePicker {...props} slotProps={slotProps} />
		</Fieldset>
	);
}

const Fieldset = styled(Box)({
	padding: 0,
	margin: 0,
	border: 0,
	display: 'flex',
	alignItems: 'center',
	marginBottom: 16,
	'& .MuiTextField-root': {
		maxWidth: 207,
	},
});

const Label = styled(InputLabel)(({ theme }) => ({
	color: 'inherit',
	paddingRight: 9,
	lineHeight: 1.214,
	fontSize: theme.typography.pxToRem(14),
	fontWeight: theme.typography.fontWeightBold,
}));
