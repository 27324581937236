import { Button, ButtonProps } from '@mui/material';
import { ReactNode } from 'react';
import { useAuth } from '../../context/Auth';

export type SignOutButtonProps = ButtonProps & {
	children?: ReactNode;
};

export function SignOutButton({
	children = 'Sign out',
	...props
}: SignOutButtonProps) {
	const { logout } = useAuth();

	return (
		<Button variant='text' {...props} onClick={logout}>
			{children}
		</Button>
	);
}
