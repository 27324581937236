import * as yup from 'yup';

export const validationSchema = yup.object({
	name: yup
		.string()
		.min(3, 'The prize name must not be shorter than 3 characters')
		.max(150, 'The prize name must not exceed 150 characters')
		.required('You must give the prize a name'),
	points: yup
		.number()
		.min(0, 'The prize cost can not be lower than 0')
		.max(100, 'The prize cost can not exceed 100 points')
		.required('You must assign the prize a cost'),
	donable: yup.boolean(),
});
