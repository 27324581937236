import { ReactNode, useContext, useEffect, useMemo } from 'react';
import { Navigate, NavigateProps } from 'react-router-dom';
import { AbilityContext } from '../../context/Authorization';
import {
	Permission,
	evaluatePermissions,
} from '../../utils/helpers/authorization';

export type PageAuthorizationGuardProps = {
	permissions: Permission[];
	children: ReactNode;
	redirectTo: NavigateProps['to'];
	onUnauthorized?: () => void;
};

export function PageAuthorizationGuard({
	children,
	redirectTo,
	permissions,
	onUnauthorized,
}: PageAuthorizationGuardProps) {
	const ability = useContext(AbilityContext);
	const authorized = useMemo(
		() => evaluatePermissions(ability, permissions),
		[permissions]
	);

	useEffect(() => {
		if (!authorized && onUnauthorized) onUnauthorized();
	}, [authorized, onUnauthorized]);

	if (!authorized) {
		if (onUnauthorized) return null;
		return <Navigate to={redirectTo} replace />;
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
}
