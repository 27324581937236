import { createContext, useContext } from 'react';
import { PrizeRedemption } from '../../api/PrizeRedemption';

export type PrizeRedemptionContextObj = {
	redeemPrizes: PrizeRedemption['redeemPrizes'];
};

export const PrizeRedemptionContext = createContext<
	PrizeRedemptionContextObj | undefined
>(undefined);

export function usePrizeRedemptionContext() {
	const result = useContext(PrizeRedemptionContext);

	if (!result) throw new OutsidePrizeRedemptionContextError();

	return result;
}

export class OutsidePrizeRedemptionContextError extends Error {
	constructor(
		message = 'usePrizeRedemptionContext() cannot be used outside a OutsidePrizeRedemptionContextError'
	) {
		super(message);
	}
}
